import { IconButton, LinearProgress, MenuItem, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IPerson } from '../../interfaces/IPerson';
import { State } from '../../redux/combine_reducers';
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import { DesktopDatePicker, DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import deLocale from "date-fns/locale/de";
import { Link } from '@mui/icons-material';

export interface IProps {
    currentObject: IPerson,
    setCurrentObject: Function;
    enableFullEdit?: boolean;
    children?: string | React.ReactElement;
    disabled?: boolean;
}

const CustomerEdit:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const companyArray = useSelector((state: State) => state.companyArray.storeCompany);
    const genderArray = useSelector((state: State) => state.genderArray.storeGender);
    // Marketing Array
    const sourceArray = useSelector((state: State) => state.sourceArray.storeSources);
    const mediumArray = useSelector((state: State) => state.mediumArray.storeMediums);
    const campaignArray = useSelector((state: State) => state.campaignArray.storeCampaign);
    //
    const leadStateArray = useSelector((state: State) => state.leadStateArray.storeLeadState);
    const leadSourceArray = useSelector((state: State) => state.leadSourceArray.storeLeadSources);

    const handleInput = (event:React.ChangeEvent<HTMLInputElement>) => {
        let splitTest = event.target.name.split("-");
        let attrName = splitTest[1];
        let testVal = {...props.currentObject} as any

        if (attrName in testVal) {
            if (splitTest[0] === "number") {
                //props.setCurrentObject({...props.currentObject, attrName : Number(event.target.value)})
                testVal[attrName] = Number(event.target.value);
            } 
            else if (splitTest[0] === "boolean") {
                //props.setCurrentObject({...props.currentObject, attrName : String(event.target.value) === "true"})
                testVal[attrName] =  String(event.target.value) === "true";
            }
            else {
                //console.log({...props.currentObject, attrName : String(event.target.value)})
                //props.setCurrentObject({...props.currentObject, attrName : String(event.target.value)});
                testVal[attrName] = String(event.target.value);
            }
        } else {
            console.warn(`Can't ${attrName} in Interface`)
        }

        props.setCurrentObject({...testVal})

    }

    const checkString = (possibleString:string|null|undefined|number) => {
        if (possibleString == undefined) {
            return "";
        } else {
            return String(possibleString);
        }
    }

    const genMarkeingTsx = () => {
        if (props.enableFullEdit === undefined || props.enableFullEdit == true) {
            return(
                <>
                    <Typography variant='h6'>Marketing</Typography>
                    <Box sx={{mt: 3}}></Box>

                    <GridContainer>
                        <GridItem sm={12}>
                            <TextField
                                label="Erstellt"
                                size="small"
                                value={(props.currentObject.Lead_Created_at == undefined) ? "" : new Date(props.currentObject.Lead_Created_at).toLocaleString() }
                                fullWidth
                                disabled
                            />
                        </GridItem>

                        <GridItem sm={6}>
                            <TextField
                                type="number"
                                label="ID bei CleverReach"
                                name="number-idLead"
                                size="small"
                                value={checkString(props.currentObject.idLead)}
                                onChange={handleInput}
                                fullWidth
                                disabled={Number(localStorage.getItem('isMarketing')) !== 1 && Number(localStorage.getItem('isAdmin')) !== 1}
                            />
                        </GridItem>

                        <GridItem sm={6}>
                            <TextField
                                select
                                label="utm_source"
                                name="number-idSource"
                                type="number"
                                size="small"
                                value={checkString(props.currentObject.idSource)}
                                onChange={handleInput}
                                fullWidth
                                disabled={Number(localStorage.getItem('isMarketing')) !== 1 && Number(localStorage.getItem('isAdmin')) !== 1}
                            >
                                {sourceArray.map(x => 
                                    <MenuItem value={x.idSource}>
                                        {x.Source}
                                    </MenuItem>
                                )}
                            </TextField>
                        </GridItem>


                        <GridItem sm={6}>
                            <TextField
                                select
                                label="utm_campaign"
                                name="number-idCampaign"
                                type="number"
                                size="small"
                                value={checkString(props.currentObject.idCampaign)}
                                onChange={handleInput}
                                fullWidth
                                disabled={Number(localStorage.getItem('isMarketing')) !== 1 && Number(localStorage.getItem('isAdmin')) !== 1}
                            >
                                {campaignArray.map(x => 
                                    <MenuItem value={x.idCampaign}>
                                        {x.Campaign}
                                    </MenuItem>
                                )}
                            </TextField>
                        </GridItem>

                        <GridItem sm={6}>
                            <TextField
                                select
                                label="utm_medium"
                                name="number-idMedium"
                                type="number"
                                size="small"
                                value={checkString(props.currentObject.idMedium)}
                                onChange={handleInput}
                                fullWidth
                                disabled={Number(localStorage.getItem('isMarketing')) !== 1 && Number(localStorage.getItem('isAdmin')) !== 1}
                            >
                                {mediumArray.map(x => 
                                    <MenuItem value={x.idMedium}>
                                        {x.Medium}
                                    </MenuItem>
                                )}
                            </TextField>
                        </GridItem>
                    </GridContainer>


                    <Box sx={{mt: 5}}></Box>
                    <Typography variant='h6'>Drittquellen</Typography>
                    <Box sx={{mt: 3}}></Box>
                    <GridContainer>
                        <GridItem sm={12}>
                            <Box sx={{display: "flex"}}>
                            <TextField
                                label="Stripe"
                                name="string-StripeId_Customer"
                                size="small"
                                value={checkString(props.currentObject.StripeId_Customer)}
                                onChange={handleInput}
                                fullWidth
                                disabled={localStorage.getItem("isAdmin") == "0"}
                            />
                            <IconButton
                                disabled={props.currentObject.StripeId_Customer === null}
                                onClick={() =>{
                                    window.open(`https://dashboard.stripe.com/customers/${props.currentObject.StripeId_Customer}`, "_blank")
                                }}
                            ><Link /> </IconButton>
                            </Box>
                        </GridItem>
                    </GridContainer>
                </>
            )
        }
    }

    const genMetaDataTsx = () => {
        if (props.enableFullEdit === undefined || props.enableFullEdit == true) {
            return(
                <>
                    <GridItem sm={12}>
                        <TextField
                            select
                            label="Stage"
                            name="number-idLeadState"
                            type="number"
                            size="small"
                            value={String(props.currentObject.idLeadState)}
                            onChange={handleInput}
                            fullWidth
                        >
                            {leadStateArray.map(x => 
                                <MenuItem value={x.idLeadState}>
                                    {x.State}
                                </MenuItem>
                            )}
                        </TextField>
                    </GridItem>

                    <GridItem sm={12}>
                        <TextField
                            select
                            label="Quelle"
                            name="number-idLeadSource"
                            type="number"
                            size="small"
                            value={String(props.currentObject.idLeadSource)}
                            onChange={handleInput}
                            fullWidth
                        >
                            {leadSourceArray.map(x => 
                                <MenuItem value={x.idLeadSource}>
                                    {x.Source}
                                </MenuItem>
                            )}
                        </TextField>
                    </GridItem>
                </>
            )
        }
    }

    return(
        <>
            <Box sx={{mt: 5}}></Box>

            {genMarkeingTsx()}


            <Box sx={{mt: 5}}></Box>
            <Typography variant='h6'>{(props.children === undefined) ? <>Kontakt</> : props.children}</Typography>
            <Box sx={{mt: 3}}></Box>

            <GridContainer>
                {genMetaDataTsx()}

                <GridItem sm={5}>
                    <TextField
                        label="Vorname"
                        name="string-FirstName"
                        size="small"
                        disabled={(props.disabled !== undefined && props.disabled == true)}
                        value={checkString(props.currentObject.FirstName)}
                        error={props.currentObject.FirstName === ""}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>

                <GridItem sm={5}>
                    <TextField
                        label="Nachname"
                        name="string-LastName"
                        size="small"
                        disabled={(props.disabled !== undefined && props.disabled == true)}
                        value={checkString(props.currentObject.LastName)}
                        error={props.currentObject.LastName === ""}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>

                <GridItem sm={2}>
                    <TextField
                        select
                        label="Geschlecht"
                        disabled={(props.disabled !== undefined && props.disabled == true)}
                        name="number-idGender"
                        type="number"
                        size="small"
                        value={String(props.currentObject.idGender)}
                        onChange={handleInput}
                        fullWidth
                    >
                        {genderArray.map(x => 
                            <MenuItem value={x.idGender}>
                                {x.Gender}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>


                <GridItem sm={6}>
                    <TextField
                        type="email"
                        label="Email"
                        name="string-Email"
                        size="small"
                        disabled={(props.disabled !== undefined && props.disabled == true)}
                        value={checkString(props.currentObject.Email)}
                        error={props.currentObject.Email === ""}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>

                <GridItem sm={3}>
                    <TextField
                        select
                        label="Newsletter"
                        name="boolean-hasNewsletter"
                        size="small"
                        value={(props.currentObject.hasNewsletter == true) ? "true" : "false"}
                        onChange={handleInput}
                        fullWidth
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField>
                </GridItem>

                <GridItem sm={3}>
                    <TextField
                        select
                        label="Gesperrt"
                        name="boolean-isBlocked"
                        size="small"
                        value={(props.currentObject.isBlocked == true) ? "true" : "false"}
                        onChange={handleInput}
                        fullWidth
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField>
                </GridItem>


                <GridItem sm={6}>
                    <TextField
                        label="Rufnummer"
                        name="string-PhoneNumber"
                        size="small"
                        value={checkString(props.currentObject.PhoneNumber)}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>

                <GridItem sm={6}>
                    <TextField
                        label="Mobilrufnummer"
                        name="string-MobileNumber"
                        size="small"
                        value={checkString(props.currentObject.MobileNumber)}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>


                <GridItem sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                        <DesktopDatePicker
                            label="Geburtstag"
                            value={(props.currentObject.Birthday == undefined) ? null : props.currentObject.Birthday}
                            openTo="year"
                            onChange={(newValue: Date | null) => { 
                                console.log(typeof newValue);
                                if (newValue === null) {
                                    props.setCurrentObject({ ...props.currentObject, Birthday: newValue })
                                }
                                else {
                                    props.setCurrentObject({ ...props.currentObject, Birthday: newValue })
                                }
                            }}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>
                </GridItem>

                <GridItem sm={6}>
                    <TextField
                        label="Geburtsort"
                        name="string-Birthplace"
                        size="small"
                        disabled={(props.disabled !== undefined && props.disabled == true)}
                        value={checkString(props.currentObject.Birthplace)}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>


                <GridItem sm={12}>
                    <TextField
                        label="Interner Kommentar"
                        name="string-InternComment"
                        size="small"
                        value={checkString(props.currentObject.InternComment)}
                        onChange={handleInput}
                        fullWidth
                        multiline
                        rows={10}
                    />
                </GridItem>


            </GridContainer>
        </>
    )

}
export default CustomerEdit;