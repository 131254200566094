import { AttachEmail, Close } from "@mui/icons-material";
import { Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import React, { useState } from "react";
import { getFetch } from "../../hooks/useFetch";
import { IOffer } from "../../interfaces/IOffer";


interface IProps {
    offerObject?: IOffer;
}


const SendOffer:React.FC<IProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    //
    const [wasTriggerd,setWasTriggerd] = useState(false);
    const [wasSuccessfully,setWasSuccessfully] = useState(false);


    const handleClickSend = () => {
        if (props.offerObject != undefined && props.offerObject.idOffer !== null) {
            getFetch(`/offer/sendAsMail/`,props.offerObject.idOffer,() => {},setWasSuccessfully);
            setWasTriggerd(true);
        }
    }

    return(
        <>

            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <DialogTitle>Angebotsversand an den Kunden</DialogTitle>
                
                <DialogContent>
                    <Collapse in={wasTriggerd}>
                        <Alert
                            severity={wasSuccessfully ? "success" : "error" }

                            sx={{ mb: 2 }}
                        >
                            {wasSuccessfully ? "Die Email wurde erfolgreich versendet!" : "Ein Fehler ist aufgetaucht!" }
                        </Alert>
                    </Collapse>


                    <DialogContentText>
                        Bist du dir sicher, dass du das Angebot an den Kunden senden möchtest?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() =>setIsOpen(false)}>Schließen</Button>
                    <Button disabled={wasTriggerd} onClick={handleClickSend}>Senden</Button>
                </DialogActions>
            </Dialog>

            <IconButton
                sx={{float: "right"}}
                onClick={() => {setWasTriggerd(false); setWasSuccessfully(true); setIsOpen(true)}}
                disabled={props.offerObject == undefined || props.offerObject.idOffer === null || props.offerObject.idOffer <= 0}
            ><AttachEmail/></IconButton>
        </>
    )
}
export default SendOffer;