import { useState,useEffect } from "react";

const bearer_token = localStorage.getItem('bearer_token');

export function deleteFetch(endpoint:string, idToDelete:number | number[], setWasSuccessfullyDeleted:Function) {
    // Settings 
    const requestOptions = {
        method: 'DELETE',
        headers : { "Authorization" : "Bearer " + bearer_token }
    };
    var endpoint = `/api/${endpoint}`

    if (Array.isArray(idToDelete)) {
        idToDelete.map(x => endpoint = endpoint + `/${x}`)
    } else {
        endpoint = endpoint +  `/${idToDelete}`
    }


    fetch(endpoint, requestOptions)
    .then(res => {
        if (res.status === 200) { setWasSuccessfullyDeleted(true);  setWasSuccessfullyDeleted(true);}
        else {throw Error(`ERROR DELETE in ${endpoint}: ${res.status}`)}
    })
    .catch((error) => {
        console.error(error);
        setWasSuccessfullyDeleted(false);
    })
}

export function uploadFetch(
    endpoint:string,
    isNew:boolean, 
    uploadlObject:any, 
    setFunction:Function, 
    setWasSuccessfullyUploaded?: Function,
    setIsLoading?: Function,
    setWasSaved?: Function
) {
    (setIsLoading) && setIsLoading(true);
    // Settings 
    (setIsLoading != undefined) && setIsLoading(true);

    const requestOptions = {
        method: (isNew) ? 'POST' : 'PUT',
        headers: { 'Content-Type': 'application/json', "Authorization" : "Bearer " + bearer_token },
        body: JSON.stringify(uploadlObject)
    };

    fetch(`/api/${endpoint}`, requestOptions)
    .then(res => {
        if (res.status === 200) { return res.json()}
        else {throw Error(`ERROR ${(isNew) ? 'POST' : 'PUT'} in ${endpoint}: ${res.status}`)}
    })
    .then(res => {
        (setWasSuccessfullyUploaded) && setWasSuccessfullyUploaded(true);
        setFunction(res);
        (setIsLoading != undefined) && setIsLoading(false);
        (setWasSaved != undefined) && setWasSaved(true);
    })
    .catch((error) => {
        console.error(error);
        (setWasSuccessfullyUploaded) && setWasSuccessfullyUploaded(false);
        (setIsLoading != undefined) && setIsLoading(false);
        (setWasSaved != undefined) && setWasSaved(true);
    })
}

export function getFetch<S>(endpoint:string,idToGet:number|string|undefined|null,setFunction?:Function,setWasSuccessfully?:Function,forceTry:boolean=false) { 
    let targetEndpoint = `/api/${endpoint}`;
    let tryFetch = true;

    if (forceTry && idToGet == undefined) {
        console.log("NO CHANGE")
    }
    else if (endpoint.includes("/") && !endpoint.includes("?")) {
        targetEndpoint = targetEndpoint + `${idToGet}`
        
        tryFetch = (idToGet !== undefined)
    }

    const requestOptions = {
        headers: { "Authorization" : "Bearer " + bearer_token },
    };


    if (!tryFetch && !forceTry) {
        return null;
    } else {
        fetch(targetEndpoint,requestOptions)
        .then(res => {
            if (res.status === 200) { return res.json()}
            else if (res.status === 204) { return null}
            else {throw Error(`ERROR GET in ${endpoint}: ${res.status}`)}
        })
        .then(res => {
            (setFunction !== undefined) && setFunction(res);
            (setWasSuccessfully !== undefined) && setWasSuccessfully(true);
        })
        .catch((error) => {
            console.error(error);
            (setWasSuccessfully !== undefined) && setWasSuccessfully(false);
        })
    }
}

export function useFetch<S>(endpoint:string,targetId: number | string| null | undefined = undefined,forceNoTargetId: boolean | undefined | null =false) : [S | undefined,Function,Boolean] {
    const [responseValue,setResponseValue] = useState<S>();
    const [wasSuccessfully,setWasSuccessfully] = useState(true);


    const requestOptions = {
        headers: { "Authorization" : "Bearer " + bearer_token },
    };

    useEffect(() => {
        let targetEndpoint = `/api/${endpoint}`;
        let execute = true;
    
        if (endpoint.includes("/") && (forceNoTargetId == undefined || forceNoTargetId === false)) {
            targetEndpoint = targetEndpoint + `${targetId}`
            execute = (targetId != undefined);
        }

        if (execute) {
            fetch(targetEndpoint,requestOptions)
            .then(res => {
                if (res.status === 200) { return res.json()}
                else if (res.status === 204) { return null}
                else {throw Error(`ERROR GET in ${endpoint}: ${res.status}`)}
            })
            .then(res => {
                setResponseValue(res);
            })
            .catch((error) => {
                console.error(error);
                setWasSuccessfully(false);
            })
        }

    },[])


    return [
        responseValue,
        setResponseValue,
        wasSuccessfully
    ]
}
