import { IconButton, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRoomFullObject } from '../../../interfaces/IRoom';
import { staticRentTimeFrameArray } from '../../Rent/TmpRentTimeFrameArray';
import { State } from '../../../redux/combine_reducers';
import GridContainer from '../../core/GridContainer';
import GridItem from '../../core/GridItem';


interface IProps {
    roomObject: IRoomFullObject;
    setRoomObject: Function
}



const RoomEdit:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const locationArray = useSelector((state: State) => state.locationArray.storeLocation);
    const roomTypeArray = useSelector((state: State) => state.roomTypeArray.storeRoomType);
    const timeFrameArray = staticRentTimeFrameArray;


    const handleInput = (event:React.ChangeEvent<HTMLInputElement>) => {
        let splitTest = event.target.name.split("-");
        let attrName = splitTest[1];
        let testVal = {...props.roomObject} as any

        if (attrName in testVal) {
            if (splitTest[0] === "number") {
                //props.setCurrentObject({...props.currentObject, attrName : Number(event.target.value)})
                testVal[attrName] = Number(event.target.value);
            } 
            else if (splitTest[0] === "boolean") {
                //props.setCurrentObject({...props.currentObject, attrName : String(event.target.value) === "true"})
                testVal[attrName] =  String(event.target.value) === "true";
            }
            else {
                console.log({...props.roomObject, attrName : String(event.target.value)})
                //props.setCurrentObject({...props.currentObject, attrName : String(event.target.value)});
                testVal[attrName] = String(event.target.value);
            }
        } else {
            console.warn(`Can't ${attrName} in Interface`)
        }

        props.setRoomObject({...testVal})
    }

    const checkString = (possibleString:string|null|undefined|number) => {
        if (possibleString == undefined) {
            return "";
        } else {
            return String(possibleString);
        }
    }



    return(
        <>
            <GridContainer>
                <GridItem sm={12}>
                    <TextField
                        select
                        label="Gesperrt"
                        name="boolean-isBlocked"
                        size="small"
                        value={(props.roomObject.isBlocked == true) ? "true" : "false"}
                        onChange={handleInput}
                        fullWidth
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField>
                </GridItem>


                <GridItem sm={4}>
                    <TextField
                        select
                        label="Standort"
                        name="number-idLocation"
                        type="number"
                        size="small"
                        value={String(props.roomObject.idLocation)}
                        onChange={handleInput}
                        fullWidth
                        disabled={props.roomObject.idRoom != undefined && props.roomObject.idRoom > 0}
                    >
                        {locationArray.map(x => 
                            <MenuItem value={x.idLocation}>
                                {x.ShortName} - {x.Location}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>

                <GridItem sm={4}>
                    <TextField
                        select
                        label="Raumkategorie"
                        name="number-idRoomType"
                        type="number"
                        size="small"
                        value={String(props.roomObject.idRoomType)}
                        onChange={handleInput}
                        fullWidth
                    >
                        {roomTypeArray.map(x => 
                            <MenuItem value={x.idRoomType}>
                                {x.RoomType}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>

                <GridItem sm={4}>
                    <TextField
                        select
                        label="Atelier"
                        name="boolean-isStudio"
                        size="small"
                        value={(props.roomObject.isStudio == true) ? "true" : "false"}
                        onChange={handleInput}
                        fullWidth
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField>
                </GridItem>


                <GridItem sm={4}>
                    <TextField
                        label="Raumnummer"
                        name="number-RoomNumber"
                        size="small"
                        type="number"
                        value={props.roomObject.RoomNumber}
                        error={props.roomObject.RoomNumber == ""}
                        onChange={handleInput}
                        fullWidth
                        disabled={props.roomObject.idRoom != undefined && props.roomObject.idRoom > 0}
                    />
                </GridItem>

                <GridItem sm={8}>
                    <TextField
                        label="Raumbezeichnung"
                        name="string-Title"
                        size="small"
                        value={checkString(props.roomObject.Title)}
                        error={props.roomObject.Title === ""}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>


                <GridItem sm={6}>
                    <TextField
                        label="Raumgröße"
                        name="number-SquareMeters"
                        size="small"
                        type="number"
                        value={props.roomObject.SquareMeters}
                        error={props.roomObject.SquareMeters === null}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>

                <GridItem sm={6}>
                    <TextField
                        label="Volumen"
                        name="number-Volume"
                        size="small"
                        type="number"
                        value={props.roomObject.Volume}
                        error={props.roomObject.Volume === null}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>


                <GridItem sm={4}>
                    <TextField
                        label="Höhe"
                        name="number-Height"
                        size="small"
                        type="number"
                        value={props.roomObject.Height}
                        error={props.roomObject.Height === null}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>
                <GridItem sm={4}>
                    <TextField
                        label="Breite"
                        name="number-Width"
                        size="small"
                        type="number"
                        value={props.roomObject.Width}
                        error={props.roomObject.Width ===null}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>
                <GridItem sm={4}>
                    <TextField
                        label="Länge"
                        name="number-Length"
                        size="small"
                        type="number"
                        value={props.roomObject.Length}
                        error={props.roomObject.Length === null}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>

                <GridItem sm={6}>
                    <TextField
                        label="Türhöhe (cm)"
                        name="number-DoorHeight"
                        size="small"
                        type="number"
                        value={(props.roomObject.DoorHeight) === null ? "" : props.roomObject.DoorHeight}
                        placeholder='212,5'
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>
                <GridItem sm={6}>
                    <TextField
                        label="Türbreite (cm)"
                        name="number-DoorWidth"
                        size="small"
                        type="number"
                        value={(props.roomObject.DoorWidth) === null ? "" : props.roomObject.DoorWidth}
                        placeholder='100'
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>


                <GridItem sm={12}>
                    <TextField
                        label="Kundenbeschreibung"
                        name="string-CustomerDescription"
                        size="small"
                        value={checkString(props.roomObject.CustomerDescription)} 
                        onChange={handleInput}
                        fullWidth
                        multiline
                        rows={5}
                    />
                </GridItem>
                <GridItem sm={12}>
                    <TextField
                        label="Aktionstext"
                        name="string-ActionText_Temp"
                        size="small"
                        value={checkString(props.roomObject.ActionText_Temp)} 
                        onChange={handleInput}
                        fullWidth
                        multiline
                        rows={5}
                    />
                </GridItem>
            </GridContainer>
        </>
    )

}
export default RoomEdit;