import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../redux/combine_reducers';
import GenericOverview from '../core/GenericOverview';
import RoomType from './RoomType';



const RoomTypes:React.FC = () => {
    const dispatch = useDispatch();
    const roomTypeArray = useSelector((state: State) => state.roomTypeArray.storeRoomType);
    // Selected Id
    const [selectedIdRegion,setSelectedIdRegion] = useState<number|undefined>();

    const columns: GridColDef[] = [
        { 
            field: 'idRoomType', 
            headerName: 'Nr.', 
            width: 90,
        },
        {
            field: 'RoomType',
            headerName: 'Raumart',
            flex: 1,
        },

    ];

    return(
        <GenericOverview
            headline="Raumarten"
            columns={columns}
            data={roomTypeArray}
            idField="idRoomType"
            setTargetId={setSelectedIdRegion}
        >
            <RoomType idRoomType={selectedIdRegion}/>
        </GenericOverview>
    )


}
export default RoomTypes;