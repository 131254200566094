import { TextField } from '@mui/material';
import React, { MutableRefObject, RefObject } from 'react';

interface IProps {
    children?: React.ReactNode
    // Notwendige
    label: string;
    isEdit?: boolean;
    name?: string;
    value?: string | number | null;
    setFunction?: Function;
    isValidated?: boolean;
    type?: "text" | "number" | 'password';
    rows?: number;
    
}


const InputField:React.FC<IProps> = (props) => { 


    const hanndleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        if (props.setFunction !== undefined) {
            if (props.type === undefined || props.type === "text" || props.type === "password" ) {
                props.setFunction(String(event.target.value))
            } else {
                props.setFunction(Number(event.target.value))
            }
        }
    }


    return(
        <TextField
            name={(props.name == undefined) ? undefined : props.name} 
            disabled={(props.isEdit === undefined) ? false :  !(props.isEdit) }
            error={(props.isValidated === undefined  || props.isEdit === undefined ||  props.isEdit == false) ? false : !props.isValidated}
            label={props.label}
            type={(props.type === undefined) ? "text" : props.type}
            fullWidth
            variant="standard"
            onChange={hanndleChange}
            value={(props.value == undefined) ? "" : props.value}
            rows={(props.rows === undefined) ? undefined : props.rows}
            multiline={(props.rows !== undefined)}
            //autoComplete={(props.type !== undefined && props.type === "password") ? "current-password" : undefined }
        />
    )
}
export default InputField;