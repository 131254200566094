import { AddCircle } from '@mui/icons-material';
import { Box, IconButton, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFetch } from '../../hooks/useFetch';
import { IPerson } from '../../interfaces/IPerson';
import { State } from '../../redux/combine_reducers';
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import Customer from '../Customer/Customer';

interface IProps {
    isNew: boolean;
    idPerson: number | null;
    setIdPerson: Function;
    saveCounter: number;
    // Rent state
    idRentState?: number | null;
    // Um im Parent zu visualisieren
    setIsBlockedCompany: Function;
    setIsCreditCheckedCompany: Function;
    // Zur Childvalidierung
    setMetadataPerson: Function;
}


const SelectCustomer:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const companyArray = useSelector((state: State) => state.companyArray.storeCompany);
    // Zur Auswahl
    const [idCompany, setIdCompany] = useState<number | null>((props.isNew) ? -1 : null);
    //const [idPerson, setIdPerson] = useState<number | undefined>(undefined);
    const [personFromCompanyArray,setPersonFromCompanyArray] = useState<IPerson[]>();
    // Metastates
    const [wasSuccessfullyLoad,setWasSuccessfullyLoad] = useState(true);

    useEffect(() => {(props.isNew ) && setIdCompany(-1)},[props.isNew])

    useEffect(() => {
        if (idCompany !== undefined) {
            props.setIdPerson(-1);
            getFetch("person/byCompany/",idCompany,setPersonFromCompanyArray,setWasSuccessfullyLoad);
        }
    },[idCompany])

    if (props.isNew) {
        return(
            <>
                <Box sx={{mt: 5}}></Box>
                <GridContainer>
                    <GridItem sm={12}>
                        <TextField
                            select
                            label="Firma"
                            value={(idCompany == undefined) ? null : idCompany}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIdCompany(Number(event.target.value))}
                            variant="standard"
                            fullWidth
                        >
                            <MenuItem value={-1}>Neue Firma</MenuItem>
                        {companyArray.map( x =>
                            <MenuItem value={x.idCompany}>{x.Company}</MenuItem>
                        )}
                        </TextField>
                    </GridItem>

                    <GridItem sm={12}>
                        <TextField
                            select
                            label="Person"
                            value={(props.idPerson == undefined) ? null : props.idPerson}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setIdPerson(Number(event.target.value))}
                            variant="standard"
                            fullWidth
                        >
                            <MenuItem value={-1}>Neuer Kontakt</MenuItem>
                        {personFromCompanyArray !== undefined && personFromCompanyArray.map( x =>
                            <MenuItem value={x.idPerson}>{x.FirstName} {x.LastName}</MenuItem>
                        )}
                        </TextField>
                    </GridItem>
                </GridContainer>
                
                <Box sx={{mt: 5}}></Box>
                <Customer
                    idPerson={props.idPerson}
                    setIdPerson={props.setIdPerson}
                    idCompany={idCompany}
                    personArray={personFromCompanyArray}
                    setPersonArray={setPersonFromCompanyArray}
                    saveCounter={props.saveCounter}
                    // Um im Parent zu visualisieren
                    setIsBlockedCompany={props.setIsBlockedCompany}
                    setIsCreditCheckedCompany={props.setIsCreditCheckedCompany}
                    // Rent state
                    idRentState={props.idRentState}
                    // Für die Childvalidierung
                    setMetaState={props.setMetadataPerson}
                />       
            </>
        )
    } else {
        return(
            <>
                <Box sx={{mt: 5}}></Box>
                <Customer
                    idPerson={props.idPerson}
                    setIdPerson={props.setIdPerson}
                    personArray={personFromCompanyArray}
                    setPersonArray={setPersonFromCompanyArray}
                    saveCounter={props.saveCounter}
                    setIsBlockedCompany={props.setIsBlockedCompany}
                    setIsCreditCheckedCompany={props.setIsCreditCheckedCompany}
                    // Rent state
                    idRentState={props.idRentState}
                    // Für die Childvalidierung
                    setMetaState={props.setMetadataPerson}
                />
            </>
        )
    }
}
export default SelectCustomer;