import React, { useCallback, useEffect, useState } from 'react';
import { IDocumentWithMetastates } from '../../interfaces/IDocument';
import { IMIMEType } from '../../interfaces/IMIMEType';
import { staticMimeTypeArray } from '../Document/array_MIMEType';
import { Accept, useDropzone } from 'react-dropzone';
import { Card, CardContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import DocumentTableRow from './DocumentTableRow';
import { AddCircle } from '@mui/icons-material';


interface IProps {
    documentArray: IDocumentWithMetastates[];
    setDocumentArray: Function;
    disablePublic?: boolean;
}



const DocumentOverview:React.FC<IProps> = (props) => {
    const mimeTypeArray = staticMimeTypeArray;
    //
    const [addNew, setAddNew] = useState(false);

    async function fileToBase64(file:File) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
        return String(result_base64).split(",")[1];
    }

    async function callBackDriver(acceptedFiles:File[]) {
        let updatedArray = [];

        let minId = Math.min(...props.documentArray.map(x => Number(x.idDocument))) -1;
        //(minId >0) && (minId = -1);
        if (props.documentArray.length === 0 || minId > 0) {
            minId = -1;
        }


        for (let acceptedFile of acceptedFiles) {
            let fileName:string = String(acceptedFile["name"]);
            let mimeType: IMIMEType | undefined = mimeTypeArray.find(y => y.MIMEType === acceptedFile["type"])

            minId = minId - 1;


            if (mimeType != undefined) {
                updatedArray.push({
                    Data: await fileToBase64(acceptedFile),
                    idDocumentType: 1,
                    FileName: fileName,
                    idMIMEType: mimeType!.idMIMEType,
                    isPublic: false,
                    wasChanged: true,
                    idDocument: minId
                } as IDocumentWithMetastates)
            }
        }
        props.setDocumentArray([...props.documentArray, ...updatedArray])
    }


    const onDrop = useCallback( (acceptedFiles:File[]) => { 
        //setNewFileArray(acceptedFiles);
        callBackDriver(acceptedFiles);
        setAddNew(false);
     }, [props.documentArray] );

    const { getRootProps, getInputProps, isDragActive } = useDropzone( { 
        onDrop,
        noClick: true,
        /*
        accept: mimeTypeArray.reduce((accObj,obj) => { 
            accObj[obj.MIMEType] = [obj.FileEnding];
            return accObj;
        }, {} as Accept)
        */
    } );


    const dropZoneTsx = () => {
        return(
            <div>
                <input {...getInputProps()} accept={String(mimeTypeArray.map(x => x.MIMEType).join(", "))}/>
                <Card style={{minHeight: 150}}>
                    <CardContent>
                        <Typography variant="body2">
                            Dokument(e) hier reinziehen
                        </Typography>
                        <br />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Datentyp</TableCell>
                                    <TableCell>Datenendung</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mimeTypeArray.map(x =>
                                    <TableRow>
                                        <TableCell>{x.MIMEType}</TableCell>
                                        <TableCell>{x.FileEnding}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </div>
        )
    }


    return(
        <>
            <div {...getRootProps()}>
                <Typography variant='h5'>
                    Dokument
                    <IconButton onClick={() => setAddNew(!addNew)} style={{float: 'right'}}><AddCircle/></IconButton>
                </Typography>

                {(isDragActive || addNew) && dropZoneTsx()}
            

                <TableContainer hidden={isDragActive || addNew}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Art</TableCell>
                                {(props.disablePublic == undefined || props.disablePublic == false) && <TableCell>Öffentlich</TableCell>}
                                <TableCell>Name</TableCell>
                                <TableCell>Aktionen</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {props.documentArray.map(x =>
                                <DocumentTableRow 
                                    key={x.idDocument}
                                    documentObject={x}
                                    documentArray={props.documentArray}
                                    setDocumentArray={props.setDocumentArray}
                                    disablePublic={props.disablePublic}
                                />
                            )}
                        </TableBody>
                    </Table>


                </TableContainer>
            </div>
        </>
    )
}
export default DocumentOverview;

