import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILeadState } from "../interfaces/ILeadState";


const initialState =  { storeLeadState: [] as ILeadState[] }; 

const sliceLeadState = createSlice({
    name: "LEADSTATEARRAY",
    initialState,
    reducers: {
        setLeadState: (state, action: PayloadAction<ILeadState[]>) => {
            state.storeLeadState = [...action.payload];
        },
        addLeadState: (state, action: PayloadAction<ILeadState>) => {
            state.storeLeadState = [...state.storeLeadState,action.payload];
        },
        updateLeadState: (state, action: PayloadAction<ILeadState>) => {
            let tmpArray =  state.storeLeadState.map(x => x.idLeadState === action.payload.idLeadState ? action.payload : x);
            state.storeLeadState = tmpArray;
        }
    }
})
export const { setLeadState, addLeadState, updateLeadState} = sliceLeadState.actions;
export default sliceLeadState.reducer;