import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IPostcode } from '../../interfaces/IPostcode';
import { State } from '../../redux/combine_reducers';
import GridItem from '../core/GridItem';
import { Add } from '@mui/icons-material';
import { uploadFetch } from '../../hooks/useFetch';
import { actionCreatorsPostcode } from '../../redux/actionCreators';
import { bindActionCreators } from '@reduxjs/toolkit';



interface IProps {
    isEmpty: boolean;
    //isNew: boolean;
    idPostcode: number | null;
    setIdPostcode: Function;
    setIsValidatedPostcode?: Function;
}



const Postcode:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const countryArray = useSelector((state: State) => state.countryArray.storeCountry);
    const postcodeArray = useSelector((state: State) => state.postcodeArray.storePostcode);
    const { addUpdatePostcodeArray  } = bindActionCreators(actionCreatorsPostcode, dispatch);
    // 
    const [idCountry,setIdCountry] = useState((props.idPostcode === null ||  props.idPostcode < 0) ? 1 : Number(postcodeArray.find(x => x.idPostcode === props.idPostcode)?.idCountry));
    //
    const [localIdPostcode,setLocalIdPostcode] = useState<number | null>(null);
    const [currentSelected,setCurrentSelected] = useState<IPostcode | null>(null);
    //
    const [filteredArray,setFilteredArray] = useState<IPostcode[]>([]);
    //
    const [isValidated,setIsValidated] = useState(false);
    // 
    const [isLoading,setIsLoading] = useState(false);
    const [searchTerm,setSearchTerm] = useState("");
    //
    const [isOpenNew, setIsOpenNew] = useState(false);
    const [idNewCountry,setNewIdCountry] = useState(1);
    const [newCity,setNewCity] = useState("");
    const [newPostcode,setNewPostcode] = useState("");
    const [isLoadingNew,setIsLoadingNew] = useState(false);


    useEffect(() => {
        if (props.idPostcode === null || props.idPostcode == -1) {
            setCurrentSelected(null);
            setLocalIdPostcode(null);
        }
        else if (props.idPostcode !== null && props.idPostcode !== localIdPostcode) {
            let tmpObject = postcodeArray.find(x => x.idPostcode === props.idPostcode)

            if (tmpObject !== undefined) {
                //setSearchTerm(tmpObject.Postcode);
                setFilteredArray([tmpObject])
                setCurrentSelected(tmpObject);
                setIsLoading(true);
            } 
        }
    },[props.idPostcode])



    useEffect(() => {
        if (searchTerm !== "") {
            setIsLoading(true);
            setFilteredArray([
                ...postcodeArray
                    .filter(x => x.idCountry === idCountry)
                    .filter(x => 
                        x.Postcode.toLocaleLowerCase().startsWith(searchTerm.toLocaleLowerCase()) 
                        || x.City.toLocaleLowerCase().startsWith(searchTerm.toLocaleLowerCase()) 
                    )
            ])

            //tmpArray = tmpArray.filter( x => x.dealname?.toLowerCase().includes(filter.toLocaleLowerCase()) );
        } else {
            setFilteredArray([]);
        }
    },[searchTerm])


    useEffect(() => {
        console.log(filteredArray);
        setIsLoading(false);
    },[filteredArray])

    useEffect(() => {
        setIsValidated(currentSelected !== null)
    },[currentSelected])

    useEffect(() => {
        (props.setIsValidatedPostcode !== undefined) && props.setIsValidatedPostcode(isValidated);
    },[isValidated])

    const handleChange = (e:React.SyntheticEvent, value: IPostcode | null) => {
        if (value !== null) {
            setLocalIdPostcode(Number(value.idPostcode));
            props.setIdPostcode(Number(value.idPostcode));
            setCurrentSelected(value);
        }
    }
    const handleInputChange = (e:React.SyntheticEvent, value: string) => {
        setSearchTerm(value)
    }

    const handleClose = () => {
        setNewIdCountry(1);
        setNewPostcode("");
        setNewCity("");
        setIsOpenNew(false);
    }


    const handleSaveFunc = (newObject:IPostcode) => {
        setIdCountry(newObject.idCountry);
        setLocalIdPostcode(Number(newObject.idPostcode));
        setCurrentSelected(newObject);
        addUpdatePostcodeArray(newObject);
        props.setIdPostcode(Number(newObject.idPostcode));
        setIsLoadingNew(false);
        handleClose();
    }

    const handleSave = () => {
        let newObject:IPostcode = {
            idCountry: idNewCountry,
            Postcode: newPostcode,
            City: newCity
        }
        setIsLoadingNew(true);
        uploadFetch("/postcode",true,newObject,handleSaveFunc,() => {},setIsLoadingNew)
    }


    return(
        <>
            <Dialog
                open={isOpenNew}
                onClose={handleClose}
                fullWidth sx={{minWidth: "sm"}}
            >
                <DialogTitle>Neue Postleitzahl</DialogTitle>
                <DialogContent>
                    {isLoadingNew && <LinearProgress />}
                    <TextField
                        label="Land"
                        value={idNewCountry}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>)  => {
                            setNewIdCountry(Number(event.target.value));
                        }}
                        size="small"
                        fullWidth
                        select
                        sx={{mt: 2}}
                    >
                        {countryArray.map(x =>
                            <MenuItem key={`country-${x.idCountry}`} value={x.idCountry}>{x.ISO3166}</MenuItem>
                        )}
                    </TextField>
                    <TextField
                        label="Postleitzahl"
                        value={newPostcode}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>)  => {
                            setNewPostcode(event.target.value);
                        }}
                        size="small"
                        fullWidth
                        sx={{mt: 2}}
                    />
                    <TextField
                        label="Stadt"
                        value={newCity}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>)  => {
                            setNewCity(event.target.value);
                        }}
                        size="small"
                        fullWidth
                        sx={{mt: 2}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbruch</Button>
                    <Button 
                        onClick={handleSave}
                        disabled={newPostcode === "" || newCity === "" || isLoadingNew}
                    >Speichern</Button>
                </DialogActions>
            </Dialog>

            <GridItem sm={2}>
                <TextField
                    label="Land"
                    value={idCountry}
                    onChange={(event:React.ChangeEvent<HTMLInputElement>)  => {
                        setIdCountry(Number(event.target.value));
                        props.setIdPostcode(null);
                        setCurrentSelected(null);
                        setLocalIdPostcode(null);
                    }}
                    size="small"
                    fullWidth
                    select
                >
                    {countryArray.map(x =>
                        <MenuItem key={`country-${x.idCountry}`} value={x.idCountry}>{x.ISO3166}</MenuItem>
                    )}
                </TextField>

            </GridItem>

            <GridItem sm={8}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={filteredArray}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    isOptionEqualToValue={(option: IPostcode, value:IPostcode) => option.idPostcode === value.idPostcode}
                    fullWidth
                    loading={isLoading}
                    value={currentSelected}
                    disabled={props.isEmpty}
                    getOptionLabel={(row: IPostcode) => `${row.Postcode} ${row.City}` }
                    size="small"
                    renderInput={(params) => 
                    <TextField error={!props.isEmpty && !isValidated}  {...params} label="Postleitzahl" />}
                />
            </GridItem>


            <GridItem sm={2}>
                <IconButton onClick={() => setIsOpenNew(true)}><Add/></IconButton>
            </GridItem>

        </>
    )
}
export default Postcode;