import React, { useEffect, useState } from 'react';
import { bindActionCreators } from "redux";
import { useDispatch } from 'react-redux';
import { useFetch } from '../../hooks/useFetch';
import { actionCreatorsAssets, actionCreatorsCompany, actionCreatorsCountry, actionCreatorsGender, actionCreatorsLocation, actionCreatorsPostcode, actionCreatorsProductHasAssets, actionCreatorsProductHasRoom, actionCreatorsProducts, actionCreatorsRegions, actionCreatorsRents, actionCreatorsRentState, actionCreatorsRoomPrice, actionCreatorsRooms, actionCreatorsRoomType, actionCreatorsSources, actionCreatorsUnits, actionCretorsRentTyps, actionCreatorsMediums, actionCreatorsCampaign, actionCreatorsLeadState, actionCreatorsRentAutomation, actionCreatorsCompanyCategory, actionCreatorsAppendix, actionCreatorsLeadSources, actionCreatorsVat } from '../../redux/actionCreators';
import { Alert, Box, CircularProgress, createTheme, Dialog, DialogTitle, Grid, ThemeProvider } from '@mui/material';
import Navbar from '../navbar/Navbar';
import Home from '../home/Home';
import Error404 from '../errorPages/Error404';
import RoomTypes from '../RoomType/RoomTypes';
import Products from '../Product/Products';
import Units from '../Unit/Units';
import Regions from '../Region/Regions';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Rents from '../Rent/Rents';
//import Rent from '../Rent_Old/Rent';
import Rooms from '../Room/Rooms';
import AlertError from './AlertError'
import WaitingSequence from './WaitingSequence';
import Locations from '../Location/Locations';
import Customers from '../Customer/Customers';
import RoomPrices from '../Price/RoomPrice/RoomPrices';
import Mediums from '../Marketing/Medium/Mediums';
import Campaigns from '../Marketing/Campaign/Campaigns';
import TestingPublicRent from '../Testing/TestingPublicRent';
import Messages from '../Dashboard/Messages';
import { IRoomType } from '../../interfaces/IRoomType';
import { IRoomPrice } from '../../interfaces/IRoomPrice';
import { IRoom } from '../../interfaces/IRoom';
import { IAsset } from '../../interfaces/IAsset';
import { IProduct } from '../../interfaces/IProduct';
import { IProductHasRoom } from '../../interfaces/IProductHasRoom';
import { IProductHasAsset } from '../../interfaces/IProductHasAsset';
import { IUnit } from '../../interfaces/IUnit';
import { IRegion } from '../../interfaces/IRegion';
import { ILocation } from '../../interfaces/ILocation';
import { IRent } from '../../interfaces/IRent';
import { IRentType } from '../../interfaces/IRentType';
import { IRentState } from '../../interfaces/IRentState';
import { ICountry } from '../../interfaces/ICountry';
import { IPostcode } from '../../interfaces/IPostcode';
import { ICompany } from '../../interfaces/ICompany';
import { IGender } from '../../interfaces/IGender';
import { ICampaign } from '../../interfaces/ICampaign';
import { ILeadSource } from '../../interfaces/ILeadSource';
import { IMedium } from '../../interfaces/IMedium';
import { ILeadState } from '../../interfaces/ILeadState';
import RentStates from '../Rent/RentState/RentStates';
import { IRentAutomation } from '../../interfaces/IRentAutomation';
import RentAutomations from '../Rent/RentAutomation/RentAutomations';
import { ICompanyCategory } from '../../interfaces/ICompanyCategory';
import CompanyCategories from '../Customer/CompanyCategory/CompanyCategories';
import LeadStates from '../Marketing/LeadState/LeadStates';
import { IAppendix } from '../../interfaces/IAppendix';
import Appendixes from '../Mail/Appendix/Appendizes';
import Dashboard from '../Dashboard/Dashboard';
import Sources from '../Marketing/Source/Sources';
import { ISource } from '../../interfaces/ISource';
import LeadSources from '../Marketing/LeadSource/LeadSources';
import Calender from '../Rent/Calender/Calender';
import InovicePositionTemplateOverview from '../Inovice/InovicePositionTemplateOverview';
import Invoice from '../Inovice/InvoiceOverview';
import Inovice from '../Inovice/InvoiceOverview';
import { HandoverOverview } from '../HandoverProtocoll/HandoverOverview';
import { IVat } from '../../interfaces/IVat';
//import Mediums from '../Marketing/Medium/Mediums';

declare module '@mui/material/styles' {
    interface Theme {
      status: {
        danger: string;
      };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
      status?: {
        danger?: string;
      };
    }
  }


const DynamicComponente:React.FC = () => {
    const dispatch = useDispatch();
    const { setRoomArray } = bindActionCreators(actionCreatorsRooms, dispatch);
    const { setRoomPriceArray } = bindActionCreators(actionCreatorsRoomPrice, dispatch);
    //const { setAssetArray } = bindActionCreators(actionCreatorsAssets, dispatch);
    const { setRoomTypeArray } = bindActionCreators(actionCreatorsRoomType, dispatch);
    const { setProductArray } = bindActionCreators(actionCreatorsProducts, dispatch);
    const { setProductHasRoomArray } = bindActionCreators(actionCreatorsProductHasRoom, dispatch);
    //const { setProductHasAssetArray } = bindActionCreators(actionCreatorsProductHasAssets, dispatch);
    const { setUnitArray } = bindActionCreators(actionCreatorsUnits, dispatch);
    const { setRegionArray } = bindActionCreators(actionCreatorsRegions, dispatch);
    const { setLocationArray } = bindActionCreators(actionCreatorsLocation, dispatch);
    // Für die Mieten
    const { setRentArray } = bindActionCreators(actionCreatorsRents, dispatch);
    const { setRentTypeArray } = bindActionCreators(actionCretorsRentTyps, dispatch);
    const { setRentStateArray } = bindActionCreators(actionCreatorsRentState, dispatch);
    const { setVatObject } = bindActionCreators(actionCreatorsVat, dispatch);
    // Addresse, Psotcode etc.
    const { setCountryArray } = bindActionCreators(actionCreatorsCountry, dispatch);
    const { setPostcodeArray } = bindActionCreators(actionCreatorsPostcode, dispatch);
    const { setCompanyArray  } = bindActionCreators(actionCreatorsCompany, dispatch);
    // Weitere
    const { setGenderArray } = bindActionCreators(actionCreatorsGender, dispatch);
    // Marketing
    const { setCampaignArray } = bindActionCreators(actionCreatorsCampaign, dispatch);
    const { setSourceArray } = bindActionCreators(actionCreatorsSources, dispatch);
    const { setMediumArray } = bindActionCreators(actionCreatorsMediums, dispatch);
    const { setLeadState } = bindActionCreators(actionCreatorsLeadState, dispatch);
    const { setLeadSourceArray } = bindActionCreators(actionCreatorsLeadSources, dispatch);
    const { setCompanyCategory } = bindActionCreators(actionCreatorsCompanyCategory, dispatch);
    // Auto, Mailsing etc.
    const { setRentAutomationArray } = bindActionCreators(actionCreatorsRentAutomation, dispatch);
    const { setAppendixArray } = bindActionCreators(actionCreatorsAppendix, dispatch);


    // Raum
    const [localRoomArray,setLocalRoomArray,wasLocalSuccessfullyRoomArray] = useFetch<IRoom[]>("room");
    const [localRoomTypeArray,setLocalRoomTypeArray,wasLocalSuccessfullyRoomType] = useFetch<IRoomType[]>("roomType");
    const [localRoomPrice,setLocalRoomPrice,wasLocalSuccessfullyRoomPriceArray] = useFetch<IRoomPrice[]>("roomPrice");
    //Assets
    //const [localAssetArray,setLocalAssetArray,wasLocalSuccessfullyLocalAssetArray] = useFetch<IAsset[]>("asset");
    // Produkte
    const [localProductsArray,setLocalProductsArray,wasLocalSuccessfullyProductsArray] = useFetch<IProduct[]>("product");
    const [localProductHasRoom,setLocalProductHasRoom,wasLocalSuccesfullyProductHasRoomArray] = useFetch<IProductHasRoom[]>("productHasRoom");
    //const [localProductHasAsset,setLocalProductHasAsset,wasLocalSuccessfullyProducttHasAsset] = useFetch<IProductHasAsset[]>("productHasAsset");
    // Globale Aray
    const [localUnitsArray,setLocalUnitsArray,wasLocalSuccessfullyUnitsArray] = useFetch<IUnit[]>("unit");
    const [localRegionsArray,setLocalRegionsArray,wasLocalSuccessfullyRegionsArray] = useFetch<IRegion[]>("region");
    const [localLocationArray,setLocalLocationArray,wasLocalSuccessfullyLocationArray] = useFetch<ILocation[]>("location");
    // Meite
    const [localRentArray,setLocalRentArray,wasLocalSuccessfullyRentArray] = useFetch<IRent[]>("rent");
    const [localRentTypeArray,setLocalRentTypeArray,wasLocalSuccessfullyRentTypeArray] = useFetch<IRentType[]>("rentType");
    const [localRentStateArray,setLocalRentStateArray,wasLocalSuccessfullyRentStateArray] = useFetch<IRentState[]>("rentState");
    const [localVatObject,setLocalVatObject,wasLocalSuccessfullyVatObject] = useFetch<IVat>("rent/vat",undefined,true);
    // Addresse, Psotcode etc.
    const [localCountryArray,setLocalCountryArray,wasLocalSuccessfullyCountryArray] = useFetch<ICountry[]>("country");
    const [localPostcodeArray,setLocalPostcodeArray,wasLocalSuccessfullyPostcodeArray] = useFetch<IPostcode[]>("postcode");
    const [localCompanyArray,setLocalCompanyArray,wasLocalSuccessfullCompanyArray] = useFetch<ICompany[]>("company");
    // Wetie
    const [localGenderArray, setLocalGenderArray, wasLocalSuccessfullyGenderArray] = useFetch<IGender[]>("gender");
    // Marketing
    const [localCampaingArray, setLocalCampaingArray, wasLocalSuccessfullyCampaingArray] = useFetch<ICampaign[]>("campaign");
    const [localSourceArray, setLocalSourceArray, wasLocalSuccessfullySourceArray] = useFetch<ISource[]>("source");
    const [localMediumArray, setLocalMediumArray, wasLocalSuccessfullyMediumArray] = useFetch<IMedium[]>("medium");
    const [localLeadStateAttay, setLocalLeadStateAttay, wasLocalSuccessfullyLeadStateAttay] = useFetch<ILeadState[]>("leadState");
    const [localLeadSourceAttay, setLocalLeadSourceAttay, wasLocalSuccessfullyLeadSourceAttay] = useFetch<ILeadSource[]>("leadSource");
    const [localcompanyCategoryArray, setLocalCompanyCategoryArray, wasLocalSuccessfullycompanyCategoryArray] = useFetch<ICompanyCategory[]>("companyCategory");
    // Auto, Mailsing etc.
    const [localRentAutomationArray, setLocalRentAutomationArray, wasLocalSuccessfullyRentAutomationArray] = useFetch<IRentAutomation[]>("rentAutomation");
    const [localAppendixArray, setLocalAppendixArray, wasLocalSuccessfullyAppendixArray] = useFetch<IAppendix[]>("appendix");



    const theme = createTheme({});


    useEffect(() => {(localRoomArray) && setRoomArray(localRoomArray)},[localRoomArray]);
    useEffect(() => {(localRoomTypeArray) && setRoomTypeArray(localRoomTypeArray)},[localRoomTypeArray]);
    useEffect(() => {(localRoomPrice) && setRoomPriceArray(localRoomPrice)},[localRoomPrice]);
    //useEffect(() => {(localAssetArray) && setAssetArray(localAssetArray)},[localAssetArray]);
    useEffect(() => {(localProductsArray) && setProductArray(localProductsArray)},[localProductsArray]);
    useEffect(() => {(localProductHasRoom) && setProductHasRoomArray(localProductHasRoom)},[localProductHasRoom]);
    //useEffect(() => {(localProductHasAsset) && setProductHasAssetArray(localProductHasAsset)},[localProductHasAsset]);
    useEffect(() => {(localUnitsArray) && setUnitArray(localUnitsArray)},[localUnitsArray]);
    useEffect(() => {(localRegionsArray) && setRegionArray(localRegionsArray)},[localRegionsArray])
    useEffect(() => {(localLocationArray) && setLocationArray(localLocationArray)},[localLocationArray])
    // Miete
    useEffect(() => {(localRentArray) && setRentArray(localRentArray)},[localRentArray])
    useEffect(() => {(localRentTypeArray) && setRentTypeArray(localRentTypeArray)},[localRentTypeArray])
    useEffect(() => {(localRentStateArray) && setRentStateArray(localRentStateArray)},[localRentStateArray])
    useEffect(() => {(localVatObject) && setVatObject(localVatObject)},[localVatObject])
    // Addresse, Psotcode etc.
    useEffect(() => {(localCountryArray) && setCountryArray(localCountryArray)},[localCountryArray])
    useEffect(() => {(localPostcodeArray) && setPostcodeArray(localPostcodeArray)},[localPostcodeArray])
    useEffect(() => {(localCompanyArray) && setCompanyArray(localCompanyArray)},[localCompanyArray])
    // Weitere
    useEffect(() => {(localGenderArray) && setGenderArray(localGenderArray)},[localGenderArray])
    // Marketing
    useEffect(() => {(localCampaingArray) && setCampaignArray(localCampaingArray)},[localCampaingArray])
    useEffect(() => {(localSourceArray) && setSourceArray(localSourceArray)},[localSourceArray])
    useEffect(() => {(localMediumArray) && setMediumArray(localMediumArray)},[localMediumArray])
    useEffect(() => {(localLeadStateAttay) && setLeadState(localLeadStateAttay)},[localLeadStateAttay])
    useEffect(() => {(localLeadSourceAttay) && setLeadSourceArray(localLeadSourceAttay)},[localLeadSourceAttay])
    useEffect(() => {(localcompanyCategoryArray) && setCompanyCategory(localcompanyCategoryArray)},[localcompanyCategoryArray])
    // Auto, Mailsing etc.
    useEffect(() => {(localRentAutomationArray) && setRentAutomationArray(localRentAutomationArray)},[localRentAutomationArray])
    useEffect(() => {(localAppendixArray) && setAppendixArray(localAppendixArray)},[localAppendixArray])
    


    if (
        !wasLocalSuccessfullyRoomArray
        || !wasLocalSuccessfullyRoomType
        || !wasLocalSuccessfullyRoomPriceArray
        || !wasLocalSuccessfullyProductsArray
        //|| !wasLocalSuccessfullyLocalAssetArray
        || !wasLocalSuccesfullyProductHasRoomArray
        //|| !wasLocalSuccessfullyProducttHasAsset
        || !wasLocalSuccessfullyUnitsArray
        || !wasLocalSuccessfullyRegionsArray
        || !wasLocalSuccessfullyLocationArray
        // Miete
        || !wasLocalSuccessfullyRentArray
        || !wasLocalSuccessfullyRentTypeArray
        || !wasLocalSuccessfullyRentStateArray
        || !wasLocalSuccessfullyVatObject
        // Addresse, Psotcode etc.
        || !wasLocalSuccessfullyCountryArray
        || !wasLocalSuccessfullyPostcodeArray
        || !wasLocalSuccessfullCompanyArray
        // Weite
        || !wasLocalSuccessfullyGenderArray
        //Marketing
        || !wasLocalSuccessfullyCampaingArray
        || !wasLocalSuccessfullySourceArray
        || !wasLocalSuccessfullyMediumArray
        || !wasLocalSuccessfullyLeadStateAttay
        || !wasLocalSuccessfullyLeadSourceAttay
        // Auto, Mailsing etc.
        || !wasLocalSuccessfullyRentAutomationArray
        || !wasLocalSuccessfullyAppendixArray
    ) {
        return(
            <AlertError/>
        )

    }
    else if (
        localRoomArray === undefined
        || localRoomTypeArray === undefined
        || localRoomArray === undefined
        || localProductsArray === undefined
        || localProductHasRoom === undefined
        //|| localProductHasAsset === undefined
        || localUnitsArray === undefined
        || localRegionsArray === undefined
        || localLocationArray === undefined
        // Miete
        || localRentArray === undefined
        || localRentTypeArray === undefined
        || localRentStateArray === undefined
        || localVatObject === undefined
        // Addresse, Psotcode etc.
        || localCountryArray === undefined
        || localPostcodeArray === undefined
        || localCompanyArray === undefined
        // Weitere
        || localGenderArray === undefined
        // Marekting
        || localCampaingArray === undefined
        || localSourceArray === undefined
        || localMediumArray === undefined
        || localLeadStateAttay === undefined
        || localcompanyCategoryArray === undefined
        // Auto, Mailsing etc.
        || localRentAutomationArray === undefined
        || localAppendixArray === undefined


        
    ) {
        return (
                <WaitingSequence />
        ) 
    }
    else {
        return(

            <ThemeProvider theme={theme}>
                <Router>
                    <Navbar>
                        <Routes>     
                            <Route path="/test" element={<RoomTypes />}/>
                            <Route path="/roomTypes" element={<RoomTypes />}/>
                            <Route path="/products" element={<Products />}/>
                            <Route path="/units" element={<Units />}/>
                            <Route path="/regions" element={<Regions />}/>
                            <Route path="/locations" element={<Locations />}/>
                            {/* Personen */}
                            <Route path='/customers' element={<Customers/>}/>
                            <Route path='/customers/:idPerson/:idRent' element={<Customers/>}/>
                            <Route path='/customers/:idPerson' element={<Customers/>}/>
                            {/* Mieten */}
                            <Route path="/rentStates" element={<RentStates />}/>
                            <Route path="/calender" element={<Calender />}/>
                            <Route path="/rents" element={<Rents />}/>
                            <Route path="/rents/:idRent" element={<Rents />}/>
                            {/*<Route path="/rent/:idRentParam" element={<Rent />}/>*/}
                            {/* Übergabeprotokoll */}
                            <Route path="/handoverprotocol" element={<HandoverOverview />}/>
                            {/* Räume */}
                            <Route path="/rooms" element={<Rooms />}/>
                            <Route path="/roomPrices" element={<RoomPrices />}/>
                            <Route path="/home" element={<Dashboard />}/>
                            <Route path="/" element={<Dashboard />}/>
                            {/* Marekting */}
                            <Route path="/leadStates" element={<LeadStates />}/>
                            <Route path="/leadSources" element={<LeadSources />}/>
                            <Route path="/campaigns" element={<Campaigns />}/>
                            <Route path="/sources" element={<Sources />}/>
                            <Route path="/mediums" element={<Mediums />}/>
                            <Route path="/companyCategory" element={<CompanyCategories />}/>
                            {/* Marekting */}
                            <Route path="/testingPublicRent" element={<TestingPublicRent />}/>
                            {/* Auto, Mailsing etc. */}
                            <Route path="/rentAutomations" element={<RentAutomations />}/>
                            <Route path="/appendizes" element={<Appendixes />}/>
                            {/* Rechnungen */}
                            <Route path="/inovicePositionTemplate" element={<InovicePositionTemplateOverview />}/>
                            <Route path="/inovice" element={<Inovice />}/>
                            <Route path="/inovice/:idInvoice" element={<Inovice />}/>
                        </Routes>
                    </Navbar> 
                </Router>
            </ThemeProvider>
            
        )
    }
}
export default DynamicComponente;