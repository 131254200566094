import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProductHasRoom } from "../interfaces/IProductHasRoom";
import { IRoomType } from "../interfaces/IRoomType";

const initialState =  { storeProductHasRoom: [] as IProductHasRoom[] }; 

const sliceProductHasRoom = createSlice({
    name: "PRODUCTHASROOMARRAY",
    initialState,
    reducers: {
        setProductHasRoomArray: (state, action: PayloadAction<IProductHasRoom[]>) => {
            state.storeProductHasRoom = [...action.payload];
        },
        addProductHasRoomArray: (state, action: PayloadAction<IProductHasRoom>) => {
            state.storeProductHasRoom = [...state.storeProductHasRoom,action.payload];
        },
        deleteProductHasRoomArray: (state, action: PayloadAction<IProductHasRoom>) => {
            state.storeProductHasRoom = [...state.storeProductHasRoom.filter(x  => x.idProduct !== action.payload.idProduct || x.idRoom !== action.payload.idRoom)];
        }
    }
})
export const { setProductHasRoomArray, addProductHasRoomArray, deleteProductHasRoomArray
 } = sliceProductHasRoom.actions;
export default sliceProductHasRoom.reducer;