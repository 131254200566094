import React, { useEffect, useState } from 'react';
import { DataGrid, deDE, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IconButton, TextField, Typography } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import GridContainer from './GridContainer';
import GridItem from './GridItem';
import { Box } from '@mui/system';


interface IProps {
    targetId?: number;
    setTargetId: Function;
    headline?: String;
    columns: GridColDef[];
    data: any[];
    idField: string;
    children?: React.ReactNode;
    colDataGrid?: number
    enableAddButton?: boolean;
    autoHeight?: boolean;

    setSearchState?: Function;
}


const GenericOverview:React.FC<IProps> = (props) => {
    const defaulColDataGrid = 6;
    //const [selectedModal, setSelectedModal] = useState<GridInputSelectionModel | undefined>();
    //
    const [winHeight,setWinHeight] = useState(window.innerHeight);

    /*
    useEffect(() => {
        if (props.targetId != undefined && Array.isArray(selectedModal) && props.targetId != selectedModal[0]) {
            setSelectedModal([props.targetId])
        }
    },[props.targetId])

    useEffect(() => {
        if (selectedModal != undefined) {
            console.log(selectedModal);
            (Array.isArray(selectedModal)) && props.setTargetId(selectedModal[0])
            //props.setTargetId(selectedModal[props.idField])
            //props.setTargetId(selectedModal[0]);
        }
    },[selectedModal])
    */

    useEffect(() => {console.log(window.innerHeight)},[window.innerHeight])

    window.addEventListener('resize', () => setWinHeight(window.innerHeight));

    return(
        <>
            
            <Typography variant='h4' gutterBottom>
                {(props.headline) && props.headline}




                {(props.enableAddButton == undefined || props.enableAddButton == true) &&
                    <IconButton onClick={() => props.setTargetId(-1)} style={{float: 'right'}}><AddCircle/></IconButton>
                }
            </Typography>

            {(props.setSearchState != undefined) &&
                <>
                    <TextField
                        name="Suche"
                        label="Suche"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => (props.setSearchState != undefined) && props.setSearchState(String(event.target.value)) }
                    />
                    <br />
                    <br />
                </>
            }


            <GridContainer>
                <GridItem sm={(props.colDataGrid === undefined) ? defaulColDataGrid : props.colDataGrid}>
                    <Box sx={{  width: '100%', height: (props.autoHeight != undefined && props.autoHeight == true) ? undefined : (props.data.length == 0) ? 300 : (winHeight-(winHeight*0.2))}}>
                        <DataGrid
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            rows={props.data}
                            columns={props.columns}
                            autoHeight={props.autoHeight == undefined ? false : props.autoHeight == true}
                            getRowId={(row) => row[props.idField]}
                            onRowClick={(params,event,details) => props.setTargetId(Number(params.id))}
                            //onSelectionModelChange={(x) => props.setTargetId(Number(x[0]))}
                            //selectRow
                            //onSelectionModelChange={(x:GridInputSelectionModel) => setSelectedModal(x)}
                            //selectionModel={selectedModal}
                            //pageSize={10}
                            /*
                            selectionModel={
                                (props.targetId == undefined) 
                                ? undefined
                                : [props.targetId]
                            }
                            */
                        />
                    </Box>
                </GridItem>

                <GridItem sm={(props.colDataGrid === undefined) ? (12 - defaulColDataGrid) : (12 - props.colDataGrid)}>
                    {(props.children !== undefined) && props.children}
                </GridItem>
            </GridContainer>
        </>
    )
}
export default GenericOverview;
