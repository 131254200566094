import { Check, Close } from '@mui/icons-material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../redux/combine_reducers';
import GenericOverview from '../../core/GenericOverview';
import CompanyCategory from './CompanyCategory';


const CompanyCategories:React.FC = () => {
    const dispatch = useDispatch();
    const companyCategoryArray = useSelector((state: State) => state.companyCategoryArray.storeCompanyCategory);
    // Selected Id
    const [selectedId,setSelectedId] = useState<number|undefined>();
    //(units*pricePerUnit).toFixed(2).replace(".",",") + " €" 
    const columns: GridColDef[] = [
        { 
            field: 'idCompanyCategory', 
            headerName: 'Nr.', 
            width: 90,
        },
        {
            field: 'Category',
            headerName: 'Business',
            flex: 1
        }

    ];

    return(
        <GenericOverview
            headline="Unternehmenskategorie"
            columns={columns}
            data={companyCategoryArray}
            idField="idCompanyCategory"
            setTargetId={setSelectedId}
        >
            <CompanyCategory idCompanyCategory={selectedId} />
        </GenericOverview>
    )

}
export default CompanyCategories;