import { FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidatenText, useValidationNumber } from '../../../hooks/useValidaten';
import { IRentAutomation } from '../../../interfaces/IRentAutomation';
import { IRentState } from '../../../interfaces/IRentState';
import { actionCreatorsRentAutomation  } from '../../../redux/actionCreators';
import { State } from '../../../redux/combine_reducers';
import GenericEdit, { IInputValues, ISetValues } from '../../core/GenericEdit';
import InputField from '../../core/InputField';
import TestRentMail from '../TestRentMail/TestRentMail';
import { staticRentAutomationTimeFieldArray } from './staticRentAutomationTimeField';




interface IProps {
    idRentAutomation?: number;
}


const RentAutomation:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const rentAutomationArray = useSelector((state: State) => state.rentAutomationArray.storeRentAutomation);
    const rentStateArray = useSelector((state: State) => state.rentStateArray.storeRentState);
    const rentTypeArray = useSelector((state: State) => state.rentTypeArray.storeRentType);
    const rentAutomationTimeFieldArray = staticRentAutomationTimeFieldArray;
    const { setRentAutomationArray,addRentAutomationArray, updateRentAutomationArray } = bindActionCreators(actionCreatorsRentAutomation, dispatch)
    // Main Object
    const [rentAutomationObj,setRentAutomationObj] = useState<IRentAutomation | undefined>();
    // Haupstates
    const [idRentAutomation,setIdRentAutomation] = useState<number | null>();
    const [name,setName, vName] = useValidatenText();
    const [daysBefore,setDaysBefore, vDaysBefore] = useValidationNumber();
    const [idRentState_Current,setIdRentState_Current] = useState(1);
    const [idRentType_Current,setIdRentType_Current] = useState(3);
    const [executeBeforceDate,setExecuteBeforceDate] = useState(true);
    const [senMail,setSenMail] = useState(false);
    const [sendMailRentState, setSendMailRentState] = useState(false);
    const [changeStateToTarget,setChangeStateToTarget] = useState(false);
    const [changeTypeToTarget,setChangeTypeToTarget] = useState(false);
    const [idRentAutomationTimeField,setIdRentAutomationTimeField, vIdRentAutomationTimeField] = useValidationNumber(1);
    // Haupstates -> Optional
    const [idRentType_Target,setIdRentType_Target] = useState<number | null>(null);
    const [idRentState_Target,setIdRentState_Target] = useState<number | null>(null);
    // Haupstates -> Mail senden
    const [mailTitle,setMailTitle, vMailTitle] = useValidatenText();
    const [mailBody,setMailBody, vMailBody] = useValidatenText();
    // Validierung
    const [validationArray, setValidationArray] = useState<boolean[]>([]);
    // Metastatea
    const [isNew,setIsNew] = useState(false);
    const [isEmpty,setIsEmpty] = useState(true);


    useEffect(() => {
        resetValues();
        if (props.idRentAutomation === undefined) {
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            
            if (props.idRentAutomation === -1) {
                setIsNew(true)
            } else {
                setIsNew(false);
                setRentAutomationObj(rentAutomationArray.find(x => x.idRentAutomation === props.idRentAutomation));
            }
        }
    },[props.idRentAutomation])

    useEffect(() => {
        setValidationArray([vName && helpCheckVal(vMailTitle) && helpCheckVal(vMailBody),vDaysBefore ])
    },[vName,vDaysBefore,vMailTitle,vMailBody,senMail])


    const helpCheckVal = (valState:boolean) => {
        if (senMail) {
            return valState;
        } else {
            return true;
        }
    }



    const valuesArray:ISetValues[] = [
        {
            Attribute: "idRentAutomation",
            Value: idRentAutomation,
            SetFunction: setIdRentAutomation,
            IsPrimaryKey: true
        },
        {
            Attribute: "idRentAutomationTimeField",
            Value: idRentAutomationTimeField,
            SetFunction: setIdRentAutomationTimeField,
            Type: 'number'
        },
        {
            Attribute: "Name",
            Value: name,
            SetFunction: setName,
        }, 
        {
            Attribute: "sendMail",
            Value: senMail,
            SetFunction: setSenMail,
            Type: 'boolean',
        }, 
        {
            Attribute: "sendMailRentState",
            Value: sendMailRentState,
            SetFunction: setSendMailRentState,
            Type: 'boolean',
        },
        {
            Attribute: "changeStateToTarget",
            Value: changeStateToTarget,
            SetFunction: setChangeStateToTarget,
            Type: 'boolean',
        },
        {
            Attribute: "changeTypeToTarget",
            Value: changeTypeToTarget,
            SetFunction: setChangeTypeToTarget,
            Type: 'boolean',
        },
        {
            Attribute: "idRentState_Current",
            Value: idRentState_Current,
            SetFunction: setIdRentState_Current,
            Type: 'number'
        },
        {
            Attribute: "idRentType_Current",
            Value: idRentType_Current,
            SetFunction: setIdRentType_Current,
            Type: 'number'
        },
        {
            Attribute: "DaysBefore",
            Value: daysBefore,
            SetFunction: setDaysBefore,
            Type: 'number'
        },
        {
            Attribute: "executeBeforceDate",
            Value: executeBeforceDate,
            SetFunction: setExecuteBeforceDate,
            Type: 'boolean'
        },




        {
            Attribute: "MailTitle",
            Value: mailTitle,
            SetFunction: setMailTitle,
            PossibleUndefined: true,
            Type: 'string'
        }, 
        {
            Attribute: "MailBody",
            Value: mailBody,
            SetFunction: setMailBody,
            PossibleUndefined: true,
            Type: 'string'
        }, 
        {
            Attribute: "idRentType_Target",
            Value: idRentType_Target,
            SetFunction: setIdRentType_Target,
            PossibleUndefined: true,
            Type: 'number'
        },
        {
            Attribute: "idRentState_Target",
            Value: idRentState_Target,
            SetFunction: setIdRentState_Target,
            PossibleUndefined: true,
            Type: 'number'
        },
        /*
        {
            Attribute: "Headline_1",
            Value: headline_1,
            SetFunction: setHeadline_1,
            PossibleUndefined: true,
            Type: 'string'
        }, 
        {
            Attribute: "Headline_2",
            Value: headline_2,
            SetFunction: setHeadline_2,
            PossibleUndefined: true,
            Type: 'string'
        }, 
        {
            Attribute: "OptionalFooter",
            Value: optionalFooter,
            SetFunction: setOptionalFooter,
            PossibleUndefined: true,
            Type: 'string'
        }
        */
    ];

    const inputField:IInputValues[] = [
        {
            InputElement: <InputField isEdit={!isEmpty} label='Name der Automatisierung' value={name} setFunction={setName} isValidated={vName}/>,
        },

        {
            InputElement:   <TextField
                                select
                                disabled={isEmpty}
                                label="Aktuelle Buchungsart"
                                value={String(idRentType_Current)}
                                error={!idRentType_Current && !isEmpty}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdRentType_Current( Number(event.target.value) ) }
                                variant="standard"
                                fullWidth
                            >
                                {rentTypeArray.map(x => 
                                    <MenuItem value={x.idRentType}>
                                        {x.Type}
                                    </MenuItem>
                                )}
                            </TextField>,
            ColSpanSm: 6
            
        },
        {
            InputElement:   <TextField
                                select
                                disabled={isEmpty}
                                label="Aktueller Buchungsstatus"
                                value={String(idRentState_Current)}
                                error={!idRentState_Current && !isEmpty}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdRentState_Current( Number(event.target.value) ) }
                                variant="standard"
                                fullWidth
                            >
                                {rentStateArray.map(x => 
                                    <MenuItem value={x.idRentState}>
                                        {x.State}
                                    </MenuItem>
                                )}
                            </TextField>,
            ColSpanSm: 6
        },

        {
            InputElement:   <TextField
                                select
                                disabled={isEmpty}
                                label="Angezielte Buchungsart (optional)"
                                value={String(idRentType_Target)}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdRentType_Target( Number(event.target.value) ) }
                                variant="standard"
                                fullWidth
                            >
                                {rentTypeArray.map(x => 
                                    <MenuItem value={x.idRentType}>
                                        {x.Type}
                                    </MenuItem>
                                )}
                            </TextField>,
            ColSpanSm: 6
            
        },
        {
            InputElement:   <TextField
                                select
                                disabled={isEmpty}
                                label="Angezielter Buchungsstatus (optional)"
                                value={String(idRentState_Target)}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdRentState_Target( Number(event.target.value) ) }
                                variant="standard"
                                fullWidth
                            >
                                {rentStateArray.map(x => 
                                    <MenuItem value={x.idRentState}>
                                        {x.State}
                                    </MenuItem>
                                )}
                            </TextField>,
            ColSpanSm: 6
        },




        {
            InputElement: <InputField isEdit={!isEmpty} label='Tage' value={daysBefore} setFunction={setDaysBefore} isValidated={vDaysBefore || !isEmpty}/>,
            ColSpanSm: 4
        },


        {
            InputElement:   <TextField
                                select
                                disabled={isEmpty}
                                label="Ausführung"
                                value={(executeBeforceDate) ? "yes" : "no"}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setExecuteBeforceDate( event.target.value === "yes") }
                                variant="standard"
                                fullWidth
                            >
                                <MenuItem value="yes">vor</MenuItem>
                                <MenuItem value="no">nach</MenuItem>
                            </TextField>,
            ColSpanSm: 4
        },


        {
            InputElement:   <TextField
                                select
                                disabled={isEmpty}
                                label="Datumsattribut"
                                value={String(idRentAutomationTimeField)}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdRentAutomationTimeField( Number(event.target.value) ) }
                                variant="standard"
                                fullWidth
                            >
                                {rentAutomationTimeFieldArray.map(x => 
                                    <MenuItem value={x.idRentAutomationTimeField}>
                                        {x.Title}
                                    </MenuItem>
                                )}
                            </TextField>,
            ColSpanSm: 4
        },


        {
            InputElement:         <FormControl>
                                    <FormLabel>Mail versenden</FormLabel>
                                    <RadioGroup 
                                        row
                                        defaultValue="false" 
                                        value={(senMail) ? "true" : "false"} 
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setSenMail(event.target.value === "true") }
                                    >
                                        <FormControlLabel disabled={isEmpty} value="true" control={<Radio />} label="Ja" />
                                        <FormControlLabel disabled={isEmpty} value="false" control={<Radio />} label="Nein" />
                                    </RadioGroup>
                                </FormControl>
        },

        {
            InputElement:         <FormControl>
                                    <FormLabel>Mail versenden vom Zielstatus (falls vorhanden)</FormLabel>
                                    <RadioGroup 
                                        row
                                        defaultValue="false" 
                                        value={(sendMailRentState) ? "true" : "false"} 
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setSendMailRentState(event.target.value === "true") }
                                    >
                                        <FormControlLabel disabled={isEmpty} value="true" control={<Radio />} label="Ja" />
                                        <FormControlLabel disabled={isEmpty} value="false" control={<Radio />} label="Nein" />
                                    </RadioGroup>
                                </FormControl>
        },

        {
            InputElement:         <FormControl>
                                    <FormLabel>Ändern auf Zielart</FormLabel>
                                    <RadioGroup 
                                        row
                                        defaultValue="false" 
                                        value={(changeTypeToTarget) ? "true" : "false"} 
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setChangeTypeToTarget(event.target.value === "true") }
                                    >
                                        <FormControlLabel disabled={isEmpty} value="true" control={<Radio />} label="Ja" />
                                        <FormControlLabel disabled={isEmpty} value="false" control={<Radio />} label="Nein" />
                                    </RadioGroup>
                                </FormControl>
        },

        {
            InputElement:         <FormControl>
                                    <FormLabel>Ändern auf Zielstatus</FormLabel>
                                    <RadioGroup 
                                        row
                                        defaultValue="false" 
                                        value={(changeStateToTarget) ? "true" : "false"} 
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setChangeStateToTarget(event.target.value === "true") }
                                    >
                                        <FormControlLabel disabled={isEmpty} value="true" control={<Radio />} label="Ja" />
                                        <FormControlLabel disabled={isEmpty} value="false" control={<Radio />} label="Nein" />
                                    </RadioGroup>
                                </FormControl>
        },



        {
            InputElement: <InputField isEdit={!isEmpty && senMail} label='Title' value={mailTitle} setFunction={setMailTitle} isValidated={helpCheckVal(vMailTitle)} />,
        },
        {
            InputElement: <InputField isEdit={!isEmpty && senMail} label='Emailkörper' value={mailBody} rows={20} setFunction={setMailBody} isValidated={helpCheckVal(vMailBody)}/>,

        },
        {
            InputElement: <div style={{minHeight: 200, backgroundColor: "with"}}><Typography>HTML Ansicht:</Typography> <div dangerouslySetInnerHTML={{ __html: (mailBody === null) ? "" : mailBody }} /></div>,
        },
        /*
        {
            InputElement: <InputField isEdit={!isEmpty && sendMail} label='Fußzeile' rows={3} value={optionalFooter} setFunction={setOptionalFooter}/>,
        },
        */

    ]

    const resetValues = () => {
        setRentAutomationObj(undefined);
        setIdRentAutomation(null);
        setIdRentAutomationTimeField(2);
        setName(null);
        setExecuteBeforceDate(false);
        setSenMail(false);
        setSendMailRentState(false);
        setChangeStateToTarget(false);
        setChangeTypeToTarget(false);
        setIdRentState_Current(1);
        setIdRentType_Current(3);
        setDaysBefore(null);

        setMailTitle(null);
        setMailBody(null);
        setIdRentType_Target(null);
        setIdRentState_Target(null);
        //valuesArray.filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey == false).map(x => (x.Type !== undefined && x.Type === "boolean") ? x.SetFunction(false) : x.SetFunction(null))
    }


    return(
        <>  
            <TestRentMail mailTitle={mailTitle} mailBody={mailBody} disabled={isEmpty || !senMail} />
            <GenericEdit
                headlineNew="Neue Automatisierung"
                headlineAttr="Name"
                setValuesDef={valuesArray}
                inputFilds={inputField}
                // Zum Hochladen
                endpoint="rentAutomation"
                // Hauptelemeten
                mainObject={rentAutomationObj}
                setMainObject={setRentAutomationObj}
                // Zum Validieren
                validatenArray={validationArray}
                // Metastates
                isEmpty={isEmpty}
                isNew={isNew}
                updatedReduxArray={updateRentAutomationArray}
                addReduxArray={ addRentAutomationArray}
            />
        </>
    )

}
export default RentAutomation;