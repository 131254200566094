import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICampaign } from "../interfaces/ICampaign";


const initialState =  { storeCampaign: [] as ICampaign[] }; 

const sliceCampaign = createSlice({
    name: "CAMPAIGNARRAY",
    initialState,
    reducers: {
        setCampaignArray: (state, action: PayloadAction<ICampaign[]>) => {
            state.storeCampaign = [...action.payload];
        },
        addCampaignArray: (state, action: PayloadAction<ICampaign>) => {
            state.storeCampaign = [...state.storeCampaign,action.payload];
        },
        updateCampaignArray: (state, action: PayloadAction<ICampaign>) => {
            let tmpArray =  state.storeCampaign.map(x => x.idCampaign === action.payload.idCampaign ? action.payload : x);
            state.storeCampaign = tmpArray;
        }
    }
})
export const { setCampaignArray, addCampaignArray, updateCampaignArray} = sliceCampaign.actions;
export default sliceCampaign.reducer;