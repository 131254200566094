import { Alert, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRent } from '../../interfaces/IRent';
import { State } from '../../redux/combine_reducers';
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import { staticRentTimeFrameArray } from '../Rent/TmpRentTimeFrameArray';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import deLocale from "date-fns/locale/de";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Calculate, Link, LockReset, Restore } from '@mui/icons-material';
import RentGetAppendix from './RentGetAppendix';
import RentInvoiceStatus from './RentInvoiceStatus';
import { IPerson } from '../../interfaces/IPerson';
import CustomerEdit from '../Customer_new/CustomerEdit';
import { RentThirdPary } from './RentThirdPary';



export interface IProps {
    currentObject: IRent;
    setCurrentObject: Function;
}



const RentEdit: React.FC<IProps> = (props) => {
    // Redux
    const dispatch = useDispatch();
    const rentArray = useSelector((state: State) => state.rentArray.storeRent);
    const rentTypeArray = useSelector((state: State) => state.rentTypeArray.storeRentType);
    const rentStateArray = useSelector((state: State) => state.rentStateArray.storeRentState);
    const roomArray = useSelector((state: State) => state.roomArray.storeRoom);
    const locationArray = useSelector((state: State) => state.locationArray.storeLocation);
    const vatObject = useSelector((state: State) => state.vatObject.storeVAT);
    //const regionArray = useSelector((state: State) => state.regionArray.storeRegions);
    const roomPriceArray = useSelector((state: State) => state.roomPriceArray.storeRoomPrice);
    const unitArray = useSelector((state: State) => state.unitsArray.storeUnits);
    const productArray = useSelector((state: State) => state.productArray.storeProducts);
    const productHasRoomArray = useSelector((state: State) => state.productHasRoomArray.storeProductHasRoom);
    // Dialoge
    const [isOpenResetRentState, setIsOpenResetRentState] = useState(false);
    //
    const [wasChanged, setWasChanged] = useState((props.currentObject.idRent < 0));
    const [showPriceWarning, setShowPriceWarning] = useState(false);
    //
    const [disableRentStateValidation, setDisableRentStateValidation] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [idLocation, setIdLocation] = useState(1);

    useEffect(() => {
        if (props.currentObject.idRent > 0 && props.currentObject.idPerson > 0) {
            window.history.pushState({}, "", `/customers/${props.currentObject.idPerson}/${props.currentObject.idRent}`);
        }
    }, [props.currentObject])


    useEffect(() => {
        if (props.currentObject?.idRentTimeFrame != undefined) {
            setIsEditable(staticRentTimeFrameArray.find(x => x.idRentTimeFrame === props.currentObject?.idRentTimeFrame)?.isEditable == true)
        }
    }, [props.currentObject?.idRentTimeFrame])

    useEffect(() => {
        if (props.currentObject?.idRoom) {
            let newIdLocation = roomArray.find(x => x.idRoom === props.currentObject?.idRoom)?.idLocation;

            if (newIdLocation != undefined && newIdLocation != idLocation) {
                setIdLocation(newIdLocation);
            }
        }
    }, [props.currentObject?.idRoom])


    const genPrice = () => {
        let tmpObject = { ...props.currentObject };

        if (props.currentObject.FromDate != undefined) {

            let currentPrice = 0;
            let currentMinUnits = 0;


            if (props.currentObject?.idRentTimeFrame !== 1) {
                let foundTimeFrame = staticRentTimeFrameArray.find(x => x.idRentTimeFrame === props.currentObject?.idRentTimeFrame);
                let tmpFromDate = new Date(props.currentObject.FromDate);


                tmpFromDate.setMonth(tmpFromDate.getMonth() + Number(foundTimeFrame?.Units));



                tmpObject.Units = Number(foundTimeFrame?.Units);
                tmpObject.ToDate = `${tmpFromDate.getFullYear()}-${tmpFromDate.getMonth() + 1}-${tmpFromDate.getDate()}`;
                console.log(`${tmpFromDate.getFullYear()}-${tmpFromDate.getMonth() + 1}-${tmpFromDate.getDate()}`)
                //tmpObject.ToDate = tmpFromDate
                /*
                props.setCurrentObject({
                    ...tmpObject,
                    ToDate: tmpFromDate
                })
                */

            }
            else if (props.currentObject.ToDate != undefined) {
                let dateFromDate = new Date(props.currentObject.FromDate);
                let dateToDate = new Date(props.currentObject.ToDate);
                let units = dateToDate.getMonth() - dateFromDate.getMonth() + (12 * (dateToDate.getFullYear() - dateFromDate.getFullYear()))
                tmpObject.Units = units;
                /*
                props.setCurrentObject({
                    ...props.currentObject,
                    Units: units,
                    //FormData: dateFromDate,
                    //ToDate: dateToDate
                });
                */
            }

            roomPriceArray
                .filter(x => x.idRoom === tmpObject.idRoom)
                .map(x => {
                    if (x.MinUnits <= Number(tmpObject.Units) && tmpObject.Units && x.MinUnits >= currentMinUnits) {
                        currentMinUnits = x.MinUnits;
                        currentPrice = Number(x.PricePerUnit);
                    }
                });

            tmpObject.PricePerUnit = currentPrice;
            tmpObject.Deposit = Math.round((currentPrice * tmpObject.DepositInMounths * (1+vatObject.VAT)) * 100) / 100

            //props.setCurrentObject({...tmpObject});
        }
        return tmpObject;
    }


    useEffect(() => {

        if (wasChanged) {
            setShowPriceWarning(false);
            // if: nur werte ändern, wenn Status unter Vertrag
            if (props.currentObject.idRentState < 7) {
                props.setCurrentObject(genPrice());
            }
            // end: if
        } else {
            let testObject = genPrice();

            if (props.currentObject.PricePerUnit != testObject.PricePerUnit || props.currentObject.Deposit != testObject.Deposit) {
                setShowPriceWarning(true);
                // if: nur werte ändern, wenn Status unter Vertrag
                if (props.currentObject.idRentState < 7) {
                    props.setCurrentObject({
                        ...props.currentObject,
                        Deposit: Math.round((props.currentObject.PricePerUnit * props.currentObject.DepositInMounths * (1+vatObject.VAT)) * 100) / 100
                    })
                }
                // end: if

            } else {
                setShowPriceWarning(false);
                // if: nur werte ändern, wenn Status unter Vertrag
                if (props.currentObject.idRentState < 7) {
                    props.setCurrentObject(genPrice());
                }
                // end: if
            }
        }
    }, [
        props.currentObject?.idRoom,
        props.currentObject?.idRentTimeFrame,
        props.currentObject?.FromDate,
        props.currentObject?.ToDate,
        props.currentObject?.DepositInMounths
    ])

    /*
    // useEffect: Preis berechnent
    useEffect(() => {
        if (props.currentObject != undefined && props.currentObject?.idRoom != undefined && props.currentObject?.Units != undefined) {
            let currentPrice = 0;
            let currentMinUnits = 0;

            roomPriceArray
                .filter(x => x.idRoom === props.currentObject?.idRoom)
                .map(x => {
                    if (x.MinUnits <= Number(props.currentObject?.Units) && props.currentObject?.Units && x.MinUnits >= currentMinUnits) {
                        currentMinUnits = x.MinUnits;
                        currentPrice = Number(x.PricePerUnit);
                    }
                });

            props.setCurrentObject({
                ...props.currentObject,
                PricePerUnit: currentPrice,
                Deposit: Math.round((currentPrice * props.currentObject.DepositInMounths) * 100) / 100
            });

        }
    }, [
        props.currentObject?.Units,
        props.currentObject?.DepositInMounths,
    ])
    */

    /*
    useEffect(() => {
        if (props.currentObject != undefined) {
            props.setCurrentObject({
                ...props.currentObject,
                Deposit: Math.round((props.currentObject.PricePerUnit * props.currentObject.DepositInMounths) * 100) / 100
            });
        }
    }, [props.currentObject?.Units, props.currentObject?.DepositInMounths])
    */

    /*
    const handleAddThirdPartyPerson = () => {
        props.setThirdPartyPerson({
            Email: "",
            FirstName: "",
            idCompany: -1,
            hasNewsletter: false,
            idGender: 1,
            idLeadSource: 1,
            idLeadState: 5,
            idPerson: -1,
            isBlocked: false,
            LastName: "",
            idLead: null,
            Birthday: null,
            Birthplace: null,
            idCampaign: null,
            idMedium: null,
            InternComment: null,
            MobileNumber: null,
            PhoneNumber: null,
            StripeId_Customer: null
        } as IPerson)
    }

    const handleRemoveThirdPartyPerson = () => {
        props.setCurrentObject({
            ...props.currentObject,
            ThirdParty_idPerson: null
        } as IRent);
        props.setThirdPartyPerson(null);
    }
    */


    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        let splitTest = event.target.name.split("-");
        let attrName = splitTest[1];
        let testVal = { ...props.currentObject } as any

        if (attrName in testVal) {
            if (splitTest[0] === "number") {
                //props.setCurrentObject({...props.currentObject, attrName : Number(event.target.value)})
                testVal[attrName] = Number(event.target.value);
            }
            else if (splitTest[0] === "boolean") {
                //props.setCurrentObject({...props.currentObject, attrName : String(event.target.value) === "true"})
                testVal[attrName] = String(event.target.value) === "true";
            }
            else {
                console.log({ ...props.currentObject, attrName: String(event.target.value) })
                //props.setCurrentObject({...props.currentObject, attrName : String(event.target.value)});
                testVal[attrName] = String(event.target.value);
            }
        } else {
            console.warn(`Can't ${attrName} in Interface`)
        }

        props.setCurrentObject({ ...testVal })
    }

    /*
    const recalcPrices = (idRoom:number,presetUnits:number=0) => {
        let units = presetUnits;
        let pricePerUnit = 0;
        let deposi = 0;
        let foundRoom = roomArray.find(x => x.idRoom == idRoom);

        if (props.currentObject?.FromDate != undefined && props.currentObject?.ToDate != undefined) {
            let dateFromDate = new Date(props.currentObject?.FromDate);
            let dateToDate = new Date(props.currentObject?.ToDate);
            units = dateToDate.getMonth() - dateFromDate.getMonth() + (12* (dateToDate.getFullYear() - dateFromDate.getFullYear()))
        }

        if (foundRoom != undefined && units > 0) {
            let priceArray = roomPriceArray.filter(x => x.idRoom === idRoom);
            let lastMinUnits = 0;

            priceArray.map(x => {
                    if (x.MinUnits <= units && x.MinUnits >= lastMinUnits) {
                        lastMinUnits = x.MinUnits;
                        pricePerUnit = Number(x.PricePerUnit);
                    }
                }
            )
            deposi = Math.round((pricePerUnit*1.5)*100)/100;
        }

        props.setCurrentObject({
            ...props.currentObject,
            idRoom: Number(idRoom),
            Units: units,
            PricePerUnit: pricePerUnit,
            Deposit: deposi,
        } as IRent)
    }
    */

    const handleChangeLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIdLocation(Number(event.target.value))
        props.setCurrentObject({
            ...props.currentObject,
            idRoom: -1,
            Units: 0,
            PricePerUnit: 0,
            Deposit: 0,
        } as IRent)
    }


    const handleChangeRoom = (event: React.ChangeEvent<HTMLInputElement>) => {
        //recalcPrices(Number(event.target.value))
        setWasChanged(true);
        props.setCurrentObject({ ...props.currentObject, idRoom: Number(event.target.value) });
    }


    const handleRentTimeFrame = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWasChanged(true);
        props.setCurrentObject({ ...props.currentObject, idRentTimeFrame: Number(event.target.value) });
    }

    const handleFromDate = (event: Date | null) => {
        if (props.currentObject != undefined) {
            if (typeof event === "object" && event !== null) {
                props.setCurrentObject({ ...props.currentObject, FromDate: new Date(event).toISOString().split("T")[0] });
            }
            else {
                props.setCurrentObject({ ...props.currentObject, FromDate: null });
            }
        }
    }

    const checkRentStateDisabled = (currentIdRentState: number) => {
        let testOrgRent = rentArray.find(x => x.idRent === props.currentObject?.idRent);

        if (disableRentStateValidation === true) {
            return false;
        }
        else if (testOrgRent === undefined || testOrgRent.RentNummer == undefined) {
            // Bei neu -> nur offen

            return currentIdRentState !== 1;
        }
        else {
            return currentIdRentState < testOrgRent.idRentState;
        }
    }

    /*
    const handleChandeTimeFrame = (event:React.ChangeEvent<HTMLInputElement>) => {
        let idTimeFrame = Number(event.target.value);
        let foundTimeFrame = staticRentTimeFrameArray.find(x => x.idRentTimeFrame === idTimeFrame);
        let tmpObject = {...props.currentObject};

        if (foundTimeFrame != undefined && foundTimeFrame.isEditable == false && props.currentObject?.FromDate != undefined) {
            let tmpFromDate = new Date(props.currentObject?.FromDate);
            tmpFromDate.setMonth(tmpFromDate.getMonth() + foundTimeFrame.Units);

            tmpObject.ToDate = tmpFromDate.toISOString().split("T")[0];
            tmpObject.Units = Number(foundTimeFrame?.Units);
        }

        tmpObject.idRentTimeFrame = Number(foundTimeFrame?.idRentTimeFrame);
        props.setCurrentObject({...tmpObject});
    }

    const handleChangeFromDate = (newValue:Date|null) => {
        if (newValue !== null) {
            let foundTimeFrame = staticRentTimeFrameArray.find(x => x.idRentTimeFrame === props.currentObject?.idRentTimeFrame);
            let tmpFromDate = new Date(newValue);
            let tmpToDate = new Date();

            if (props.currentObject?.ToDate != undefined )
                { tmpToDate = new Date(props.currentObject?.ToDate); }
            else
                {
                    tmpToDate = new Date(tmpFromDate);
                    tmpToDate.setMonth(tmpFromDate.getMonth() + 1);
                }

            if (tmpToDate < tmpFromDate) {
                tmpToDate = new Date(tmpFromDate);
                tmpToDate.setMonth(tmpFromDate.getMonth() + 1);
            }

            if (foundTimeFrame != undefined && foundTimeFrame?.isEditable == false) {
                tmpToDate = new Date(tmpFromDate);
                tmpToDate.setMonth(tmpFromDate.getMonth() + foundTimeFrame.Units);
            }

            props.setCurrentObject({
                ...props.currentObject,
                FromDate: tmpFromDate.toISOString().split("T")[0],
                ToDate: tmpToDate.toISOString().split("T")[0],
            } as IRent);
        }

    }



    <GridItem sm={6}>
                        <DesktopDatePicker
                            label="Von"
                            value={(props.currentObject.FromDate == undefined) ? null : props.currentObject.FromDate}
                            onChange={handleFromDate}

                            renderInput={(params) => <TextField fullWidth size="small" {...params} error={props.currentObject?.FromDate == undefined} />}
                        />
                    </GridItem>
    */


    const checkString = (possibleString: string | null | undefined | number) => {
        if (possibleString == undefined) {
            return "";
        } else {
            return String(possibleString);
        }
    }


    const genNumberByContext = () => {
        if (props.currentObject?.idRentType === 1) {
            return (
                <GridItem sm={12}>
                    <TextField
                        label="Reservierungsnummer"
                        size="small"
                        value={checkString(props.currentObject.RequestNumber)}
                        disabled={true}
                        fullWidth
                        variant='standard'
                    />
                </GridItem>
            )
        } else {
            return (
                <>
                    <GridItem sm={6}>
                        <TextField
                            label="Reservierungsnummer"
                            size="small"
                            value={checkString(props.currentObject?.RequestNumber)}
                            disabled={true}
                            fullWidth
                            variant='standard'
                        />
                    </GridItem>

                    <GridItem sm={6}>
                        <TextField
                            label="Buchungsnummer"
                            size="small"
                            value={checkString(props.currentObject?.RentNummer)}
                            disabled={true}
                            fullWidth
                            variant='standard'
                        />
                    </GridItem>
                </>
            )
        }
    }


    const dateWrapper = (possibleDate: Date | null) => {
        if (possibleDate === null) {
            return null;
        } else {
            if (isNaN(possibleDate.getTime())) {
                return possibleDate;
            }
            else {
                let userTimezoneOffset = possibleDate.getTimezoneOffset() * 60000;
                return new Date(possibleDate.getTime() - userTimezoneOffset);
            }
        }
    }


    if (props.currentObject == undefined) { return <>Bitte Buchung wählen....</> }
    else {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                <Dialog
                    open={isOpenResetRentState}
                    onClose={() => setIsOpenResetRentState(false)}
                >
                    <DialogTitle>Deaktivierung Miete-Status-Validierung</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Sie sind dabei die Miete-Status-Validierung zu deaktiveren.
                            Nach einer Änderung des Status wird die Validierung reaktiviert.
                            <p />Bitte beachten Sie:
                            <ul>
                                <li>Mögliche Automatisierung wird ggf. erneut ausgeführt</li>
                                <li>
                                    Email versand, (DocuSign)-Integrationen etc. werden nur ausgeführt,
                                    wenn von einem niedrigeren auf einen höheren Status gewechselt wird.
                                    <ul>
                                        <li>Funktioniert: Reservierung zu Vertrag</li>
                                        <li>Funktioniert nicht: Aktiv zu Vertrag</li>
                                    </ul>
                                </li>
                            </ul>
                            <p />
                            Wollen Sie die Miete-Status-Validierung deaktiveren?
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={() => setIsOpenResetRentState(false)}>Schließen</Button>
                            <Button onClick={() => { setDisableRentStateValidation(true); setIsOpenResetRentState(false) }}>Deaktivieren</Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>


                <RentGetAppendix rentObject={props.currentObject} />

                <Collapse style={{ marginTop: 25 }} in={showPriceWarning} >
                    <Alert
                        severity="warning"
                        sx={{ mb: 2 }}
                    >
                        Es liegen nicht die aktuellsten Preise vor!
                    </Alert>
                </Collapse>
                <Collapse style={{ marginTop: 25 }} in={props.currentObject.DepositInMounths != 2}>
                    <Alert
                        severity="warning"
                        sx={{ mb: 2 }}
                    >
                        Die Kaution entspricht nicht zweimal der Monatsmiete.
                    </Alert>
                </Collapse>
                <Box sx={{ mt: 5 }} />

                <GridContainer>
                    <GridItem sm={12}>
                        <TextField
                            select
                            label="Zeitraum"

                            disabled={props.currentObject === undefined || props.currentObject.idRentState >= 7}
                            name="number-idRentTimeFrame"
                            size="small"
                            value={checkString(props.currentObject.idRentTimeFrame)}
                            onChange={handleRentTimeFrame}
                            error={props.currentObject.idRentTimeFrame == null}
                            fullWidth
                        >
                            {staticRentTimeFrameArray.map(x =>
                                <MenuItem value={x.idRentTimeFrame}>
                                    {x.Description}
                                </MenuItem>
                            )}
                        </TextField>
                    </GridItem>




                    <GridItem sm={6}>
                        <DesktopDatePicker
                            label="Von"
                            disabled={props.currentObject === undefined || props.currentObject.idRentState >= 7}
                            value={(props.currentObject.FromDate == undefined) ? null : props.currentObject.FromDate}
                            onChange={(newValue: Date | null) => { setWasChanged(true); props.setCurrentObject({ ...props.currentObject, FromDate: dateWrapper(newValue) }) }}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} error={props.currentObject?.FromDate == undefined || isNaN(new Date(props.currentObject?.FromDate).getTime())} />}
                        />
                    </GridItem>

                    <GridItem sm={6}>
                        <DesktopDatePicker
                            label="Bis"
                            value={(props.currentObject.ToDate == undefined) ? null : props.currentObject.ToDate}
                            disabled={!isEditable || props.currentObject === undefined || props.currentObject.idRentState >= 7}
                            onChange={(newValue: Date | null) => { setWasChanged(true); props.setCurrentObject({ ...props.currentObject, ToDate: dateWrapper(newValue) }) }}
                            renderInput={(params) => <TextField fullWidth size="small" disabled={!isEditable} {...params} error={props.currentObject?.ToDate == undefined || isNaN(new Date(props.currentObject?.ToDate).getTime())} />}
                        />
                    </GridItem>



                    <GridItem sm={12}>
                        <DesktopDatePicker
                            label="Vertragsabschluss"
                            value={(props.currentObject.ConclusionOfContract == undefined) ? null : props.currentObject.ConclusionOfContract}
                            onChange={(newValue: Date | null) => props.setCurrentObject({ ...props.currentObject, ConclusionOfContract: dateWrapper(newValue) })}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </GridItem>


                    <GridItem sm={6}>
                        <DesktopDatePicker
                            label="Übergabeprotokoll"
                            value={(props.currentObject.HandingOverDate == undefined) ? null : props.currentObject.HandingOverDate}
                            onChange={(newValue: Date | null) => props.setCurrentObject({ ...props.currentObject, HandingOverDate: dateWrapper(newValue) })}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </GridItem>


                    <GridItem sm={6}>
                        <DesktopDatePicker
                            label="Kündigungsdatum"
                            value={(props.currentObject.Terminated_at == undefined) ? null : props.currentObject.Terminated_at}
                            onChange={(newValue: Date | null) => props.setCurrentObject({ ...props.currentObject, Terminated_at: dateWrapper(newValue) })}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </GridItem>


                    <GridItem sm={6}>
                        <DesktopDatePicker
                            label="Vertragsende"
                            value={(props.currentObject.EndOfContract == undefined) ? null : props.currentObject.EndOfContract}
                            onChange={(newValue: Date | null) => props.setCurrentObject({ ...props.currentObject, EndOfContract: dateWrapper(newValue) })}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </GridItem>

                    <GridItem sm={6}>
                        <DesktopDatePicker
                            label="Auszugstermin"
                            value={(props.currentObject.MovingOut == undefined) ? null : props.currentObject.MovingOut}
                            onChange={(newValue: Date | null) => props.setCurrentObject({ ...props.currentObject, MovingOut: dateWrapper(newValue) })}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </GridItem>

                    <GridItem sm={6}>
                        <TextField
                            label="Stromzählerstand Mietbeginn"
                            size="small"
                            type="number"
                            value={props.currentObject.ElectricityMeterReadingBegin}
                            fullWidth
                            onChange={handleInput}
                            name="number-ElectricityMeterReadingBegin"
                        />
                    </GridItem>
                    <GridItem sm={6}>

                        <TextField
                            label="Stromzählerstand Mietende"
                            size="small"
                            type="number"
                            value={props.currentObject.ElectricityMeterReadingEnd}
                            fullWidth
                            onChange={handleInput}
                            name="number-ElectricityMeterReadingEnd"
                        />
                    </GridItem>

                    <GridItem sm={12}></GridItem>
                    <GridItem sm={12}></GridItem>
                    {genNumberByContext()}
                    <GridItem sm={12}>
                        <Box sx={{display: "flex"}}>
                            <TextField
                                label="DocuSign Vertragsnummer"
                                size="small"
                                value={checkString(props.currentObject?.EnvelopeId)}
                                disabled={true}
                                fullWidth
                                variant='standard'
                            />
                            <IconButton
                                disabled={props.currentObject?.EnvelopeId === null}
                                onClick={() =>{
                                    window.open(`https://apps.docusign.com/send/documents/details/${props.currentObject?.EnvelopeId }`, "_blank")
                                }}
                            ><Link /> </IconButton>
                        </Box>
                    </GridItem>

                    <GridItem sm={12}>
                        <RentThirdPary
                            key={`thirdperson-${props.currentObject.idRent}`}
                            currentRent={props.currentObject}
                            setCurrentRent={props.setCurrentObject}
                        />
                        {/*(props.thirdPartyPerson === null)
                            ? <Button 
                                variant='contained' 
                                sx={{float: "right"}}
                                disabled={props.currentObject.idRentState >= 7}
                                onClick={handleAddThirdPartyPerson}
                            >Weitere Vertragspartei hinzufügen</Button>
                            : <><CustomerEdit
                                key={`thirdParty-${props.thirdPartyPerson.idPerson}`}
                                currentObject={props.thirdPartyPerson} 
                                setCurrentObject={props.setThirdPartyPerson}
                                enableFullEdit={false}
                                disabled={props.currentObject.idRentState >= 7}
                              >
                                <>
                                    <>Drittpartei</>
                                    <Button 
                                        sx={{float: "right"}} 
                                        variant='outlined' 
                                        color="error"
                                        disabled={props.currentObject.idRentState >= 7}
                                        onClick={handleRemoveThirdPartyPerson}
                                    >Drittpartei Entfernen</Button>
                                </>
                              </CustomerEdit>

                            */}
                    </GridItem>

                    <GridItem sm={12}></GridItem>
                    <GridItem sm={12}></GridItem>
                    <GridItem sm={12}>
                        <RentInvoiceStatus rentObject={props.currentObject} />
                    </GridItem>
                    <GridItem sm={12}></GridItem>
                    <GridItem sm={12}></GridItem>

                    <GridItem sm={(props.currentObject.idRentType === 1) ? 12 : 5}>
                        <TextField
                            select
                            disabled={props.currentObject === undefined || props.currentObject.idRentState >= 7}
                            label="Art"
                            name="number-idRentType"
                            size="small"
                            value={checkString(props.currentObject.idRentType)}
                            onChange={handleInput}
                            error={props.currentObject.idRentType == null}
                            fullWidth
                        >
                            {rentTypeArray.map(x =>
                                <MenuItem value={x.idRentType}>
                                    {x.Type}
                                </MenuItem>
                            )}
                        </TextField>
                    </GridItem>

                    {(props.currentObject.idRentType != 1) &&
                        <>
                            <GridItem sm={5}>
                                <TextField
                                    select
                                    label="Status"
                                    name="number-idRentState"
                                    size="small"
                                    value={checkString(props.currentObject.idRentState)}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setDisableRentStateValidation(false); handleInput(event); }}
                                    error={props.currentObject.idRentState == null}
                                    fullWidth
                                >
                                    {rentStateArray.map(x =>
                                        <MenuItem
                                            value={x.idRentState}
                                            disabled={checkRentStateDisabled(Number(x.idRentState))}
                                        >
                                            {x.State}
                                        </MenuItem>
                                    )}
                                </TextField>
                            </GridItem>

                            <GridItem sm={2}>
                                <IconButton
                                    onClick={() => setIsOpenResetRentState(true)}
                                    disabled={localStorage.getItem('isAdmin') == undefined || Number(localStorage.getItem('isAdmin')) !== 1}
                                ><LockReset /></IconButton>
                            </GridItem>
                        </>
                    }

                    <GridItem sm={12}>
                        <TextField
                            select
                            label="Produkt"
                            name="number-idProduct"
                            size="small"
                            value={checkString(props.currentObject.idProduct)}
                            onChange={handleInput}
                            error={props.currentObject.idProduct == null}
                            fullWidth
                            disabled={props.currentObject.RentNummer != undefined}
                        >
                            {productArray.map(x =>
                                <MenuItem value={x.idProduct}>
                                    {x.Title}
                                </MenuItem>
                            )}
                        </TextField>
                    </GridItem>

                    <GridItem sm={12}>
                        <TextField
                            select
                            label="Standort"
                            size="small"
                            value={String(idLocation)}
                            onChange={handleChangeLocation}
                            fullWidth
                            disabled={props.currentObject.RentNummer != undefined}
                        >
                            {locationArray.map(x =>
                                <MenuItem value={x.idLocation}>
                                    {x.ShortName}
                                </MenuItem>
                            )}
                        </TextField>
                    </GridItem>

                    <GridItem sm={12}>
                        <TextField
                            select
                            label="Raum"
                            name="number-idRoom"
                            size="small"
                            value={checkString(props.currentObject.idRoom)}
                            onChange={handleChangeRoom}
                            error={props.currentObject.idRoom == null || props.currentObject.idRoom < 0}
                            fullWidth
                            disabled={props.currentObject.RentNummer != undefined}
                        >
                            {roomArray.filter(x => x.idLocation === idLocation).map(x => {
                                const now = new Date()
                                //console.log(now);
                                if (x.RoomNumber == "61") {
                                    console.log("lel");
                                }
                                //console.log(now.getDate());
                                let rent: IRent[] | null = rentArray
                                    .filter(y => y.idRoom === x.idRoom)
                                    .filter(y => y.idRoom === x.idRoom)
                                    .filter(y => y.idRentType === 3)
                                    .filter(y => y.idRentState < 15)
                                    .filter(y => {
                                        let testFromDate = now;
                                        let testToDate = now;
                                        (props.currentObject?.FromDate != undefined) && (testFromDate = new Date(props.currentObject?.FromDate));
                                        (props.currentObject?.ToDate != undefined) && (testToDate = new Date(props.currentObject?.ToDate));

                                        if (y.FromDate == undefined || y.ToDate == undefined) {
                                            return true;
                                        } else {
                                            return (new Date(y.FromDate) <= testToDate && new Date(y.ToDate) >= testFromDate)
                                        }

                                    });
                                return (
                                    <MenuItem value={x.idRoom} disabled={rent != undefined && rent.length > 0 || x.isBlocked == true}>
                                        {(x.Title == undefined) ? x.RoomNumber : x.Title} {(x.isBlocked == true) && <>(Blockiert)</>}
                                    </MenuItem>
                                )
                            }
                            )}
                        </TextField>
                    </GridItem>

                    <GridItem sm={6}>
                        <TextField
                            label="MwSt"
                            name="number-MwSt"
                            size="small"
                            type="number"
                            value={vatObject.VAT*100}
                            onChange={handleInput}
                            fullWidth
                            disabled
                        />
                    </GridItem>


                    <GridItem sm={6}>
                        <TextField
                            label="Monate"
                            name="number-Units"
                            size="small"
                            type="number"
                            value={props.currentObject.Units}
                            onChange={handleInput}
                            fullWidth
                            disabled
                        />
                    </GridItem>

                    <GridItem sm={4}>
                        <TextField
                            label="Preis pro Monat (netto)"
                            name="number-PricePerUnit"
                            size="small"
                            value={ (props.currentObject.PricePerUnit).toFixed(2).replace(".", ",") + " €"}
                            onChange={handleInput}
                            fullWidth
                            disabled
                        />
                    </GridItem>

                    <GridItem sm={4}>
                        <TextField
                            label="Preis pro Monat (brutto)"
                            name="number-PricePerUnit"
                            size="small"
                            value={ (props.currentObject.PricePerUnit*(1+vatObject.VAT)).toFixed(2).replace(".", ",") + " €"}
                            onChange={handleInput}
                            fullWidth
                            disabled
                        />
                    </GridItem>


                    <GridItem sm={4}>
                        <TextField
                            label="Total (netto)"
                            size="small"
                            value={(Number(props.currentObject.Units) * Number(props.currentObject.PricePerUnit)).toFixed(2).replace(".", ",") + " €"}
                            fullWidth
                            disabled
                        />
                    </GridItem>

                    <GridItem sm={6}>
                        <TextField
                            label="Kaution"
                            name="number-Deposit"
                            size="small"
                            value={Number(props.currentObject.Deposit).toFixed(2).replace(".", ",") + " €"}//let funk = (props.currentObject.DepositInMounths == undefined ?  0
                            onChange={handleInput}
                            fullWidth
                            disabled
                        />
                    </GridItem>

                    <GridItem sm={6}>
                        <TextField
                            label="Kaution im Monatsmieten"
                            disabled={props.currentObject === undefined || props.currentObject.idRentState >= 7}
                            name="number-DepositInMounths"
                            size="small"
                            type="number"
                            value={props.currentObject.DepositInMounths}
                            onChange={handleInput}
                            fullWidth

                        />
                    </GridItem>
                    { /*
                    <GridItem sm={6}>
                        <Box sx={{float: 'right'}}>
                            <IconButton
                                title='Bestimme aktuellsten Preis'
                                disabled={props.currentObject == undefined || props.currentObject.idRoom < 0}
                                onClick={() => recalcPrices(Number(props.currentObject?.idRoom))}
                            ><Calculate/></IconButton>
                        </Box>
                    </GridItem>
                    */}



                    <GridItem sm={12}>
                        <TextField
                            label="Sonderregelungen für den Mietvertrag (LaTeX-Code)"
                            name="string-SpecialArrangement"
                            size="small"
                            value={checkString(props.currentObject.SpecialArrangement)}
                            onChange={handleInput}
                            fullWidth
                            multiline
                            rows={5}
                        />
                    </GridItem>

                    <GridItem sm={12}>
                        <TextField
                            label="Kundenbemerkung"
                            name="string-CustomerMessage"
                            size="small"
                            value={checkString(props.currentObject.CustomerMessage)}
                            onChange={handleInput}
                            fullWidth
                            multiline
                            rows={5}
                        />
                    </GridItem>

                </GridContainer>
            </LocalizationProvider>
        )
    }


}
export default RentEdit;

