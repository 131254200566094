import { MenuItem, TextField } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidationNumber } from '../../../hooks/useValidaten';
import { IRoomPrice } from '../../../interfaces/IRoomPrice';
import { actionCreatorsRoomPrice } from '../../../redux/actionCreators';
import { State } from '../../../redux/combine_reducers';
import GenericEdit, { IInputValues, ISetValues } from '../../core/GenericEdit';
import InputField from '../../core/InputField';



interface IProps {
    idPrice?: number;
}

const RoomPrice:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const roomPriceArray = useSelector((state: State) => state.roomPriceArray.storeRoomPrice);
    const roomArray = useSelector((state: State) => state.roomArray.storeRoom);
    const unitArray = useSelector((state: State) => state.unitsArray.storeUnits);
    const { addRoomPriceArray, updateRoomPriceArray } = bindActionCreators(actionCreatorsRoomPrice,dispatch);
    // Hauptobject
    const [roomPriceObject, setRoomPriceObject] = useState<IRoomPrice | undefined>();
    // Hauptvalues
    const [idPrice, setIdPrice]  = useState<number>();
    const [idRoom,setIdRoom,vIdRoom] = useValidationNumber();
    const [idUnit,setIdUnit,vIdUnit] = useValidationNumber();
    const [minUnits,setMinUnits, vMinUnits] = useValidationNumber();
    const [maxUnits,setMaxUnits] = useState<number | null>(null);
    const [pricePerUnit,setPricePerUnit,vPricePerUnit] = useValidationNumber();
    // Validierung
    const [validationArray, setValidationArray] = useState<boolean[]>([]);
    // Metastatea
    const [isNew,setIsNew] = useState(false);
    const [isEmpty,setIsEmpty] = useState(true);



    useEffect(() => {
        resetValues();
        if (props.idPrice === undefined) {
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            
            if (props.idPrice === -1) {
                setIsNew(true)
                //resetValues();
            } else {
                setIsNew(false);
                setRoomPriceObject(roomPriceArray.find(x => x.idPrice === props.idPrice));
            }
        }
    },[props.idPrice])

    useEffect(() => {setValidationArray([vIdRoom,vIdUnit,vMinUnits,vPricePerUnit])},[vIdRoom,vIdUnit,vMinUnits,vPricePerUnit]);


    const valuesArray:ISetValues[] = [
        {
            Attribute: "idPrice",
            Value: idPrice,
            SetFunction: setIdPrice,
            IsPrimaryKey: true
        },
        {
            Attribute: "idRoom",
            Value: idRoom,
            SetFunction: setIdRoom,
        },
        {
            Attribute: "idUnit",
            Value: idUnit,
            SetFunction: setIdUnit,
        },
        {
            Attribute: "MinUnits",
            Value: minUnits,
            SetFunction: setMinUnits,
        },
        {
            Attribute: "PricePerUnit",
            Value: pricePerUnit,
            SetFunction: setPricePerUnit,
        },
        {
            Attribute: "MaxUnits",
            Value: maxUnits,
            SetFunction: setMaxUnits,
            PossibleUndefined: true,
            Type: 'number'
        },
    ]

    const inputField:IInputValues[] = [
        {
            InputElement:   <TextField
                                select
                                disabled={isEmpty}
                                label="Raum"
                                value={String(idRoom)}
                                error={!vIdRoom && !isEmpty}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdRoom( Number(event.target.value) ) }
                                variant="standard"
                                fullWidth
                            >
                                {roomArray.map(x => 
                                    <MenuItem value={x.idRoom}>
                                        {x.RoomNumber}
                                    </MenuItem>
                                )}
                            </TextField>,
        },
        {
            InputElement:   <TextField
                                select
                                disabled={isEmpty}
                                label="Einheit"
                                value={String(idUnit)}
                                error={!vIdUnit && !isEmpty}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdUnit( Number(event.target.value) ) }
                                variant="standard"
                                fullWidth
                            >
                                {unitArray.map(x => 
                                    <MenuItem value={x.idUnit}>
                                        {x.Unit}
                                    </MenuItem>
                                )}
                            </TextField>,
        },
        {
            ColSpanSm: 6,
            InputElement: <InputField isEdit={!isEmpty} type='number' label='Min. Einheiten' value={minUnits} setFunction={setMinUnits} isValidated={vMinUnits && !isEmpty}/>,
        },
        {
            ColSpanSm: 6,
            InputElement: <InputField isEdit={!isEmpty} type='number' label='Max. Einheiten' value={maxUnits} setFunction={setMaxUnits} />,
        },

        {
            InputElement: <InputField isEdit={!isEmpty} type='number' label='Preis pro Einheit' value={pricePerUnit} setFunction={setPricePerUnit} isValidated={vPricePerUnit && !isEmpty}/>,
        },
    ]

    const resetValues = () => {
        valuesArray.filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey === false).map(x => (x.Type !== undefined && x.Type === "boolean") ? x.SetFunction(false) : x.SetFunction(null))
    }

    return(
        <>  
            <GenericEdit
                headlineNew="Neuer Preis"
                headline="Preis"
                setValuesDef={valuesArray}
                inputFilds={inputField}
                // Zum Hochladen
                endpoint="roomPrice"
                // Hauptelemeten
                mainObject={roomPriceObject}
                setMainObject={setRoomPriceObject}
                // Zum Validieren
                validatenArray={validationArray}
                // Metastates
                isEmpty={isEmpty}
                isNew={isNew}
                updatedReduxArray={updateRoomPriceArray}
                addReduxArray={ addRoomPriceArray }
            />
        </>
    )

}
export default RoomPrice;