import { Download } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getFetch } from "../../hooks/useFetch";

export interface IProps {
    idOffer: number | null;
}



const OfferGetDocument:React.FC<IProps> = (props) => {
    const [dataObject,setDataObject] = useState<any|null>(null);

    function base64ToBlob(resBackend:any) {
        if ("Data" in resBackend && "FileName" in resBackend) {
            // Link -> https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
            // decode a Base64-encoded string into a new string with a character for each byte of the binary data.
            let byteCharacters = atob(resBackend["Data"]);
            // Each character's code point (charCode) will be the value of the byte. 
            // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
            let byteArray = new Uint8Array(byteNumbers);
            // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
            let blob = new Blob([byteArray], {type: "application/pdf"});
            // Erstelle Link zum BLOB
            let blobUrl = URL.createObjectURL(blob);
            // Erstelle html-Objekt für den Download
            let a = document.createElement("a");
            // setze link auf den neu erstellen Link zum Blolb
            a.href = blobUrl;
            // Setze Downloadtitle für den Datenname
            a.download = resBackend["FileName"];
            // Click 
            a.click();
        }
    }

    //useEffect(() => { (dataObject != undefined) && base64ToBlob(dataObject) },[dataObject])

    return(
        <>
            <IconButton 
                sx={{float: "right"}} 
                onClick={() => {
                    getFetch("/offer/generate/",props.idOffer,base64ToBlob)
                }}
                disabled={props.idOffer == undefined || props.idOffer <= 0}
            ><Download/></IconButton>
        </>
    )
}
export default OfferGetDocument;