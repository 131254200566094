import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../redux/combine_reducers';
import GenericOverview from '../../core/GenericOverview';
import RoomPrice from './RoomPrice';


const RoomPrices:React.FC = () => {
    const dispatch = useDispatch();
    const roomPriceArray = useSelector((state: State) => state.roomPriceArray.storeRoomPrice);
    const roomArray = useSelector((state: State) => state.roomArray.storeRoom);
    const unitArray = useSelector((state: State) => state.unitsArray.storeUnits);
    // Selected Id
    const [selectedId,setSelectedId] = useState<number|undefined>();

    const columns: GridColDef[] = [
        { 
            field: 'idRoom', 
            headerName: 'WorkBox Nr.', 
            width: 110,
            valueGetter: (params: GridValueGetterParams) => roomArray.find(x => x.idRoom === Number(params.row.idRoom))?.RoomNumber
        },
        {
            field: 'idUnit',
            headerName: 'Einheit',
            width: 80,
            valueGetter: (params: GridValueGetterParams) => unitArray.find(x => x.idUnit === Number(params.row.idUnit))?.Unit
        },
        {
            field: 'MinUnits',
            headerName: 'Min. Einheiten',
            width: 110,
        },
        {
            field: 'MaxUnits',
            headerName: 'Max. Einheiten',
            width: 110,
        },
        {
            field: 'PricePerUnit',
            headerName: 'Preis pro Einheit',
            flex: 1,
        },

    ];

    return(
        <GenericOverview
            headline="Raumpreise"
            columns={columns}
            data={roomPriceArray}
            idField="idPrice"
            setTargetId={setSelectedId}
            colDataGrid={6}
        >
            <RoomPrice idPrice={selectedId} />
        </GenericOverview>
    )

}
export default RoomPrices;