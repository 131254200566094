import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPostcode } from "../interfaces/IPostcode";



const initialState =  { storePostcode: [] as IPostcode[] }; 

const slicePostcode = createSlice({
    name: "POSTCODEARRAY",
    initialState,
    reducers: {
        setPostcodeArray: (state, action: PayloadAction<IPostcode[]>) => {
            state.storePostcode = [...action.payload];
        },
        addUpdatePostcodeArray: (state, action: PayloadAction<IPostcode>) => {
            let testObject = state.storePostcode.find(x => x.idPostcode === action.payload.idPostcode);
            
            if (testObject) {
                state.storePostcode = [...state.storePostcode.map(x => x.idPostcode === action.payload.idPostcode ? action.payload : x)];
            } else {
                state.storePostcode = [action.payload, ...state.storePostcode];
            }
        },
    }
})
export const { setPostcodeArray, addUpdatePostcodeArray } = slicePostcode.actions;
export default slicePostcode.reducer;