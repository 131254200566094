import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { DateTimePickerProps } from "@mui/x-date-pickers";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IRoom } from "../../../interfaces/IRoom";
import { State } from "../../../redux/combine_reducers";
import { setCampaignArray } from "../../../redux/slice_Campaign";
import GridItem from "../../core/GridItem";
import { borders } from '@mui/system';
import Stack from '@mui/material/Stack';
import { styled, createTheme, ThemeProvider } from '@mui/system';


const Calender: React.FC = () => {
    const rentArray = useSelector((state: State) => state.rentArray.storeRent);
    const roomArray = useSelector((state: State) => state.roomArray.storeRoom);

    // 
    const [currentDate, setCurrentDate] = useState(new Date());
    const [tableHeadDateArray, setTableHeadStringArray] = useState<Date[]>([]);
    const [currentLocation, setCurrentLocation] = useState(Number);
    //setCurrentLocation("LMS");
    const [idLocation, setIdLocation] = useState<number>(1);
    const [shownMonth, setShownMonth] = useState<number>(3);
    const [firstShownMonth, setFirstShownMonth] = useState<number>(0);
    const [lastShownMonth, setLastShownMonth] = useState<number>(3);
    const [shownMonthArr, setShownMonthArr] = useState<String[]>([]);
    const [loading, setLoading] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState("#D0D2D4");
    //setCurrentLocation(1);


    function addDays(date: Date) {
        date.setDate(date.getDate() + 1);
    }
    function tsxCurrentCell(roomObject: IRoom, dateObject: Date) {
        let filteredRentArry = rentArray
            .filter(x => x.idRoom === roomObject.idRoom)
            .filter(x => x.FromDate != undefined && x.ToDate != undefined)
            .filter(x => new Date(x.ToDate!) >= dateObject);
        let backgroundColor = "#D0D2D4"; //grau (default)
        let brderR = '1px solid';
        let brderL = '1px solid';
        let personId = "";
        let rentId = "";
        let link = "";
        let founedRent = filteredRentArry
        for (let i = 0; i < 7; i++) {



            filteredRentArry.map(x => {
                personId = x.idPerson.toString()
                rentId = x.idRent.toString()
            })
            if (roomObject.isBlocked == true) {                                            //gespert
                backgroundColor = "#F7FA8B";  // gelb
            } else if (founedRent.length === 0) {                                       //free
                backgroundColor = "#ABEA7A";  //grün
            } else if (founedRent.length >= 1 && founedRent.find(x => x.idRentType === 3) != undefined) {        //Miete 
                backgroundColor = "#EA7A7A";  // rot 
                link = "https://login.workbox.berlin/customers/" + personId + "/" + rentId;
            } else {   //Anfrage
                backgroundColor = "#8AC7FF";  // hellblau
            }


        }

        return (
            <>
                <TableCell
                    key={`${roomObject.idRoom}-${dateObject}`}
                    onClick={() => {
                        console.log(".......");
                        console.log(rentId);
                        (link !== "") && window.open(link + "");
                    }}
                    style={{
                        borderTop: "1px solid",
                        borderBottom: "1px solid",
                        borderRight: brderR,
                        borderLeft: brderL,
                        borderColor: "#E8E8E8",
                        backgroundColor: backgroundColor,
                        color: backgroundColor,
                        padding: '8px',
                    }}
                >
                    {"." /*founedRent.length > 0 ? "" : ""*/}
                </TableCell>
            </>
        )
    }
    // function tsxCurrentCell(roomObject: IRoom, dateObject: Date) {
    //     let filteredRentArry = rentArray
    //         .filter(x => x.idRoom === roomObject.idRoom);
    //     let backgroundColor = "#D0D2D4"; //grau (default)
    //     let anzahlVomArt = [0, 0, 0, 0, 0, 0];
    //     let brderR = '1px solid';
    //     let brderL = '1px solid';

    //     let personId = "";
    //     let rentId = "";

    //     let d = dateObject;
    //     for (let i = 0; i < 7; i++) {

    //         let founedRent = filteredRentArry
    //             .filter(x => new Date(x.FromDate!) <= d)
    //             .filter(x => new Date(x.ToDate!) >= d)
    //             .filter(x => x.FromDate != undefined && x.ToDate != undefined)
    //         if (roomObject.isBlocked == true) {                                            //gespert
    //             anzahlVomArt[0] += 1;    //backgroundColor = "#F7FA8B";  // gelb
    //         } else if (founedRent.length === 0) {                                       //free
    //             anzahlVomArt[1] += 1;    //backgroundColor = "#ABEA7A";  //grün
    //         } else if (founedRent.length === 1 && founedRent[0].idRentType === 3) {        //Miete 
    //             anzahlVomArt[2] += 1;    //backgroundColor = "#EA7A7A";  // rot 
    //         } else if (founedRent.length === 1 && founedRent[0].idRentType === 1) {   //Anfrage
    //             anzahlVomArt[3] += 1;    //backgroundColor = "#8AC7FF";  // hellblau
    //         } else if (founedRent.length === 2) {                                       //Mehrere Anträge
    //             anzahlVomArt[4] += 1;    //backgroundColor = "#FFBA52";  //orange
    //         } else {                                                                       //Anfrage - Offen (1 oder mehrere)
    //             anzahlVomArt[5] += 1;
    //         }

    //         addDays(d);
    //     }
    //     switch (anzahlVomArt.indexOf(Math.max(...anzahlVomArt))) {
    //         case 0:
    //             backgroundColor = "#F7FA8B"; // gelb
    //             break;
    //         case 1:
    //             backgroundColor = "#ABEA7A"; //grün
    //             break;
    //         case 2:
    //             backgroundColor = "#EA7A7A"; // rot 
    //             break;
    //         case 3:
    //             backgroundColor = "#8AC7FF"; // hellblau
    //             break;
    //         case 4:
    //             backgroundColor = "#FFBA52";  //orange
    //             break;
    //         case 5:
    //             backgroundColor = "#0062FF";  // ? blue     
    //             break;

    //     }
    //     /*
    //     if (dateObject.getDay() % 7 != 0) {
    //         brderR = '0px solid';
    //     }
    //     if (dateObject.getDay() % 7 != 1) {
    //         brderL = '0px solid';
    //     }
    //     */
    //     filteredRentArry.map(x => {
    //         personId = x.idPerson.toString()
    //         rentId = x.idRent.toString()
    //     })
    //     return (
    //         <>
    //             <TableCell
    //                 onClick={() => window.open("https://login.workbox.berlin/customers/" + personId + "/" + rentId, "_blank")}
    //                 style={{
    //                     borderTop: "1px solid",
    //                     borderBottom: "1px solid",
    //                     borderRight: brderR,
    //                     borderLeft: brderL,
    //                     borderColor: "#E8E8E8",
    //                     backgroundColor: backgroundColor,
    //                     color: backgroundColor,
    //                     padding: '8px'
    //                 }}
    //             >
    //                 {"." /*founedRent.length > 0 ? "" : ""*/}
    //             </TableCell>
    //         </>
    //     )
    // }


    useEffect(() => {
        let retunrArray: Date[] = [];
        let firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth());
        var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + shownMonth);

        while (firstDay <= lastDay) {
            retunrArray.push(new Date(firstDay));
            firstDay.setDate(firstDay.getDate() + 1);

        }

        setTableHeadStringArray([...retunrArray]);               ////shown date arr
    }, [currentDate, shownMonth])




    const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

    const [selectedMonth, setSelectedMonth] = useState<string>(monthNames[currentDate.getMonth()]);

    const changeShownMonth = (event: SelectChangeEvent) => {
        const num = Number(event.target.value as string);
        setShownMonth(num)
    };

    const changeLoc = () => {
        idLocation === 1 ? setIdLocation(2) : setIdLocation(1)
    };
    const changeYear = (event: SelectChangeEvent) => {
        const Jahr = Number(event.target.value as string);
        setCurrentDate(new Date(Jahr, currentDate.getMonth(), currentDate.getDate()))
    };

    function daysInMonth(month: number, year: number): number {
        if (month == 0 || month == -1) {
            return 0;
        } else {
            return new Date(year, month, 0).getDate();
        }
    }
    const secventShow = (month: number, currentDate: Date) => {
        const tables = [];
        let a = 0;
        for (let i = 0; i < month; i++) {
            tables.push(


                <Grid item xs={12 / shownMonth} key={i}> {/* Add a unique key prop to each table */}
                    <Table
                        sx={{ border: 1, borderColor: "#E8E8E8", color: "#5B5B5B", padding: '8px' }}
                    >
                        <TableHead>
                            <TableRow
                                sx={{
                                    border: 1,
                                    fontSize: '37.5px',
                                    borderColor: "#E8E8E8",
                                    color: "#5B5B5B",
                                    textAlign: 'center',
                                    verticalAlign: 'middle',
                                    alignItems: 'center', // Align the content vertically
                                    justifyContent: 'center', // Align the content horizontally

                                }}
                            >
                                {i % 3 == 0 ? (
                                    <TableCell sx={{ padding: '7px', width: "2%", border: 1, borderColor: "#E8E8E8", color: "#5B5B5B", textAlign: 'center', verticalAlign: 'middle', horizontalAlign: 'middle' }}>Räume</TableCell>
                                ) : null}
                                <TableCell colSpan={5} sx={{ padding: '7px', textAlign: 'center' }}>{currentDate.getMonth() + i > 11 ? monthNames[currentDate.getMonth() + i - 12] : monthNames[currentDate.getMonth() + i]}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/*i % 3 === 0 && roomArray.filter(x => x.idLocation === idLocation).map(x => (

                                <TableCell sx={{ border: 1, borderColor: "#E8E8E8", color: "#5B5B5B", textAlign: 'center' }}>{x.RoomNumber}</TableCell>

                            ))*/}

                            {roomArray
                                .filter(x => x.idLocation === idLocation)
                                .sort((a, b) => Number(a.RoomNumber) > Number(b.RoomNumber) ? 1 : -1)
                                .map(x =>
                                    <TableRow sx={{ border: 1 }}>
                                        {i % 3 === 0 ? <TableCell sx={{ maxWidth: 2, minWidth: 2, border: 1, borderColor: "#E8E8E8", color: "#5B5B5B", padding: '8px' }}>{x.RoomNumber}</TableCell> : null}
                                        {tableHeadDateArray.slice(a, daysInMonth(i + 1, currentDate.getFullYear()) + a).map((y, i) => i % 7 == 0 ? tsxCurrentCell(x, y) : null)}
                                    </TableRow>
                                )}

                            {/*roomArray
                                .filter(x => x.idLocation === idLocation)
                                .map(x => (
                                    <TableRow
                                        sx={{ border: 1, borderColor: "#E8E8E8", color: "#5B5B5B", textAlign: 'center' }}
                                        key={x.idLocation} // Add a unique key prop to each row
                                    >
                                        {tableHeadDateArray
                                            .slice(a, daysInMonth(i + 1, currentDate.getFullYear()) + a)
                                            .map(y => tsxCurrentCell(x, y))}
                                    </TableRow>
                                ))*/}
                        </TableBody>
                    </Table>
                </Grid >
            );
            a += daysInMonth(i + 1, currentDate.getFullYear());

        }

        return (


            <Grid container spacing={0.1}>
                {tables}

            </Grid>
        ); // Return the container with all tables inside
    };


    const showHead = (month: number, currentDate: Date) => {
        const tables = [];
        let a = 0;
        for (let i = 0; i < month; i++) {
            tables.push(
                <TableRow
                    sx={{
                        border: 1,
                        fontSize: '37.5px',
                        borderColor: "#E8E8E8",
                        color: "#5B5B5B",
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        alignItems: 'center', // Align the content vertically
                        justifyContent: 'center', // Align the content horizontally

                    }}
                >
                    {i % 3 == 0 ? (
                        <TableCell sx={{ padding: '7px', width: "2%", border: 1, borderColor: "#E8E8E8", color: "#5B5B5B", textAlign: 'center', verticalAlign: 'middle', horizontalAlign: 'middle' }}>Räume</TableCell>
                    ) : null}
                    <TableCell colSpan={5} sx={{ padding: '7px', width: "95%", textAlign: 'center' }}>{currentDate.getMonth() + i > 11 ? monthNames[currentDate.getMonth() + i - 12] : monthNames[currentDate.getMonth() + i]}</TableCell>
                </TableRow>
            );
            a += daysInMonth(i + 1, currentDate.getFullYear());

        }

        return (
            { tables }
        ); // Return the container with all tables inside
    };
    const showBody = (month: number, currentDate: Date) => {
        const tables: any[] = [];
        let a = 0;
        for (let i = 0; i < month; i++) {
            tables.push(

                roomArray.filter(x => x.idLocation === idLocation).map(x =>
                    <TableRow sx={{ border: 1 }}>
                        {i % 3 === 0 ? <TableCell sx={{ width: "2%", border: 1, borderColor: "#E8E8E8", color: "#5B5B5B", padding: '8px' }}>{x.RoomNumber}</TableCell> : null}
                        {tableHeadDateArray.slice(a, daysInMonth(i + 1, currentDate.getFullYear()) + a).map((y, i) => i % 7 == 0 ? tsxCurrentCell(x, y) : null)}
                    </TableRow>
                )

            );
            a += daysInMonth(i + 1, currentDate.getFullYear());
        }
        return (
            { tables }
        ); // Return the container with all tables inside
    };
    return (

        <>
            <GridItem sm={1}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 0, sm: 20, md: 45 }}
                    justifyContent="center"
                    alignItems="center"
                >

                    {/*
    <IconButton                                                                     
        sx={{ float: "left", border: 1, borderRadius: 3, borderColor: "#D0D2D4" }}
        onClick={() => {
            //chooseMonth(currentDate, shownMonth) //setzt month auf 0,3,6 oder 9
            setLastShownMonth(firstShownMonth)
            setFirstShownMonth(currentDate.getMonth() - shownMonth)
            currentDate.setMonth(currentDate.getMonth() - shownMonth)
        }}
    >
        <ArrowLeft />
    </IconButton>*/}
                    <IconButton
                        sx={{ float: "left", border: 1, borderRadius: 3, borderColor: "#D0D2D4" }}
                        onClick={() => {
                            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
                            setLastShownMonth(currentDate.getMonth());
                            setFirstShownMonth(currentDate.getMonth() - shownMonth);
                        }}
                    >
                        <ArrowLeft />
                    </IconButton>

                    {/*
    <Box sx={{ minWidth: 120, color: "#5B5B5B" }}>
        <FormControl fullWidth>
            <InputLabel>Monat</InputLabel>
            <Select
                value={monthNames[currentDate.getMonth()]}
                label="Monat"
                onChange={handleChangeMonth}
                sx={{ color: "#5B5B5B" }}
            >
                <MenuItem value={"Januar"}>Januar</MenuItem>
                <MenuItem value={"Februar"}>Februar</MenuItem>
                <MenuItem value={"März"}>März</MenuItem>
                <MenuItem value={"April"}>April</MenuItem>
                <MenuItem value={"Mai"}>Mai</MenuItem>
                <MenuItem value={"Juni"}>Juni</MenuItem>
                <MenuItem value={"Juli"}>Juli</MenuItem>
                <MenuItem value={"August"}>August</MenuItem>
                <MenuItem value={"September"}>September</MenuItem>
                <MenuItem value={"Oktober"}>Oktober</MenuItem>
                <MenuItem value={"November"}>November</MenuItem>
                <MenuItem value={"Dezember"}>Dezember</MenuItem>
            </Select>
        </FormControl>
    </Box>
*/}



                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel>{"  Zeitraum"}</InputLabel>
                            <Select
                                value={shownMonth.toString()}
                                label="Monate angezeigt"
                                onChange={changeShownMonth}
                                sx={{ color: "#5B5B5B" }}
                            >
                                <MenuItem value={3}>{"3 Monate"}</MenuItem>
                                <MenuItem value={6}>{"6 Monate"}</MenuItem>
                                <MenuItem value={12}>{"12 Monate"}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>


                    <Box sx={{ minWidth: 100 }}>
                        <FormControl fullWidth>
                            <InputLabel>Jahr</InputLabel>
                            <Select
                                value={currentDate.getFullYear().toString()}
                                label="Jahr"
                                onChange={changeYear}
                                sx={{ color: "#5B5B5B" }}
                            >
                                <MenuItem value={"2021"}>{"2021"}</MenuItem>
                                <MenuItem value={"2022"}>{"2022"}</MenuItem>
                                <MenuItem value={"2023"}>{"2023"}</MenuItem>
                                <MenuItem value={"2024"}>{"2024"}</MenuItem>
                                <MenuItem value={"2025"}>{"2025"}</MenuItem>
                                <MenuItem value={"2026"}>{"2026"}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 100 }}>
                        <FormControl fullWidth>
                            <InputLabel>Ort</InputLabel>
                            <Select
                                value={idLocation === 1 ? "LMS" : "EBS"}
                                label="Ort"
                                onChange={changeLoc}
                                sx={{ color: "#5B5B5B" }}
                            >
                                <MenuItem value={"LMS"}>{"LMS"}</MenuItem>
                                <MenuItem value={"EBS"}>{"EBS"}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <IconButton
                        sx={{ float: "right", border: 1, borderRadius: 3, borderColor: "#D0D2D4" }}
                        onClick={() => {
                            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
                            setLastShownMonth(currentDate.getMonth());
                            setFirstShownMonth(currentDate.getMonth() + shownMonth);
                        }}
                    >
                        <ArrowRight />
                    </IconButton>
                </Stack>
            </GridItem>
            <p></p>
            {/*<IconButton
        sx={{ float: "right", border: 1, borderRadius: 3, borderColor: "#D0D2D4" }}
        onClick={() => {
            setFirstShownMonth(currentDate.getMonth())
            setLastShownMonth(currentDate.getMonth() + shownMonth)
            currentDate.setMonth(currentDate.getMonth() + shownMonth)
        }}
    >
        <ArrowRight />
    </IconButton>*/}

            {/*}                                            LAST TABLE
                
            <Table sx={{ border: 1, color: "#E8E8E8", borderRadius: 6, borderCollapse: "separate", borderSpacing: 0 }}>
                <TableHead>

                    <TableCell sx={{ width: '100px', padding: '4px', fontSize: '12px', color: "#5B5B5B", textAlign: 'center', verticalAlign: 'middle', horizontalAlign: 'middle' }}>Räume</TableCell>
                    {monthNames.slice(currentDate.getMonth(), currentDate.getMonth() + shownMonth).map(x => <TableCell sx={{ padding: '4px', fontSize: '12px', color: "#5B5B5B", borderLeft: 1, borderColor: "#E8E8E8" }}>{x}</TableCell>)}
                    {/*
        tableHeadDateArray.map(x =>
            <TableCell sx={{ color: "#5B5B5B", borderLeft: 1, borderColor: "#E8E8E8" }}> {x.getDate()}.{x.getMonth() + 1}</TableCell>
        )*/}
            {/*

                </TableHead>



                <Fragment>
                    <TableBody sx={{ borderSpacing: '100px', padding: '2px', fontSize: '8px', color: "#5B5B5B", border: 1, borderColor: "#E8E8E8" }}>
                        {roomArray.filter(x => x.idLocation === idLocation).map(x => <TableRow sx={{ border: 1 }}>
                            <TableCell sx={{ padding: '2px', fontSize: '8px', border: 1, borderColor: "#E8E8E8", color: "#5B5B5B" }}>{x.RoomNumber}</TableCell>
                            {tableHeadDateArray.map(y => tsxCurrentCell(x, y))}
                        </TableRow>
                        )}
                    </TableBody>
                </Fragment>

            </Table>
11:30-:
*/}

            {secventShow(shownMonth, currentDate)}

        </>
    )
}
export default Calender;