import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import { IPerson } from '../../interfaces/IPerson';
import { State } from '../../redux/combine_reducers';
import GenericOverview from '../core/GenericOverview';
import GridContainer from '../core/GridContainer';
import InputField from '../core/InputField';
import WaitingSequence from '../core/WaitingSequence';
import CustomerFullObject from '../Customer_new/CustomerFullObject';
import Customer from './Customer';

type TPossibleColor = 
    "#e8e8e8" // Grau -> neu
    | "#e6e600" // Geld -> Kontaktiert
    | "#D22B2B" // Rot -> Unqualifiziert
    | "#1B7CED" // Blau -> Unqualifiziert
    | "#228B22" // Grün
    | "#000000" // Schwarz
;

const Customers:React.FC = () => {
    const paramObject = useParams();

    const dispatch = useDispatch();
    const companyArray = useSelector((state: State) => state.companyArray.storeCompany);
    const leadStateArray = useSelector((state: State) => state.leadStateArray.storeLeadState);
    //
    const idRent = (paramObject.idRent == undefined) ? undefined : Number(paramObject.idRent); 
    // Für die Suche
    const [searchString, setSearchString] = useState("");
    // Nachladen aus dem Backend
    const [personArray,setPersonArray,wasSuccessfulFlyLoad] = useFetch<IPerson[]>("person");
    // Selected
    const [selecedId,setSelecedId] = useState<number | undefined>((paramObject.idPerson == undefined) ? undefined : Number(paramObject.idPerson));

    useEffect(() => {console.log(leadStateArray)},[leadStateArray])

    useEffect(() => {console.log(searchString)},[searchString])


    const tsxCurrentStateDot = (params: GridRenderCellParams) : React.ReactNode => {
        let colorOne:TPossibleColor = "#e8e8e8";
        let colorTwo:TPossibleColor = "#e8e8e8";


        /*
        UPDATE LeadState set State = "Neu" WHERE idLeadState = 5;
UPDATE LeadState set State = "Kontaktiert" WHERE idLeadState = 10;
UPDATE LeadState set State = "Unqualifiziert" WHERE idLeadState = 15;
UPDATE LeadState set State = "Besichtigt" WHERE idLeadState = 20;
UPDATE LeadState set State = "Interessiert" WHERE idLeadState = 25;
UPDATE LeadState set State = "Mieter" WHERE idLeadState = 30;
UPDATE LeadState set State = "Beendet" WHERE idLeadState = 35;
INSERT INTO LeadState VALUES (40,"Lost",NULL);
INSERT INTO LeadState VALUES (45,"Black List",NULL);
*/

        let value = 0

        if (params.row.idLeadState === 10) { colorOne  = "#e6e600"; colorTwo = "#e6e600"; }
        else if (params.row.idLeadState === 15) { colorOne  = "#D22B2B"; colorTwo = "#D22B2B";}
        else if (params.row.idLeadState === 20) { colorOne  = "#1B7CED"; colorTwo = "#1B7CED";}
        else if (params.row.idLeadState === 25) { colorOne  = "#1B7CED"; colorTwo = "#228B22";}
        else if (params.row.idLeadState === 30) { colorOne  = "#228B22"; colorTwo = "#228B22";}
        else if (params.row.idLeadState === 35) { colorOne  = "#228B22"; colorTwo = "#D22B2B";}
        else if (params.row.idLeadState === 40) { colorOne  = "#1B7CED"; colorTwo = "#D22B2B";}
        else if (params.row.idLeadState === 45) { colorOne  = "#000000"; colorTwo = "#000000";}

        return (
            <span style={{
                    height: 25,
                    width: 25,
                    //backgroundColor: currentColor,
                    background: `linear-gradient(to right, ${colorOne} 50%, ${colorTwo} 0%)`,
                    borderRadius: "50%",
                    display: "inline-block",
                    //color: currentColor
                }}
            />
        )
    } 




    const columns: GridColDef[] = [
        /*
        { 
            field: 'idPerson', 
            headerName: 'Nr.', 
            width: 90,
            //valueGetter: (params: GridValueGetterParams)
        },
        */
        {
            field: 'idLeadState',
            headerName: 'Stage',
            width: 100,
            valueGetter: (params: GridValueGetterParams) => leadStateArray.find(x => x.idLeadState === Number(params.row.idLeadState))?.State
        },
        {
            field: 'Lead_Created_at',
            headerName: 'Datum',
            width: 160,
            valueGetter: (params: GridValueGetterParams) => (params.row.Lead_Created_at) ? new Date(params.row.Lead_Created_at).toLocaleString()  : ""
        },
        {
            field: 'FirstName',
            headerName: 'Vorname',
            flex: 1,
        },
        {
            field: 'LastName',
            headerName: 'Nachname',
            flex: 1,
        },
        {
            field: 'idCompany',
            headerName: 'Firma',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => companyArray.find(x => x.idCompany === Number(params.row.idCompany))?.Company
        },
        {
            field: 'Status',
            headerName: 'Status',
            width: 85,
            renderCell: tsxCurrentStateDot
        }


    ];

    if (personArray === undefined) {
        return <WaitingSequence />
    } else {


        return(
            <>
                <GenericOverview
                    headline="Kontakte"
                    columns={columns}
                    data={
                        (searchString == "")
                            ? personArray
                            : personArray.filter( x =>
                                x.FirstName.toLowerCase().includes(searchString.toLowerCase())
                                || x.LastName.toLowerCase().includes(searchString.toLowerCase())
                                || String(x.Company).toLowerCase().includes(searchString.toLowerCase())
                            )
                    }
                    idField="idPerson"
                    setTargetId={setSelecedId}
                    colDataGrid={6}
                    setSearchState={setSearchString}
                >
                    {/* <Customer idPerson={selecedId} personArray={personArray} setPersonArray={setPersonArray}/> */}
                    <CustomerFullObject 
                        idPerson={selecedId} 
                        personArray={personArray}
                        setPersonArray={setPersonArray}
                        idRent={idRent}
                    />
                </GenericOverview>
            </>


        )
    }

}
export default Customers;
