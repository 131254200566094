
import { useSelect } from '@mui/base';
import { Send } from '@mui/icons-material';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem, Modal, Snackbar, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFetch } from '../../../hooks/useFetch';
import { State } from '../../../redux/combine_reducers';


interface ITestMail {
    idRent: number;
    MailTitle: string;
    MailBody: string;
}


interface IProps {
    mailTitle?: string | null;
    mailBody?: string | null;
    disabled?: boolean;
}



const TestRentMail:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const rentArray = useSelector((state: State) => state.rentArray.storeRent);
    // Interne States
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [wasSend,setWasSend] = useState(false);
    const [idRent, setIdRent] = useState<number | null>(null);

    const sendMail = () => {
        if (idRent !== null) {
            let uploadObject:ITestMail =  {
                idRent: idRent,
                MailTitle: (props.mailTitle == undefined) ? "" : props.mailTitle,
                MailBody: (props.mailBody == undefined) ? "" : props.mailBody
            }
            uploadFetch("system/testMail",true,uploadObject,() => {});
            setIdRent(null);
            setWasSend(true);
            setIsModalOpen(false);
        }

    }


    return(
        <div>

            <Dialog
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id="test"
                
            >
                <DialogTitle>Testmail versenden</DialogTitle>
                
                <DialogContent>
                    <DialogContentText>Bitte wählen Sie eine Buchung</DialogContentText>

                    <TextField
                        select
                        label="Buchung"
                        value={String(idRent)}
                        error={idRent == undefined}
                        onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdRent( Number(event.target.value) ) }
                        variant="standard"
                        fullWidth
                    >
                        {rentArray.map(x => 
                            <MenuItem value={x.idRent}>
                                {x.RentNummer}
                            </MenuItem>
                        )}
                    </TextField>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setIsModalOpen(false)}>Abbruch</Button>
                    <Button disabled={idRent === null} onClick={() => sendMail()}>Senden</Button>
                </DialogActions>
            </Dialog>

            <Snackbar 
                key="testmail" 
                open={wasSend} 
                autoHideDuration={6000} 
                onClose={() => setWasSend(false)}  
                anchorOrigin={{ 'vertical' : 'bottom', 'horizontal' : 'right' }} 
            >
                <Alert onClose={() => setWasSend(false)} severity="success" sx={{ width: '100%' }}>
                    Email wurde erfolgreich übergeben!
                </Alert>
            </Snackbar>



            <IconButton 
                disabled={props.disabled == undefined ? false : props.disabled} 
                style={{float: "right"}} 
                onClick={() => setIsModalOpen(true)}
            >
                <Send/> 
            </IconButton>
        </div>
    )

}
export default TestRentMail;