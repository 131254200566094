import React, { useEffect, useState } from "react";
import { IHandoverProtocol, IHandoverProtocolFullObject } from "../../interfaces/IHandoverProtocol";
import AlertError from "../core/AlertError";
import WaitingSequence from "../core/WaitingSequence";
import { AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Close } from "@mui/icons-material";
import { handoverData } from "./HandoverOverview";
import { HandoverEdit } from "./HandoverEdit";
import { IDamage } from "../../interfaces/IDamage";

interface IProps {
    idHandoverProtocol: number | null;
    setIdHandoverProtocol: Function;
}

export const getEmptyHandoverObject = () => {
    return {
        idHandoverProtocol: -1,
        idRent: -1,
        isAssumption: true,
        ElectricityMeterReading: 0
    } as IHandoverProtocol
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


export const HandoverDialogEdit:React.FC<IProps> = (props) => {
    const [handoverOverFullObject, setHandoverOverFullObject] = useState<IHandoverProtocolFullObject|undefined>(undefined);
    const [wasSuccessfully, setWasSuccessfully] = useState(true);
    //
    const [handoverObject, setHandoverObject] = useState(getEmptyHandoverObject());
    const [damageArray, setDamageArray] = useState<IDamage[]>([]);

    const dialogBodyTsx = () => {
        if (!wasSuccessfully) {return <AlertError/>}
        else if (handoverOverFullObject === undefined && (props.idHandoverProtocol === null || props.idHandoverProtocol < 0)) { return <WaitingSequence/> }
        else {
            return(
                <>
                    <HandoverEdit
                        handoverObject={handoverObject}
                        setHandoverObject={setHandoverObject}
                        damageArray={damageArray}
                        setDamageArray={setDamageArray}
                    />
                </>
            )
        }
    }

    /// useEffect: nur für Mookup
    useEffect(() => {
        if (props.idHandoverProtocol !== null) {
            let testObject = handoverData.find(x => x.idHandoverProtocol === props.idHandoverProtocol);

            if (testObject !== undefined) {
                setHandoverOverFullObject(testObject);
            }
        }
    })
    /// end: useEffect

    const handleClose = () => {
        props.setIdHandoverProtocol(null);
    }


    useEffect(() => {
        if (handoverOverFullObject !== undefined) {
            setHandoverObject(handoverOverFullObject);
            setDamageArray(handoverOverFullObject.DamageArray);
        }
    },[handoverOverFullObject])


    return(
        <>
        <Dialog
            fullScreen
            open={props.idHandoverProtocol !== null}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar sx={{backgroundColor: "#000"}}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                    <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Übergabeprotokoll
                    </Typography>
                    <Button autoFocus color="inherit" onClick={() => {}}>
                        Speichern
                    </Button>
                </Toolbar>
            </AppBar>

            <Box sx={{m: 2}}>
                {dialogBodyTsx()}
            </Box>
        </Dialog>
        </>
    )

}