import React from "react";
import { IInoiceState } from "../../interfaces/IInoiceState";
import { Box, Chip, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { IInvoiceStatus } from "../../interfaces/Additional/IInvoiceStatus";
import { Add, FindInPage, Link } from "@mui/icons-material";
import { IRent } from "../../interfaces/IRent";


interface IProps {
    stripeStateArray?: IInvoiceStatus[];
    isLoading?: boolean;
    // Explizit für  Mieten
    rentObject?: IRent;
    isLoadingSave?: boolean;
    createRentSubscriptionSchedule?: Function;
}


const StripeStateOverview:React.FC<IProps> = (props) => {
    const genStateChip = (status:string) => {
        // if: für mieten
        if (status === "draft") {
            return <Chip size="small" variant="outlined" label="Entwurf"/>
        }
        else if (status === "void") {
            return <Chip size="small" variant="outlined" label="Storniert"/>
        }
        else if (status === "open") {
            return <Chip size="small" color="primary" variant="outlined" label="Offen"/>
        }
        else if (status === "paid") {
            return <Chip size="small" color="success" variant="outlined" label="Gezahlt"/>
        }
        else if (status === "uncollectible") {
            return <Chip size="small" color="error" variant="outlined" label="Fehlerhaft"/>
        }
        // if: subscription
        else if (status === "not_created") {
            if (props.rentObject != undefined && new Date(String(props.rentObject.FromDate)).getTime() <= new Date().getTime()) {
                return <Chip size="small" color="error" variant="outlined" label="Nicht Erstellt"/>
            } else {
                return <Chip size="small" variant="outlined" label="Nicht Erstellt"/>
            }
        }
        else if (status === "not_started") {
            return <Chip size="small" color="primary" variant="outlined" label="Nicht gestartet"/>
        }
        else if (status === "active") {
            return <Chip size="small" color="primary" variant="outlined" label="Läuft"/>
        }
        else if (status === "completed") {
            return <Chip size="small" variant="outlined" label="Beendet"/>
        }



        else {
            return <Chip size="small" color="warning" variant="outlined" label={`Unbekannter Status: ${status}`}/>
        }
    }


    const numberToPrice = (price:number|null|undefined) => {
        if (price == undefined) {
            return "-"
        } else {
            return price.toFixed(2).replace(".",",") + " €"
        }
    }

    const genTableBody = () => {
        if (props.isLoading != undefined && props.isLoading == true) {
            return(
                <>
                    <TableRow><TableCell colSpan={4}>Bitte warten...</TableCell></TableRow>
                </>
            )
        }
        else if (props.stripeStateArray == undefined || props.stripeStateArray.length === 0) {
            return(
                <>
                    <TableRow><TableCell colSpan={4}>Keine Stripe-Einträge vorhanden</TableCell></TableRow>
                </>
            )
        }
        else {
            return(
                <>
                    <Dialog
                        open={props.isLoadingSave != undefined && props.isLoadingSave}
                    >
                        <DialogTitle>Abbonement wird erstellt</DialogTitle>
                        <DialogContent>
                            <LinearProgress/>
                        </DialogContent>
                    </Dialog>

                    {props.stripeStateArray.map(x => 
                        <TableRow>
                            <TableCell>{x.Title}</TableCell>
                            <TableCell>{genStateChip(x.Status)}</TableCell>
                            <TableCell>{numberToPrice(x.Total)}</TableCell>
                            <TableCell> 
                                {(
                                    x.Status === "not_created" 
                                    && props.rentObject != undefined 
                                    && props.createRentSubscriptionSchedule != undefined
                                    && new Date(String(props.rentObject.FromDate)).getTime() <= new Date().getTime()
                                    && props.stripeStateArray!.filter(x => x.Type === "Invoice").every(x => x.Status === "paid")
                                ) &&
                                    <IconButton
                                        onClick={() => props.createRentSubscriptionSchedule!()}
                                    ><Add /> </IconButton>
                                }
                                {(x.Link !== null) &&
                                    <IconButton
                                        onClick={() =>{
                                            window.open(String(x.Link), "_blank")
                                        }}
                                    ><Link /> </IconButton>
                                }
                                {(x.PDF !== null) &&
                                    <IconButton
                                        onClick={() =>{
                                            window.open(String(x.PDF), "_blank")
                                        }}
                                    ><FindInPage /> </IconButton>
                                }
                            </TableCell>
                        </TableRow>
                    )}
                </>
            )
        }
    }


    return(
        <Box>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Rechnung</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Total (Netto)</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {genTableBody()}
                </TableBody>
            </Table>
        </Box>
    )
}
export default StripeStateOverview;