import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRent } from "../interfaces/IRent";

const initialState =  { storeRent: [] as IRent[] }; 

const sliceRoom = createSlice({
    name: "RENTARRAY",
    initialState,
    reducers: {
        setRentArray: (state, action: PayloadAction<IRent[]>) => {
            state.storeRent = [...action.payload];
        },
        addRentArray: (state, action: PayloadAction<IRent>) => {
            state.storeRent = [action.payload,...state.storeRent];
        },
        deleteRentArray: (state, action: PayloadAction<IRent>) => {
            let tmpArray =  state.storeRent.map(x => x.idRent === action.payload.idRent ? action.payload : x);
            state.storeRent = tmpArray;
        }
    }
})
export const { setRentArray, addRentArray, deleteRentArray } = sliceRoom.actions;
export default sliceRoom.reducer;