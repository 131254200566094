import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, deDE } from "@mui/x-data-grid";
import { HandoverCard } from "./HandoverCard";




export const HandoverAssetsOverview:React.FC = () => {
    const [isOpenAddNew, setIsOpenAddNew] = useState(false);

    const columns:GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 50,
        },
        { 
            field: 'Units', 
            headerName: 'Anzahl', 
            width: 100,
        }, 
        { 
            field: 'Asset', 
            headerName: 'Text', 
            flex: 1
        }, 
        {
            field: "Actions",
            headerName: 'Aktionen', 
            width: 90,
            renderCell: (params:GridRenderCellParams) =>
                <Button variant="contained" color="warning">Löschen</Button>
        }
    ];

    const dataArray:any[] = [
        { id: 1, Units: 3, Asset: "Langfeldlampe" }
    ]

    return (
        <>
            <Dialog
                open={isOpenAddNew}
                onClose={() => setIsOpenAddNew(false)}
                maxWidth="sm" fullWidth
            >
                <DialogTitle>Schaden hinzufügen</DialogTitle>
                <DialogContent>
                    <>
                        <Box sx={{mt: 2}}/>

                        <TextField
                            label="Position"
                            fullWidth
                            select
                        >
                            <MenuItem key={`idPerson-1`} value={1}>Linke Wand</MenuItem>
                            <MenuItem key={`idPerson-3`} value={3}>Wand gegenüber Eingang</MenuItem>
                            <MenuItem key={`idPerson-3`} value={3}>Rechte Wand</MenuItem>
                            <MenuItem key={`idPerson-3`} value={3}>Wand beim Eingang</MenuItem>
                        </TextField>
                        <Box sx={{mt: 2}}/>
                        <TextField
                            label="Schaden"
                            fullWidth
                            select
                        >
                            <MenuItem key={`idPerson-11`} value={1}>Loch/Löscher</MenuItem>
                            <MenuItem key={`idPerson-21`} value={2}>Unsauber</MenuItem>
                            <MenuItem key={`idPerson-31`} value={3}>Neustreichen notwendig</MenuItem>
                        </TextField>
                        <Box sx={{mt: 2}}/>
                        <TextField
                            label="Text"
                            fullWidth
                        />
                    </>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" sx={{float: "right"}} onClick={() => setIsOpenAddNew(true)}>Abbruch</Button>
                    <Button variant="contained" sx={{float: "right"}} onClick={() => setIsOpenAddNew(true)}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>


            <HandoverCard 
                title="Ausstattung" 
                btn={
                    <Button variant="contained" sx={{float: "right"}} onClick={() => setIsOpenAddNew(true)}>
                        Ausstattung hinzufügen
                    </Button>
                }
            >
                <Grid item sm={12}>
                    <DataGrid
                        sx={{mt: 3}}
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        rows={dataArray}
                        columns={columns}
                        getRowId={(row) => row["id"]}
                        autoHeight
                        disableRowSelectionOnClick
                    />
                </Grid>

            </HandoverCard>
        </>
    )
}