import React, { useEffect, useState } from "react";
import { IRent } from "../../interfaces/IRent";
import { IPerson, IPersonFullObject } from "../../interfaces/IPerson";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, MenuItem, TextField } from "@mui/material";
import { State } from "../../redux/combine_reducers";
import { useSelector } from "react-redux";
import { getFetch, uploadFetch, useFetch } from "../../hooks/useFetch";
import { ICompany } from "../../interfaces/ICompany";
import CustomerEdit from "../Customer_new/CustomerEdit";
import { IAddress } from "../../interfaces/IAddress";
import CompanyEdit from "../Customer_new/CompanyEdit";
import AddressEdit from "../Customer_new/AddressEdit";
import { resetAddressObject, resetCompanyObject } from "../Customer_new/CustomerFullObject";


interface IProps {
    currentRent: IRent;
    setCurrentRent: Function;
}

const resetPerson = (idCompany?:number | null) => {
    return {
        Email: "",
        FirstName: "",
        idCompany: (idCompany == undefined) ? -1 : idCompany,
        hasNewsletter: false,
        idGender: 1,
        idLeadSource: 1,
        idLeadState: 5,
        idPerson: -1,
        isBlocked: false,
        LastName: "",
        idLead: null,
        Birthday: null,
        Birthplace: null,
        idCampaign: null,
        idMedium: null,
        InternComment: null,
        MobileNumber: null,
        PhoneNumber: null,
        StripeId_Customer: null
    } as IPerson
}


export const RentThirdPary:React.FC<IProps> = (props) => {
    const [personArray,setPersonArray,wasSuccessfullyPerson] = useFetch<IPerson[]>("person");
    const [companyArray,setCompanyArray,wasSuccessfullyCompany] = useFetch<ICompany[]>("company");
    //
    const [isEmptyMode, setIsEmptyMode] = useState((props.currentRent.ThirdParty_idPerson === null))
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenChoice, setIsOpenChoice] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    // 
    const [thirdPersonPerson, setThirdPersonPerson] = useState<IPerson>(resetPerson(props.currentRent.idCompany));
    const [companyObject, setComapnyObject] = useState<ICompany | null>(null);
    const [addressObject, setAddressObject] = useState<IAddress | null>(null);


    useEffect(() => {
        if (personArray !== undefined) {
            if (props.currentRent.ThirdParty_idPerson !== null) {
                let personObject = personArray.find(x => x.idPerson === props.currentRent.ThirdParty_idPerson);
                (personObject !== undefined) ? setThirdPersonPerson(personObject) : setThirdPersonPerson(resetPerson(props.currentRent.idCompany));
            } 
            else {
                setThirdPersonPerson(resetPerson(props.currentRent.idCompany))
            }
        }
    },[personArray])

    const handleClose = () => {
        if (props.currentRent.ThirdParty_idPerson === null && !isEmptyMode) {
            setIsEmptyMode(true);
            setThirdPersonPerson(resetPerson(props.currentRent.idCompany));
        }
        setIsOpenChoice(false);
        setIsOpenEdit(false);
    }


    const setPersonWrapper = (respinsePerson: IPersonFullObject | undefined) => {
        if (respinsePerson !== undefined && respinsePerson.CompanyObject?.AddressObject !== undefined) {
            setThirdPersonPerson(respinsePerson);
            setComapnyObject(respinsePerson.CompanyObject);
            setAddressObject(respinsePerson.CompanyObject?.AddressObject);
            //props.setThirdPartyPerson(respinsePerson);
            setIsOpenEdit(true);
            setIsLoading(false);
        }
    }

    const handleAdd = () => {
        if (Number(thirdPersonPerson?.idPerson) < 0 && personArray !== undefined) {
            let personObject = personArray.find(x => x.idPerson === props.currentRent.idPerson);

            if (Number(thirdPersonPerson?.idCompany) === Number(personObject?.idCompany)) {
                setComapnyObject(null);
                setAddressObject(null);
            }
            else if (Number(thirdPersonPerson?.idCompany) > 0 && companyArray) {
                let tmpCompany = companyArray.find(x => x.idCompany === Number(thirdPersonPerson?.idCompany));
                setComapnyObject(tmpCompany!);
                getFetch("address/", Number(tmpCompany!.idAddress), setAddressObject);
                
            }
            else {
                setComapnyObject(resetCompanyObject());
                setAddressObject(resetAddressObject());
            }
            setThirdPersonPerson(thirdPersonPerson);
            //props.setThirdPartyPerson(thirdPersonPerson);
            setIsOpenEdit(true);
        } else {
            getFetch("person/", Number(thirdPersonPerson?.idPerson), setPersonWrapper);
        }
        setIsEmptyMode(false);
        
        /*
        let toSaveThirdPerson = thirdPersonPerson;

        if (Number(thirdPersonPerson?.idPerson) > 0 && personArray !== undefined) {
            let personObject = personArray.find(x => x.idPerson === Number(thirdPersonPerson?.idPerson));
            
            if (personObject !== undefined) {
                toSaveThirdPerson = personObject
            }
        }
        setThirdPersonPerson(toSaveThirdPerson);
        props.setThirdPartyPerson(toSaveThirdPerson);
        setIsOpenEdit(true);
        */
    }

    const handleOpenEdit = () => {
        setThirdPersonPerson(resetPerson(props.currentRent.idCompany));
        setIsOpenEdit(true);
        setIsLoading(true);
        getFetch("person/", Number(props.currentRent.ThirdParty_idPerson), setPersonWrapper);
    }

    const setThridParyWrapper = (res:IPersonFullObject) => {
        //props.setThirdPartyPerson(res);
        setThirdPersonPerson(res);
        props.setCurrentRent({...props.currentRent, ThirdParty_idPerson: Number(res.idPerson) } as IRent);
        setIsOpenEdit(false);
    }


    const handleSaveThirdPerson = () => {
        if (thirdPersonPerson != undefined) {
            let uploadPerson:IPerson|IPersonFullObject = {
                ...thirdPersonPerson,
                isBlocked: Boolean(thirdPersonPerson.isBlocked)
            }

            if (companyObject != undefined && addressObject != undefined) {
                uploadPerson = {
                    ...thirdPersonPerson,
                    isBlocked: Boolean(thirdPersonPerson.isBlocked),
                    CompanyObject: {
                        ...companyObject,
                        AddressObject: addressObject
                    }
                }
            }
            uploadFetch("/person",(Number(thirdPersonPerson.idPerson) < 0),uploadPerson,setThridParyWrapper);
        }
    }

    const handleRemoveThirdPerson = () => {
        //props.setThirdPartyPerson(null);
        props.setCurrentRent({...props.currentRent, ThirdParty_idPerson: null});
        setThirdPersonPerson(resetPerson(props.currentRent.idCompany));
        setIsOpenChoice(false);
        setIsOpenEdit(false);
        setIsEmptyMode(true);
    }

    


    if (!wasSuccessfullyPerson || !wasSuccessfullyCompany) {
        return(
            <>Fehler!</>
        )
    }
    else if  (personArray === undefined || companyArray === undefined) {
        return(
            <>Bitte warten...</>
        )
    }
    else if (isEmptyMode && props.currentRent.ThirdParty_idPerson === null) {
        return(
            <>
                <Dialog
                    open={isOpenChoice}
                    onClose={() => setIsOpenChoice(false)}
                    maxWidth="sm" fullWidth
                >
                    <DialogTitle>Kunden wählen</DialogTitle>
                    <DialogContent>
                        {(isLoading) && <LinearProgress />}
                        <TextField
                            label="Firma wählen"
                            size="small"
                            sx={{mt: 2}}
                            fullWidth
                            select
                            disabled={isLoading}
                            value={thirdPersonPerson?.idCompany}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => (thirdPersonPerson !== null) && setThirdPersonPerson({...thirdPersonPerson, idCompany: Number(event.target.value)})}
                        >
                            <MenuItem key={`idCompany--1}`} value={-1}>Neuer Firma...</MenuItem>
                            {companyArray.map(x =>
                                <MenuItem key={`idCompany-${x.idCompany}`} value={x.idCompany}>{x.Company}</MenuItem>
                            )}
                        </TextField>
                        <TextField
                            label="Kunde wählen"
                            size="small"
                            sx={{mt: 2}}
                            fullWidth
                            select
                            disabled={isLoading}
                            value={thirdPersonPerson?.idPerson}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => (thirdPersonPerson !== null) && setThirdPersonPerson({...thirdPersonPerson, idPerson: Number(event.target.value)})}
                        >
                            <MenuItem key={`idPerson--1}`} value={-1}>Neuer Kunde...</MenuItem>
                            {personArray.filter(x => x.idCompany === Number(thirdPersonPerson?.idCompany)).map(x =>
                                <MenuItem 
                                    key={`idPerson-${x.idPerson}`} 
                                    value={x.idPerson}
                                    disabled={x.idPerson === Number(props.currentRent.idPerson)}
                                >{x.FirstName} {x.LastName}</MenuItem>
                            )}
                        </TextField>
                    </DialogContent>

                    <DialogActions>
                        <Button disabled={isLoading} variant="outlined" onClick={handleClose}>Abbruch</Button>
                        <Button disabled={isLoading} variant="contained" onClick={handleAdd}>Hinzufügen</Button>
                    </DialogActions>

                </Dialog>



                <Button 
                    variant='contained' 
                    sx={{float: "right"}}
                    disabled={props.currentRent.idRentState >= 7}
                    onClick={() => setIsOpenChoice(true)}
                >Weitere Vertragspartei hinzufügen</Button>
            </>
        )
    } 
    else {
        return(
            <>
                <Dialog
                    open={isOpenEdit}
                    onClose={handleClose}
                >
                    <DialogTitle>Drittpartei</DialogTitle>
                    <DialogContent>
                        {(isLoading) && <LinearProgress/>}
                        { (thirdPersonPerson != undefined) &&
                            <CustomerEdit 
                                currentObject={thirdPersonPerson}
                                setCurrentObject={setThirdPersonPerson}
                                enableFullEdit={false}
                            />
                        }
                        { (companyObject != undefined && addressObject != undefined) &&
                            <>
                                <CompanyEdit 
                                    currentObject={companyObject!}
                                    setCurrentObject={setComapnyObject}
                                />
                                <AddressEdit 
                                    currentObject={addressObject!}
                                    setCurrentObject={setAddressObject}
                                />
                            </>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>Abbruch</Button>
                        <Button 
                            variant="contained"
                            color="success"
                            onClick={handleSaveThirdPerson}
                        >Speichern</Button>
                    </DialogActions>
                </Dialog>

                <Button
                    variant="outlined"
                    color="error"
                    sx={{float: "right", ml: 2}}
                    onClick={handleRemoveThirdPerson}
                    disabled={props.currentRent.idRentState >= 7}
                >Entfernen</Button>

                <Button
                    variant="outlined"
                    sx={{float: "right"}}
                    onClick={handleOpenEdit}
                    disabled={props.currentRent.idRentState >= 7}
                >{thirdPersonPerson.FirstName} {thirdPersonPerson.LastName} ({thirdPersonPerson.Company})</Button>

            </>
        )
    }
}