import { Alert, Box, Button, Collapse, Snackbar, Tab, Tabs, Typography } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFetch, uploadFetch } from '../../hooks/useFetch';
import { IAddress } from '../../interfaces/IAddress';
import { IChildMetadata } from '../../interfaces/IChildMetadata';
import { ICompany } from '../../interfaces/ICompany';
import { IDocumentWithMetastates } from '../../interfaces/IDocument';
import { IOfferFullObject } from '../../interfaces/IOffer';
import { IPerson, IPersonFullObject } from '../../interfaces/IPerson';
import { IRent } from '../../interfaces/IRent';
import { actionCreatorsRents } from '../../redux/actionCreators';
import { State } from '../../redux/combine_reducers';
import AlertError from '../core/AlertError';
import WaitingSequence from '../core/WaitingSequence';
import DocumentOverview from '../Document/DocumentOverview';
import Rents from '../Rent/Rents';
import RentEdit from '../Rent_New/RentEdit';
import AddressEdit from './AddressEdit';
import CompanyEdit from './CompanyEdit';
import CustomerEdit from './CustomerEdit';
import CustomerOfferOverview from './CustomerOfferOverview';
import CustomerRentOverview from './CustomerRentOverview';
import LinearProgress from '@mui/material/LinearProgress';
import Fade from '@mui/material/Fade';
import { useNavigate } from 'react-router-dom';



interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}





export interface IProps {
    idPerson?: number | null;
    setIdPerson?: Function;
    personArray?: IPerson[];
    setPersonArray?: Function;
    idRent?: number;
    // Rent state
    idRentState?: number | null;
    // Für REnt
    idCompany?: number | null;
    // Fals selber Child
    saveCounter?: number;
    resetSaveCounter?: number;
    // Um im Parent zu visualisieren
    setIsBlockedCompany?: Function;
    setIsCreditCheckedCompany?: Function;
    // Zur Childvalidierung
    setMetaState?: Function;
}


export const resetCompanyObject = () => {
    return {
        Company: "",
        idAddress: -1,
        idCreditState: 1,
        isBlocked: false,
        CompanyRegistrationNumber: undefined,
        Email: undefined,
        idCompanyCategory: undefined,
        PhoneNumber: undefined,
        InternComment: undefined,
        Ranking: undefined,
        Sales: undefined,
        TaxNumber: undefined,
        Website: undefined

    } as ICompany;
}

export const resetAddressObject = () => {
    return {
        idPostcode: -1,
        Street: "",
        StreetNr: "",
        Floor: undefined,
        StreetAditional: undefined
    } as IAddress;
}

const newRentObject = (idPerson:number|undefined) => {
    return {
        idPerson: (idPerson === undefined) ? -1 : idPerson,
        idProduct: 1,
        idRentState: 1,
        idRentTimeFrame: 2,
        idRentType: 1,
        idRoom: -1,
        idUnit: 1,
        PricePerUnit: 0,
        DepositInMounths: 2,
        Units: 0,
        FromDate: undefined,
        ToDate: undefined,
        CustomerMessage: undefined,
        Deposit: 0,
        HandingOverDate: undefined,
        SpecialArrangement: undefined,
        ThirdParty_idPerson: null

    } as IRent;
}



const CustomerFullObject: React.FC<IProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const companyArray = useSelector((state: State) => state.companyArray.storeCompany);
    const genderArray = useSelector((state: State) => state.genderArray.storeGender);
    const rentArray = useSelector((state: State) => state.rentArray.storeRent);
    //
    const { addRentArray, setRentArray } = bindActionCreators(actionCreatorsRents, dispatch);
    //
    const [fullEditObject, setFullEditObject] = useState<IPersonFullObject | undefined>();
    const [wasSuccessfully, setWasSuccessfully] = useState(true);
    const [isNew, setIsNew] = useState(false);
    const [wasSaved, setWasSaved] = useState(false);
    const [wasSavedSuccessfully, setWasSavedSuccessfully] = useState(true);
    const [wasSavedSuccessfullyRent, setWasSavedSuccessfullyRent] = useState(true);
    const [childSaveCounter, setChildSaveCounter] = useState(0);
    // Zum Edit
    const [personObject, setPersonObject] = useState<IPerson | undefined>();
    const [companyObject, setCompanyObject] = useState<ICompany>(resetCompanyObject());
    const [addressObject, setAddressObject] = useState<IAddress>(resetAddressObject());
    const [rentObject, setRentObject] = useState<IRent | null>(null);
    const [documentArray, setDocumentArray] = useState<IDocumentWithMetastates[]>([]);
    const [offerArray, setOfferArray] = useState<IOfferFullObject[]>([]);
    // Metstates
    const [currentTab, setCurrentTab] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    //
    const [childMetadataRent, setChildMetadataRent] = useState<IChildMetadata>({} as IChildMetadata);

    const setPersonWrapper = (respinsePerson: IPersonFullObject | undefined) => {
        if (respinsePerson != undefined) {
            setFullEditObject(respinsePerson);
            setPersonObject(respinsePerson);

            if (respinsePerson.CompanyObject == undefined) {
                setCompanyObject(resetCompanyObject());
                setAddressObject(resetAddressObject());
                setOfferArray([]);
            } else {
                setCompanyObject(respinsePerson.CompanyObject);
                setDocumentArray(respinsePerson.DocumentArray);
                (respinsePerson.OfferArray != undefined) && setOfferArray(respinsePerson.OfferArray);

                if (respinsePerson.CompanyObject.AddressObject == undefined) {
                    setAddressObject(resetAddressObject());
                } else {
                    setAddressObject(respinsePerson.CompanyObject.AddressObject);
                }

            }
            setIsNew(false);
        }
    }

    const setSavePersonWrapper = (responsePerson: IPersonFullObject | undefined) => {
        if (responsePerson != undefined) {
            if (props.personArray != undefined && props.setPersonArray != undefined) {
                if (isNew) {
                    props.setPersonArray([responsePerson, ...props.personArray]);
                } else {
                    props.setPersonArray([...props.personArray.map(x => x.idPerson === responsePerson.idPerson ? responsePerson : x)]);
                }
            }
            setPersonWrapper(responsePerson);
            saveRent(responsePerson);
            /*
            if (thirdPary === null) {
                saveRent(responsePerson);
            } else {
                saveThridParty(responsePerson);
            }
            */
            
            setChildSaveCounter(1);

            if (rentObject == undefined) {
                setLoading(false);
                setWasSaved(true);
            }
        }
    }

    /*
    const setSaveThirdPartyWrapper = (response: IPerson | undefined, responsePerson: IPersonFullObject | undefined) => {
        if (response !== undefined && responsePerson !== undefined) {

            if (thirdPary !== null && props.personArray !== undefined && props.setPersonArray) {
                if (Number(thirdPary?.idPerson) < 0) {
                    props.setPersonArray([
                        response,
                        ...props.personArray
                    ])
                } else {
                    props.setPersonArray([
                        ...props.personArray.map(x => x.idPerson === response.idPerson ? response : x)
                    ]);
                    
                }
            }

            setThirdPary(response);
            saveRent(responsePerson,response);
        }
    }
    */

    const setSaveRentWrapper = (responseObject: IRent | undefined) => {
        if (responseObject != undefined) {
            let findObject = rentArray.find(x => x.idRent === Number(responseObject.idRent));

            if (findObject == undefined) {
                addRentArray(responseObject);
            } else {
                setRentArray([...rentArray.map(x => (x.idRent === Number(responseObject.idRent)) ? responseObject : x)])
            }
            setRentObject(responseObject);
            setLoading(false);
            setWasSaved(true);
        }
    }


    useEffect(() => {
        if (childSaveCounter > 0 && childMetadataRent != undefined && childMetadataRent.wasSaved) {
            setChildSaveCounter(0);
        }
    }, [childSaveCounter, childMetadataRent])

    useEffect(() => {
        if (props.idPerson != undefined) {
            setRentObject(null);
            //setThirdPary(null);

            if (props.idPerson > 0) {
                getFetch("person/", props.idPerson, setPersonWrapper, setWasSuccessfully);
                setIsNew(false);
                //navigate(`/${props.idPerson}`, { replace: true });
                window.history.pushState({}, "", `/customers/${props.idPerson}`);
            } else {
                setIsNew(true);
                setCompanyObject(resetCompanyObject());
                setAddressObject(resetAddressObject());
                setDocumentArray([]);
                setOfferArray([]);
                setPersonObject({
                    Email: "",
                    FirstName: "",
                    idCompany: -1,
                    hasNewsletter: false,
                    idGender: 1,
                    idLeadSource: 1,
                    idLeadState: 5,
                    isBlocked: false,
                    LastName: "",
                    idLead: null,
                    Birthday: null,
                    Birthplace: null,
                    idCampaign: null,
                    idMedium: null,
                    InternComment: null,
                    MobileNumber: null,
                    PhoneNumber: null,
                } as IPersonFullObject);
                window.history.pushState({}, "", `/customers/`);
            }
        }
    }, [props.idPerson])

    useEffect(() => {
        if (props.idPerson != undefined && props.idRent != undefined) {
            let testObject = rentArray
                .filter(x => x.idPerson == props.idPerson)
                .find(x => x.idRent == props.idRent);
            
            if (testObject != undefined) {
                setRentObject(testObject);

                /*
                if (testObject.ThirdParty_idPerson !== null && props.personArray !== undefined) {
                    let testThridParty = props.personArray.find(x => x.idPerson === testObject!.ThirdParty_idPerson);
                    (testThridParty !== undefined) && setThirdPary(testThridParty);
                }
                */
                setCurrentTab(1);
            }
        }
    },[props.idPerson, props.idRent])

    useEffect(() => {
        if (props.idPerson  != undefined && props.idPerson > 0) {
            window.history.pushState({}, "", `/customers/${props.idPerson}`);
        }
    },[currentTab])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    }
    
    const savePerson = () => {
        if (personObject != undefined) {
            let uploadPersion = {
                ...personObject,
                isBlocked: Boolean(personObject?.isBlocked),
                Birthday: (personObject.Birthday == undefined) ? null : new Date(personObject.Birthday).toISOString().split("T")[0],
                CompanyObject: {
                    ...companyObject,
                    isBlocked: Boolean(companyObject?.isBlocked),
                    AddressObject: addressObject,
                },
                DocumentArray: documentArray,
                OfferArray: offerArray.map(x => {
                    return {
                        ...x,
                        Created_at: new Date((x.Created_at) ? x.Created_at : new Date()).toISOString().split("T")[0],
                        Expire_at: new Date((x.Expire_at) ? x.Expire_at : new Date()).toISOString().split("T")[0],

                    } as IOfferFullObject
                })
            } as IPersonFullObject;

            console.log(uploadPersion)
            uploadFetch("/person", isNew, uploadPersion, setSavePersonWrapper, setWasSavedSuccessfully);
            setLoading(true);
        }
    }

    /*
    const saveThridParty = (responseObject: IPersonFullObject | undefined) => {
        console.log("---------------");
        console.log("THIRD PARTY")
        if (thirdPary !== null && responseObject !== undefined) {
            let uploadPersion = {
                ...thirdPary,
                isBlocked: Boolean(thirdPary?.isBlocked),
                Birthday: (thirdPary!.Birthday == undefined) ? null : new Date(thirdPary!.Birthday).toISOString().split("T")[0],
                idCompany: responseObject.idCompany
            } as IPerson;
            console.log(uploadPersion);
            uploadFetch("/person", (Number(thirdPary!.idPerson) < 0), uploadPersion, (res:any) => setSaveThirdPartyWrapper(res,responseObject), setWasSavedSuccessfully);
        }
    }
    */

    const saveRent = (responsePerson: IPersonFullObject | undefined, localThirdPary: IPerson | undefined = undefined) => {
        if (rentObject != undefined && responsePerson != undefined) {
            let uploadObject: IRent = {
                ...rentObject,
                idPerson: Number(responsePerson.idPerson),
                FromDate: (rentObject.FromDate == undefined) ? undefined : new Date(rentObject.FromDate).toISOString().split("T")[0],
                ToDate: (rentObject.ToDate == undefined) ? undefined : new Date(rentObject.ToDate).toISOString().split("T")[0],
            };

            if (localThirdPary != undefined) {
                uploadObject.ThirdParty_idPerson = Number(localThirdPary.idPerson);
            }

            uploadFetch("/rent", (uploadObject.idRent == undefined || uploadObject.idRent <= 0), uploadObject, setSaveRentWrapper, setWasSavedSuccessfullyRent)
        }
    }

    const getRentPriceNull = () => {
        return (rentObject != null && (!rentObject.Units || !rentObject.PricePerUnit || !rentObject.Deposit || !rentObject.DepositInMounths))
    }



    if (props.idPerson == undefined) { return <>Bitte Kontakt wählen...</> }
    else if (!setWasSuccessfully) { return <AlertError /> }
    else if (personObject == undefined || (personObject.idPerson != props.idPerson && props.idPerson !== -1)) { return <WaitingSequence /> }
    else {
        return (
            <>

                {(loading) && <LinearProgress />}

                <Snackbar
                    key="savebar"
                    open={wasSaved}
                    autoHideDuration={6000}
                    onClose={() => setWasSaved(false)}
                    anchorOrigin={{ 'vertical': 'bottom', 'horizontal': 'right' }}
                >
                    <Alert onClose={() => setWasSaved(false)} severity={(wasSavedSuccessfully && wasSavedSuccessfullyRent) ? "success" : "error"} sx={{ width: '100%' }}>
                        {(wasSavedSuccessfully && wasSavedSuccessfullyRent) ? "Erfolgreich gespeichert!" : "Es ist ein Fehler aufgetretten!"}
                    </Alert>
                </Snackbar>


                <Collapse style={{ marginTop: 25 }} in={
                    (rentObject != null && rentObject.idRentState === 14
                        && (rentObject.EndOfContract == undefined || rentObject.MovingOut == undefined))
                } >
                    <Alert
                        severity="error"
                        sx={{ mb: 2 }}
                    >
                        Bitte stelle sicher, dass bei der Miete das Vertragsende und der Auszugstermin (12Uhr) gesetzt wurde!
                    </Alert>
                </Collapse>


                <Collapse style={{ marginTop: 25 }} in={(rentObject != null && rentObject.idRentState !== 1 && rentObject.RentNummer == undefined)} >
                    <Alert
                        severity="error"
                        sx={{ mb: 2 }}
                    >
                        Für die Miete wurde keine Mietnummer generiert! Im Zweifel melde dich an einen Administrator.
                    </Alert>
                </Collapse>


                <Collapse style={{ marginTop: 25 }} in={(rentObject != null && rentObject.idRentState >= 10 && rentObject.ConclusionOfContract == undefined)} >
                    <Alert
                        severity="error"
                        sx={{ mb: 2 }}
                    >
                        Bitte das Datum den Vertragsabschlusses setzen!
                    </Alert>
                </Collapse>

                <Collapse style={{ marginTop: 25 }} in={getRentPriceNull()} >
                    <Alert
                        severity="error"
                        sx={{ mb: 2 }}
                    >
                        Ohne die Preiangaben ist keine Speicherung möglich!<br />Gegebenfalls Zeitraum anpassen
                    </Alert>
                </Collapse>

                <Collapse style={{ marginTop: 25 }} in={(rentObject != null && (rentObject.idRoom == undefined || rentObject.idRoom < 0))} >
                    <Alert
                        severity="error"
                        sx={{ mb: 2 }}
                    >
                        Es muss ein Raum gewehlt werden
                    </Alert>
                </Collapse>

                <Typography variant='h5'>
                    Kontaktverwaltung
                    <div style={{ float: 'right' }}>
                        <Button
                            disabled={
                                loading
                                || personObject.FirstName === ""
                                || personObject.LastName === ""
                                || personObject.Email === ""
                                || companyObject.Company === ""
                                || addressObject.Street === ""
                                || addressObject.StreetNr === ""
                                || addressObject.idPostcode < 0
                                || (
                                    rentObject != undefined && (
                                        rentObject.FromDate == undefined
                                        || rentObject.idRoom == undefined || rentObject.idRoom < 0
                                    )
                                )
                                || getRentPriceNull()
                            }
                            style={{ marginLeft: 5 }}
                            variant="contained"
                            onClick={() => savePerson()}
                        >
                            Speichern
                        </Button>
                    </div>
                </Typography>

                <Box sx={{ width: '100%', mt: 3 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Kontakt" />
                            <Tab label="Buchungen" />
                            <Tab label="Angebote" />
                            <Tab label="Dokumente" />
                        </Tabs>
                    </Box>

                    <TabPanel value={currentTab} index={0}>
                        <CustomerEdit currentObject={personObject} setCurrentObject={setPersonObject} />
                        <CompanyEdit currentObject={companyObject} setCurrentObject={setCompanyObject} />
                        <AddressEdit currentObject={addressObject} setCurrentObject={setAddressObject} />
                    </TabPanel>

                    <TabPanel value={currentTab} index={1}>
                        <Typography variant='h6'>
                            Buchungen
                            <Button
                                variant='outlined'
                                color='success'
                                sx={{ float: 'right' }}
                                onClick={() => setRentObject(newRentObject(personObject.idPerson))}
                            >Neue Buchung</Button>
                        </Typography>
                        <CustomerRentOverview
                            rentArray={rentArray.filter(x => x.idPerson === Number(personObject.idPerson))}
                            setCurrentObject={setRentObject}
                        />
                        { (rentObject == undefined)
                            ? <>Bitte Buchung wählen....</> 
                            :
                            <RentEdit
                                key={`rent-${rentObject.idRent}`}
                                currentObject={rentObject}
                                setCurrentObject={setRentObject}
                            />
                        }

                        {/*<Rents idPerson={personObject.idPerson} saveCounter={childSaveCounter} setMetastateChild={setChildMetadataRent}/>*/}
                    </TabPanel>


                    <TabPanel value={currentTab} index={2}>
                        <CustomerOfferOverview offerArray={offerArray} setOfferArray={setOfferArray} />
                    </TabPanel>


                    <TabPanel value={currentTab} index={3}>
                        <DocumentOverview documentArray={documentArray} setDocumentArray={setDocumentArray} disablePublic={true} />
                    </TabPanel>
                </Box>
            </>
        )
    }
}
export default CustomerFullObject;
