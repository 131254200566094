import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar/Navbar';
import { useValidatenText } from './hooks/useValidaten';
import WaitingSequence from './components/core/WaitingSequence';
import DynamicComponente from './components/core/DynamicComponente';
import Login from './Login/Login';
import JanitorSite from './components/JanitorSite/JanitorSite';

function App() {
  const bearer_token = localStorage.getItem('bearer_token');
  const [isLogged,setIsLogged] = useState<boolean | null>(null);
  const [isJanitor,setIsJanitor] = useState(localStorage.getItem('isJanitor'));

  const [msg, setMsg] = useState<string | null>(null);

  

  fetch("/api/login"  ,{
    headers : { "Authorization" : "Bearer " + bearer_token }
  })
  .then(res => {
      if (res.status == 200) {
          setIsLogged(true);
      } else {throw Error(String(res.status))}
  })
  .catch( error => {
      if (String(error).includes("401")) {
          setMsg("Ihre Session ist abgelaufen.")
      } else if (String(error).includes("404")) {
          setMsg("Die Anmeldedaten konnten nicht gefunden werden. Bitte überprüfen Sie den Anmeldename und das Passwort");
      } else if (String(error).includes("403")) {
          setMsg(`Ihr Konto wurde deaktiviert!`);
      } else {
          setMsg(`Es ist ein unbekannter Fehler aufgeretten. Möglicherweise ist der Dienst vorübergehend offline.`);
      }
      setIsLogged(false);
  })

  if (isLogged == undefined) {
    return <WaitingSequence />
  }
  else if (isLogged && (Number(isJanitor) === 1 || Number(isJanitor) === 0)) {
    if (Number(isJanitor) === 1) {
      return <JanitorSite/>
    } else {
      return <DynamicComponente />
    }
  } 
  else {
    return <Login msg={msg}/>
  }

  /*
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
  */
}

export default App;
