
import { AddCircle } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../redux/combine_reducers';
import AlertRemove from '../core/AlertRemove';
import Product from './Product';
import Hotkeys from 'react-hot-keys';
import { JSONTree } from 'react-json-tree';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import GenericOverview from '../core/GenericOverview';


const Products:React.FC = () => {
    const dispatch = useDispatch();
    const productArray = useSelector((state: State) => state.productArray.storeProducts);
    // Selected Id
    const [selectedId,setSelectedId] = useState<number|undefined>();

    const columns: GridColDef[] = [
        { 
            field: 'idProduct', 
            headerName: 'Nr.', 
            width: 90,
        },
        {
            field: 'isEnabled',
            headerName: 'Aktiv',
            width: 100,
            valueGetter: (params: GridValueGetterParams) => (params.row.isEnabled == true) ? "Ja" :  "Nein" ,
        },
        {
            field: 'isPublic',
            headerName: 'Öffentlich',
            width: 100,
            valueGetter: (params: GridValueGetterParams) => (params.row.isPublic == true) ? "Ja" :  "Nein" ,
        },
        {
            field: 'hasAllRooms',
            headerName: 'Alle Räume',
            width: 100,
            valueGetter: (params: GridValueGetterParams) => (params.row.hasAllRooms == true) ? "Ja" :  "Nein" ,
        },
        {
            field: 'Title',
            headerName: 'Bezeichnung',
            flex: 1
        }

    ];

    

    return(
        <GenericOverview
            headline="Produkte"
            columns={columns}
            data={productArray}
            idField="idProduct"
            setTargetId={setSelectedId}
        >
            <Product idProduct={selectedId} />
        </GenericOverview>
    )
}
export default Products;