import { Help } from '@mui/icons-material';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidatenText } from '../../../hooks/useValidaten';
import { ILeadState } from '../../../interfaces/ILeadState';
import { actionCreatorsLeadState, actionCreatorsMediums, actionCreatorsUnits } from '../../../redux/actionCreators';
import { State } from '../../../redux/combine_reducers';
import GenericEdit, { IInputValues, ISetValues } from '../../core/GenericEdit';
import InputField from '../../core/InputField';



interface IProps {
    idLeadState?: number;
}


const LeadState:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const leadStateArray = useSelector((state: State) => state.leadStateArray.storeLeadState);
    const { setLeadState, addLeadState, updateLeadState } = bindActionCreators(actionCreatorsLeadState,dispatch);
    // Main Object
    const [mainObject, setMainObject] = useState<ILeadState | undefined>();
    // Haupstates
    const [idLeadState,setIdLeadState] = useState<number>();
    const [state,setState,vState] = useValidatenText();
    const [sendMail,setSendMail] = useState<boolean>(false);
    const [createTmpToken,setCreateTmpToken] = useState<boolean>(false);
    // Optionale States
    const [mailTitle,setMailTitle] = useState<string | null>(null);
    const [mailBody,setMailBody] = useState<string | null>(null);
    // Validierung
    const [validationArray, setValidationArray] = useState<boolean[]>([]);
    // Metastatea
    const [isNew,setIsNew] = useState(false);
    const [isEmpty,setIsEmpty] = useState(true);
    const [isTime,setIsTime] = useState<boolean>(false);


    useEffect(() => {
        if (props.idLeadState === undefined) {
            
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            resetValues();
            
            if (props.idLeadState === -1) {
                setIsNew(true)
            } else {
                setIsNew(false);
                setMainObject(leadStateArray.find(x => x.idLeadState=== props.idLeadState));
            }
        }
    },[props.idLeadState])

    useEffect(() => {setValidationArray([vState && helpCheckVal(mailTitle) && helpCheckVal(mailBody)])},[vState,sendMail,mailTitle,mailBody])

    const helpCheckVal = (strState:string|null) => {
        if (sendMail) {
            return (strState !== null && strState !== "");
        } else {
            return true;
        }
    }

    const valuesArray:ISetValues[] = [
        {
            Attribute: "idLeadState",
            Value: idLeadState,
            SetFunction: setIdLeadState,
            IsPrimaryKey: true
        },
        {
            Attribute: "State",
            Value: state,
            SetFunction: setState,
        },
        {
            Attribute: "sendMail",
            Value: sendMail,
            SetFunction: setSendMail
        },
        {
            Attribute: "createTmpToken",
            Value: createTmpToken,
            SetFunction: setCreateTmpToken
        },
        {
            Attribute: "MailTitle",
            Value: mailTitle,
            SetFunction: setMailTitle,
            PossibleUndefined: true,
            Type: 'string'
        },
        {
            Attribute: "MailBody",
            Value: mailBody,
            SetFunction: setMailBody,
            PossibleUndefined: true,
            Type: 'string'
        }
    ];

    const inputField:IInputValues[] = [
        {
            InputElement: <InputField isEdit={!isEmpty} label='Status' value={state} setFunction={setState} isValidated={vState}/>,
        },
        {
            InputElement:         <FormControl>
                                    <FormLabel>Temporärer Authentifizierungsschlüssel <span title="Ermöglicht das einmalige externe Authentifizieren (bspw. um Dokumente hochladen zu können)">{<Help/>}</span> </FormLabel>
                                    <RadioGroup 
                                        row
                                        defaultValue="false" 
                                        value={(createTmpToken) ? "true" : "false"} 
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setCreateTmpToken(event.target.value === "true") }
                                    >
                                        <FormControlLabel disabled={isEmpty} value="true" control={<Radio />} label="Ja" />
                                        <FormControlLabel disabled={isEmpty} value="false" control={<Radio />} label="Nein" />
                                    </RadioGroup>
                                </FormControl>
        },
        {
            InputElement:         <FormControl>
                                    <FormLabel>Mail versenden</FormLabel>
                                    <RadioGroup 
                                        row
                                        defaultValue="false" 
                                        value={(sendMail) ? "true" : "false"} 
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setSendMail(event.target.value === "true") }
                                    >
                                        <FormControlLabel disabled={isEmpty} value="true" control={<Radio />} label="Ja" />
                                        <FormControlLabel disabled={isEmpty} value="false" control={<Radio />} label="Nein" />
                                    </RadioGroup>
                                </FormControl>
        },
        {
            InputElement: <InputField isEdit={!isEmpty && sendMail} label='Title' value={mailTitle} setFunction={setMailTitle} isValidated={helpCheckVal(mailTitle)} />,
        },
        {
            InputElement: <InputField isEdit={!isEmpty && sendMail} label='Emailkörper' value={mailBody} rows={20} setFunction={setMailBody} isValidated={helpCheckVal(mailBody)}/>,

        },
        {
            InputElement: <div style={{minHeight: 200, backgroundColor: "with"}}><Typography>HTML Ansicht:</Typography> <div dangerouslySetInnerHTML={{ __html: (mailBody === null) ? "" : mailBody }} /></div>,
        },
        
    ]

    const resetValues = () => {
        valuesArray.filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey == false).map(x => (x.Type !== undefined && x.Type === "boolean") ? x.SetFunction(false) : x.SetFunction(null))
    }

    


    return(
        <>  
            <GenericEdit
                headlineNew="Neues Lead Status"
                headlineAttr="State"
                setValuesDef={valuesArray}
                inputFilds={inputField}
                // Zum Hochladen
                endpoint="leadState"
                // Hauptelemeten
                mainObject={mainObject}
                setMainObject={setMainObject}
                // Zum Validieren
                validatenArray={validationArray}
                // Metastates
                isEmpty={isEmpty}
                isNew={isNew}
                updatedReduxArray={updateLeadState}
                addReduxArray={ addLeadState}
            />
        </>
    )

}
export default LeadState;