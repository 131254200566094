import { Typography } from "@mui/material";
import React, { useState } from "react";
import { IInvoice } from "../../interfaces/IInvoice";
import { useFetch } from "../../hooks/useFetch";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams, deDE } from "@mui/x-data-grid";
import { Check, Close } from "@mui/icons-material";
import GenericOverview from "../core/GenericOverview";
import AlertError from "../core/AlertError";
import WaitingSequence from "../core/WaitingSequence";
import InvoiceChoiceEdit from "./InvoiceEdit";
import InvoiceEdit from "./InvoiceEdit";
import { useParams } from "react-router-dom";


const InvoiceOverview: React.FC = () => {
    const paramObject = useParams();

    const [ invoiceArray, setInvoiceArray, wasSuccessfullyInvoiceArray ] = useFetch<IInvoice[]>("Invoice");
    const [targetId, setTargetId] = useState<number | null>((paramObject.idInvoice == undefined) ? null : Number(paramObject.idInvoice));

    const wrapperSetId = (newTargetId:number) => {
        if (newTargetId > 0) {
            window.history.pushState({}, "", `/inovice/${newTargetId}`);
        } else {
            window.history.pushState({}, "", `/inovice`);
        }
        setTargetId(newTargetId);
    }


    const columns: GridColDef[] = [
        {
            field: 'idInvoice',
            headerName: 'Nr.',
            width: 50,
        },
        {
            field: 'InvoiceState',
            headerName: 'Status',
            width: 100,
        },
        {
            field: 'LoctionShortName',
            headerName: 'Standort',
            width: 90,
        },
        {
            field: 'RoomName',
            headerName: 'Raumnummer',
            width: 120,
        },
        {
            field: 'Company',
            headerName: 'Kunde',
            flex: 1
        },
        {
            field: 'Total',
            headerName: 'Total',
            width: 120,
            valueGetter: (params: GridValueGetterParams) => Number(params.row.Total).toFixed(2).replace(".",",") + " €" 
        },
    ];


    if (!wasSuccessfullyInvoiceArray) { return <AlertError /> }
    else if (invoiceArray === undefined) { return <WaitingSequence /> }
    else {
        return (
            <GenericOverview
                headline="Rechnungen"
                columns={columns}
                data={invoiceArray}
                idField="idInvoice"
                setTargetId={wrapperSetId}
                colDataGrid={4}
            >
                <InvoiceEdit
                    key={`inoviceEdit-${targetId}`}
                    targetId={targetId}
                    setTargetId={setTargetId}
                    invoiceArray={invoiceArray}
                    setInvoiceArray={setInvoiceArray}
                />
            </GenericOverview>
        )
    }
}
export default InvoiceOverview;