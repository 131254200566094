import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useFetch } from '../../hooks/useFetch';
import { IMessage } from '../../interfaces/IMessage';
import AlertError from '../core/AlertError';
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import MarketingStatistic from './MarketingStatistic';
import Message from './Message';


const Messages:React.FC = () => {
    const [messages,setMessages,wasSuccessfully] = useFetch<IMessage[]>("message");

    useEffect(() => {console.log(messages)},[messages])

    return(
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant='h4' gutterBottom>
                    Meldungen
                </Typography>

                <GridContainer>
                { (!wasSuccessfully ) 
                    ? <AlertError />
                    : (messages === undefined) 
                        ? <>Bitte warten...</>
                        : (messages === null || messages.length === 0)
                            ? <>Keine Nachrichten vorhanden</>
                            : messages.map(x => 
                                <GridItem>
                                    <Message
                                        key={x.idMessage}
                                        message={x}
                                        messageArray={messages}
                                        setMessageArray={setMessages}
                                    />
                                </GridItem>
                        )
                }
                </GridContainer>
        </Box>
    )

}
export default Messages;