import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRentState } from "../interfaces/IRentState";


const initialState =  { storeRentState: [] as IRentState[] }; 

const sliceRentState = createSlice({
    name: "RENTSTATEARRAY",
    initialState,
    reducers: {
        setRentStateArray: (state, action: PayloadAction<IRentState[]>) => {
            state.storeRentState = [...action.payload];
        },
        addRentStateArray: (state, action: PayloadAction<IRentState>) => {
            state.storeRentState = [...state.storeRentState,action.payload];
        },
        updateRentArray: (state, action: PayloadAction<IRentState>) => {
            let tmpArray =  state.storeRentState.map(x => x.idRentState === action.payload.idRentState ? action.payload : x);
            state.storeRentState = tmpArray;
        }
    }
})
export const { setRentStateArray, addRentStateArray, updateRentArray } = sliceRentState.actions;
export default sliceRentState.reducer;