import React, { useEffect, useState } from 'react';
import { ICompany } from '../../interfaces/ICompany';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../redux/combine_reducers';
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import { DesktopDatePicker, DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import deLocale from "date-fns/locale/de";
import { MenuItem, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { staticCreditStateArray } from '../Customer/staticCreditStateArray';



export interface IProps {
    currentObject: ICompany,
    setCurrentObject: Function;
}



const CompanyEdit:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const companyCategoryArray = useSelector((state: State) => state.companyCategoryArray.storeCompanyCategory);
    const companyArray = useSelector((state: State) => state.companyArray.storeCompany);


    const handleInput = (event:React.ChangeEvent<HTMLInputElement>) => {
        let splitTest = event.target.name.split("-");
        let attrName = splitTest[1];
        let testVal = {...props.currentObject} as any

        if (attrName in testVal) {
            if (splitTest[0] === "number") {
                //props.setCurrentObject({...props.currentObject, attrName : Number(event.target.value)})
                testVal[attrName] = Number(event.target.value);
            } 
            else if (splitTest[0] === "boolean") {
                //props.setCurrentObject({...props.currentObject, attrName : String(event.target.value) === "true"})
                testVal[attrName] =  String(event.target.value) === "true";
            }
            else {
                console.log({...props.currentObject, attrName : String(event.target.value)})
                //props.setCurrentObject({...props.currentObject, attrName : String(event.target.value)});
                testVal[attrName] = String(event.target.value);
            }
        } else {
            console.warn(`Can't ${attrName} in Interface`)
        }

        props.setCurrentObject({...testVal})

    }


    const checkString = (possibleString:string|null|undefined) => {
        if (possibleString == undefined) {
            return "";
        } else {
            return possibleString;
        }
    }


    return(
        <>
            <Box sx={{mt: 5}}></Box>
            <Typography variant='h6'>Firma</Typography>
            <Box sx={{mt: 3}}></Box>

            <GridContainer>
                <GridItem sm={12}>
                    <TextField
                        label="Firma"
                        name="string-Company"
                        size="small"
                        value={checkString(props.currentObject.Company)}
                        error={props.currentObject.Company === ""}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>


                <GridItem sm={12}>
                    <TextField
                        select
                        label="Unternehmenskategorie"
                        size="small"
                        value={String(props.currentObject.idCompanyCategory)}
                        name="number-idCompanyCategory"
                        onChange={handleInput}
                        fullWidth
                    >
                        { companyCategoryArray.map(x =>
                            <MenuItem value={x.idCompanyCategory}>
                                {x.Category}
                            </MenuItem>
                        )}

                    </TextField>
                </GridItem>


                <GridItem sm={6}>
                    <TextField
                        select
                        label="Gesperrt"
                        name="boolean-isBlocked"
                        size="small"
                        value={(props.currentObject.isBlocked == true) ? "true" : "false"}
                        onChange={handleInput}
                        fullWidth
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField>
                </GridItem>

                <GridItem sm={6}>
                    <TextField
                        select
                        label="Bonitätsstatus"
                        size="small"
                        value={String(props.currentObject.idCreditState)}
                        name="number-idCreditState"
                        onChange={handleInput}
                        fullWidth
                    >
                        { staticCreditStateArray.map(x =>
                            <MenuItem value={x.idCreditState}>
                                {x.CreditState}
                            </MenuItem>
                        )}

                    </TextField>
                </GridItem>


                <GridItem sm={6}>
                    <TextField
                        label="Umsatz"
                        name="number-Sales"
                        size="small"
                        type="number"
                        value={props.currentObject.Sales}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>

                <GridItem sm={6}>
                    <TextField
                        label="Ranking"
                        name="string-Ranking"
                        size="small"
                        value={checkString(props.currentObject.Ranking)}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>


                <GridItem sm={6}>
                    <TextField
                        label="StNr."
                        name="string-TaxNumber"
                        size="small"
                        value={checkString(props.currentObject.TaxNumber)}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>

                <GridItem sm={6}>
                    <TextField
                        label="Handelsnummer"
                        name="string-CompanyRegistrationNumber"
                        size="small"
                        value={checkString(props.currentObject.CompanyRegistrationNumber)}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>


                <GridItem sm={6}>
                    <TextField
                        label="Firmen-Email"
                        name="string-Email"
                        size="small"
                        value={checkString(props.currentObject.Email)}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>

                <GridItem sm={6}>
                    <TextField
                        label="Internetpräsenz"
                        name="string-Website"
                        size="small"
                        value={checkString(props.currentObject.Website)}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>


            </GridContainer>
            
            <Box sx={{mt: 5}}></Box>
            <Typography variant='h6' gutterBottom>Konfoinformationen</Typography>

            <GridContainer>
                <GridItem sm={12}>
                    <TextField
                        label="IBAN"
                        name="string-IBAN"
                        size="small"
                        value={checkString(props.currentObject.IBAN)}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>
                <GridItem sm={6}>
                    <TextField
                        label="BIC"
                        name="string-BIC"
                        size="small"
                        value={checkString(props.currentObject.BIC)}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>
                <GridItem sm={6}>
                    <TextField
                        label="Bank"
                        name="string-Bank"
                        size="small"
                        value={checkString(props.currentObject.Bank)}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>
            </GridContainer>
        </>
    )

}
export default CompanyEdit;