import { Check, Close } from '@mui/icons-material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../redux/combine_reducers';
import GenericOverview from '../../core/GenericOverview';
import LeadSource from './LeadSoure';

const LeadSources:React.FC = () => {
    const dispatch = useDispatch();
    const leadSourceArray = useSelector((state: State) => state.leadSourceArray.storeLeadSources);
    // Selected Id
    const [selectedId,setSelectedId] = useState<number|undefined>();

    const columns: GridColDef[] = [
        { 
            field: 'idLeadSource', 
            headerName: 'Nr.', 
            width: 90,
        },
        {
            field: 'Source',
            headerName: 'Quelle',
            flex: 1,
        },

    ];

    return(
        <GenericOverview
            headline="Lead Quelle"
            columns={columns}
            data={leadSourceArray}
            idField="idLeadSource"
            setTargetId={setSelectedId}
        >
            <LeadSource idLeadSource={selectedId} />
        </GenericOverview>
    )

}
export default LeadSources;