import { Close } from '@mui/icons-material';
import { Alert, Button, Collapse, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';


interface IProps {
    wasRemoved: boolean;
    setWasRemoved: Function;
    wasSuccessullyRemoved: boolean;
}

const AlertRemove:React.FC<IProps> = (props) => {
    return(
        <Collapse in={props.wasRemoved}>
            <Alert
                severity={props.wasSuccessullyRemoved ? "success" : "error" }
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => props.setWasRemoved(false)}
                     >
                        <Close fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
            >
                {props.wasSuccessullyRemoved ? "Erfolgreich gelöscht!" : "Ein Fehler ist aufgetaucht!" }
            </Alert>
        </Collapse>
    )

}
export default AlertRemove;