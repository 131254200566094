import { AddCircle } from '@mui/icons-material';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useChildMetadata } from '../../hooks/useChildMetadata';
import { getFetch, uploadFetch } from '../../hooks/useFetch';
import { ICompanyHasDocument } from '../../interfaces/ICompanyHasDocument';
import { IRoomHasDocument } from '../../interfaces/IRoomHasDocument';
import Document from '../Document/Document';

interface IPropsEdit {
    idCompany?: number | null;
    companyHasDocObject?: ICompanyHasDocument | null;
    saveCounter: number;
    isEmpty: boolean;
    isNew: boolean;
    // Nur wenn neu
    companyHasDocArray?: ICompanyHasDocument[];
    setCompanyHasDocArray?: Function;
    roomHasDocNewCounter?: number;
    setRoomHasDocNewCounter?: Function;
    // Für Childs
    dispatcherChildArrayMetastate: Function;
}

interface IPropsOverview {
    idCompany?: number | null;
    isEmpty: boolean;
    isNew: boolean;
    saveCounter: number;
}





const CompanyHasDocument:React.FC<IPropsEdit> = (props) => {
    const [roomHasDocumentObjectJustNew,setRoomHasDocumentObjectJustNew] = useState<IRoomHasDocument|null>(null);
    // 
    const [idCompany,setIdCompany] = useState<number | null>(null);
    const [idDocument,setIdDocument] = useState<number | null>(null);
    // Metastate
    const [isNew,setIsNew] = useState(true);

    useEffect(() => {
        if (props.companyHasDocObject != undefined) {
            setIdCompany(props.companyHasDocObject.idCompany);
            setIdDocument(props.companyHasDocObject.idDocument);
            setIsNew(false);
        }
    },[props.companyHasDocObject])

    useEffect(() => {
        if (props.idCompany != undefined && props.idCompany > 0) {
            setIdCompany(props.idCompany);
        }
    },[props.idCompany])


    useEffect(() => {
        console.log("SAVE!");
        console.log(props.saveCounter);
        console.log(idCompany);
        console.log(idDocument);
        console.log(isNew);
        if (
            props.saveCounter > 0
            && idCompany != null && idCompany  > 0
            && idDocument != null && idDocument > 0
            && isNew
        ) {
            uploadFetch("companyHasDocument",true,{ idCompany: idCompany, idDocument: idDocument } as ICompanyHasDocument,setRoomHasDocumentObjectJustNew);
            setIsNew(false);
        }
    },[props.saveCounter,idCompany,idDocument])

    useEffect(() => {
        if (
            roomHasDocumentObjectJustNew !== null
            && props.companyHasDocObject !== undefined
            && props.setCompanyHasDocArray !== undefined
            && props.roomHasDocNewCounter !== undefined
            && props.setRoomHasDocNewCounter !== undefined
        ) {
            /*
            props.setRoomHasDocument([...props.roomHasDocument,roomHasDocumentObjectJustNew]);
            props.setRoomHasDocNewCounter(props.roomHasDocNewCounter-1);
            */
        }
    },[roomHasDocumentObjectJustNew])


    return(
        <>
            <Document 
                idDocument={idDocument}
                saveCounter={props.saveCounter}
                setIdDocument={setIdDocument}
                isEmpty={props.isEmpty}
                isNew={props.isNew}
                idDocumentType={4}
            />
        </>
    )

}




const CompanyHasDocuments:React.FC<IPropsOverview> = (props) => {
    const [companyHasDocArray,setCompanyHasDocArray] = useState<ICompanyHasDocument[]>([]);
    const [roomHasDocNewCounter,setRoomHasDocNewCounter] = useState(0);
    // Metastate
    const [wasSuccessfully,setWasSuccessfully] = useState(false);
    const [isNew,setIsNew] = useState(false);
    // Local saveCounter
    const [localSaveCounter,setLocalSaveCounter] = useState(0);
    // ChildValidaten
    const [ childMetastateArray, dispatcherChildArrayMetastate ] = useChildMetadata();


    useEffect(() => {
        if (props.idCompany != undefined && !isNew) {
            setCompanyHasDocArray([]);
            setRoomHasDocNewCounter(0);
            setLocalSaveCounter(0);
            getFetch("companyHasDocument/byCompany/",props.idCompany,setCompanyHasDocArray,setWasSuccessfully);
        } else {
            setIsNew(false);
        }
    },[props.idCompany])
    
    useEffect(() => {
        if (props.isNew) {
            setCompanyHasDocArray([]);
            setRoomHasDocNewCounter(0);
            setIsNew(props.isNew);
        }
    },[props.isNew])

    useEffect(() => {
        if (props.saveCounter > 0) {
            setLocalSaveCounter(props.saveCounter);
        }
    },[props.saveCounter])


    return(
        <>
            <Typography variant='h5'>
                Dokumente

                <IconButton disabled={props.isEmpty} onClick={() => { setLocalSaveCounter(0); setRoomHasDocNewCounter(roomHasDocNewCounter+1) }} style={{float: 'right'}}><AddCircle/></IconButton>
            </Typography>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Art</TableCell>
                            <TableCell>Öffentlich</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            Array.from(Array(roomHasDocNewCounter).keys()).map(i => 
                                <CompanyHasDocument
                                    key={`new-${i}`}
                                    idCompany={props.idCompany}
                                    isEmpty={props.isEmpty}
                                    isNew={props.isNew}
                                    saveCounter={localSaveCounter}
                                    dispatcherChildArrayMetastate={dispatcherChildArrayMetastate}
                                />
                            )
                        }
                        {(companyHasDocArray != undefined) && companyHasDocArray.map(x =>
                            <CompanyHasDocument
                                key={`${x.idCompany}-${x.idDocument}`}
                                companyHasDocObject={x}
                                isEmpty={props.isEmpty}
                                isNew={false}
                                saveCounter={localSaveCounter}
                                dispatcherChildArrayMetastate={dispatcherChildArrayMetastate}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
export default CompanyHasDocuments;
