import { Delete, FileDownload, FileUpload, Restore } from '@mui/icons-material';
import { IconButton, MenuItem, TableCell, TableRow, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { IDocumentWithMetastates } from '../../interfaces/IDocument';
import { IMIMEType } from '../../interfaces/IMIMEType';
import { staticDocumentTypeArray } from './array_DocumentType';
import { staticMimeTypeArray } from './array_MIMEType';


interface IProps {
    documentObject: IDocumentWithMetastates;
    documentArray: IDocumentWithMetastates[];
    setDocumentArray: Function;
    disablePublic?: boolean;
}



const DocumentTableRow:React.FC<IProps> = (props) => {
    // Ref für den Upload
    const fileUploadElement = React.useRef<HTMLInputElement>(null);
    //
    const documentTypeArray = staticDocumentTypeArray;
    const mimeTypeArray = staticMimeTypeArray;


    const localSetter = (localDocObject:IDocumentWithMetastates) => {
        props.setDocumentArray([...props.documentArray.map(x => x.idDocument === localDocObject.idDocument ? {
            ...localDocObject,
            wasChanged: true
        } : x)])
    }

    const setFromFile = (uplodatedFile:File) => {
        if (uplodatedFile !== undefined && "name" in uplodatedFile && "type" in uplodatedFile) {
            let fileName:string = String(uplodatedFile["name"]);
            let mimeType: IMIMEType | undefined = mimeTypeArray.find(x => x.MIMEType === uplodatedFile["type"])
            
            if (mimeType === undefined) {
                //setModalIsOpenMIMIEError(true);
                console.error("Can't load file")
            } else {
                // Init FileRead
                let reader = new FileReader();
                reader.onload = (event:object) => {
                    // Caste als Base64
                    let base64WithMime = String(reader.result);
                    console.log(base64WithMime);
                    //console.log(base64WithMime);
                    //setTmpData(base64WithMime);
                    localSetter({
                        ...props.documentObject,
                        Data: base64WithMime.split(",")[1],
                        FileName: fileName,
                        idMIMEType: mimeType!.idMIMEType
                    })
                }
                // Lade datei in Reader
                reader.readAsDataURL(uplodatedFile);
            }
        }
    }

    /// const: Tmp. Speichern der Datei
    const handleFileUpload = (e:React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files != undefined) {
            let uplodatedFile = e.target.files[0];
            //setDocumentObj({...documentObj, Data: (e.target.files[0])});
            setFromFile(uplodatedFile);
        }
    };
    /// end: const

    /// func: Setzen der Values
    function downloadFile() {
        if (props.documentObject.Data != undefined) {
            // Link -> https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
            // decode a Base64-encoded string into a new string with a character for each byte of the binary data.
            let byteCharacters = atob(props.documentObject?.Data);
            // Each character's code point (charCode) will be the value of the byte. 
            // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
            let byteArray = new Uint8Array(byteNumbers);
            // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
            let blob = new Blob([byteArray], {type: mimeTypeArray.find(x => x.idMIMEType === props.documentObject.idMIMEType)?.MIMEType});
            // Erstelle Link zum BLOB
            let blobUrl = URL.createObjectURL(blob);
            // Erstelle html-Objekt für den Download
            let a = document.createElement("a");
            // setze link auf den neu erstellen Link zum Blolb
            a.href = blobUrl;
            // Setze Downloadtitle für den Datenname
            a.download = props.documentObject.FileName;
            // Click 
            a.click();
        }
    }
    /// end: func


    const setRemoved =(getRemoved:boolean) => {
        localSetter({
            ...props.documentObject,
            getRemoved: getRemoved
        })
    }

    if (props.documentObject.getRemoved != undefined && props.documentObject.getRemoved == true) {
        return(
            <>
                <TableRow>
                    <TableCell colSpan={2}>Gelöscht</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <IconButton size="small" style={{marginLeft: 5}}  onClick={() => setRemoved(false)}><Restore/></IconButton>
                    </TableCell>
                </TableRow>
            </>
        )
    } else {
        return(
            <>
                <TableRow>
                    <TableCell>
                        <TextField
                            select
                            value={String(props.documentObject.idDocumentType)}
                            onChange={ 
                                (event:React.ChangeEvent<HTMLInputElement>)  => 
                                localSetter({...props.documentObject,idDocumentType: Number(event.target.value) })
                            }
                            variant="standard"
                            fullWidth
                        >
                            {documentTypeArray.map(x => 
                                <MenuItem value={x.idDocumentType}>
                                    {x.DocumentType}
                                </MenuItem>
                            )}
                        </TextField>
                    </TableCell>
    
                    {(props.disablePublic == undefined || props.disablePublic == false) &&
                        <TableCell>
                            <TextField
                                select
                                value={(props.documentObject.isPublic) ? "yes" : "no"}
                                onChange={ 
                                    (event:React.ChangeEvent<HTMLInputElement>)  => 
                                    localSetter({...props.documentObject,isPublic: (event.target.value === "yes") })
                                }
                                variant="standard"
                                fullWidth
                            >
                                <MenuItem value="yes">Ja</MenuItem>
                                <MenuItem value="no">Nein</MenuItem>
                            </TextField>
                        </TableCell>
                    }

    
                    <TableCell>{props.documentObject.FileName}</TableCell>
    
                    <TableCell>
                        <input type="file" onChange={handleFileUpload} hidden={true} ref={fileUploadElement} accept={String(mimeTypeArray.map(x => x.MIMEType).join(", "))}/>
    
                        <IconButton disabled={props.documentObject.Data == undefined} size="small" style={{marginLeft: 5}}  onClick={() => setRemoved(true)} color="error"><Delete/></IconButton>
                        {(props.documentObject.idDocument == undefined || props.documentObject.idDocument <= 0) && <IconButton size="small" style={{marginLeft: 5}}  onClick={() => fileUploadElement.current?.click()}><FileUpload/></IconButton>}
                        <IconButton disabled={props.documentObject.Data == undefined} size="small" style={{marginLeft: 5}}  onClick={() => downloadFile()}><FileDownload/></IconButton>
                    </TableCell>
                </TableRow>
            </>
        )
    }
}
export default DocumentTableRow;


