import { Grid } from '@mui/material';
import React from 'react';

interface IProps {
    children?: React.ReactNode
}


const GridContainer:React.FC<IProps> = (props) => { 
    return(
        <Grid container spacing={2} columns={{ xs: 4, sm: 12}}>
            {props.children}
        </Grid>
    )
}
export default GridContainer;