import { Alert, Button, Collapse, Dialog, IconButton, InputAdornment, MenuItem, Snackbar, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DesktopDatePicker, DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidatenText, useValidationNumber } from '../../hooks/useValidaten';
import { IPerson } from '../../interfaces/IPerson';
import { State } from '../../redux/combine_reducers';
import { setCompanyArray } from '../../redux/slice_Company';
import deLocale from "date-fns/locale/de";
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import InputField from '../core/InputField';
import { IChildMetadata } from '../../interfaces/IChildMetadata';
import Company from './Company';
import { getFetch, uploadFetch, useFetch } from '../../hooks/useFetch';
import { AddCircle } from '@mui/icons-material';
import Document from '../Document/Document';
import Rents from '../Rent/Rents';
import { getLeadStateFromRentState } from '../Rent/func_rentStateToLeadState';


export interface IProps {
    idPerson?: number | null;
    setIdPerson?: Function;
    personArray?: IPerson[];
    setPersonArray?: Function;
    // Rent state
    idRentState?: number | null;
    // Für REnt
    idCompany?: number | null; 
    // Fals selber Child
    saveCounter?: number;
    resetSaveCounter?: number;
    // Um im Parent zu visualisieren
    setIsBlockedCompany?: Function;
    setIsCreditCheckedCompany?: Function;
    // Zur Childvalidierung
    setMetaState?: Function;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }



const Customer:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const companyArray = useSelector((state: State) => state.companyArray.storeCompany);
    const genderArray = useSelector((state: State) => state.genderArray.storeGender);
    // Marketing Array
    const leadStateArray = useSelector((state: State) => state.leadStateArray.storeLeadState);
    const leadSourceArray = useSelector((state: State) => state.sourceArray.storeSources);
    const mediumArray = useSelector((state: State) => state.mediumArray.storeMediums);
    const campaignArray = useSelector((state: State) => state.campaignArray.storeCampaign);
    //const gend = useSelector((state: State) => state.gen)
    // Haupobjelt
    const [personObject,setPersonObject] = useState<IPerson | undefined>();
    // Hauptvalues
    const [idPerson, setIdPerson] = useState<number | null>(null);
    const [idCompany, setIdCompany] = useState<number | null>(null);
    const [firstName, setFirstName, vFirstName] = useValidatenText();
    const [lastName, setLastName, vLastName] = useValidatenText();
    const [idGender,setIdGender, vIdGender] = useValidationNumber();
    const [email, setEmail, vEmail] = useValidatenText();
    const [isBlocked,setIsBlocked] = useState(false);
    const [phoneNumber,setPhoneNumber] = useState<string | null>(null);
    const [mobileNumber,setMobileNumber] = useState<string | null>(null);
    const [birthday,setBirthday] = useState<Date | null>(null);
    const [birthplace,setBirthplace] = useState<string | null>(null);
    const [hasNewslatter,setHasNewslatter] = useState(false);
    const [internComment,setInternComment] = useState<string | null>(null);
    // Marketingattr
    const [idLead,setIdLead] = useState<number | null>(null);
    const [idLeadState,setIdLeadState] = useState(5);
    const [idLeadSource, setIdLeadSource] = useState(1);
    const [idCompaigne,setIdCompaigne] = useState<number | null>(null);
    const [idMedium,setIdMedium] = useState<number | null>(null);
    const [leadCreatedAt,setLeadCreatedAt] = useState<Date | null>(null);
    // Handling von Dokumenten
    const [idDocument,setIdDocument] = useState<number|null>();
    const [documentWasChanged,setDocumentWasChanged] = useState(false);
    // States bzgl. der Frima, zum visualisieren
    const [isBlockedCompany,setIsBlockedCompany] = useState(false);
    const [isCreditCheckedCompany,setIsCreditCheckedCompany] = useState(true);
    // VAlidungsstates
    const [isValidatedCustomer,setIsValidatedCustomer] = useState(false);
    const [isValidatedCompany, setIsValidatedCompany] = useState(false);
    const [isValidatedAll,setIsValidatedAll] = useState(false);
    // Savestates
    const [wasSavedCustomer,setWasSavedCustomer] = useState(false);
    const [wasSavedCompany,setWasSavedCompany] = useState(false);
    const [wasSavedAll,setWasSavedAll] = useState(false);
    // Save HEaltystaty
    const [wasSuccessfullySavedCustomer,setWasSuccessfullySavedCustomer] = useState(true);
    const [wasSuccessfullySavedCompany,setWasSuccessfullySavedCompany] = useState(true);
    const [wasSuccessfullySavedAll,setWasSuccessfullySavedAll] = useState(true);
    // Metastatea
    const [isChild,setIsChild] = useState(props.saveCounter !== undefined);
    const [isNew,setIsNew] = useState(false);
    const [isEmpty,setIsEmpty] = useState(true);
    const [saveParent,setSaveParent] = useState(false);
    // ChildVAlues
    const [saveCounter, setSaveCounter] = useState(0);
    const [resetSaveCounter, setResetSaveCounter] = useState(0);
    const [childMetadataRent, setChildMetadataRent] = useState<IChildMetadata>({} as IChildMetadata);
    const [childMetadataCompany,setChildMetadataCompany] = useState<IChildMetadata>({} as IChildMetadata);

    const [value, setValue] = React.useState(1);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    useEffect(() => {
        if (props.saveCounter !== undefined && props.saveCounter > 0) {
            setSaveCounter(props.saveCounter)
            setSaveParent(true);
        }
    },[props.saveCounter])
    
    useEffect(() => {
        if (props.resetSaveCounter !== undefined) {
            setResetSaveCounter(props.resetSaveCounter);
            setWasSavedCustomer(false);
        }
    },[props.resetSaveCounter])

    useEffect(() => {
        if (saveCounter === 0 && !wasSavedAll && props.idPerson !== idPerson) {
            resetValues();
            if (props.idPerson === undefined || props.idPerson === null) {
                setIsEmpty(true);
                setIsNew(false);
            } else {
                setIsEmpty(false);
                
                if (props.idPerson === -1) {
                    setIsNew(true)
                    setIdCompany(-1);
                    //resetValues();
                } else {
                    setIsNew(false);
    
                    if (props.personArray !== undefined) {
                        setPersonObject(props.personArray.find(x => x.idPerson === props.idPerson));
                    } else {
                        getFetch("person/",props.idPerson,setPersonObject);
                    }
                }
            }
        }
    },[props.idPerson])

    useEffect(() => {
        if (props.idCompany !== undefined) {
            setIdCompany(props.idCompany);
        }
    },[props.idCompany])

    useEffect(() => setValues(personObject),[personObject])

    useEffect(() => {
        if (personObject !== undefined && wasSavedAll) {
            //setResetSaveCounter(resetSaveCounter+1);
            (personObject.idPerson != undefined && props.setIdPerson != undefined) && props.setIdPerson(personObject.idPerson);

            if (props.personArray !== undefined && props.setPersonArray !== undefined) {
                if (isNew) {
                    props.setPersonArray([personObject, ...props.personArray]);
                    setIsNew(false);
                } else {
                    props.setPersonArray([...props.personArray.map(x => x.idPerson === Number(personObject.idPerson) ? personObject : x )]);
                }
            }

            setSaveCounter(0);
        }
    },[personObject,wasSavedAll])

    useEffect(() => {
        setIsValidatedCompany(childMetadataCompany.isValidated);
        setWasSavedCompany(childMetadataCompany.wasSaved);
        setWasSuccessfullySavedCompany(childMetadataCompany.wasSuccessfullySaved);
    },[childMetadataCompany])

    useEffect(() => { setIsValidatedCustomer(vFirstName && vLastName && vEmail && vIdGender) },[vFirstName,vLastName,vEmail,vIdGender])


    useEffect(() => { 
        if (isNew || isChild || isEmpty) {
            setIsValidatedAll(isValidatedCustomer && isValidatedCompany); 
        } else {
            setIsValidatedAll(isValidatedCustomer && isValidatedCompany && childMetadataRent.isValidated); 
        }
    },[isValidatedCustomer,isValidatedCompany,childMetadataRent.isValidated])

    useEffect(() => { 
        if (isNew || isChild || isEmpty) {
            setWasSavedAll(wasSavedCustomer && wasSavedCompany); 
        } else {
            setWasSavedAll(wasSavedCustomer && wasSavedCompany && childMetadataRent.wasSaved); 
        }
    },[wasSavedCustomer,wasSavedCompany,childMetadataRent.wasSaved])

    useEffect(() => { 
        if (isNew || isChild || isEmpty) {
            setWasSuccessfullySavedAll(wasSuccessfullySavedCustomer && wasSuccessfullySavedCompany); 
        } else {
            setWasSuccessfullySavedAll(wasSuccessfullySavedCustomer && wasSuccessfullySavedCompany && childMetadataRent.wasSuccessfullySaved); 
        }
    },[wasSuccessfullySavedCustomer,wasSuccessfullySavedCompany,childMetadataRent.wasSuccessfullySaved])

    useEffect(() => {
        if (
            personObject?.idLeadState === idLeadState // Falls händisch verändert wurde
            && childMetadataRent.wasSaved && childMetadataRent.wasSuccessfullySaved && childMetadataRent.openNumberValue != undefined
        ) {
            setIdLeadState(getLeadStateFromRentState(childMetadataRent.openNumberValue ))
            console.log("here!")
        }
    },[childMetadataRent])

    useEffect(() => {console.log(idLeadState)},[idLeadState])


    useEffect(() => {
        if (props.setMetaState !== undefined) {
            props.setMetaState({
                idChild: `person-${idPerson}`,
                isValidated: isValidatedAll,
                wasSaved: wasSavedAll,
                wasSuccessfullySaved: wasSuccessfullySavedAll
            } as IChildMetadata)
        }
    },[isValidatedAll,wasSavedAll,wasSuccessfullySavedAll])

    useEffect(() => {
        if (props.idRentState != undefined) {
            if (props.idRentState === 10) {
                setIdLeadState(30);
            }
            else if (props.idRentState === 20) {
                setIdLeadState(20);
            }
        }
    },[props.idRentState])

    useEffect(() => {
        if (saveParent 
            && ( (documentWasChanged && idDocument !== null) || (!documentWasChanged) )
            && idCompany != undefined 
            && idCompany > 0  
            && idGender != undefined 
            && firstName != undefined 
            && lastName != undefined 
            && email != undefined
            && childMetadataCompany.wasSaved
        ) 
        {
            let tmpObject:IPerson = {
                idCompany: idCompany,
                idGender: idGender,
                FirstName: firstName,
                LastName: lastName,
                Email: email,
                isBlocked: isBlocked,
                idLeadState: idLeadState,
                idLeadSource: idLeadSource,
                hasNewsletter: hasNewslatter,
                StripeId_Customer: null
            };
            (phoneNumber != undefined) && (tmpObject.PhoneNumber = phoneNumber);
            (mobileNumber != undefined) && (tmpObject.MobileNumber = mobileNumber);
            (birthday != undefined) && (tmpObject.Birthday = birthday.toISOString());
            (birthplace != undefined) && (tmpObject.Birthplace = birthplace);
            (internComment != undefined) && (tmpObject.InternComment = internComment);

            // Marketing
            (idLead != undefined) && (tmpObject.idLead = idLead);
            (idCompaigne != undefined) && (tmpObject.idCampaign = idCompaigne);
            (idMedium != undefined) && (tmpObject.idMedium = idMedium);
            (leadCreatedAt != undefined) && (tmpObject.Lead_Created_at = leadCreatedAt.toISOString());
            //Dok
            (idDocument != undefined)  && (tmpObject.idDocument = idDocument);
            // Final
            (!isNew && idPerson != undefined) && (tmpObject.idPerson = idPerson);

            if (
                !isChild 
                && childMetadataRent.openNumberValue != undefined 
                && personObject?.idLeadState === idLeadState // Falls händisch verändert wurde
            ) {
                tmpObject.idLeadState = getLeadStateFromRentState(childMetadataRent.openNumberValue);
            }

            uploadFetch("person",isNew,tmpObject,setPersonObject,setWasSuccessfullySavedCustomer);
            setWasSavedCustomer(true);
            setSaveParent(false);
        }
    },[saveParent, idDocument, idCompany, childMetadataCompany.wasSaved])


    // Zum weiterrechen, wenn vorhanden
    useEffect(() => {(props.setIsBlockedCompany !== undefined) && props.setIsBlockedCompany(isBlockedCompany)},[isBlockedCompany])
    useEffect(() => {(props.setIsCreditCheckedCompany !== undefined) && props.setIsCreditCheckedCompany(isCreditCheckedCompany)},[isCreditCheckedCompany])


    const setValues = (localCustomerObject: IPerson | undefined) => {
        if (localCustomerObject != undefined && localCustomerObject.idPerson !== idPerson ) {
            setIdPerson(Number(localCustomerObject.idPerson));
            setIdCompany(localCustomerObject.idCompany);
            setIdGender(localCustomerObject.idGender);
            setFirstName(localCustomerObject.FirstName);
            setLastName(localCustomerObject.LastName);
            setEmail(localCustomerObject.Email);
            setIsBlocked(Boolean(localCustomerObject.isBlocked));
            setHasNewslatter(Boolean(localCustomerObject.hasNewsletter));
            (localCustomerObject.PhoneNumber) && setPhoneNumber(localCustomerObject.PhoneNumber);
            (localCustomerObject.MobileNumber) && setMobileNumber(localCustomerObject.MobileNumber);
            (localCustomerObject.Birthday) && setBirthday(new Date(localCustomerObject.Birthday));
            (localCustomerObject.Birthplace) && setBirthplace(localCustomerObject.Birthplace);
            (localCustomerObject.InternComment) && setInternComment(localCustomerObject.InternComment);
            // Markeitng
            (localCustomerObject.idLead) && setIdLead(localCustomerObject.idLead);
            (localCustomerObject.idLeadState) && setIdLeadState(localCustomerObject.idLeadState);
            (localCustomerObject.idLeadSource) && setIdLeadSource(localCustomerObject.idLeadSource);
            (localCustomerObject.idCampaign) && setIdCompaigne(localCustomerObject.idCampaign);
            (localCustomerObject.idMedium) && setIdMedium(localCustomerObject.idMedium);
            (localCustomerObject.Lead_Created_at) && setLeadCreatedAt(new Date(localCustomerObject.Lead_Created_at));
            // Documente
            (localCustomerObject.idDocument) && setIdDocument(localCustomerObject.idDocument);
        }
    }

    const resetValues = () => {
        //saveClose();
        setIdPerson(null);
        (props.idCompany === undefined) && setIdCompany(null);
        setIdGender(null);
        setFirstName(null);
        setLastName(null);
        setEmail(null);
        setIsBlocked(false);
        setPhoneNumber(null);
        setMobileNumber(null);
        setBirthday(null);
        setBirthplace(null);
        setHasNewslatter(false);
        setInternComment(null);
        // Marketing
        setIdLead(null);
        setIdLeadState(5);
        setIdLeadSource(1);
        setIdCompaigne(null);
        setIdMedium(null);
        setLeadCreatedAt(null);
        // Dokumente
        setIdDocument(null);
        setDocumentWasChanged(false);
    }

    const saveClose = () => {
        setWasSavedCustomer(false);
        setResetSaveCounter(resetSaveCounter+1);
        setSaveCounter(0);
    }
        
    return(
        <>
            { (props.setMetaState === undefined) &&
                <Snackbar 
                    key="savebar" 
                    open={wasSavedAll} 
                    autoHideDuration={6000} 
                    onClose={() => saveClose()}  
                    anchorOrigin={{ 'vertical' : 'bottom', 'horizontal' : 'right' }} 
                >
                    <Alert onClose={() => saveClose()} severity={(wasSuccessfullySavedAll) ? "success" : "error"} sx={{ width: '100%' }}>
                        {(wasSuccessfullySavedAll) ? "Erfolgreich gespeichert!" : "Es ist ein Fehler aufgetretten!"}
                    </Alert>
                </Snackbar>
            }
            
            <Typography variant={(isChild) ? 'h6' : 'h5'}>
                {(isEmpty) ? "Bitte Wählen" : (isNew) ? "Neuer Kunde" : (personObject === undefined ) ? "ERROR" : `${personObject.FirstName} ${personObject.LastName}` }
                { (!isChild) && 

                    <div style={{float: 'right'}}>
                        <Button 
                            disabled={(isEmpty || !isValidatedAll || saveCounter > 0)} 
                            style={{marginLeft: 5}} 
                            variant={(saveCounter > 0) ? "outlined" : "contained"}
                            onClick={() => { setSaveParent(true) ;setSaveCounter(saveCounter+1);}}
                        >
                            {(saveCounter > 0) ? "Bitte warten..." : "Speichern"}
                        </Button>
                    </div>

                }
            </Typography>


            <Collapse in={isBlocked} style={{marginTop: 25}}>
                <Alert
                    severity="error"
                    sx={{ mb: 2 }}
                >
                    Die Person wurde gesperrt!
                </Alert>
            </Collapse>

            <Collapse in={isBlockedCompany}>
                <Alert
                    severity="error"
                    sx={{ mb: 2 }}
                >
                    Der Kunde wurde gesperrt!
                </Alert>
            </Collapse>

            <Collapse in={!isCreditCheckedCompany}>
                <Alert
                    severity="warning"
                    sx={{ mb: 2 }}
                >
                    Es liegt keine Bonitätsprüfung vor!
                </Alert>
            </Collapse>

            {(!isNew && !isChild && !isEmpty) &&
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Angebote" />
                            <Tab label="Buchungen" />
                        </Tabs>
                    </Box>

                    <TabPanel value={value} index={0}>
                        Item One
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        
                            <>
                                <Rents idPerson={idPerson} saveCounter={(saveParent) ? 0 : saveCounter} setMetastateChild={setChildMetadataRent}/>
                            </>                
                        
                    </TabPanel>
                </Box>
            }
            
            <Box sx={{mt: 5}}></Box>
            <Typography variant='h6'>Person</Typography>
            <GridContainer>
                <GridItem sm={12}>
                    <TextField
                        select
                        disabled={isEmpty }
                        label="Stage"
                        value={String(idLeadState)}
                        onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdLeadState( Number(event.target.value) ) }
                        variant="standard"
                        fullWidth
                    >
                        {leadStateArray.map(x => 
                            <MenuItem value={x.idLeadState}>
                                {x.State}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>

                <GridItem sm={5}>
                    <InputField isEdit={!isEmpty} label="Vorname" value={firstName} setFunction={setFirstName} isValidated={isEmpty || vFirstName}/>
                </GridItem>

                <GridItem sm={5}>
                    <InputField isEdit={!isEmpty} label="Nachname" value={lastName} setFunction={setLastName} isValidated={isEmpty || vLastName}/>
                </GridItem>
                <GridItem sm={2}>
                    <TextField
                        select
                        disabled={isEmpty}
                        label="Geschlecht"
                        value={String(idGender)}
                        error={!vIdGender && !isEmpty}
                        onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdGender( Number(event.target.value) ) }
                        variant="standard"
                        fullWidth
                    >
                        {genderArray.map(x => 
                            <MenuItem value={x.idGender}>
                                {x.Gender}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>

                <GridItem sm={6}>
                    <InputField isEdit={!isEmpty} label="Email" value={email} setFunction={setEmail} isValidated={isEmpty || vEmail}/>
                </GridItem>

                <GridItem sm={3}>
                    <TextField
                        select
                        disabled={isEmpty}
                        label="Newsletter"
                        value={(hasNewslatter) ? "yes" : "no"}
                        onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setHasNewslatter( (event.target.value) === "yes" ) }
                        variant="standard"
                        fullWidth
                    >
                        <MenuItem value="yes">Ja</MenuItem>
                        <MenuItem value="no">Nein</MenuItem>
                    </TextField>
                </GridItem>

                <GridItem sm={3}>
                    <TextField
                        select
                        disabled={isEmpty}
                        label="Gesperrt"
                        value={(isBlocked) ? "yes" : "no"}
                        onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIsBlocked( (event.target.value) === "yes" ) }
                        variant="standard"
                        fullWidth
                    >
                        <MenuItem value="yes">Ja</MenuItem>
                        <MenuItem value="no">Nein</MenuItem>
                    </TextField>
                </GridItem>

                <GridItem sm={6}>
                    <TextField
                        disabled={isEmpty}
                        value={phoneNumber}
                        onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(event.target.value)}
                        label="Rufnummer"
                        fullWidth
                        type="text"
                        variant="standard"
                        InputProps ={{ startAdornment: <InputAdornment position="start">+49</InputAdornment>}}
                    />
                </GridItem> 
                <GridItem sm={6}>
                    <TextField
                        disabled={isEmpty}
                        value={mobileNumber}
                        onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setMobileNumber(event.target.value)}
                        label="Mobilrufnummer"
                        fullWidth
                        type="text"
                        variant="standard"
                        InputProps ={{ startAdornment: <InputAdornment position="start">+49</InputAdornment>}}
                    />
                </GridItem> 

                <GridItem sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                        <DesktopDatePicker
                            disabled={isEmpty}
                            label="Geburtstag"
                            value={birthday}
                            openTo="year"
                            onChange={(newValue: Date | null) => { (newValue !== null) && setBirthday(newValue); }}
                            renderInput={(params) => <TextField fullWidth variant="standard" {...params} />}
                        />
                    </LocalizationProvider>
                </GridItem>
                <GridItem>
                    <InputField label='Geburtsort' isEdit={!isEmpty} value={birthplace} setFunction={setBirthplace}/>
                </GridItem> 
                <GridItem sm={12}>
                    <TextField
                        disabled={isEmpty}
                        value={(internComment == undefined) ? "" : internComment}
                        onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setInternComment(event.target.value)}
                        label="Kommentar zur Person"
                        fullWidth
                        type="text"
                        rows={10}
                        variant="standard"
                        multiline
                    />
                </GridItem> 

                <Document
                    idDocument={idDocument}
                    setIdDocument={setIdDocument}
                    idDocumentType={1}
                    label="Personalausweis"
                    isEmpty={isEmpty}
                    isNew={isNew}
                    setDocumentWasChanged={setDocumentWasChanged}
                    saveCounter={saveCounter}
                />
            </GridContainer>

            { (isNew && !isChild) &&
                <>
                    <Box sx={{mt: 5}}></Box>
                    <Typography variant='h5'>Firmenauswahl (neuer Kontakt)</Typography>
                    <GridContainer>
                        <GridItem sm={12}>
                            <TextField
                                select
                                disabled={isEmpty || !isNew}
                                label="Firma"
                                value={String(idCompany)}
                                error={(idCompany === undefined) && !isEmpty}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdCompany( Number(event.target.value) ) }
                                variant="standard"
                                fullWidth
                            >
                                <MenuItem value={-1}>Neue Firma</MenuItem>
                                { companyArray.map(x => 
                                    <MenuItem value={x.idCompany}>
                                        {x.Company}
                                    </MenuItem>
                                )}
                            </TextField>
                        </GridItem>
                        
                        { /*
                        <GridItem sm={1}>
                            <IconButton disabled={isEmpty || !isNew} onClick={() => setIdCompany(-1)} style={{float: 'right'}}><AddCircle/></IconButton>
                        </GridItem>
                        */}

                    </GridContainer>
                </> 
            }
            <Company
                idCompany={idCompany}
                setIdCompany={setIdCompany}
                saveCounter={saveCounter}
                resetSaveCounter={resetSaveCounter}
                setChildMetadata={setChildMetadataCompany}
                // Zum Visualiseren
                setIsBlocked={setIsBlockedCompany}
                setIsCreditChecked={setIsCreditCheckedCompany}
            />



            <Box sx={{mt: 5}}></Box>
            <Typography variant='h6'>Marketing</Typography>

            <GridContainer>
                <GridItem sm={12}>
                    <InputField type="number" isEdit={!isEmpty} label="ID bei CleverReach (keine Eingabe erstellt einen neuen CR-Lead)" value={idLead} setFunction={setIdLead} />
                </GridItem>


                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                    <GridItem sm={12}>
                        <DesktopDateTimePicker
                            label="Erstellt"
                            value={leadCreatedAt}
                            onChange={(newValue: Date | null) => (newValue !== null) && setLeadCreatedAt(newValue) }
                            //minutesStep={15}
                            //maxDate={toDateTime}
                            renderInput={(params) => <TextField margin="normal" variant="standard" {...params} disabled={isEmpty}  fullWidth />}
                        />
                    </GridItem>
                </LocalizationProvider>

                <GridItem sm={12}>
                    <TextField
                        select
                        disabled={isEmpty}
                        label="Quelle"
                        value={String(idLeadSource)}
                        onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdLeadSource( Number(event.target.value) ) }
                        variant="standard"
                        fullWidth
                    >
                        {leadSourceArray.map(x => 
                            <MenuItem value={x.idSource}>
                                {x.Source}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>

                <GridItem sm={6}>
                    <TextField
                        select
                        disabled={isEmpty}
                        label="Kampagne"
                        value={String(idCompaigne)}
                        onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdCompaigne( Number(event.target.value) ) }
                        variant="standard"
                        fullWidth
                    >
                        {campaignArray.map(x => 
                            <MenuItem value={x.idCampaign}>
                                {x.Campaign}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>
                <GridItem sm={6}>
                    <TextField
                        select
                        disabled={isEmpty}
                        label="Medium"
                        value={String(idMedium)}
                        onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdMedium( Number(event.target.value) ) }
                        variant="standard"
                        fullWidth
                    >
                        {mediumArray.map(x => 
                            <MenuItem value={x.idMedium}>
                                {x.Medium}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>
            </GridContainer>
        </>
    )
}
export default Customer;

