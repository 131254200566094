import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../redux/combine_reducers';
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import { DesktopDatePicker, DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import deLocale from "date-fns/locale/de";
import { MenuItem, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { staticCreditStateArray } from '../Customer/staticCreditStateArray';
import { IAddress } from '../../interfaces/IAddress';
import Postcode from '../Postcode/Postcode';



export interface IProps {
    currentObject: IAddress;
    setCurrentObject: Function;
}



const AddressEdit:React.FC<IProps> = (props) => {
    const handleInput = (event:React.ChangeEvent<HTMLInputElement>) => {
        let splitTest = event.target.name.split("-");
        let attrName = splitTest[1];
        let testVal = {...props.currentObject} as any

        if (attrName in testVal) {
            if (splitTest[0] === "number") {
                //props.setCurrentObject({...props.currentObject, attrName : Number(event.target.value)})
                testVal[attrName] = Number(event.target.value);
            } 
            else if (splitTest[0] === "boolean") {
                //props.setCurrentObject({...props.currentObject, attrName : String(event.target.value) === "true"})
                testVal[attrName] =  String(event.target.value) === "true";
            }
            else {
                console.log({...props.currentObject, attrName : String(event.target.value)})
                //props.setCurrentObject({...props.currentObject, attrName : String(event.target.value)});
                testVal[attrName] = String(event.target.value);
            }
        } else {
            console.warn(`Can't ${attrName} in Interface`)
        }

        props.setCurrentObject({...testVal})

    }


    const checkString = (possibleString:string|null|undefined|number) => {
        if (possibleString == undefined) {
            return "";
        } else {
            return String(possibleString);
        }
    }


    return(
        <>
            <Box sx={{mt: 5}}></Box>
            <Typography variant='h6'>Adresse</Typography>
            <Box sx={{mt: 3}}></Box>

            <GridContainer>
                <GridItem sm={8}>
                    <TextField
                        label="Straße"
                        name="string-Street"
                        size="small"
                        value={checkString(props.currentObject.Street)}
                        onChange={handleInput}
                        fullWidth
                        error={props.currentObject.Street === ""}
                    />
                </GridItem>

                <GridItem sm={4}>
                    <TextField
                        label="Nummer"
                        name="string-StreetNr"
                        size="small"
                        value={checkString(props.currentObject.StreetNr)}
                        onChange={handleInput}
                        fullWidth
                        error={props.currentObject.StreetNr === ""}
                    />
                </GridItem>


                <GridItem sm={6}>
                    <TextField
                        label="Adresszusatz"
                        name="string-StreetAditional"
                        size="small"
                        value={checkString(props.currentObject.StreetAditional)}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>

                <GridItem sm={6}>
                    <TextField
                        label="Stockwerk"
                        name="string-Floor"
                        size="small"
                        value={checkString(props.currentObject.Floor)}
                        onChange={handleInput}
                        fullWidth
                    />
                </GridItem>

                <Postcode 
                    idPostcode={props.currentObject.idPostcode} 
                    setIdPostcode={
                        (newIdPostcode:number|null) => (newIdPostcode != null) 
                            && props.setCurrentObject({...props.currentObject, idPostcode: newIdPostcode})
                    }
                    isEmpty={false}
                />

            </GridContainer>
        </>
    )

}
export default AddressEdit;