import { CameraAltTwoTone } from '@mui/icons-material';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidatenText, useValidationNumber } from '../../../hooks/useValidaten';
import { ICampaign } from '../../../interfaces/ICampaign';
import { actionCreatorsCampaign } from '../../../redux/actionCreators';
import { State } from '../../../redux/combine_reducers';
import GenericEdit, { IInputValues, ISetValues } from '../../core/GenericEdit';
import InputField from '../../core/InputField';



interface IProps {
    idCampaign?: number;
}


const Campaign:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const campaignArray = useSelector((state: State) => state.campaignArray.storeCampaign);
    const { addCampaignArray, updateCampaignArray } = bindActionCreators(actionCreatorsCampaign, dispatch)
    // Main Object
    const [campaignObject,setCampaignObject] = useState<ICampaign | undefined>();
    // Haupstates
    const [idCampaign,setIdCampaign] = useState<number>();
    const [campaing,setCampaing,vCampaing] = useValidatenText();
    const [pricePerDay, setPricePerDay, vPricePerDay] = useValidationNumber();
    const [url,setUrl] = useState<string | null>(null);
    const [externId,setExternId] = useState<string | null>(null);
    // Optionale States
    const [urlIdentifier,setUrlIdentifier] = useState<string | null>(null);
    // Validierung
    const [validationArray, setValidationArray] = useState<boolean[]>([]);
    // Metastatea
    const [isNew,setIsNew] = useState(false);
    const [isEmpty,setIsEmpty] = useState(true);
    const [isTime,setIsTime] = useState<boolean>(false);


    useEffect(() => {
        if (props.idCampaign === undefined) {
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            resetValues();
            if (props.idCampaign === -1) {
                setIsNew(true)
                
            } else {
                setIsNew(false);
                setCampaignObject(campaignArray.find(x => x.idCampaign=== props.idCampaign));
            }
        }
    },[props.idCampaign])

    useEffect(() => {setValidationArray([vCampaing,vPricePerDay])},[vCampaing,vPricePerDay])

    const valuesArray:ISetValues[] = [
        {
            Attribute: "idCampaign",
            Value: idCampaign,
            SetFunction: setIdCampaign,
            IsPrimaryKey: true
        },
        {
            Attribute: "Campaign",
            Value: campaing,
            SetFunction: setCampaing,
        },
        {
            Attribute: "PricePerDay",
            Value: pricePerDay,
            SetFunction: setPricePerDay,
        },
        {
            Attribute: "URL",
            Value: url,
            SetFunction: setUrl,
            PossibleUndefined: true,
            Type: "string"
        },
        {
            Attribute: "ExternId",
            Value: externId,
            SetFunction: setExternId,
            PossibleUndefined: true,
            Type: "string"
        },
        {
            Attribute: "URL_Identifier",
            Value: urlIdentifier,
            SetFunction: setUrlIdentifier,
            PossibleUndefined: true,
            Type: 'string'
        }
    ];

    const inputField:IInputValues[] = [
        {
            InputElement: <InputField isEdit={!isEmpty} label='Kampagne' value={campaing} setFunction={setCampaing} isValidated={vCampaing}/>,
        },
        {
            InputElement: <InputField isEdit={!isEmpty} label='URL Kennung' value={urlIdentifier} setFunction={setUrlIdentifier} />,
        },
        {
            InputElement: <InputField isEdit={!isEmpty} label='Preis pro Tag' type='number' value={pricePerDay} setFunction={setPricePerDay} isValidated={vPricePerDay}/>,
        },
        {
            ColSpanSm: 8,
            InputElement: <InputField isEdit={!isEmpty} label='URL' value={url} setFunction={setUrl}/>,
        },
        {
            ColSpanSm: 4,
            InputElement: <InputField isEdit={!isEmpty} label='Externe ID' value={externId} setFunction={setExternId}/>,
        }
        
    ]

    const resetValues = () => {
        valuesArray.filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey == false).map(x => (x.Type !== undefined && x.Type === "boolean") ? x.SetFunction(false) : x.SetFunction(null))
    }

    


    return(
        <>  
            <GenericEdit
                headlineNew="Neue Marketing Kampagne"
                headlineAttr="Campaign"
                setValuesDef={valuesArray}
                inputFilds={inputField}
                // Zum Hochladen
                endpoint="campaign"
                // Hauptelemeten
                mainObject={campaignObject}
                setMainObject={setCampaignObject}
                // Zum Validieren
                validatenArray={validationArray}
                // Metastates
                isEmpty={isEmpty}
                isNew={isNew}
                updatedReduxArray={updateCampaignArray}
                addReduxArray={ addCampaignArray}
            />
        </>
    )

}
export default Campaign;