import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidatenText } from '../../../hooks/useValidaten';
import { ICompanyCategory } from '../../../interfaces/ICompanyCategory';
import { IMedium } from '../../../interfaces/IMedium';
import { actionCreatorsCompanyCategory, actionCreatorsMediums, actionCreatorsUnits } from '../../../redux/actionCreators';
import { State } from '../../../redux/combine_reducers';
import GenericEdit, { IInputValues, ISetValues } from '../../core/GenericEdit';
import InputField from '../../core/InputField';



interface IProps {
    idCompanyCategory?: number;
}


const CompanyCategory:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const companyCategoryArray = useSelector((state: State) => state.companyCategoryArray.storeCompanyCategory);
    const { setCompanyCategory, addUpdateCompanyCategoryArray } = bindActionCreators(actionCreatorsCompanyCategory, dispatch)
    // Main Object
    const [companyCategoryObject,setCompanyCategoryObject] = useState<ICompanyCategory | undefined>();
    // Haupstates
    const [idCompanyCategory,setIdCompanyCategory] = useState<number>();
    const [category,setCategory,vCategory] = useValidatenText();

    // Validierung
    const [validationArray, setValidationArray] = useState<boolean[]>([]);
    // Metastatea
    const [isNew,setIsNew] = useState(false);
    const [isEmpty,setIsEmpty] = useState(true);
    const [isTime,setIsTime] = useState<boolean>(false);


    useEffect(() => {
        if (props.idCompanyCategory === undefined) {
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            
            if (props.idCompanyCategory === -1) {
                setIsNew(true)
                resetValues();
            } else {
                setIsNew(false);
                setCompanyCategoryObject(companyCategoryArray.find(x => x.idCompanyCategory=== props.idCompanyCategory));
            }
        }
    },[props.idCompanyCategory])

    useEffect(() => {setValidationArray([vCategory])},[vCategory])

    const valuesArray:ISetValues[] = [
        {
            Attribute: "idCompanyCategory",
            Value: idCompanyCategory,
            SetFunction: setIdCompanyCategory,
            IsPrimaryKey: true
        },
        {
            Attribute: "Category",
            Value: category,
            SetFunction: setCategory,
        }
    ];

    const inputField:IInputValues[] = [
        {
            InputElement: <InputField isEdit={!isEmpty} label='Unternehmenskategorie' value={category} setFunction={setCategory} isValidated={vCategory}/>,
        }
        
    ]

    const resetValues = () => {
        valuesArray.filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey == false).map(x => (x.Type !== undefined && x.Type === "boolean") ? x.SetFunction(false) : x.SetFunction(null))
    }

    


    return(
        <>  
            <GenericEdit
                headlineNew="Neue Unternehmenskategorie"
                headlineAttr="Category"
                setValuesDef={valuesArray}
                inputFilds={inputField}
                // Zum Hochladen
                endpoint="companyCategory"
                // Hauptelemeten
                mainObject={companyCategoryObject}
                setMainObject={setCompanyCategoryObject}
                // Zum Validieren
                validatenArray={validationArray}
                // Metastates
                isEmpty={isEmpty}
                isNew={isNew}
                addUpdatedReduxArray={addUpdateCompanyCategoryArray}
            />
        </>
    )

}
export default CompanyCategory;