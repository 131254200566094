import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRoom } from "../interfaces/IRoom";

const initialState =  { storeRoom: [] as IRoom[] }; 

const sliceRoom = createSlice({
    name: "ROOMARRAY",
    initialState,
    reducers: {
        setRoomArray: (state, action: PayloadAction<IRoom[]>) => {
            state.storeRoom = [...action.payload];
        },
        addRoomArray: (state, action: PayloadAction<IRoom>) => {
            state.storeRoom = [...state.storeRoom,action.payload];
        },
        updataRoomArray: (state, action: PayloadAction<IRoom>) => {
            let tmpArray =  state.storeRoom.map(x => x.idRoom === action.payload.idRoom ? action.payload : x);
            state.storeRoom = tmpArray;
        }
    }
})
export const { setRoomArray, addRoomArray, updataRoomArray
 } = sliceRoom.actions;
export default sliceRoom.reducer;