import { IRentAutomationTimeField } from "../../../interfaces/IRentAutomationTimeField";

export const staticRentAutomationTimeFieldArray:IRentAutomationTimeField[] =  [
    {
        idRentAutomationTimeField: 1,
        Title: "Startdatum Miete",
        Attribute: "FromDate"
    },
    {
        idRentAutomationTimeField: 2,
        Title: "Enddatum Miete",
        Attribute: "ToDate"
    },
    {
        idRentAutomationTimeField: 3,
        Title: "Erstelldatum",
        Attribute: "Created_at"
    },
    {
        idRentAutomationTimeField: 4,
        Title: "Kündigungsdatum",
        Attribute: "Terminated_at"
    },
];