import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRentType } from "../interfaces/IRentType";


const initialState =  { storeRentType: [] as IRentType[] }; 

const sliceRentType = createSlice({
    name: "RENTTYPEARRAY",
    initialState,
    reducers: {
        setRentTypeArray: (state, action: PayloadAction<IRentType[]>) => {
            state.storeRentType = [...action.payload];
        },
        addRentTypeArray: (state, action: PayloadAction<IRentType>) => {
            state.storeRentType = [...state.storeRentType,action.payload];
        },
        updateRentTypeArray: (state, action: PayloadAction<IRentType>) => {
            let tmpArray =  state.storeRentType.map(x => x.idRentType === action.payload.idRentType ? action.payload : x);
            state.storeRentType = tmpArray;
        }
    }
})
export const { setRentTypeArray, addRentTypeArray, updateRentTypeArray } = sliceRentType.actions;
export default sliceRentType.reducer;