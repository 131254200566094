import { IDocumentType } from "../../interfaces/IDocumentType";

export const staticDocumentTypeArray:IDocumentType[] = [
    {
        idDocumentType: 1,
        DocumentType: "Personalausweis"
    },
    {
        idDocumentType: 2,
        DocumentType: "Handelsregisterauszug"
    },
    {
        idDocumentType: 3,
        DocumentType: "Bonitätsvalidierung"
    },
    {
        idDocumentType: 4,
        DocumentType: "Bilder"
    },
    {
        idDocumentType: 5,
        DocumentType: "Grundriss"
    },
    {
        idDocumentType: 6,
        DocumentType: "Sonstiges"
    },
    {
        idDocumentType: 7,
        DocumentType: "Kundenupload"
    },
    {
        idDocumentType: 8,
        DocumentType: "Angebot"
    },
    {
        idDocumentType: 10,
        DocumentType: "Vertrag"
    },
    {
        idDocumentType: 15,
        DocumentType: "Lageplan"
    },
    {
        idDocumentType: 20,
        DocumentType: "Thumbnail"
    }
]