import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";


interface IProps {
    children: React.ReactElement | React.ReactElement[];
    title: string;
    btn?: React.ReactElement;
}


export const HandoverCard:React.FC<IProps> = (props) => {

    return(
        <>
            <Card variant="outlined" sx={{mt: 5, backgroundColor: "#fafafa"}}>
                <Box sx={{m: 2}}>
                    <Typography color="text.secondary" sx={{mb: 2}}>
                        {props.title}
                        {(props.btn !== undefined) &&
                            <Box sx={{float: "right"}}>
                                {props.btn}
                            </Box>
                        }
                    </Typography>
                    <Grid container spacing={2}>
                        {props.children}
                    </Grid>
                </Box>
            </Card>
        </>
    )
}