import { useReducer } from "react";
import { IChildMetadata } from "../interfaces/IChildMetadata";

enum EActions {
    ADDUPDATECHILDMETADATA = "ADDUPDATECHILDMETADATA"
}

interface IAction {
    dispatchAction: EActions,
    payload: IChildMetadata
}

function reducer(state:IChildMetadata[], action:IAction) {
    switch(action.dispatchAction) {
        case EActions.ADDUPDATECHILDMETADATA:
            let testObject = state.find(x => x.idChild === action.payload.idChild);

            if (testObject) {
                return [...state.map(x => x.idChild === action.payload.idChild ? action.payload : x)];
            } else {
                return [...state, action.payload];
            }


        default:
            return state;
    }

}


export function useChildMetadata() : [IChildMetadata[], Function] {
    const [state,dispatch] = useReducer(reducer,[] as IChildMetadata[]);
    
    return [
        state,
        (payload:IChildMetadata) => dispatch({dispatchAction: EActions.ADDUPDATECHILDMETADATA, payload: payload})
    ]
}