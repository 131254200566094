import { Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IRoomFullObject } from '../../../interfaces/IRoom';
import GridContainer from '../../core/GridContainer';
import GridItem from '../../core/GridItem';



interface IProps {
    roomObject: IRoomFullObject;
    setRoomObject: Function
}



const RoomPriceEdit:React.FC<IProps> = (props) => {

    const handleInput = (event:React.ChangeEvent<HTMLInputElement>) => {
        let testVal = {...props.roomObject} as any
        testVal[event.target.name]["PricePerUnit"] = Number(event.target.value);
        props.setRoomObject({...testVal})
    }

    if (props.roomObject.Month_1 == undefined 
        || props.roomObject.Month_3 == undefined
        || props.roomObject.Month_6 == undefined
        || props.roomObject.Month_12 == undefined
    ) {
        return <>Error in Preisstukur</>
    } else {
        return(
            <>
                <GridContainer>
                    <GridItem sm={4}><Box sx={{mt: 1}}>1 Monat</Box></GridItem>
                    <GridItem sm={6}>
                        <TextField
                            label="Preis pro Monat"
                            name="Month_1"
                            size="small"
                            type="number"
                            value={props.roomObject.Month_1.PricePerUnit}
                            error={props.roomObject.Month_1.PricePerUnit === null}
                            onChange={handleInput}
                            fullWidth
                        />
                    </GridItem>

                    <GridItem sm={4}><Box sx={{mt: 1}}>3 Monate</Box></GridItem>
                    <GridItem sm={6}>
                        <TextField
                            label="Preis pro Monat"
                            name="Month_3"
                            size="small"
                            type="number"
                            value={props.roomObject.Month_3.PricePerUnit}
                            error={props.roomObject.Month_3.PricePerUnit === null}
                            onChange={handleInput}
                            fullWidth
                        />
                    </GridItem>

                    <GridItem sm={4}><Box sx={{mt: 1}}>6 Monate</Box></GridItem>
                    <GridItem sm={6}>
                        <TextField
                            label="Preis pro Monat"
                            name="Month_6"
                            size="small"
                            type="number"
                            value={props.roomObject.Month_6.PricePerUnit}
                            error={props.roomObject.Month_6.PricePerUnit === null}
                            onChange={handleInput}
                            fullWidth
                        />
                    </GridItem>

                    <GridItem sm={4}><Box sx={{mt: 1}}>12 Monate</Box></GridItem>
                    <GridItem sm={6}>
                        <TextField
                            label="Preis pro Monat"
                            name="Month_12"
                            size="small"
                            type="number"
                            value={props.roomObject.Month_12.PricePerUnit}
                            error={props.roomObject.Month_12.PricePerUnit === null}
                            onChange={handleInput}
                            fullWidth
                        />
                    </GridItem>
                </GridContainer>
            </>
        )
    }
}
export default RoomPriceEdit;