import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILeadSource } from "../interfaces/ILeadSource";


const initialState =  { storeLeadSources: [] as ILeadSource[] }; 

const sliceLeadSource = createSlice({
    name: "LEADSOURCESARRAY",
    initialState,
    reducers: {
        setLeadSourceArray: (state, action: PayloadAction<ILeadSource[]>) => {
            state.storeLeadSources = [...action.payload];
        },
        addLeadSourceArray: (state, action: PayloadAction<ILeadSource>) => {
            state.storeLeadSources = [...state.storeLeadSources,action.payload];
        },
        updateLeadSourceArray: (state, action: PayloadAction<ILeadSource>) => {
            let tmpArray =  state.storeLeadSources.map(x => x.idLeadSource === action.payload.idLeadSource ? action.payload : x);
            state.storeLeadSources = tmpArray;
        }
    }
})
export const { setLeadSourceArray, addLeadSourceArray, updateLeadSourceArray
 } = sliceLeadSource.actions;
export default sliceLeadSource.reducer;