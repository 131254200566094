import { Box, Typography, Card, CardContent, CardActions, Button, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { uploadFetch, useFetch } from '../../hooks/useFetch';
import { IMessage } from '../../interfaces/IMessage';
import AlertError from '../core/AlertError';


interface IProps {
    message: IMessage;
    messageArray: IMessage[];
    setMessageArray: Function;
}



const Message:React.FC<IProps> = (props) => {
    const [messageObj, setMessageObj] = useState<IMessage>(props.message);
    const [messageObjUploaded, setMessageObjUploaded] = useState<IMessage | null>(null);

    useEffect(() => {
        if (messageObjUploaded !== null) {
            props.setMessageArray([...props.messageArray.filter(x => x.idMessage !== messageObjUploaded.idMessage )]);
        }
    },[messageObjUploaded])

    const clickHandler = () => {
        let uploadObject:IMessage = {...props.message, isReaded : true};
        uploadFetch("message",false,uploadObject,setMessageObjUploaded);
    }

    return(
        <>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {new Date(messageObj.Created_at).toLocaleString()}
                </Typography>
                <Typography variant="h5" component="div">
                {messageObj.Title}
                </Typography>
                <Typography variant="body2">
                <TextField fullWidth multiline variant='standard' rows={15} defaultValue={props.message.Text}/>
                </Typography>
              </CardContent>
              <CardActions>
                <Button disabled={messageObj.isReaded == true} onClick={() => clickHandler()} size="small">Gelesen markieren</Button>
                
                { (messageObj.URL != undefined) &&
                    <div style={{float: "right"}}>
                        <Button  href={messageObj.URL} size="small">Link</Button>  
                    </div>
                }
              </CardActions>
            </Card>
        </>
    )

}
export default Message;