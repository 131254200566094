import { Check, Close } from '@mui/icons-material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../redux/combine_reducers';
import GenericOverview from '../../core/GenericOverview';
import Appendix from './Appendix';

const Appendizes:React.FC = () => {
    const dispatch = useDispatch();
    const sourcesArray = useSelector((state: State) => state.appendixArray.storeAppendix);
    // Selected Id
    const [selectedId,setSelectedId] = useState<number|undefined>();

    const columns: GridColDef[] = [
        { 
            field: 'idAppendix', 
            headerName: 'Nr.', 
            width: 90,
        },
        {
            field: 'sendFile',
            headerName: 'Datei',
            width: 90,
            renderCell: (params: GridRenderCellParams) => (Boolean(params.row.sendFile) === true) ? <Check /> : <Close/>,
        },
        {
            field: 'compileLatex',
            headerName: 'LaTeX',
            width: 90,
            renderCell: (params: GridRenderCellParams) => (Boolean(params.row.compileLatex) === true) ? <Check /> : <Close/>,
        },
        {
            field: 'Title',
            headerName: 'Titel',
            flex: 1,
        },
    ];
    //<LeadSource idLeadSource={selectedId}/>

    return(
        <GenericOverview
            headline="Anhänge"
            columns={columns}
            data={sourcesArray}
            idField="idAppendix"
            setTargetId={setSelectedId}
        >
            <Appendix idAppendix={selectedId}/>
        </GenericOverview>
    )

}
export default Appendizes;