import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISource } from "../interfaces/ISource";


const initialState =  { storeSources: [] as ISource[] }; 

const sliceSource = createSlice({
    name: "SOURCESARRAY",
    initialState,
    reducers: {
        setSourceArray: (state, action: PayloadAction<ISource[]>) => {
            state.storeSources = [...action.payload];
        },
        addSourceArray: (state, action: PayloadAction<ISource>) => {
            state.storeSources = [...state.storeSources,action.payload];
        },
        updateSourceArray: (state, action: PayloadAction<ISource>) => {
            let tmpArray =  state.storeSources.map(x => x.idSource === action.payload.idSource ? action.payload : x);
            state.storeSources = tmpArray;
        }
    }
})
export const { setSourceArray, addSourceArray, updateSourceArray
 } = sliceSource.actions;
export default sliceSource.reducer;