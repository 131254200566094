import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILocation } from "../interfaces/ILocation";

const initialState =  { storeLocation: [] as ILocation[] }; 

const sliceLocation = createSlice({
    name: "LOCAITONARRAY",
    initialState,
    reducers: {
        setLocationArray: (state, action: PayloadAction<ILocation[]>) => {
            state.storeLocation = [...action.payload];
        },
        addUpdateLocationArray: (state, action: PayloadAction<ILocation>) => {
            let testObject = state.storeLocation.find(x => x.idLocation === action.payload.idLocation);
            
            if (testObject) {
                state.storeLocation = [...state.storeLocation.map(x => x.idLocation === action.payload.idLocation ? action.payload : x)];
            } else {
                state.storeLocation = [action.payload, ...state.storeLocation];
            }
        },
    }
})
export const { setLocationArray, addUpdateLocationArray } = sliceLocation.actions;
export default sliceLocation.reducer;