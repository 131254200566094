import { IRentTimeFrame } from "../../interfaces/IRentTimeFrame";

export const staticRentTimeFrameArray:IRentTimeFrame[] = [
    {
        idRentTimeFrame: 1,
        Position: 1,
        idUnit: 2,
        Units: 1,
        isEditable: true,
        Description: "Individuell"
    },
    {
        idRentTimeFrame: 2,
        Position: 2,
        idUnit: 2,
        Units: 1,
        isEditable: false,
        Description: "1 Monat"
    },
    {
        idRentTimeFrame: 3,
        Position: 3,
        idUnit: 2,
        Units: 3,
        isEditable: false,
        Description: "3 Monate"
    },
    {
        idRentTimeFrame: 4,
        Position: 4,
        idUnit: 2,
        Units: 6,
        isEditable: false,
        Description: "6 Monate"
    },
    {
        idRentTimeFrame: 5,
        Position: 5,
        idUnit: 2,
        Units: 12,
        isEditable: false,
        Description: "12 Monate"
    }
]