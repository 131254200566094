import { AddCircle } from '@mui/icons-material';
import { Card, CardContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { IRoomFullObject } from '../../../interfaces/IRoom';
import { staticMimeTypeArray } from '../../Document/array_MIMEType';
import { IDocumentWithMetastates } from '../../../interfaces/IDocument';
import { IMIMEType } from '../../../interfaces/IMIMEType';
import DocumentTableRow from '../../Document/DocumentTableRow';


interface IProps {
    roomObject: IRoomFullObject;
    setRoomObject: Function
}



const RoomDocumentOverview:React.FC<IProps> = (props) => {
    const mimeTypeArray = staticMimeTypeArray;
    const [newPossibleFiles,setNewPossibleFiles] = useState<File[]>([]);
    //
    const [addNew, setAddNew] = useState(false);
    const [newFileArray,setNewFileArray] = useState<File[]>([]);
    const [tmpArray,setTmpArray] = useState<IDocumentWithMetastates[]|null>(null);


    useEffect(() => {
        if (tmpArray !== null) {
            let localTmpArray = [...tmpArray];
            let minId = Math.min(...props.roomObject.Documents.map(x => Number(x.idDocument))) -1;
            //(minId >0) && (minId = -1);
            if (props.roomObject.Documents.length === 0 || minId > 0) {
                minId = -1;
            }

            localTmpArray = localTmpArray.map((x,i) => {
                return {
                    ...x,
                    idDocument: minId-i
                }
            })
            props.setRoomObject({...props.roomObject, Documents: [...props.roomObject.Documents, ...localTmpArray]});
            setTmpArray(null);
        }
    },[tmpArray])


    async function fileToBase64(file:File) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
        return String(result_base64).split(",")[1];
    }

    async function callBackDriver(acceptedFiles:File[],currentArray:IDocumentWithMetastates[]) {
        let updatedArray = [];

        for (let acceptedFile of acceptedFiles) {
            let fileName:string = String(acceptedFile["name"]);
            let mimeType: IMIMEType | undefined = mimeTypeArray.find(y => y.MIMEType === acceptedFile["type"])

            if (mimeType != undefined) {
                updatedArray.push({
                    Data: await fileToBase64(acceptedFile),
                    idDocumentType: 1,
                    FileName: fileName,
                    idMIMEType: mimeType!.idMIMEType,
                    isPublic: false,
                    wasChanged: true
                } as IDocumentWithMetastates)
            }
        }
        setTmpArray([...updatedArray])
    }

    const onDrop = useCallback( (acceptedFiles:File[]) => { 
        //setNewFileArray(acceptedFiles);
        callBackDriver(acceptedFiles,props.roomObject.Documents);
        setAddNew(false);
     }, [newPossibleFiles] );

    const { getRootProps, getInputProps, isDragActive } = useDropzone( { 
        onDrop,
        noClick: true,
        /*
        accept: mimeTypeArray.reduce((accObj,obj) => { 
            accObj[obj.MIMEType] = [obj.FileEnding];
            return accObj;
        }, {} as Accept)
        */
    } );

    const dropZoneTsx = () => {
        return(
            <div>
                <input {...getInputProps()} accept={String(mimeTypeArray.map(x => x.MIMEType).join(", "))}/>
                <Card style={{minHeight: 150}}>
                    <CardContent>
                        <Typography variant="body2">
                            Dokument(e) hier reinziehen
                        </Typography>
                        <br />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Datentyp</TableCell>
                                    <TableCell>Datenendung</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mimeTypeArray.map(x =>
                                    <TableRow>
                                        <TableCell>{x.MIMEType}</TableCell>
                                        <TableCell>{x.FileEnding}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </div>
        )
    }

    return(
        <>
            <div {...getRootProps()}>
                <Typography variant='h5'>
                    Dokument
                    <IconButton onClick={() => setAddNew(!addNew)} style={{float: 'right'}}><AddCircle/></IconButton>
                </Typography>

                {(isDragActive || addNew) && dropZoneTsx()}
            

                <TableContainer hidden={isDragActive || addNew}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Art</TableCell>
                                <TableCell>Öffentlich</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Aktionen</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {props.roomObject.Documents.map(x =>
                                <DocumentTableRow 
                                    key={x.idDocument}
                                    documentObject={x}
                                    documentArray={props.roomObject.Documents}
                                    setDocumentArray={
                                        (newArray:IDocumentWithMetastates[]) => props.setRoomObject({...props.roomObject, Documents: [...newArray]} as IRoomFullObject)
                                    }
                                />
                            )}
                        </TableBody>
                    </Table>


                </TableContainer>
            </div>
        </>
    )
}
export default RoomDocumentOverview;