import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompanyCategory } from "../interfaces/ICompanyCategory";

const initialState =  { storeCompanyCategory: [] as ICompanyCategory[] }; 

const siceCompanyCategory = createSlice({
    name: "COMPANYCAGEOGRYARRAY",
    initialState,
    reducers: {
        setCompanyCategory: (state, action: PayloadAction<ICompanyCategory[]>) => {
            state.storeCompanyCategory = [...action.payload];
        },
        addUpdateCompanyCategoryArray: (state, action: PayloadAction<ICompanyCategory>) => {
            let testObject = state.storeCompanyCategory.find(x => x.idCompanyCategory === action.payload.idCompanyCategory);
            
            if (testObject) {
                state.storeCompanyCategory = [...state.storeCompanyCategory.map(x => x.idCompanyCategory === action.payload.idCompanyCategory ? action.payload : x)];
            } else {
                state.storeCompanyCategory = [action.payload, ...state.storeCompanyCategory];
            }
        },
    }
})
export const { setCompanyCategory, addUpdateCompanyCategoryArray } = siceCompanyCategory.actions;
export default siceCompanyCategory.reducer;