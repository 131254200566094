import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidatenText } from '../../../hooks/useValidaten';
import { bindActionCreators } from '@reduxjs/toolkit';
import { State } from '../../../redux/combine_reducers';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import GenericEdit, { IInputValues, ISetValues } from '../../core/GenericEdit';
import InputField from '../../core/InputField';
import { actionCreatorsAppendix } from '../../../redux/actionCreators';
import { IAppendix } from '../../../interfaces/IAppendix';
import Document from '../../Document/Document';



interface IProps {
    idAppendix?: number;
}


const Appendix:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const appendixArray = useSelector((state: State) => state.appendixArray.storeAppendix);
    const { addAppendixArray, updateAppendixArray } = bindActionCreators(actionCreatorsAppendix,dispatch);
    // Main Object
    const [mainObject, setMainObject] = useState<IAppendix | undefined>();
    // Haupstates
    const [idAppendix,setIdAppendix] = useState<number>();
    const [title,setTitle,vTitle] = useValidatenText();
    const [sendFile,setSendFile] = useState<boolean>(false);
    const [compileLatex,setCompileLatex] = useState<boolean>(false);
    // Optionale
    const [idDocument,setIdDocument] = useState<number | null>(null);
    const [latex,setLatex] = useState<string | null>(null);
    // Handeln von Child
    const [documentWasChanged,setDocumentWasChanged] = useState(false);
    // Validierung
    const [validationArray, setValidationArray] = useState<boolean[]>([]);
    // Metastatea
    const [isNew,setIsNew] = useState(false);
    const [isEmpty,setIsEmpty] = useState(true);
    const [isTime,setIsTime] = useState<boolean>(false);
    // Interner saveCounter
    const [ saveCounter, setSaveCounter ] = useState(0);

    useEffect(() => {
        if (props.idAppendix === undefined && props.idAppendix !== 0) {
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            resetValues();
            
            if (props.idAppendix === -1) {
                setIsNew(true)
            } else {
                setIsNew(false);
                setMainObject(appendixArray.find(x => x.idAppendix=== props.idAppendix));
            }
        }
    },[props.idAppendix])

    useEffect(() => {setValidationArray([vTitle])},[vTitle])

    useEffect(() => {console.log(idDocument)},[idDocument])
    useEffect(() => {console.log(`compileLatex ${compileLatex}`)},[compileLatex])

    const valuesArray:ISetValues[] = [
        {
            Attribute: "idAppendix",
            Value: idAppendix,
            SetFunction: setIdAppendix,
            IsPrimaryKey: true
        },
        {
            Attribute: "Title",
            Value: title,
            SetFunction: setTitle,
        },
        {
            Attribute: "sendFile",
            Value: sendFile,
            SetFunction: setSendFile,
            Type: "boolean"
        },
        {
            Attribute: "compileLatex",
            Value: compileLatex,
            SetFunction: setCompileLatex,
            Type: "boolean"
        },
        {
            Attribute: "idDocument",
            Value: idDocument,
            SetFunction: setIdDocument,
            PossibleUndefined: true,
            Type: 'number'
        },
        {
            Attribute: "Latex",
            Value: latex,
            SetFunction: setLatex,
            PossibleUndefined: true,
            Type: 'string'
        }
    ];


    const inputField:IInputValues[] = [
        {
            InputElement: <InputField isEdit={!isEmpty} label='Titel' value={title} setFunction={setTitle} isValidated={vTitle}/>,
        },
        {
            InputElement:         <FormControl>
                                    <FormLabel>Datei versenden</FormLabel>
                                    <RadioGroup 
                                        row
                                        defaultValue="false" 
                                        value={(sendFile) ? "true" : "false"} 
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setSendFile(Boolean(event.target.value === "true")) }
                                    >
                                        <FormControlLabel disabled={isEmpty} value="true" control={<Radio />} label="Ja" />
                                        <FormControlLabel disabled={isEmpty} value="false" control={<Radio />} label="Nein" />
                                    </RadioGroup>
                                </FormControl>
        },
        {
            InputElement:         <FormControl>
                                    <FormLabel>LaTeX erstellen</FormLabel>
                                    <RadioGroup 
                                        row
                                        defaultValue="false" 
                                        value={(compileLatex) ? "true" : "false"} 
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setCompileLatex(Boolean(event.target.value === "true")) }
                                    >
                                        <FormControlLabel disabled={isEmpty} value="true" control={<Radio />} label="Ja" />
                                        <FormControlLabel disabled={isEmpty} value="false" control={<Radio />} label="Nein" />
                                    </RadioGroup>
                                </FormControl>
        },
        {
            InputElement:    <Document
                                idDocument={idDocument}
                                setIdDocument={setIdDocument}
                                idDocumentType={1}
                                label="Anhangsdatei"
                                isEmpty={isEmpty}
                                isNew={isNew}
                                setDocumentWasChanged={setDocumentWasChanged}
                                saveCounter={saveCounter}
                            />
        },
        {
            InputElement: <InputField isEdit={!isEmpty && compileLatex} label='LaTeX' value={latex} rows={20} setFunction={setLatex}/>,
        },
    ]

    const resetValues = () => {
        console.log("reset values")
        valuesArray.filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey == false).map(x => (x.Type !== undefined && x.Type === "boolean") ? x.SetFunction(false) : x.SetFunction(null))
    }



    return(
        <>  
            <GenericEdit
                headlineNew="Neuer Anhang"
                headlineAttr="Title"
                setValuesDef={valuesArray}
                inputFilds={inputField}
                // Zum Hochladen
                endpoint="appendix"
                // Hauptelemeten
                mainObject={mainObject}
                setMainObject={setMainObject}
                // Zum Validieren
                validatenArray={validationArray}
                // Metastates
                isEmpty={isEmpty}
                isNew={isNew}
                updatedReduxArray={updateAppendixArray}
                addReduxArray={ addAppendixArray}
                setSaveCounterExtern={setSaveCounter}
                childWasSaved={ (sendFile == false) ? true : (idDocument != undefined) }
            />
        </>
    )

}
export default Appendix;