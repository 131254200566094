import { combineReducers } from "@reduxjs/toolkit";
import { IProduct } from "../interfaces/IProduct";
import { IRoomType } from "../interfaces/IRoomType";
import { IUnit } from "../interfaces/IUnit";
import slice_Product from "./slice_Product";
import slice_RoomType from "./slice_RoomType";
import slice_Unit from "./slice_Unit";
import slice_Region from "./slice_Region";
import slice_ProductHasRoom from "./slice_ProductHasRoom";
import slice_Room from "./slice_Room";
import slice_ProductHasAsset from "./slice_ProductHasAsset";
import slice_Asset from "./slice_Asset";
import slice_Rent from "./slice_Rent";
import slice_RentType from "./slice_RentType";
import slice_RentState from "./slice_RentState";
import slice_Country from "./slice_Country";
import slice_Postcode from "./slice_Postcode";
import slice_Company from "./slice_Company";
import slice_Location from "./slice_Location";
import slice_Gender from "./slice_Gender";
import slice_RoomPrice from "./slice_RoomPrice";
import slice_LeadSource from "./slice_LeadSource";
import slice_Mediums from "./slice_Medium";
import slice_Campaign from "./slice_Campaign";
import slice_LeadState from "./slice_LeadState";
import slice_RentAutomation from "./slice_RentAutomation";
import slice_CompanyCategory from "./slice_CompanyCategory";
import slice_Appendix from "./slice_Appendix";
import slice_Source from "./slice_Source";
import slice_vat from "./slice_vat";

const mainReducer = combineReducers({
    roomTypeArray: slice_RoomType,
    assetArray: slice_Asset,
    productArray: slice_Product,
    productHasRoomArray: slice_ProductHasRoom,
    productHasAssetArray: slice_ProductHasAsset,
    unitsArray: slice_Unit,
    regionArray: slice_Region,
    locationArray: slice_Location,
    roomArray: slice_Room,
    roomPriceArray: slice_RoomPrice,
    // Für die Mieten
    rentArray: slice_Rent,
    rentTypeArray: slice_RentType,
    rentStateArray: slice_RentState,
    vatObject: slice_vat,
    // Addresse, Psotcode etc.
    companyArray: slice_Company,
    countryArray: slice_Country,
    postcodeArray: slice_Postcode,
    // Weitere
    genderArray: slice_Gender,
    // Marketing
    campaignArray: slice_Campaign,
    sourceArray: slice_Source,
    mediumArray: slice_Mediums,
    leadStateArray: slice_LeadState,
    leadSourceArray: slice_LeadSource,
    companyCategoryArray: slice_CompanyCategory,
    // Auto, Mailsing etc.
    rentAutomationArray: slice_RentAutomation,
    appendixArray: slice_Appendix


})
export default mainReducer;
export type State = ReturnType<typeof mainReducer>;