import { AddCircle } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IChildMetadata } from '../../interfaces/IChildMetadata';
import { IRent } from '../../interfaces/IRent';
import { State } from '../../redux/combine_reducers';
import GenericOverview from '../core/GenericOverview';
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import Rent from './Rent';

export interface IProps {
    idPerson?: number | null;
    saveCounter?: number;
    setMetastateChild?: Function;
}

const Rents:React.FC<IProps> = (props) => {
    const paramObject = useParams();

    const dispatch = useDispatch();
    const rentStateArray = useSelector((state: State) => state.rentStateArray.storeRentState);
    const rentTypeArray = useSelector((state: State) => state.rentTypeArray.storeRentType);
    const rentArray = useSelector((state: State) => state.rentArray.storeRent);
    // Selected Id
    const [selectedId,setSelectedId] = useState<number|undefined>((paramObject.idRent == undefined) ? undefined : Number(paramObject.idRent));
    // Hilsvalues wenn Child von Person
    const [personRentArray, setPersonRentArray] = useState<IRent[]>([]);
    //
    const [winHeight,setWinHeight] = useState(window.innerHeight);


    //useEffect(() => {(props.idPerson != undefined) && setSelectedId(undefined)},[props.idPerson])


    useEffect(() => {
        if (props.idPerson == undefined) {
            //setSelectedId(undefined);
            setPersonRentArray([]);
        }
        else  {
            let tmpArray = rentArray.filter(x => x.idPerson === props.idPerson);
            
            if (tmpArray.length === 1) {
                setSelectedId(tmpArray[0].idRent);
                setPersonRentArray([]);
            } 
            else if (tmpArray.length > 0) {
                setSelectedId(undefined);
                setPersonRentArray([...tmpArray]);
            }
            else {
                setSelectedId(undefined);
                setPersonRentArray([]);

                if (selectedId != -1) {
                    resetMetastateChild();
                }
                
            }
        }
    },[props.idPerson, rentArray])

    const resetMetastateChild = () => {
        if (props.setMetastateChild != undefined) {
            props.setMetastateChild({
                idChild: "rent-empty",
                isValidated: true,
                wasSaved: true,
                wasSuccessfullySaved: true,
            } as IChildMetadata)
        }
    }

    const prepareDate = (dateAsString:string,withTime?:boolean) => {
        if (dateAsString == undefined){ 
            return "-"; 
        }
        else {
            let  currentDate = new Date(dateAsString);
            let dd = currentDate.getDate();
            let mm = currentDate.getMonth() +1;
            let yyyy = currentDate.getFullYear();
            let hh = currentDate.getUTCHours();
            let min = currentDate.getMinutes();


            
            if (withTime == undefined || withTime === false) {
                return `${(dd > 9 ? '' : '0') + dd}.${(mm > 9 ? '' : '0') + mm}.${yyyy}`
            } else {
                return currentDate.toLocaleString();
                //return `${(dd > 9 ? '' : '0') + dd}.${(mm > 9 ? '' : '0') + mm}.${yyyy}, ${(hh > 9 ? '' : '0') + hh}:${(min > 9 ? '' : '0') + min}`
            }
            
        }
    }

    const getTotal = (row: any) => {
        let units = Number(row.Units);
        let pricePerUnit = Number(row.PricePerUnit);
        return (units*pricePerUnit).toFixed(2).replace(".",",") + " €" 
    }

    const columnsRequest: GridColDef[] = [
        { 
            field: 'RequestNumber', 
            headerName: 'Anfragenummer', 
            minWidth: 150,
            flex: 1
            //valueGetter: (params: GridValueGetterParams)
        },        
        { 
            field: 'Created_at', 
            headerName: 'Erstellt', 
            width: 170,
            valueGetter: (params: GridValueGetterParams) => prepareDate(params.row.Created_at,true)
        },   
        { 
            field: 'RentTimeFrame', 
            headerName: 'Zeitraum', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => params.row.RentTimeFrame
        },   
        { 
            field: 'Units', 
            headerName: 'Umsatz', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => getTotal(params.row)
        },
    ]

    const columnsRent: GridColDef[] = [
        { 
            field: 'RentNummer', 
            headerName: 'Buchhungsnummer', 
            minWidth: 150,
            flex: 1
            //valueGetter: (params: GridValueGetterParams)
        },          
        { 
            field: 'RentTimeFrame', 
            headerName: 'Zeitraum', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => params.row.RentTimeFrame
        },    
        { 
            field: 'FromDate', 
            headerName: 'Von', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => prepareDate(params.row.FromDate)
        },
        { 
            field: 'ToDate', 
            headerName: 'Bis', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => prepareDate(params.row.ToDate)
        },
        { 
            field: 'Units', 
            headerName: 'Umsatz', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => getTotal(params.row)
        },
    ]

    const columnsPerson: GridColDef[] = [
        { 
            field: 'RentNummer', 
            headerName: 'Auftragsnummer', 
            minWidth: 150,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => (Number(params.row.idRentType) == 1) ? String(params.row.RequestNumber) : String(params.row.RentNummer)
        },       
        { 
            field: 'idRentType', 
            headerName: 'Art', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => params.row.RentType
        },
        { 
            field: 'idRentState', 
            headerName: 'Status', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => (Number(params.row.idRentType) == 1) ? "-" : params.row.RentState
        },
        { 
            field: 'ToDate', 
            headerName: 'Bis', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => prepareDate(params.row.ToDate)
        },
        { 
            field: 'Units', 
            headerName: 'Umsatz', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => getTotal(params.row)
        },
    ]



    const tsxContextChildPerson = () => {
        console.log(props.idPerson)
        if (props.idPerson != undefined)  {
            if (rentArray.filter(x => x.idPerson === props.idPerson).length > 0) {
                if (rentArray.filter(x => x.idPerson === props.idPerson).length === 1) {
                    return(
                        <>
                            <Rent 
                                idRent={rentArray.filter(x => x.idPerson === props.idPerson)[0].idRent} 
                                idPerson={props.idPerson}
                                setMetastateChild={props.setMetastateChild}
                            />
                        </>
    
                    )
    
                } else {
                    return(
                        <>
                            <GenericOverview
                                headline="Mieten"
                                columns={columnsPerson}
                                data={rentArray.filter(x => x.idRentType === 3 && x.idRentState === 5)}
                                idField="idRent"
                                setTargetId={setSelectedId}
                                colDataGrid={12}
                            />
                            {(selectedId != undefined)  &&
                                <Rent 
                                    idRent={selectedId} 
                                    idPerson={props.idPerson}
                                    setMetastateChild={props.setMetastateChild}
                                />
                            }
                        </>
                    )
                }
    
            } else if (selectedId) {
                return(
                    <Rent 
                        idRent={-1} 
                        idPerson={props.idPerson}
                        setMetastateChild={props.setMetastateChild}
                    />
                )
            } 
        }

    }


    if (props.idPerson === undefined) {
        return(
            <>
            <Typography>
                    <IconButton onClick={() => setSelectedId(-1)} style={{float: 'right'}}><AddCircle/></IconButton>
            </Typography>

            <GridContainer>
                <GridItem>
                <Box sx={{  width: '100%', height: (winHeight-(winHeight*0.2)), overflowY: "scroll" }}>
                    <GenericOverview
                        headline="Anfragen"
                        columns={columnsRequest}
                        data={rentArray.filter(x => x.idRentType === 1)}
                        idField="idRent"
                        targetId={selectedId}
                        setTargetId={setSelectedId}
                        colDataGrid={12}
                        enableAddButton={false}
                        autoHeight={true}
                    />
    
                    <GenericOverview
                        headline="Reservierte Mieten"
                        columns={columnsRent}
                        data={rentArray.filter(x => x.idRentType === 3 && x.idRentState === 5)}
                        idField="idRent"
                        targetId={selectedId}
                        setTargetId={setSelectedId}
                        colDataGrid={12}
                        enableAddButton={false}
                        autoHeight={true}
                    />
    
                    <GenericOverview
                        headline="Aktive Mieten"
                        columns={columnsRent}
                        data={rentArray.filter(x => x.idRentType === 3 && x.idRentState === 10)}
                        idField="idRent"
                        targetId={selectedId}
                        setTargetId={setSelectedId}
                        colDataGrid={12}
                        enableAddButton={false}
                        autoHeight={true}
                    />
    
                    <GenericOverview
                        headline="Gekündigte Mieten"
                        columns={columnsRent}
                        data={rentArray.filter(x => x.idRentType === 3 && x.idRentState === 15)}
                        idField="idRent"
                        targetId={selectedId}
                        setTargetId={setSelectedId}
                        colDataGrid={12}
                        enableAddButton={false}
                        autoHeight={true}
                    />
    
                    <GenericOverview
                        headline="Beendete Mieten"
                        columns={columnsRent}
                        data={rentArray.filter(x => x.idRentType === 3 && x.idRentState === 20)}
                        idField="idRent"
                        targetId={selectedId}
                        setTargetId={setSelectedId}
                        colDataGrid={12}
                        enableAddButton={false}
                        autoHeight={true}
                    />
                </Box>
                </GridItem>
    
                <GridItem>
                    <Rent 
                        idRent={selectedId} 
                    />
                </GridItem>
            </GridContainer>
            </>
        )
    } else {
        return(
            <>
                <div style={{float: "right"}}>
                    <Button variant="outlined" style={{marginLeft: 5}} color="success" onClick={() => {setSelectedId(undefined); setSelectedId(-1)} }>Buchung erstellen</Button>
                </div>
                
                {(personRentArray.length > 0) &&
                    <GenericOverview
                        headline="Mieten"
                        columns={columnsPerson}
                        data={personRentArray}
                        idField="idRent"
                        setTargetId={setSelectedId}
                        colDataGrid={12}
                        enableAddButton={false}
                        autoHeight={true}
                    />
                }

                {(selectedId) &&
                    <Rent 
                        idRent={selectedId} 
                        idPerson={props.idPerson}
                        saveCounter={props.saveCounter}
                        setMetastateChild={props.setMetastateChild}
                    />
                }

            </>
        )

    }


}
export default Rents;