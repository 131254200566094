import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAsset } from "../interfaces/IAsset";

const initialState =  { storeAssets: [] as IAsset[] }; 

const sliceAsset = createSlice({
    name: "ASSETARRAY",
    initialState,
    reducers: {
        setAssetArray: (state, action: PayloadAction<IAsset[]>) => {
            state.storeAssets = [...action.payload];
        },
        addAssetArray: (state, action: PayloadAction<IAsset>) => {
            state.storeAssets = [...state.storeAssets,action.payload];
        },
        updateAssetArray: (state, action: PayloadAction<IAsset>) => {
            let tmpArray =  state.storeAssets.map(x => x.idAsset === action.payload.idAsset ? action.payload : x);
            state.storeAssets = tmpArray;
        }
    }
})
export const { setAssetArray, addAssetArray, updateAssetArray} = sliceAsset.actions;
export default sliceAsset.reducer;