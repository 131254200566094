import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidatenText } from '../../hooks/useValidaten';
import { IRoomType } from '../../interfaces/IRoomType';
import { actionCreatorsRoomType } from '../../redux/actionCreators';
import { State } from '../../redux/combine_reducers';
import GenericEdit, { IInputValues, ISetValues } from '../core/GenericEdit';
import InputField from '../core/InputField';



interface IProps {
    idRoomType?: number;
}

const RoomType:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const roomTypeArray = useSelector((state: State) => state.roomTypeArray.storeRoomType);
    const { setRoomTypeArray, addRoomTypeArray, updateRoomTypeArray } = bindActionCreators(actionCreatorsRoomType, dispatch)
    // Main Object
    const [roomTypeObject,setRoomTypeObject] = useState<IRoomType | undefined>();
    // Haupstates
    const [idRoomType,setIdRoomType] = useState<number>();
    const [roomType,setRoomType,vRoomType] = useValidatenText();
    // Validierung
    const [validationArray, setValidationArray] = useState<boolean[]>([]);
    // Metastatea
    const [isNew,setIsNew] = useState(false);
    const [isEmpty,setIsEmpty] = useState(true);

    useEffect(() => {
        if (props.idRoomType === undefined) {
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            
            if (props.idRoomType === -1) {
                setIsNew(true)
                resetValues();
            } else {
                setIsNew(false);
                setRoomTypeObject(roomTypeArray.find(x => x.idRoomType === props.idRoomType));
            }
        }
    },[props.idRoomType])

    useEffect(() => {setValidationArray([vRoomType])},[vRoomType])

    const valuesArray:ISetValues[] = [
        {
            Attribute: "idRoomType",
            Value: idRoomType,
            SetFunction: setIdRoomType,
            IsPrimaryKey: true
        },
        {
            Attribute: "RoomType",
            Value: roomType,
            SetFunction: setRoomType,
        }
    ];

    const inputField:IInputValues[] = [
        {
            InputElement: <InputField isEdit={!isEmpty} label='Raumart' value={roomType} setFunction={setRoomType} isValidated={vRoomType}/>,
        }
        
    ]

    const resetValues = () => {
        valuesArray.filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey == false).map(x => (x.Type !== undefined && x.Type === "boolean") ? x.SetFunction(false) : x.SetFunction(null))
    }


    return(
        <>  
            <GenericEdit
                headlineNew="Neue Raumart"
                headlineAttr="RoomType"
                setValuesDef={valuesArray}
                inputFilds={inputField}
                // Zum Hochladen
                endpoint="roomType"
                // Hauptelemeten
                mainObject={roomTypeObject}
                setMainObject={setRoomTypeObject}
                // Zum Validieren
                validatenArray={validationArray}
                // Metastates
                isEmpty={isEmpty}
                isNew={isNew}
                updatedReduxArray={updateRoomTypeArray}
                addReduxArray={ addRoomTypeArray }
            />
        </>
    )

}
export default RoomType;