import { Alert, Button, Collapse, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import deLocale from "date-fns/locale/de";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidatenText, useValidationNumber } from '../../hooks/useValidaten';
import { IRent } from '../../interfaces/IRent';
import { State } from '../../redux/combine_reducers';
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import InputField from '../core/InputField';
import { IRoom } from '../../interfaces/IRoom';
import SelectCustomer from '../Rent/SelectCustomer';
import { staticRentTimeFrameArray } from './TmpRentTimeFrameArray';
import { IRentTimeFrame } from '../../interfaces/IRentTimeFrame';
import { IProduct } from '../../interfaces/IProduct';
import { uploadFetch } from '../../hooks/useFetch';
import { bindActionCreators } from '@reduxjs/toolkit';
import { actionCreatorsRents } from '../../redux/actionCreators';
import { IChildMetadata } from '../../interfaces/IChildMetadata';
//import { useHotkeys } from 'react-hotkeys-hook';
import { JSONTree } from 'react-json-tree';

interface IProps {
    idRent?: number;
    idPerson?: number | null;
    saveCounter?: number;
    // Optionale VAlues
    setAddNew?: Function;
    // Wenn Child
    setIdLeadState?: Function;
    setMetastateChild?: Function;
}


const Rent: React.FC<IProps> = (props) => {
    // Redux
    const dispatch = useDispatch();
    const rentArray = useSelector((state: State) => state.rentArray.storeRent);
    const rentTypeArray = useSelector((state: State) => state.rentTypeArray.storeRentType);
    const rentStateArray = useSelector((state: State) => state.rentStateArray.storeRentState);
    const roomArray = useSelector((state: State) => state.roomArray.storeRoom);
    const locationArray = useSelector((state: State) => state.locationArray.storeLocation);
    //const regionArray = useSelector((state: State) => state.regionArray.storeRegions);
    const roomPriceArray = useSelector((state: State) => state.roomPriceArray.storeRoomPrice);
    const unitArray = useSelector((state: State) => state.unitsArray.storeUnits);
    const productArray = useSelector((state: State) => state.productArray.storeProducts);
    const productHasRoomArray = useSelector((state: State) => state.productHasRoomArray.storeProductHasRoom);
    //
    const { addRentArray, setRentArray } = bindActionCreators(actionCreatorsRents, dispatch);
    // Auswahl und Filtering
    //const [filteredRoomArray, setFilteredRoomArray] = useState<IRoom[]>([]);
    // Haupobject
    const [rentObject, setRentObject] = useState<IRent | undefined>();
    // MainStates
    const [idRent, setIdRent] = useState<number | null>();
    const [idRentType, setIdRentType, vIdRentType] = useValidationNumber();
    const [idRentState, setIdRentState, vIdRentState] = useValidationNumber();
    const [idRentTimeFrame, setIdRentTimeFrame, vIdRentTimeFrame] = useValidationNumber(2);
    const [preIdRentState, setPreIdRentState] = useState<number>();
    const [idProduct, setIdProduct, vIdProduct] = useValidationNumber();
    const [idPerson, setIdPerson, vIdPerson] = useValidationNumber();
    const [idPaymentInformation, setIdPaymentInformation] = useState<number | null>(null);
    const [idRoom, setIdRoom, vIdRoom] = useValidationNumber();
    const [idUnit, setIdUnit, vIdUnit] = useValidationNumber(5);
    const [fromDateTime, setFromDateTime] = useState<Date | null>(null);
    const [toDateTime, setToDateTime] = useState<Date | null>(null);
    const [teminatedAt, setTeminatedAt] = useState<Date | null>(null);
    const [handingOverDate, setHandingOverDate] = useState<Date | null>(null);
    const [units, setUnits, vUnits] = useValidationNumber(1);
    const [pricePerUnit, setPricePerUnit, vPricePerUnit] = useValidationNumber(0);
    const [deposit, setDeposit] = useState<number | null>(null);
    const [rentNumber, setRentNumber] = useState<string | null>(null);
    const [specialArrangement, setSpecialArrangement] = useState<string | null>(null);
    const [customerMessage, setCustomerMessage] = useState<string | null>(null);
    const [electricityMeterReadingBegin, setElectricityMeterReadingBegin] = useState<number | null>(null);
    const [electricityMeterReadingEnd, setElectricityMeterReadingEnd] = useState<number | null>(null);

    // Zur Zeiteditieren
    const [isToTimeEditable, setIsToTimeEditable] = useState(false);
    // Zum HAndeln von Räumen
    const [idLocation, setIdLocation] = useState<number | null>(null);
    const [filteredRoomArray, setFilteredRoomArray] = useState<IRoom[]>([]);
    // Ob geändert wurde
    const [wasIdRentChange, setWasIdRentChange] = useState(false);
    // States bzgl. der Frima, zum visualisieren
    const [isBlockedCompany, setIsBlockedCompany] = useState(false);
    const [isCreditCheckedCompany, setIsCreditCheckedCompany] = useState(true);
    // VAlidierungsstates
    const [isTimeValidated, setIsTimeValidated] = useState(false);
    const [isValidatedRent, setIsValidatedRent] = useState(false);
    const [isValidatedPerson, setIsValidatedPerson] = useState(false);
    const [isValidatedAll, setIsValidatedAll] = useState(false);
    // Validierung der Chils
    const [metadataPerson, setMetadataPerson] = useState<IChildMetadata | null>(null);
    // Zum Triggern vom Save
    const [saveCounter, setSaveCounter] = useState(0);
    // Save States
    const [wasSavedRent, setWasSavedRent] = useState(false);
    const [wasSavedPerson, setWasSavedPerson] = useState(false);
    const [wasSavedAll, setWasSavedAll] = useState(false);
    // Save Healty States
    const [wasSuccessfullySavedRent, setWasSuccessfullySavedRent] = useState(true);
    const [wasSuccessfullySavedPerson, setWasSuccessfullySavedPerson] = useState(true);
    const [wasSuccessfullySavedAll, setWasSuccessfullySavedAll] = useState(true);
    // Metastes
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const [isNew, setIsNew] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const [isDebug, setIsDebug] = useState(false)

    useEffect(() => { console.log(`isDebug ${isDebug}`) }, [isDebug])
    //useHotkeys('shift+d+m', () => setIsDebug(!isDebug))

    const rentTimeFrameArray: IRentTimeFrame[] = staticRentTimeFrameArray;

    useEffect(() => {
        if (
            saveCounter === 0
            && props.idRent !== idRent
            && !((props.saveCounter === undefined && wasSavedAll || props.saveCounter != undefined && wasSavedRent))
        ) {
            resetValies();
            if (props.idRent === -1) {
                (props.idPerson == undefined) && setIdPerson(-1);
                setIsNew(true);
                setIsEmpty(false);
                // Default Werte
                setIdRentType(3); // Miete
                setIdRentState(5); // Reserviert
                setIdProduct(1); // Allg. Miete
            }
            else if (props.idRent === undefined) {
                setIsNew(false);
                setIsEmpty(true);
            }
            else {
                setIsNew(false);
                setIsEmpty(false);
                setRentObject(rentArray.find(x => x.idRent === props.idRent));
            }
        }
    }, [props.idRent])

    useEffect(() => { (props.saveCounter != undefined && props.saveCounter > 0) && setSaveCounter(props.saveCounter) }, [props.saveCounter])

    useEffect(() => { (saveCounter === 0) && setValues(rentObject); }, [rentObject])

    useEffect(() => {
        if (units === null && fromDateTime !== null && toDateTime !== null) {
            setUnits(
                toDateTime.getMonth() - fromDateTime.getMonth()
                + (12 * (toDateTime.getFullYear() - fromDateTime.getFullYear()))
            )
        }
    }, [fromDateTime, toDateTime])


    useEffect(() => {
        if (idRentTimeFrame !== null) {
            let rentTimeFrameObject: IRentTimeFrame | undefined = rentTimeFrameArray.find(x => x.idRentTimeFrame === idRentTimeFrame);
            if (rentTimeFrameObject !== undefined) {
                setIsToTimeEditable(rentTimeFrameObject.isEditable == true);

                if (isLoading && idRentTimeFrame !== null && idRentType === 1) {
                    setIsLoading(false)
                }
                else if (fromDateTime !== null) {
                    let tmpToDateTime = new Date(fromDateTime);
                    tmpToDateTime.setMonth(fromDateTime.getMonth() + rentTimeFrameObject.Units);

                    if (!isLoading) {
                        setUnits(null);
                        setToDateTime(tmpToDateTime);
                    } else {
                        setIsLoading(false);
                    }
                } else {
                    setUnits(rentTimeFrameObject.Units);
                }
            }
        }
    }, [idRentTimeFrame, fromDateTime])

    useEffect(() => {
        if (fromDateTime === null || toDateTime === null) {
            setIsTimeValidated(false);
        } else {
            if (toDateTime.getTime() < fromDateTime.getTime()) {
                setIsTimeValidated(false);
            } else {
                setIsTimeValidated(true);
            }
        }
    }, [fromDateTime, toDateTime, idRentTimeFrame])


    useEffect(() => { (idRentType === 1 && idRentState === null) && setIdRentState(1) }, [idRentType])

    useEffect(() => {
        if ((wasSavedRent && metadataPerson !== null && metadataPerson.wasSaved)) {
            setWasSavedAll(true);
            setSaveCounter(0);
        }
    }, [wasSavedRent, metadataPerson])
    useEffect(() => { setWasSuccessfullySavedAll(wasSuccessfullySavedRent && metadataPerson !== null && metadataPerson.wasSuccessfullySaved) }, [wasSuccessfullySavedRent, metadataPerson])


    useEffect(() => {
        if (idProduct !== null) {
            let prodictObj: IProduct | undefined = productArray.find(x => x.idProduct === idProduct);

            if (prodictObj !== undefined && prodictObj.hasAllRooms == true) {
                setFilteredRoomArray([...roomArray]);
            } else {
                let tmpArray: IRoom[] = [];
                productHasRoomArray
                    .filter(x => x.idProduct === idProduct)
                    .map(x => {
                        let tmpObjecT: IRoom | undefined = roomArray.find(y => y.idRoom === x.idRoom);

                        if (tmpObjecT !== undefined) {
                            tmpArray.push(tmpObjecT);
                        }
                    });
                setFilteredRoomArray([...tmpArray]);
            }
        }
    }, [idProduct])

    /// Preisberechnung
    useEffect(() => {
        if (units != undefined && idRoom != undefined) {

            if (
                rentObject != undefined
                && rentObject.idRoom === idRoom
                && rentObject.Units === units
            ) {
                setPricePerUnit(rentObject.PricePerUnit);
            }
            else {
                let priceArray = roomPriceArray.filter(x => x.idRoom === idRoom);
                let currentMinPrice = 0;
                let lastMinUnits = 0;

                priceArray.map(x => {
                    if (x.MinUnits <= units && x.MinUnits >= lastMinUnits) {
                        lastMinUnits = x.MinUnits;
                        currentMinPrice = Number(x.PricePerUnit);
                    }
                }
                )

                setPricePerUnit(currentMinPrice);
            }
        }
    }, [units, idRoom])

    useEffect(() => {
        if (pricePerUnit != undefined) {
            if (
                rentObject != undefined
                && rentObject.Deposit != undefined
                && rentObject.PricePerUnit === pricePerUnit
            ) {
                //setPricePerUnit(rentObject.PricePerUnit);
                setDeposit(rentObject.Deposit);
            }
            else {
                setDeposit(pricePerUnit * 1.5);
            }
        }
    }, [pricePerUnit])


    useEffect(() => {
        if (idLocation !== null && !isLoading) {
            setIdRoom(null);
        }
    }, [idLocation])

    useEffect(() => {
        setIsValidatedAll(
            vIdRentType
            && vIdRentState
            && vIdRentTimeFrame
            && vIdRoom
            && vIdProduct
            && vPricePerUnit
            && ((metadataPerson !== null) ? metadataPerson.isValidated : true)
            && ((idRentType === 1) ? true : isTimeValidated)
            && (units !== null && units > 0)

        )
    }, [
        vIdRentType,
        vIdRentState,
        vIdRentTimeFrame,
        vIdRoom,
        vIdProduct,
        vPricePerUnit,
        metadataPerson,
        isTimeValidated,
        units,
        idRentType
    ])


    useEffect(() => {
        if (
            saveCounter > 0
            && idRentType
            && idRentState
            && idRentTimeFrame
            && idProduct
            && idPerson && idPerson > 0
            && idRoom
            //&& fromDateTime 
            //&& toDateTime 
            && units
            && pricePerUnit
            && (
                ((props.idPerson === undefined && metadataPerson?.wasSaved) || (props.idPerson !== undefined))
            )
        ) {
            let uploadObject: IRent = {
                idRent: -1,
                idRentType: idRentType,
                idRentState: idRentState,
                idRentTimeFrame: idRentTimeFrame,
                idProduct: idProduct,
                idRoom: idRoom,
                idPerson: idPerson,
                idUnit: 1,
                //FromDate: `${fromDateTime.getFullYear()}-${fromDateTime.getMonth()+1}-${fromDateTime.getDate()}` ,
                //ToDate: `${toDateTime.getFullYear()}-${toDateTime.getMonth()+1}-${toDateTime.getDate()}`,
                Units: units,
                DepositInMounths: 0,
                PricePerUnit: pricePerUnit,
                StripeId_Deposite: null,
                StripeId_FirstMonth: null,
                StripeId_Rent: null,
                ElectricityMeterReadingBegin: 0,
                ElectricityMeterReadingEnd: 0,
                ThirdParty_idPerson: null


                /*
                idRent?: number;
                idRentType: number;
                idRentState: number;
                idRentTimeFrame: number;
                idProduct: number;
                idRoom: number;
                idPerson: number;
                idPaymentInformation?: number;
                idRegion: number;
                idUnit: number;
                FromDate: string;
                ToDate: string;
                Units: number;
                PricePerUnit: number;
                RentNummer?: string;
                CustomerMessage?: string;
                */
            };
            (fromDateTime != undefined) && (uploadObject.FromDate = `${fromDateTime.getFullYear()}-${fromDateTime.getMonth() + 1}-${fromDateTime.getDate()}`);
            (toDateTime != undefined) && (uploadObject.ToDate = `${toDateTime.getFullYear()}-${toDateTime.getMonth() + 1}-${toDateTime.getDate()}`);
            (handingOverDate != undefined) && (uploadObject.HandingOverDate = `${handingOverDate.getFullYear()}-${handingOverDate.getMonth() + 1}-${handingOverDate.getDate()}`);
            (teminatedAt != undefined) && (uploadObject.Terminated_at = `${teminatedAt.getFullYear()}-${teminatedAt.getMonth() + 1}-${teminatedAt.getDate()}`);
            (deposit != undefined) && (uploadObject.Deposit = deposit);

            (specialArrangement != undefined) && (uploadObject.SpecialArrangement = specialArrangement);
            (customerMessage != undefined) && (uploadObject.CustomerMessage = customerMessage);

            (!isNew && idRent) && (uploadObject.idRent = idRent);
            uploadFetch("rent", (isNew || idRent == undefined), uploadObject, setRentObject, setWasSuccessfullySavedRent);
            setWasSavedRent(true);

        }
    }, [saveCounter && idPerson && metadataPerson?.wasSaved])

    useEffect(() => {
        if (rentObject && (props.saveCounter === undefined && wasSavedAll || props.saveCounter != undefined && wasSavedRent)) {
            (rentObject.RentNummer != undefined) && setRentNumber(String(rentObject.RentNummer));
            setIdRent(rentObject.idRent);

            if (isNew) {
                console.log("NEW!")
                addRentArray(rentObject);
                setIsNew(false);
            } else {
                setRentArray([...rentArray.map(x => x.idRent === rentObject.idRent ? rentObject : x)])
            }
            setSaveCounter(0);
        }
    }, [rentObject, wasSavedRent, wasSavedAll])


    useEffect(() => {
        if (props.setMetastateChild != undefined) {
            props.setMetastateChild({
                idChild: `rent-${(rentObject == null) ? "neu" : rentObject.idRent}`,
                isValidated: isValidatedAll,
                wasSaved: wasSavedRent,
                wasSuccessfullySaved: wasSuccessfullySavedRent,
                openNumberValue: (idRentState === rentObject?.idRentState) ? undefined : idRentState
            } as IChildMetadata)
        }
    }, [rentObject, wasSavedRent, wasSuccessfullySavedRent, isValidatedAll])



    const setValues = (localRentObject: IRent | undefined) => {
        if (localRentObject !== undefined) {
            setIsLoading(true);
            setIdRent(localRentObject.idRent);
            setIdRentType(localRentObject.idRentType);
            setIdRentState(localRentObject.idRentState);
            setIdRentTimeFrame(localRentObject.idRentTimeFrame);
            setIdProduct(localRentObject.idProduct);
            setIdPerson(localRentObject.idPerson);
            setIdRoom(localRentObject.idRoom);
            setUnits(localRentObject.Units);
            setPricePerUnit(localRentObject.PricePerUnit);
            (localRentObject.FromDate) && setFromDateTime(new Date(localRentObject.FromDate));
            (localRentObject.ToDate) && setToDateTime(new Date(localRentObject.ToDate));
            (localRentObject.HandingOverDate) && setHandingOverDate(new Date(localRentObject.HandingOverDate));
            (localRentObject.Terminated_at) && setTeminatedAt(new Date(localRentObject.Terminated_at));
            (localRentObject.idPaymentInformation != undefined) && setIdPaymentInformation(localRentObject.idPaymentInformation);
            (localRentObject.RentNummer != undefined) && setRentNumber(localRentObject.RentNummer);
            (localRentObject.SpecialArrangement != undefined) && setSpecialArrangement(localRentObject.SpecialArrangement);
            (localRentObject.CustomerMessage != undefined) && setCustomerMessage(localRentObject.CustomerMessage);
            (localRentObject.Deposit != undefined) && setDeposit(localRentObject.Deposit);
            // Setzen der location
            setIdLocation(roomArray.find(x => Number(x.idRoom) === Number(localRentObject.idRoom))!.idLocation);
        }
    }

    const resetValies = () => {
        if (wasSavedAll) {
            setSaveCounter(0);

        } else {
            setSaveCounter(0);
            setIdRent(null);
            setIdLocation(null);
            setFromDateTime(null);
            setToDateTime(null);
            setHandingOverDate(null);
            setTeminatedAt(null);
            setIdRentType(null);
            setIdRentState(null);
            setIdProduct(null);
            (props.idPerson == undefined) ? setIdPerson(null) : setIdPerson(props.idPerson);
            setIdRoom(null);
            setIdRentTimeFrame(2);
            setUnits(1);
            setPricePerUnit(null);
            setIdPaymentInformation(null);
            setRentNumber(null);
            setSpecialArrangement(null);
            setCustomerMessage(null);
            setDeposit(null);
            setRentObject(undefined);
        }
    }


    return (
        <>

            {(isDebug) &&
                <JSONTree
                    data={{
                        metadataPerson: metadataPerson,
                        isValidatedAll: isValidatedAll,
                        vIdRentType: vIdRentType,
                        vIdRentState: vIdRentState,
                        vIdRentTimeFrame: vIdRentTimeFrame,
                        vIdRoom: vIdRoom,
                        vIdProduct: vIdProduct,
                        vPricePerUnit: vPricePerUnit,
                        isTimeValidated: ((idRentType === 1) ? true : isTimeValidated),
                        vUnits: (units !== null && units > 0)
                    }} />

            }


            <Snackbar
                key="savebar"
                open={wasSavedAll}
                autoHideDuration={6000}
                onClose={() => setWasSavedAll(false)}
                anchorOrigin={{ 'vertical': 'bottom', 'horizontal': 'right' }}
            >
                <Alert onClose={() => setWasSavedAll(false)} severity={(wasSuccessfullySavedAll) ? "success" : "error"} sx={{ width: '100%' }}>
                    {(wasSuccessfullySavedAll) ? "Erfolgreich gespeichert!" : "Es ist ein Fehler aufgetretten!"}
                </Alert>
            </Snackbar>


            <Typography variant='h5'>
                {
                    (isEmpty) ? "Bitte Wählen" : (isNew) ? "Neue Buchung" : (rentObject === undefined) ? "ERROR" :
                        (rentObject.idRentType === 1) ? rentObject.RequestNumber : rentObject.RentNummer}

                { /*
                <div style={{float: "right"}}>
                    <Button disabled={isEmpty || isNew || idRentState !== 1 || idRentType !== 2} variant="outlined" color="success">E-Mail</Button>
                </div>
                */}
                {(props.idPerson === undefined) &&
                    <div style={{ float: 'right' }}>
                        <Button
                            disabled={(isEmpty || !isValidatedAll || saveCounter > 0)}
                            style={{ marginLeft: 5 }}
                            variant={(saveCounter > 0) ? "outlined" : "contained"}
                            onClick={() => { setSaveCounter(saveCounter + 1); }
                            }>
                            {(saveCounter > 0) ? "Bitte warten..." : "Speichern"}
                        </Button>
                    </div>
                }
            </Typography>

            <Collapse style={{ marginTop: 25 }} in={isBlockedCompany}>
                <Alert
                    severity="error"
                    sx={{ mb: 2 }}
                >
                    Der gewählte Kunde wurde gesperrt!
                </Alert>
            </Collapse>

            <Collapse style={{ marginTop: 25 }} in={!isCreditCheckedCompany}>
                <Alert
                    severity="warning"
                    sx={{ mb: 2 }}
                >
                    Für den gewählten Kunden liegt keine Bonitätsprüfung vor!
                </Alert>
            </Collapse>


            <Box sx={{ mt: 3 }}></Box>
            <GridContainer>
                <GridItem sm={12}>
                    <TextField
                        select
                        disabled={isEmpty}
                        label="Zeitraum"
                        value={String(idRentTimeFrame)}
                        error={!vIdRentTimeFrame && !isEmpty}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIdRentTimeFrame(Number(event.target.value))}
                        variant="standard"
                        fullWidth
                    >
                        {rentTimeFrameArray.map(x =>
                            <MenuItem value={x.idRentTimeFrame}>
                                {x.Description}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                    <GridItem sm={6}>
                        <DesktopDatePicker
                            label="Von"
                            value={(fromDateTime === undefined) ? null : fromDateTime}
                            onChange={(newValue: Date | null) => setFromDateTime(newValue)}
                            //minutesStep={15}
                            //maxDate={toDateTime}
                            disabled={idProduct === undefined}
                            renderInput={(params) => <TextField margin="normal" variant="standard" {...params} error={idRentType === 3 && fromDateTime === null} disabled={isEmpty} fullWidth />}
                        />
                    </GridItem>

                    <GridItem sm={6}>
                        <DesktopDatePicker
                            label="Bis"
                            value={(toDateTime === undefined) ? null : toDateTime}
                            onChange={(newValue: Date | null) => setToDateTime(newValue)}
                            //minutesStep={15}
                            //minDate={fromDateTime}
                            disabled={fromDateTime === undefined}
                            renderInput={(params) => <TextField margin="normal" variant="standard" {...params} error={idRentType === 3 && fromDateTime === null && isToTimeEditable} disabled={isEmpty || !isToTimeEditable} fullWidth />}
                        />
                    </GridItem>


                    <GridItem sm={6}>
                        <DesktopDatePicker
                            label="Übergabedatum"
                            value={(handingOverDate === undefined) ? null : handingOverDate}
                            onChange={(newValue: Date | null) => (newValue !== null) && setHandingOverDate(newValue)}
                            //minutesStep={15}
                            //maxDate={toDateTime}
                            disabled={idProduct === undefined}
                            renderInput={(params) => <TextField margin="normal" variant="standard" {...params} disabled={isEmpty} fullWidth />}
                        />
                    </GridItem>

                    <GridItem sm={6}>
                        <DesktopDatePicker
                            label="Kündigungsdatum"
                            value={(teminatedAt === undefined) ? null : teminatedAt}
                            onChange={(newValue: Date | null) => (newValue !== null) && setTeminatedAt(newValue)}
                            //minutesStep={15}
                            //minDate={fromDateTime}
                            disabled={idProduct === undefined}
                            renderInput={(params) => <TextField margin="normal" variant="standard" {...params} disabled={isEmpty} fullWidth />}
                        />
                    </GridItem>
                </LocalizationProvider>

                <GridItem sm={12}></GridItem>
                <GridItem sm={12}></GridItem>

                {(!isNew && !isEmpty && rentObject != undefined) &&
                    (rentObject != undefined && rentObject.idRentType == 1)
                    ?
                    <>
                        <GridItem sm={12}>
                            <InputField label="Reservierungsnummer" value={rentObject?.RequestNumber} />
                        </GridItem>
                    </>
                    : (rentObject != undefined && rentObject?.RequestNumber != undefined)
                        ?
                        <>
                            <GridItem sm={4}>
                                <InputField label="Reservierungsnummer" value={rentObject?.RequestNumber} />
                            </GridItem>
                            <GridItem sm={4}>
                                <InputField label="Buchungsnummer" value={rentNumber} />
                            </GridItem>
                            <GridItem sm={4}>
                                <InputField label="Systemnummer" value={idRent} />
                            </GridItem>
                        </>
                        :
                        <>
                            <GridItem sm={6}>
                                <InputField label="Buchungsnummer" value={rentNumber} />
                            </GridItem>
                            <GridItem sm={6}>
                                <InputField label="Systemnummer" value={idRent} />
                            </GridItem>
                        </>
                }



                <GridItem sm={(idRentType === 1) ? 12 : 6}>
                    <TextField
                        select
                        disabled={isEmpty}
                        label="Art"
                        value={String(idRentType)}
                        error={!vIdRentType && !isEmpty}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIdRentType(Number(event.target.value))}
                        variant="standard"
                        fullWidth
                    >
                        {rentTypeArray.map(x =>
                            <MenuItem value={x.idRentType} disabled={(rentObject == undefined) ? false : (Number(x.idRentType) < rentObject.idRentType)}>
                                {x.Type}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>

                {(idRentType !== 1) &&
                    <GridItem sm={6}>
                        <TextField
                            select
                            disabled={isEmpty}
                            label="Status"
                            value={String(idRentState)}
                            error={!vIdRentState && !isEmpty}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIdRentState(Number(event.target.value))}
                            variant="standard"
                            fullWidth
                        >
                            {rentStateArray.filter(x => x.idRentType === idRentType).map(x =>
                                <MenuItem value={x.idRentState} disabled={(rentObject == undefined) ? false : (Number(x.idRentState) < rentObject.idRentState && rentObject.idRentType === Number(idRentType))}>
                                    {x.State}
                                </MenuItem>
                            )}
                        </TextField>
                    </GridItem>
                }



                <GridItem sm={12}>
                    <TextField
                        select
                        disabled={isEmpty}
                        label="Produkt"
                        value={String(idProduct)}
                        error={!vIdProduct && !isEmpty}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIdProduct(Number(event.target.value))}
                        variant="standard"
                        fullWidth
                    >
                        {productArray.map(x =>
                            <MenuItem value={x.idProduct}>
                                {x.Title}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>


                <GridItem sm={12}>
                    <TextField
                        select
                        disabled={isEmpty || rentObject?.idRentType === 3}
                        label="Standort"
                        value={String(idLocation)}
                        error={idLocation == null && !isEmpty}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIdLocation(Number(event.target.value))}
                        variant="standard"
                        fullWidth
                    >
                        {locationArray.map(x =>
                            <MenuItem value={x.idLocation}>
                                {x.ShortName}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>

                <GridItem sm={12}>
                    <TextField
                        select
                        disabled={isEmpty || rentObject?.idRentType === 3}
                        label="Raum"
                        value={String(idRoom)}
                        error={idRoom == null && !isEmpty}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIdRoom(Number(event.target.value))}
                        variant="standard"
                        fullWidth
                    >
                        {roomArray.filter(x => x.idLocation === idLocation).map(x => {
                            const now = new Date()
                            //console.log(now);
                            //console.log(now.getDate());
                            let rent: IRent[] | null = rentArray
                                .filter(y => y.idRoom === x.idRoom)
                                .filter(y => y.idRoom === x.idRoom)
                                .filter(y => y.idRentState < 15)
                                .filter(y => {
                                    let testFromDate = now;
                                    let testToDate = now;
                                    (fromDateTime !== null) && (testFromDate = fromDateTime);
                                    (toDateTime !== null) && (testToDate = toDateTime);

                                    if (y.FromDate == undefined || y.ToDate == undefined) {
                                        return true;
                                    } else {
                                        return (new Date(y.FromDate) <= testToDate && new Date(y.ToDate) >= testFromDate)
                                    }

                                });
                            return (
                                <MenuItem value={x.idRoom} disabled={rent != undefined && rent.length > 0 || x.isBlocked == true}>
                                    {(x.Title == undefined) ? x.RoomNumber : x.Title} {(x.isBlocked == true) && <>(Blockiert)</>}
                                </MenuItem>
                            )
                        }
                        )}
                    </TextField>
                </GridItem>


                <GridItem sm={3} xs={6}>
                    <InputField
                        type='number'
                        isEdit={!isEmpty}
                        label='Monate'
                        value={units}
                        setFunction={setUnits}
                        isValidated={(isEmpty) ? undefined : vUnits}

                    />
                </GridItem>

                <GridItem sm={3} xs={6}>
                    <InputField
                        type='number'
                        isEdit={!isEmpty}
                        label='Preis pro Monat'
                        value={pricePerUnit}
                        setFunction={setPricePerUnit}
                        isValidated={(isEmpty) ? undefined : vPricePerUnit}

                    />
                </GridItem>


                <GridItem sm={3} xs={6}>
                    <InputField
                        isEdit={!isEmpty}
                        label='Total'
                        value={(Number(units) * Number(pricePerUnit)).toFixed(2).replace(".", ",") + " €"}
                    />
                </GridItem>


                <GridItem sm={3} xs={6}>
                    <InputField
                        type='number'
                        isEdit={!isEmpty}
                        label='Kaution'
                        value={deposit}
                        setFunction={setDeposit}
                    />
                </GridItem>

                <GridItem sm={12}>
                    <InputField
                        isEdit={!isEmpty}
                        label='Sonderregelungen für den Mietvertrag (LaTeX-Code)'
                        value={specialArrangement}
                        setFunction={setSpecialArrangement}
                        rows={5}
                    />
                </GridItem>


                <GridItem sm={12}>
                    <InputField
                        isEdit={!isEmpty}
                        label='Kundenbemerkung'
                        value={customerMessage}
                        setFunction={setCustomerMessage}
                        rows={5}
                    />
                </GridItem>

            </GridContainer>


            {(props.idPerson === undefined) &&
                <>
                    <SelectCustomer
                        isNew={isNew}
                        idPerson={idPerson}
                        setIdPerson={setIdPerson}
                        saveCounter={saveCounter}
                        // Rent state
                        idRentState={idRentState}
                        // Um im Parent zu visualisieren
                        setIsBlockedCompany={setIsBlockedCompany}
                        setIsCreditCheckedCompany={setIsCreditCheckedCompany}
                        // Für die Childvalidierung
                        setMetadataPerson={setMetadataPerson}
                    />
                </>
            }

        </>
    )
}
export default Rent;

