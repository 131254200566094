import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../redux/combine_reducers';
import GenericOverview from '../core/GenericOverview';
import Region from './Region';


const Regions:React.FC = () => {
    const dispatch = useDispatch();
    const regionArray = useSelector((state: State) => state.regionArray.storeRegions);
    // Selected Id
    const [selectedIdRegion,setSelectedIdRegion] = useState<number|undefined>();

    const columns: GridColDef[] = [
        { 
            field: 'idRegion', 
            headerName: 'Nr.', 
            width: 90,
        },
        /*
        {
            field: 'ShortName',
            headerName: 'Kurzbezeichnung',
            width: 150,
        },
        */
        {
            field: 'Region',
            headerName: 'Region',
            flex: 1,
        },

    ];


    return(
        <GenericOverview
            headline="Regionen"
            columns={columns}
            data={regionArray}
            idField="idRegion"
            setTargetId={setSelectedIdRegion}
        >
            <Region idRegion={selectedIdRegion}/>
        </GenericOverview>
    )

}
export default Regions;