import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Chip, IconButton, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFetch } from "../../hooks/useFetch";
import { IPerson } from "../../interfaces/IPerson";
import { State } from "../../redux/combine_reducers";
import GridContainer from "../core/GridContainer";
import GridItem from "../core/GridItem";
import WaitingSequence from "../core/WaitingSequence"
import { IStripePaymentIndent } from "../../interfaces/IStripePaymentIndent";
import { DataGrid, GridColDef, GridRenderCellParams, deDE } from "@mui/x-data-grid";



const StripeOverview: React.FC = () => {
    const [paymentIntendArray, setPaymentIntendArray , wasSuccessfullyPaymentIntendArray] = useFetch<IStripePaymentIndent[]>("/rent/paymentWarnings",undefined,true)
    
    const tsxCurrentCreate = (params: GridRenderCellParams) : React.ReactNode => {
        let currentDate = new Date();
        let created = new Date(Number(params.row.created)*1000);
        let diff = Math.abs(currentDate.getTime() - created.getTime());
        let diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 

        let color:"warning" | "error"  = "warning";

        if (diffDays > 7) {
            color = "error";
        }

        return(
            <>
                <Chip label={created.toLocaleDateString()} color={color} variant="outlined" />
            </>
        )
    }


    const tsxLinks = (params: GridRenderCellParams) : React.ReactNode => {

        if (params.row.invoice_id != undefined) {
            return(
                <> 
                    <Link target="_blank" rel="noopener" href={`https://dashboard.stripe.com/invoices/${params.row.invoice_id}`}>Rechnung</Link>
                </>
            )
        } else {
            return(
                <> 
                    <Link target="_blank" rel="noopener" href={`https://dashboard.stripe.com/test/payments/${params.row.id}`}>Zahlung</Link>
                </>
            )
        }
    }

    
    const columns: GridColDef[] = [
        { 
            field: 'created', 
            headerName: 'Erstellt', 
            width: 200,
            renderCell: tsxCurrentCreate
        },
        /*
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 200,
        },
        */
        { 
            field: 'name', 
            headerName: 'Kunde', 
            width: 250,
        },

        { 
            field: 'email', 
            headerName: 'Email', 
            width: 259,
        },

        { 
            field: 'description_price', 
            headerName: 'Preisbeschreibung', 
            flex: 1
        },

        {
            field: 'links',
            headerName: "Links",
            width: 150,
            renderCell: tsxLinks
        }
    ];


    if (!wasSuccessfullyPaymentIntendArray) { return <>Fehler!</> }
    else if (paymentIntendArray == undefined) { return <WaitingSequence /> }
    else {
        return(
            <>
                <DataGrid
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    rows={paymentIntendArray}
                    columns={columns}
                    autoHeight={true}
                    getRowId={(row) => row["id"]}
                />
            </>
        )
    }
}
export default StripeOverview;