import React, { useEffect, useState } from "react";
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IOfferFullObject } from "../../interfaces/IOffer";
import GenericOverview from "../core/GenericOverview";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import OfferEdit from "../Offer/OfferEdit";
import { staticOfferState } from "../Offer/staticOfferState";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/combine_reducers";


interface IProps {
    offerArray: IOfferFullObject[];
    setOfferArray: Function;
}


const prepareDate = (dateAsString:string,withTime?:boolean) => {
    if (dateAsString == undefined){ 
        return "-"; 
    }
    else {
        let  currentDate = new Date(dateAsString);
        let dd = currentDate.getDate();
        let mm = currentDate.getMonth() +1;
        let yyyy = currentDate.getFullYear();
        let hh = currentDate.getUTCHours();
        let min = currentDate.getMinutes();


        
        if (withTime == undefined || withTime === false) {
            return `${(dd > 9 ? '' : '0') + dd}.${(mm > 9 ? '' : '0') + mm}.${yyyy}`
        } else {
            return currentDate.toLocaleString();
            //return `${(dd > 9 ? '' : '0') + dd}.${(mm > 9 ? '' : '0') + mm}.${yyyy}, ${(hh > 9 ? '' : '0') + hh}:${(min > 9 ? '' : '0') + min}`
        }
        
    }
}


const resetOffer = (arrayLength:number[]) => {
    let newId = Math.min(...arrayLength);

    if (newId > 0) {
        newId = -1;
    }

    return {
        idOffer: newId-1,
        idUser: null,
        idOfferState: 1,
        idLocation: 1,
        OfferHasRoomArray: [],
        idPerson: -1,
        Created_at: new Date().toISOString(),
        Expire_at: new Date(new Date().getTime()+(2*24*60*60*1000)).toISOString(),

    } as IOfferFullObject
}




const CustomerOfferOverview:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const locationArray = useSelector((state: State) => state.locationArray.storeLocation);
    //
    const [currentObject, setCurrentObject] = useState<IOfferFullObject>(resetOffer(props.offerArray.map(x => Number(x.idOffer))));
    const [isNewDialog,setIsNewDialog] = useState(false);

    const offerStateArray = staticOfferState;

   
    useEffect(() => {
        if (props.offerArray != undefined && currentObject != undefined && props.offerArray.find(x => x.idOffer === currentObject.idOffer) != undefined) {
            props.setOfferArray([...props.offerArray.map(x => x.idOffer === currentObject.idOffer ? currentObject : x)]);
        }
    },[currentObject])
    


    const columnsPerson: GridColDef[] = [     
        { 
            field: 'idLocation', 
            headerName: 'Standort', 
            valueGetter: (params: GridValueGetterParams) => locationArray.find(x => x.idLocation == params.row.idLocation)?.ShortName,
            width: 150,
        },
        { 
            field: 'Created_at', 
            headerName: 'Erstellt', 
            width: 150,
            valueGetter: (params: GridValueGetterParams) => new Date(params.row.Created_at).toLocaleDateString()
        },
        { 
            field: 'Expire_at', 
            headerName: 'Gültig bis', 
            width: 150,
            valueGetter: (params: GridValueGetterParams) => new Date(params.row.Expire_at).toLocaleDateString()
        },
        { 
            field: 'OfferHasRoomArray', 
            headerName: 'Räume', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => params.row.OfferHasRoomArray.length
        },
        { 
            field: 'idOfferState', 
            headerName: 'Status', 
            valueGetter: (params: GridValueGetterParams) => offerStateArray.find(x => x.idOfferState == params.row.idOfferState)?.OfferState,
            flex: 1
        },
    ]

    /*
    return(
        <>
        {JSON.stringify(props.offerArray)}
        </>
    )
    */
    


    return(
        <>
            <Dialog onClose={() => setIsNewDialog(false)} open={isNewDialog} maxWidth="lg">
                <DialogTitle>
                    Neues Angebot
                </DialogTitle>
                <DialogContent>
                    <OfferEdit currentObject={currentObject} setCurrentObject={setCurrentObject} />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={() => setIsNewDialog(false)}>Abbruch</Button>
                    <Button variant="contained" color="success" onClick={() => {
                        props.setOfferArray([...props.offerArray,currentObject])
                        setIsNewDialog(false);
                    }}>Speichern</Button>
                </DialogActions>
            </Dialog>


            <Typography variant='h6'>
                Angebote
                <Button 
                    variant='outlined' 
                    color='success' 
                    sx={{float: 'right'}}
                    onClick={() => {
                        setCurrentObject( resetOffer( props.offerArray.map(x => Number(x.idOffer)) ));
                        setIsNewDialog(true);
                        /*
                        let testObject = resetOffer();
                        setCurrentObject(testObject);
                        props.setOfferArray([...props.offerArray, testObject])
                        */
                    }}
                >Neues Angebot</Button>
            </Typography>

            <GenericOverview
                columns={columnsPerson}
                data={props.offerArray}
                idField="idOffer"
                setTargetId={(idOffer:number|string) => {
                        let tmpObject = props.offerArray.find(x => x.idOffer === Number(idOffer));
                        (tmpObject == undefined) ? setCurrentObject(resetOffer(props.offerArray.map(x => Number(x.idOffer)))) : setCurrentObject(tmpObject);
                    }
                }
                colDataGrid={12}
                enableAddButton={false}
                autoHeight={true}
            />


            { (props.offerArray.find(x => x.idOffer === currentObject.idOffer) != undefined) &&
                <OfferEdit currentObject={currentObject} setCurrentObject={setCurrentObject} />
            }
        </>
    )
}
export default CustomerOfferOverview;