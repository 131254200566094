import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProduct } from "../interfaces/IProduct";

const initialState =  { storeProducts: [] as IProduct[] }; 

const sliceProduct = createSlice({
    name: "PRODUCTARRAY",
    initialState,
    reducers: {
        setProductArray: (state, action: PayloadAction<IProduct[]>) => {
            state.storeProducts = [...action.payload];
        },
        addUpdateProductArray: (state, action: PayloadAction<IProduct>) => {
            let testObject = state.storeProducts.find(x => x.idProduct === action.payload.idProduct);
            
            if (testObject) {
                state.storeProducts = [...state.storeProducts.map(x => x.idProduct === action.payload.idProduct ? action.payload : x)];
            } else {
                state.storeProducts = [action.payload, ...state.storeProducts];
            }
        },
    }
})
export const { setProductArray, addUpdateProductArray } = sliceProduct.actions;
export default sliceProduct.reducer;