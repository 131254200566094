import React, { useEffect, useState } from "react";
import { IRent } from "../../interfaces/IRent";
import { IInvoiceStatus } from "../../interfaces/Additional/IInvoiceStatus";
import { Box, Chip, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { getFetch } from "../../hooks/useFetch";
import { Add, FindInPage, Link } from "@mui/icons-material";


interface IProps {
    rentObject: IRent;
}


const RentInvoiceStatus:React.FC<IProps> = (props) => {
    const [invoiceObject,setInvoiceObject] = useState<IInvoiceStatus[] | null>(null);
    const [isLoading,setIsLoading] = useState(false);
    const [isLoadingSave,setIsLoadingSave] = useState(false);

    const wrapperInvoiceObject = (localInvoiceObject:undefined|null) => {
        if (localInvoiceObject == undefined) {
            setInvoiceObject(null);
        } else {
            setInvoiceObject(localInvoiceObject)
        }
        setIsLoading(false);
    }


    useEffect(() => {
        if (false && props.rentObject.StripeId_Deposite === null && props.rentObject.StripeId_Deposite === null) {
            setIsLoading(false);
            setInvoiceObject(null);
        } else if (props.rentObject.idRent != undefined && props.rentObject.idRent > 0)  {
            setIsLoading(true);
            getFetch("/rent/invoiceStatus/",props.rentObject.idRent,wrapperInvoiceObject)
        }
    },[props.rentObject.idRent])


    const genStateChip = (status:string) => {
        // if: für mieten
        if (status === "draft") {
            return <Chip size="small" variant="outlined" label="Entwurf"/>
        }
        else if (status === "open") {
            return <Chip size="small" color="primary" variant="outlined" label="Offen"/>
        }
        else if (status === "paid") {
            return <Chip size="small" color="success" variant="outlined" label="Gezahlt"/>
        }
        else if (status === "uncollectible") {
            return <Chip size="small" color="error" variant="outlined" label="Fehlerhaft"/>
        }
        // if: subscription
        else if (status === "not_created") {
            if (new Date(String(props.rentObject.FromDate)).getTime() <= new Date().getTime()) {
                return <Chip size="small" color="error" variant="outlined" label="Nicht Erstellt"/>
            } else {
                return <Chip size="small" variant="outlined" label="Nicht Erstellt"/>
            }
        }
        else if (status === "not_started") {
            return <Chip size="small" color="primary" variant="outlined" label="Nicht gestartet"/>
        }
        else if (status === "active") {
            return <Chip size="small" color="primary" variant="outlined" label="Läuft"/>
        }
        else if (status === "completed") {
            return <Chip size="small" variant="outlined" label="Beendet"/>
        }
        else if (status === "error") {
            return <Chip size="small" variant="filled" color="error" label="Fehler"/>
        }



        else {
            return <Chip size="small" color="warning" variant="outlined" label={`Unbekannter Status: ${status}`}/>
        }
    }


    const numberToPrice = (price:number|null) => {
        if (price === null) {
            return "-"
        } else {
            return price.toFixed(2).replace(".",",") + " €"
        }
    }

    const wrapperSave = (localInvoiceObject : IInvoiceStatus[]) => {
        setIsLoadingSave(false);
        setInvoiceObject(localInvoiceObject);
    }


    const createRentSubscriptionSchedule = () => {
        setIsLoadingSave(true);
        getFetch(`/rent/createRentSubscriptionSchedule/`,props.rentObject.idRent,wrapperSave)
    } 

    const genTableBody = () => {
        if (isLoading) {
            return(
                <>
                    <TableRow><TableCell colSpan={4}>Bitte warten...</TableCell></TableRow>
                </>
            )
        }
        else if (invoiceObject === null || invoiceObject.length === 0) {
            return(
                <>
                    <TableRow><TableCell colSpan={4}>Keine Stripe-Einträge vorhanden</TableCell></TableRow>
                </>
            )
        }
        else {
            return(
                <>
                    <Dialog
                        open={isLoadingSave}
                    >
                        <DialogTitle>Abbonement wird erstellt</DialogTitle>
                        <DialogContent>
                            <LinearProgress/>
                        </DialogContent>
                    </Dialog>

                    {invoiceObject.map(x => 
                        <TableRow>
                            <TableCell>{x.Title}</TableCell>
                            <TableCell>{genStateChip(x.Status)}</TableCell>
                            <TableCell>{numberToPrice(x.Total)}</TableCell>
                            <TableCell> 
                                {(
                                    x.Status === "not_created" 
                                    && new Date(String(props.rentObject.FromDate)).getTime() <= new Date().getTime()
                                    && invoiceObject.filter(x => x.Type === "Invoice").every(x => x.Status === "paid")
                                ) &&
                                    <IconButton
                                        onClick={createRentSubscriptionSchedule}
                                    ><Add /> </IconButton>
                                }
                                {(x.Link !== null) &&
                                    <IconButton
                                        onClick={() =>{
                                            window.open(String(x.Link), "_blank")
                                        }}
                                    ><Link /> </IconButton>
                                }
                                {(x.PDF !== null) &&
                                    <IconButton
                                        onClick={() =>{
                                            window.open(String(x.PDF), "_blank")
                                        }}
                                    ><FindInPage /> </IconButton>
                                }
                            </TableCell>
                        </TableRow>
                    )}
                </>
            )
        }
    }

    /*
    const genTableBody = () => {
        if (isLoading) {
            return(
                <>
                    <TableRow><TableCell colSpan={4}>Bitte warten...</TableCell></TableRow>
                    <TableRow><TableCell colSpan={4}>Bitte warten...</TableCell></TableRow>
                </>
            )
        }
        else if (invoiceObject === null) {
            return(
                <>
                    <TableRow><TableCell>Kaution</TableCell><TableCell colSpan={3}>k.A.</TableCell></TableRow>
                    <TableRow><TableCell>Erstmiete</TableCell><TableCell colSpan={3}>k.A.</TableCell></TableRow>
                </>
            )
        }
        else{
            return(
                <>
                    <TableRow>
                        <TableCell>Kaution</TableCell>
                        <TableCell>{genStateChip(invoiceObject.Status_Deposite)}</TableCell>
                        <TableCell>{numberToPrice(invoiceObject.Total_Deposite)}</TableCell>
                        <TableCell><Link href={invoiceObject.PDF_Deposite}>Link</Link></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Erstmiete</TableCell>
                        <TableCell>{genStateChip(invoiceObject.Status_FirstMonth)}</TableCell>
                        <TableCell>{numberToPrice(invoiceObject.Total_FirstMonth)}</TableCell>
                        <TableCell><Link href={invoiceObject.PDF_FirstMonth}>Link</Link></TableCell>
                    </TableRow>
                </>
            )
        }
    }
    */


    return(
        <Box>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Rechnung</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Total (Netto)</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {genTableBody()}
                </TableBody>
            </Table>
        </Box>
    )
}
export default RentInvoiceStatus;