import { Check, Close } from '@mui/icons-material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../redux/combine_reducers';
import GenericOverview from '../../core/GenericOverview';
import Campaign from './Campaign';

const Campaigns:React.FC = () => {
    const dispatch = useDispatch();
    const campaignArray = useSelector((state: State) => state.campaignArray.storeCampaign);
    // Selected Id
    const [selectedId,setSelectedId] = useState<number|undefined>();
    //(units*pricePerUnit).toFixed(2).replace(".",",") + " €" 
    const columns: GridColDef[] = [
        { 
            field: 'idCampaign', 
            headerName: 'Nr.', 
            width: 90,
        },
        {
            field: 'PricePerDay',
            headerName: 'Preis Pro Tag',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => (Number(params.row.PricePerDay)).toFixed(2).replace(".",",") + " €" 

        },
        {
            field: 'Campaign',
            headerName: 'Kampagne',
            flex: 1
        }

    ];

    return(
        <GenericOverview
            headline="Marketing utm_campaign"
            columns={columns}
            data={campaignArray}
            idField="idCampaign"
            setTargetId={setSelectedId}
        >
            <Campaign idCampaign={selectedId}/>
        </GenericOverview>
    )

}
export default Campaigns;