import React, { useState } from "react";
import { IInvoicePositionChoice } from "../../interfaces/IInvoicePositionChoice";
import { IInvoicePositionTemplate } from "../../interfaces/IInvoicePositionTemplate";
import GridContainer from "../core/GridContainer";
import GridItem from "../core/GridItem";
import { Box, Button, DialogActions, DialogContent, MenuItem, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/combine_reducers";


interface IProps {
    parentObject: IInvoicePositionTemplate;
    idInvoicePositionChoice: number;
    setIdInvoicePositionChoice: Function;
    invoicePositionChoiceArray: IInvoicePositionChoice[];
    setInvoicePositionChoiceArray: Function;
}


const getEmptyInvoicePositionChoice = (
    parentObject: IInvoicePositionTemplate,
    invoicePositionChoiceArray: IInvoicePositionChoice[]
) => {
    let minId = -1;
    let testArray = invoicePositionChoiceArray.filter(x => x.idInvoicePositionTemplate === parentObject.idInvoicePositionTemplate);

    if (testArray.length > 0) {
        let minIdArray = Math.min(...testArray.map(x => x.idInvoicePositionChoice));

        if (minIdArray <= minId) {
            minId = minIdArray-1;
        }
    }

    return {
        idInvoicePositionChoice: minId,
        idInvoicePositionTemplate: parentObject.idInvoicePositionTemplate,
        idUnit: 1,
        InvoicePositionChoice: "",
        PricePerUnit: 0
    } as IInvoicePositionChoice;
}


const getCurrentObject = (
    idInvoicePositionChoice: number,
    parentObject: IInvoicePositionTemplate,
    invoicePositionChoiceArray: IInvoicePositionChoice[]
) => {

    let testObject = invoicePositionChoiceArray.find(x => x.idInvoicePositionChoice === idInvoicePositionChoice);

    if (testObject === undefined) {
        return getEmptyInvoicePositionChoice(parentObject, invoicePositionChoiceArray);
    } else {
        return testObject;
    }
}


const InovicePositionChoiceEdit:React.FC<IProps> = (props)  => {
    const [currentObject, setCurrentObject] = useState(getCurrentObject(
        props.idInvoicePositionChoice,
        props.parentObject,
        props.invoicePositionChoiceArray
    ));
    const dispatch = useDispatch();
    const unitArray = useSelector((state: State) => state.unitsArray.storeUnits);

    const handleSave = () => {
        let testObject = props.invoicePositionChoiceArray.find(x => x.idInvoicePositionChoice === props.idInvoicePositionChoice);
        let updateObject = {...currentObject, Unit: unitArray.find(x => x.idUnit === currentObject.idUnit)?.Unit}

        if (testObject === undefined) {
            props.setInvoicePositionChoiceArray([
                ...props.invoicePositionChoiceArray,
                updateObject
            ]);
        } else {
            props.setInvoicePositionChoiceArray([
                ...props.invoicePositionChoiceArray.map(x => x.idInvoicePositionChoice === currentObject.idInvoicePositionChoice ? updateObject : x)
            ]);
        }

        props.setIdInvoicePositionChoice(null);
    }


    return(
        <>
            <DialogContent>
                <Box sx={{mt: 3}}/>
                <GridContainer>
                    <GridItem sm={12}>
                        <TextField 
                            label="Bezeichnung"
                            value={currentObject.InvoicePositionChoice}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCurrentObject({...currentObject, InvoicePositionChoice: event.target.value})}
                            error={currentObject.InvoicePositionChoice === ""}
                            size="small"
                            fullWidth
                        />
                    </GridItem>
                    <GridItem sm={12}>
                        <TextField 
                            label="Einheit"
                            value={currentObject.idUnit}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCurrentObject({...currentObject, idUnit: Number(event.target.value)})}
                            size="small"
                            fullWidth
                            select
                        >
                            {unitArray.map(x =>
                                <MenuItem key={`idUnit-${x.idUnit}`} value={x.idUnit}>{x.Unit}</MenuItem>
                            )}
                        </TextField>
                    </GridItem>
                    <GridItem sm={12}>
                        <TextField 
                            label="Preis pro Einheit"
                            type="number"
                            value={currentObject.PricePerUnit}
                            error={currentObject.PricePerUnit <= 0}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCurrentObject({...currentObject, PricePerUnit: Number(event.target.value)})}
                            size="small"
                            fullWidth
                        />
                    </GridItem>
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setIdInvoicePositionChoice(null)}>Abbruch</Button>
                <Button 
                    onClick={handleSave}
                    disabled={currentObject.PricePerUnit <= 0 || currentObject.InvoicePositionChoice === ""}
                >Übernehmen</Button>
            </DialogActions>
        </>
    )

}
export default InovicePositionChoiceEdit;