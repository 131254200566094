import { IOfferState } from "../../interfaces/IOfferState";

export const staticOfferState:IOfferState[] = [
    {
        idOfferState: 1,
        OfferState: "Offen"
    },
    {
        idOfferState: 5,
        OfferState: "Angenommen"
    },
    {
        idOfferState: 10,
        OfferState: "Abgelehnt"
    }
];