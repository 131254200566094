import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFetch } from "../../hooks/useFetch";
import { IPerson } from "../../interfaces/IPerson";
import { State } from "../../redux/combine_reducers";
import GridContainer from "../core/GridContainer";
import GridItem from "../core/GridItem";
import WaitingSequence from "../core/WaitingSequence";


const initStartDate = () => {
    let now = new Date();
    return new Date(now.setMonth(now.getMonth()-3))
}

const monthNames = ["Januar", "Februar", "März", "April", "Mail", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember" ];

interface IMontHelper {
    Date: Date;
    Text: string;
}


const SourceStatistic:React.FC = () => {
    const dispatch = useDispatch();
    const sourceArray = useSelector((state: State) => state.sourceArray.storeSources);

    const [startDate,setStartDate] = useState<Date>(initStartDate());
    const [dateHelper,setDateHelper] = useState<IMontHelper[]>([]);
    const [personArray,setPersonArray,wasSuccessfullyLoad] = useFetch<IPerson[]>("person");
    const [total,setTotal] = useState(0);



    useEffect(() => {
        let currentDate = new Date(startDate);
        let i = 0;
        let localDateHelper:IMontHelper[] = [];

        while (i < 6) {
            localDateHelper.push({
                Date: new Date(currentDate),
                Text: monthNames[currentDate.getMonth()]
            } as IMontHelper)
            currentDate.setMonth(currentDate.getMonth()+1);
            i++;
        }

        setDateHelper([...localDateHelper]);
    },[startDate])



    if (!wasSuccessfullyLoad) { return <>Fehler!</>}
    else if (personArray == undefined) { return <WaitingSequence /> }
    else {
        return(
            <>

                <GridContainer>
                    <GridItem sm={4}>
                        <IconButton onClick={() => setStartDate(new Date(startDate.setMonth(startDate.getMonth()-1)))}><ArrowLeft/></IconButton>
                    </GridItem>
                    <GridItem sm={4}>
                        <Typography align="center">{startDate.getFullYear()}</Typography>
                    </GridItem>
                    <GridItem sm={4}>
                        <IconButton sx={{float: "right"}} onClick={() => setStartDate(new Date(startDate.setMonth(startDate.getMonth()+1)))}><ArrowRight/></IconButton>
                    </GridItem>
                </GridContainer>
    
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>utm_source</b></TableCell>
                            {dateHelper.map(x =>
                                <TableCell><b>{x.Text}</b></TableCell>
                            )}
                            <TableCell><b>Gesamtergebnis</b></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sourceArray.map(x =>
                            <TableRow>
                                <TableCell>{x.Source}</TableCell>
                                {dateHelper.map(y =>
                                    <TableCell>
                                        {
                                            personArray
                                                .filter(z => z.idSource === x.idSource)
                                                .filter(z => {
                                                    if (z.Lead_Created_at == undefined) {
                                                        return false
                                                    } else {
                                                        let leadDate = new Date(z.Lead_Created_at);
                                                        return (
                                                            leadDate.getMonth() == y.Date.getMonth()
                                                            && leadDate.getFullYear() == y.Date.getFullYear()
                                                        )

                                                    }
                                                })
                                                .length
                                        }
                                    </TableCell>
                                )}
                                <TableCell>
                                    {dateHelper
                                        .map(y =>
                                            personArray
                                                .filter(z => z.idSource === x.idSource)
                                                .filter(z => {
                                                    if (z.Lead_Created_at == undefined) {
                                                        return false
                                                    } else {
                                                        let leadDate = new Date(z.Lead_Created_at);
                                                        return (
                                                            leadDate.getMonth() == y.Date.getMonth()
                                                            && leadDate.getFullYear() == y.Date.getFullYear()
                                                        )

                                                    }
                                                })
                                                .length
                                        )
                                        .reduce((a,b) => a+b,0)
                                    }
                                </TableCell>
                            </TableRow>
                        )}

                        <TableRow>
                            <TableCell><b>Gesamtergebnis</b></TableCell>
                            {dateHelper.map(y =>
                                <TableCell>
                                    {
                                        personArray
                                            .filter(z => z.idSource != undefined)
                                            .filter(z => {
                                                if (z.Lead_Created_at == undefined) {
                                                    return false
                                                } else {
                                                    let leadDate = new Date(z.Lead_Created_at);
                                                    return (
                                                        leadDate.getMonth() == y.Date.getMonth()
                                                        && leadDate.getFullYear() == y.Date.getFullYear()
                                                    )

                                                }
                                            })
                                            .length
                                    }
                                </TableCell>
                            )}
                            <TableCell>
                                {dateHelper
                                    .map(y =>
                                        personArray
                                            .filter(z => z.idSource != undefined)
                                            .filter(z => {
                                                if (z.Lead_Created_at == undefined) {
                                                    return false
                                                } else {
                                                    let leadDate = new Date(z.Lead_Created_at);
                                                    return (
                                                        leadDate.getMonth() == y.Date.getMonth()
                                                        && leadDate.getFullYear() == y.Date.getFullYear()
                                                    )

                                                }
                                            })
                                            .length
                                    )
                                    .reduce((a,b) => a+b,0)
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
    
            </>
        )
    }

}
export default SourceStatistic;