import { Alert, Button, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFetch } from '../../hooks/useFetch';
import { useValidatenText, useValidationNumber } from '../../hooks/useValidaten';
import { IChildMetadata } from '../../interfaces/IChildMetadata';
import { ILocation } from '../../interfaces/ILocation';
import { actionCreatorsLocation } from '../../redux/actionCreators';
import { State } from '../../redux/combine_reducers';
import Address from '../Address/Address';
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import InputField from '../core/InputField';

export interface IProps {
    idLocation?: number;
}


const Location:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const locationArray = useSelector((state: State) => state.locationArray.storeLocation);
    const regionArray = useSelector((state: State) => state.regionArray.storeRegions);
    const { addUpdateLocationArray } = bindActionCreators(actionCreatorsLocation,dispatch);
    // Hauptstate
    const [locationObject,setLocationObject] = useState<ILocation|undefined>();
    // Hauptvalues
    const [idLocation,setIdLocation] = useState<number|null>(null);
    const [idAddres,setIdAddres] = useState<number|null>(null);
    const [location,setLocation,vLocation] = useValidatenText();
    const [shortName,setShortName,vShortName] = useValidatenText();
    const [regionName,setRegionName,vRegionName] = useValidatenText();
    const [idRegion,setIdRegion,vRegion] = useValidationNumber();
    // VAlidungsstates
    const [isLocationValidated,setIsLocationValidated] = useState(false);
    const [isAddressValidated, setIsAddressValidated] = useState(false);
    const [isAllValidated,setIsAllValidated] = useState(false);
    // Metastates
    const [isEmpty,setIsEmpty] = useState(true);
    const [isNew,setIsNew] = useState(false);
    const [wasSavedLocation,setWasSavedLocation] = useState(false);
    const [wasSuccessfullySavedLocation,setWasSuccessfullySavedLocation] = useState(true);
    const [wasSavedAddress,setWasSavedAddress] = useState(false);
    const [wasSuccessfullySavedAddress,setWasSuccessfullySavedAddress] = useState(true);
    const [wasSavedAll,setWasSavedAll] = useState(false)
    // Childvalues
    const [saveCounter,setSaveCounter] = useState(0);
    const [resetSaveCounter,setResetSaveCounter] = useState(0);
    const [addressChildMetastate,setAddressChildMetastate] = useState<IChildMetadata | null>(null);


    useEffect(() => {
        if (props.idLocation === undefined) {
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            
            if (props.idLocation === -1) {
                setIdAddres(-1);
                setIsNew(true)
                resetValues();
            } else {
                setIsNew(false);
                setLocationObject(locationArray.find(x => x.idLocation === props.idLocation));
            }
        }
    },[props.idLocation])

    useEffect(() => { setValues(locationObject) },[locationObject])

    useEffect(() => {
        if (addressChildMetastate !== null) {
            setIsAddressValidated(addressChildMetastate.isValidated);
            setWasSavedAddress(addressChildMetastate.wasSaved);
            setWasSuccessfullySavedAddress(addressChildMetastate.wasSuccessfullySaved);
        }
    },[addressChildMetastate])


    useEffect(() => {
        setIsAllValidated(vLocation && vShortName && vRegionName && vRegion && isAddressValidated)
    },[vLocation,vShortName,vRegionName,vRegion,isAddressValidated])

    useEffect(() => {
        if (saveCounter >0 && shortName !== null && idRegion !== null && location !== null && idAddres !== null && regionName !== null && idAddres > 0) {
            let uploadObject:ILocation = {
                idAddress: idAddres,
                idRegion: idRegion,
                ShortName: shortName,
                Location: location,
                RegionName: regionName
            };
            (!isNew && idLocation != undefined) && (uploadObject.idLocation = idLocation);
            uploadFetch("location",isNew,uploadObject,setLocationObject,setWasSavedLocation);
            setWasSavedLocation(true);
        }
    },[saveCounter,idAddres])

    useEffect(() => { setWasSavedAll(wasSavedLocation && wasSavedLocation) },[wasSavedLocation,wasSavedLocation])


    const setValues = (localObject:ILocation | undefined) => {
        if (localObject !== undefined) {
            setIdLocation(Number(localObject.idLocation));
            setLocation(localObject.Location);
            setShortName(localObject.ShortName);
            setIdAddres(localObject.idAddress);
            setIdRegion(localObject.idRegion);
            setRegionName(localObject.RegionName)
            

            if (wasSavedLocation) {
                addUpdateLocationArray(localObject);
                (isNew) && setIsNew(false);
            }
        }
    }

    const resetValues = () => {
        setIdLocation(null);
        setLocation(null);
        setIdRegion(null);
        //setIdAddres(null);
        setShortName(null);
        setLocationObject(undefined);
    }


    return(
        <>
            <Snackbar 
                key="savebar" 
                open={wasSavedAll} 
                autoHideDuration={6000} 
                onClose={() => setWasSavedAll(false)}  
                anchorOrigin={{ 'vertical' : 'bottom', 'horizontal' : 'right' }} 
            >
                <Alert onClose={() => setWasSavedAll(false)} severity={(wasSuccessfullySavedAddress && setWasSuccessfullySavedAddress) ? "success" : "error"} sx={{ width: '100%' }}>
                    {(wasSuccessfullySavedAddress && setWasSuccessfullySavedAddress) ? "Erfolgreich gespeichert!" : "Es ist ein Fehler aufgetretten!"}
                </Alert>
            </Snackbar>

            <Typography variant='h5'>{(isEmpty) ? "Bitte Wählen" : (isNew) ? "Neuer Standort" : (locationObject === undefined ) ? "ERROR" : locationObject.Location}</Typography>

            <Box sx={{mt: 3}}></Box>
            <GridContainer>
                <GridItem sm={12}>
                    <InputField isEdit={!isEmpty} label="Standort" value={location} setFunction={setLocation} isValidated={isEmpty || vLocation} />
                </GridItem>

                <GridItem sm={12}>
                    <InputField isEdit={!isEmpty} label="Kurzbeschreibung" value={shortName} setFunction={setShortName} isValidated={isEmpty || vShortName} />
                </GridItem>

                <GridItem sm={12}>
                    <InputField isEdit={!isEmpty} label="Regionbezeichnung" value={regionName} setFunction={setRegionName} isValidated={isEmpty || vRegionName} />
                </GridItem>

                <GridItem sm={12}>
                    <TextField
                        select
                        disabled={isEmpty}
                        label="Region"
                        value={String(idRegion)}
                        error={!vRegion && !isEmpty}
                        onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdRegion( Number(event.target.value) ) }
                        variant="standard"
                        fullWidth
                    >
                        {regionArray.map(x => 
                            <MenuItem value={x.idRegion}>
                                {x.Region}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>
            </GridContainer>

            <Address 
                idAddress={idAddres}
                setIdAddress={setIdAddres}
                isEmpty={isEmpty}
                saveCounter={saveCounter}
                resetSaveCounter={resetSaveCounter}
                setChildMetastateAddress={setAddressChildMetastate}
            />

            <Box sx={{mt: 10}} />
            <GridContainer>
                <GridItem xs={2}>
                    <Button disabled={isEmpty || isNew} variant="outlined" color="error">Löschen</Button>
                </GridItem>
                <GridItem xs={2}>
                    <div style={{float: 'right'}}>
                        <Button disabled={isEmpty || !isAllValidated} style={{marginLeft: 5}} variant="contained" onClick={() => setSaveCounter(saveCounter+1)}>Speichern</Button>
                    </div>
                </GridItem>
            </GridContainer>

        </>
    )

}
export default Location;