import { Check, Close } from '@mui/icons-material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../redux/combine_reducers';
import GenericOverview from '../../core/GenericOverview';
import Medium from './Medium';

const Mediums:React.FC = () => {
    const dispatch = useDispatch();
    const mediumsArray = useSelector((state: State) => state.mediumArray.storeMediums);
    // Selected Id
    const [selectedId,setSelectedId] = useState<number|undefined>();

    const columns: GridColDef[] = [
        { 
            field: 'idMedium', 
            headerName: 'Nr.', 
            width: 90,
        },
        {
            field: 'Medium',
            headerName: 'Medium',
            flex: 1
        }

    ];

    return(
        <GenericOverview
            headline="Marketing utm_medium"
            columns={columns}
            data={mediumsArray}
            idField="idMedium"
            setTargetId={setSelectedId}
        >
            <Medium idMedium={selectedId}/>
        </GenericOverview>
    )

}
export default Mediums;