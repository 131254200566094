import { Check, Close } from '@mui/icons-material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../redux/combine_reducers';
import GenericOverview from '../../core/GenericOverview';
import TestRentMail from '../TestRentMail/TestRentMail';
import RentState from './RentState';


const RentStates:React.FC = () => {
    const dispatch = useDispatch();
    const rentStateArray = useSelector((state: State) => state.rentStateArray.storeRentState);
    // Selected Id
    const [selectedId,setSelectedId] = useState<number|undefined>();

    const columns: GridColDef[] = [
        { 
            field: 'idRentState', 
            headerName: 'Nr.', 
            width: 90,
        },
        { 
            field: 'RentType', 
            headerName: 'Art', 
            width: 90,
        },
        {
            field: 'State',
            headerName: 'Status',
            flex: 1,
        }
    ];

    return(
        <GenericOverview
            headline="Buchungsstatus"
            columns={columns}
            data={rentStateArray}
            idField="idRentState"
            setTargetId={setSelectedId}
            colDataGrid={4}
        >
            <RentState idRentState={selectedId}/>
        </GenericOverview>
    )

}
export default RentStates;