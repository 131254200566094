import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { mainStore } from './redux/mainStore';
import DynamicComponente from './components/core/DynamicComponente';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



/*
async validatedLogin() {
  const textFromStorage = localStorage.getItem('backend_token');

  if (textFromStorage == undefined || textFromStorage === "") {
    return false
  }

  await fetch()

  console.log(textFromStorage);
  return (textFromStorage != undefined);
}

if (validatedLogin()) {
*/
root.render(
    <Provider store={mainStore}>
      <App />
      {/*<PageRouter /> <DynamicComponente />*/}
    </Provider>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
