import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICountry } from "../interfaces/ICountry";


const initialState =  { storeCountry: [] as ICountry[] }; 

const sliceCountry = createSlice({
    name: "COUNTRYARRAY",
    initialState,
    reducers: {
        setCountryArray: (state, action: PayloadAction<ICountry[]>) => {
            state.storeCountry = [...action.payload];
        },
        addUpdateCountryArray: (state, action: PayloadAction<ICountry>) => {
            let testObject = state.storeCountry.find(x => x.idCountry === action.payload.idCountry);
            
            if (testObject) {
                state.storeCountry = [...state.storeCountry.map(x => x.idCountry === action.payload.idCountry ? action.payload : x)];
            } else {
                state.storeCountry = [action.payload, ...state.storeCountry];
            }
        },
    }
})
export const { setCountryArray, addUpdateCountryArray } = sliceCountry.actions;
export default sliceCountry.reducer;