import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRoomPrice } from "../interfaces/IRoomPrice";


const initialState =  { storeRoomPrice: [] as IRoomPrice[] }; 

const sliceRoomPrice = createSlice({
    name: "ROOMPRICEARRAY",
    initialState,
    reducers: {
        setRoomPriceArray: (state, action: PayloadAction<IRoomPrice[]>) => {
            state.storeRoomPrice = [...action.payload];
        },
        addRoomPriceArray: (state, action: PayloadAction<IRoomPrice>) => {
            state.storeRoomPrice = [...state.storeRoomPrice,action.payload];
        },
        updateRoomPriceArray: (state, action: PayloadAction<IRoomPrice>) => {
            let tmpArray =  state.storeRoomPrice.map(x  => x.idPrice !== action.payload.idPrice && x.idRoom !== action.payload.idRoom ? action.payload : x);
            state.storeRoomPrice = tmpArray;
        },
        deleteRoomPriceArray: (state, action: PayloadAction<IRoomPrice>) => {
            state.storeRoomPrice = [...state.storeRoomPrice.filter(x  => x.idPrice !== action.payload.idPrice && x.idRoom !== action.payload.idRoom)];
        }
    }
})
export const { setRoomPriceArray, addRoomPriceArray, updateRoomPriceArray, deleteRoomPriceArray } = sliceRoomPrice.actions;
export default sliceRoomPrice.reducer;