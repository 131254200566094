import React, { useState } from "react";
import { IInvoicePositionTemplate } from "../../interfaces/IInvoicePositionTemplate";
import { IInvoicePositionChoice } from "../../interfaces/IInvoicePositionChoice";
import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, MenuItem, Snackbar, TextField, Typography } from "@mui/material";
import GridContainer from "../core/GridContainer";
import GridItem from "../core/GridItem";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams, deDE } from "@mui/x-data-grid";
import GenericOverview from "../core/GenericOverview";
import { Add } from "@mui/icons-material";
import InovicePositionChoiceEdit from "./InovicePositionChoiceEdit";
import { uploadFetch } from "../../hooks/useFetch";


interface IProps {
    targetId: number | null;
    setTargetId: Function;
    invoicePositionTemplateArray: IInvoicePositionTemplate[];
    setInvoicePositionTemplateArray: Function
    invoicePositionChoiceArray: IInvoicePositionChoice[];
    setInvoicePositionChoiceArray: Function;
}


const getEmptyInvoicePositionTemplate = () => {
    return {
        idInvoicePositionTemplate: -1,
        hasCustomeLogic: false,
        InvoicePositionTemplate: ""
    } as IInvoicePositionTemplate;
}


const getCurrentInvoicePositionTemplate = (
    targetId: number | null,
    invoicePositionTemplateArray: IInvoicePositionTemplate[]
) => {
    if (targetId === null || targetId < 0) {
        return getEmptyInvoicePositionTemplate();
    } else {
        let testObject = invoicePositionTemplateArray.find(x => x.idInvoicePositionTemplate === targetId);

        if (testObject === undefined) {
            return getEmptyInvoicePositionTemplate();
        } else {
            return testObject;
        }
    }
}


const InovicePositionTemplateEdit:React.FC<IProps> = (props) => {
    const [currentObject,setCurrentObject] = useState(getCurrentInvoicePositionTemplate(props.targetId, props.invoicePositionTemplateArray));
    const [invoicePositionChoiceArray, setInvoicePositionChoiceArray] = useState(props.invoicePositionChoiceArray.filter(x => x.idInvoicePositionTemplate === currentObject.idInvoicePositionTemplate))
    //
    const [title,setTitle] = useState(currentObject.InvoicePositionTemplate);
    const [isLoading,setIsLoading] = useState(false);
    const [wasSaved, setWasSaved] = useState(false);
    //
    const [idInvoicePositionChoice,setIdInvoicePositionChoice] = useState<number|null>(null);


    const columns: GridColDef[] = [
        { 
            field: 'idInvoicePositionChoice', 
            headerName: 'Nr.', 
            width: 90,
        },
        { 
            field: 'Unit', 
            headerName: 'Einheit', 
            width: 90,
        },
        { 
            field: 'PricePerUnit', 
            headerName: 'P.p.E.', 
            width: 100,
            renderCell: (params: GridRenderCellParams) => Number(params.row.PricePerUnit).toFixed(2).replace(".",",")+ " €",
        },
        { 
            field: 'InvoicePositionChoice', 
            headerName: 'Bezeichnung', 
            flex: 1
        },
    ];  
    
    
    const handleNewChoice = () => {
        let minId = -1;

        if (invoicePositionChoiceArray.length > 0) {
            let minIdArray = Math.min(...invoicePositionChoiceArray.map(x => x.idInvoicePositionChoice));
            
            if (minIdArray <= minId) {
                minId = minIdArray -1
            }
        }
        setIdInvoicePositionChoice(minId);
    }


    const wrapperSaveChoice = async (localArray:IInvoicePositionChoice[]) => {
        let tmpArray = props.invoicePositionChoiceArray;
        
        localArray.map(x => {
            if (tmpArray.map(y => y.idInvoicePositionChoice).indexOf(x.idInvoicePositionChoice) === -1) {
                tmpArray.push(x)
            } else {
                tmpArray = tmpArray.map(y => y.idInvoicePositionChoice === x.idInvoicePositionChoice ? x : y)
            }
        });

        props.setInvoicePositionChoiceArray([...tmpArray]);
        setInvoicePositionChoiceArray([...localArray]);
        props.setTargetId(currentObject.idInvoicePositionTemplate);
        setIsLoading(false);
        setWasSaved(true);
    }


    const wrapperSaveTemplate = async (localObject:IInvoicePositionTemplate) => {
        if (currentObject.idInvoicePositionTemplate < 0) {
            props.setInvoicePositionTemplateArray([
                ...props.invoicePositionTemplateArray,
                localObject
            ])
        }
        else {
            props.setInvoicePositionTemplateArray([
                ...props.invoicePositionTemplateArray.map(x => x.idInvoicePositionTemplate === currentObject.idInvoicePositionTemplate ? localObject : x)
            ])
        }

        setCurrentObject(localObject);
        setTitle(localObject.InvoicePositionTemplate);

        if (localObject.hasCustomeLogic) {
            setIsLoading(false);
            setWasSaved(true);
            props.setTargetId(currentObject.idInvoicePositionTemplate);
        } else {
            uploadFetch(
                "invoicePositionChoice/array",
                true,
                invoicePositionChoiceArray.map(x =>  {return {...x, idInvoicePositionTemplate: localObject.idInvoicePositionTemplate}}),
                wrapperSaveChoice,
            )
        }
    }

    const handleSave = async () => {
        setIsLoading(true);

        uploadFetch(
            "invoicePositionTemplate",
            (currentObject.idInvoicePositionTemplate < 0),
            currentObject,
            wrapperSaveTemplate
        )
    }



    if (props.targetId === null) {
        return <>Bitte wählen...</>
    } else {
        return(
            <>
                <Dialog
                    open={idInvoicePositionChoice !== null}
                    onClose={() => setIdInvoicePositionChoice(null)}
                >
                    <DialogTitle>Auswahlmöglichkeit</DialogTitle>
                        <InovicePositionChoiceEdit
                            key={`InovicePositionChoiceEdit-${idInvoicePositionChoice}`}
                            idInvoicePositionChoice={idInvoicePositionChoice!}
                            setIdInvoicePositionChoice={setIdInvoicePositionChoice}
                            parentObject={currentObject}
                            invoicePositionChoiceArray={invoicePositionChoiceArray}
                            setInvoicePositionChoiceArray={setInvoicePositionChoiceArray}
                        />
                </Dialog>

                <Snackbar 
                    key="savebar" 
                    open={wasSaved} 
                    autoHideDuration={2000} 
                    onClose={() => setWasSaved(false)}  
                    anchorOrigin={{ 'vertical' : 'bottom', 'horizontal' : 'right' }} 
                >
                    <Alert onClose={() => setWasSaved(false)} severity={"success"} sx={{ width: '100%' }}>
                        Erfolgreich gespeichert!
                    </Alert>
                </Snackbar>

                <Typography variant='h5'>
                    {(props.targetId < 0) ? <>Neue Rechnungsposition</> : <>Rechnungsposition: {title}</>}
                    

                        <div style={{float: "right"}}>
                            {/* <Button disabled={props.isEmpty || props.isNew} variant="outlined" color="error">Löschen</Button> */}
                            <Button 
                                disabled={currentObject.InvoicePositionTemplate === "" || isLoading} 
                                style={{marginLeft: 5}} 
                                variant="contained" 
                                onClick={handleSave}
                            >Speichern</Button>
                        </div>
                </Typography>

                
                {(isLoading) && <><Box sx={{mt:  5}}/><LinearProgress/></>}

                <GridContainer>
                    <GridItem sm={8}>
                        <TextField 
                            label="Bezeichnung"
                            value={currentObject.InvoicePositionTemplate}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCurrentObject({...currentObject, InvoicePositionTemplate: event.target.value})}
                            size="small"
                            fullWidth
                        />
                    </GridItem>

                    <GridItem sm={4}>
                        <TextField 
                            label="Logik"
                            value={(currentObject.hasCustomeLogic == true) ? "true" : "false"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
                                setCurrentObject({
                                    ...currentObject, 
                                    hasCustomeLogic: (event.target.value === "true")
                                })
                            }
                            size="small"
                            fullWidth
                            select
                        >
                            <MenuItem key="true" value="true">Ja</MenuItem>
                            <MenuItem key="false" value="false">Nein</MenuItem>
                        </TextField>
                    </GridItem>

                    {/*
                    <GenericOverview
                        headline="Auswahlmöglichkeiten"
                        columns={columns}
                        data={props.invoicePositionChoiceArray.filter(x => x.idInvoicePositionTemplate === Number(props.targetId))}
                        idField="idInvoicePositionTemplate"
                        setTargetId={setIdInvoicePositionChoice}
                        colDataGrid={12}
                    >
                    </GenericOverview>
                     */}

                    { (!currentObject.hasCustomeLogic) &&
                        <>
                            <GridItem sm={12}>
                                <IconButton 
                                    sx={{float: "right"}}
                                    onClick={handleNewChoice}
                                ><Add/></IconButton>
                            </GridItem>

                            <GridItem sm={12}>
                                <DataGrid
                                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                    rows={invoicePositionChoiceArray}
                                    columns={columns}
                                    getRowId={(row) => row["idInvoicePositionChoice"]}
                                    autoHeight
                                    onRowClick={(param: GridRowParams) => setIdInvoicePositionChoice(Number(param.id))}
                                    //onSelectionModelChange={(x) => props.setTargetId(Number(x[0]))}
                                    //selectRow
                                    //onSelectionModelChange={(x:GridInputSelectionModel) => setSelectedModal(x)}
                                    //selectionModel={selectedModal}
                                    //pageSize={10}
                                    /*
                                    selectionModel={
                                        (props.targetId == undefined) 
                                        ? undefined
                                        : [props.targetId]
                                    }
                                    */
                                />
                            </GridItem>
                        </>
                    }



                </GridContainer>
            </>
        )
    }
}
export default InovicePositionTemplateEdit;