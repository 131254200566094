import { Download } from "@mui/icons-material";
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import React, { useState } from "react";
import { getFetch, uploadFetch } from "../../hooks/useFetch";
import { IDocument } from "../../interfaces/IDocument";
import { IRent } from "../../interfaces/IRent";
import { staticMimeTypeArray } from "../Document/array_MIMEType";



interface IProps {
    rentObject?: IRent
}


const RentGetAppendix:React.FC<IProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);


    const handleClickDownload = () => {
        setIsLoading(true);
        uploadFetch("/appendix/forRentPost",true,props.rentObject,saveDataWrapper);

    }

    const saveDataWrapper = (fileObject:IDocument) => {
        if (fileObject?.Data != undefined) {
            // Link -> https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
            // decode a Base64-encoded string into a new string with a character for each byte of the binary data.
            let byteCharacters = atob(fileObject?.Data);
            // Each character's code point (charCode) will be the value of the byte. 
            // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
            let byteArray = new Uint8Array(byteNumbers);
            // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
            let blob = new Blob([byteArray], {type: staticMimeTypeArray.find(x => x.idMIMEType === fileObject.idMIMEType)?.MIMEType});
            // Erstelle Link zum BLOB
            let blobUrl = URL.createObjectURL(blob);
            // Erstelle html-Objekt für den Download
            let a = document.createElement("a");
            // setze link auf den neu erstellen Link zum Blolb
            a.href = blobUrl;
            // Setze Downloadtitle für den Datenname
            a.download = fileObject.FileName;
            // Click 
            a.click();
        }
        setIsLoading(false);
    }

    return (
        <>
            <Dialog
                open={isLoading}
                onClose={() => setIsLoading(false)}
            >
                <DialogTitle>Daten</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Dateien werden generiert....
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <IconButton
                sx={{float: "right"}} 
                onClick={handleClickDownload}
                disabled={
                    props.rentObject == undefined
                    || props.rentObject.RentNummer == undefined
                    || props.rentObject.idRentState != 6 && props.rentObject.idRentState != 7 && props.rentObject.idRentState != 14
                }
            ><Download/></IconButton>
        </>
    )
}
export default RentGetAppendix;