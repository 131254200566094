import { Autocomplete, FormControl, Grid, InputLabel, List, ListItem, makeStyles, MenuItem, NativeSelect, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFetch, uploadFetch } from '../../hooks/useFetch';
import { useValidatenText, useValidationNumber } from '../../hooks/useValidaten';
import { IAutoSelectList } from '../../interfaces/Additional/IAutoSelectList';
import { IAddress } from '../../interfaces/IAddress';
import { IChildMetadata } from '../../interfaces/IChildMetadata';
import { IPostcode } from '../../interfaces/IPostcode';
import { State } from '../../redux/combine_reducers';
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import InputField from '../core/InputField';
import Postcode from '../Postcode/Postcode';



interface IProps {
    idAddress?: number | null; // Entweder idAdress oder Objekt übergeben
    // Values vom Parent
    isEmpty: boolean;
    saveCounter: number;
    resetSaveCounter: number;
    // Values um ans Parent zu übergeben
    setIdAddress?: Function;
    setChildMetastateAddress: Function;

}


const Address:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const countryArray = useSelector((state: State) => state.countryArray.storeCountry);
    const postcodeArray = useSelector((state: State) => state.postcodeArray.storePostcode);
    // Haupobject
    const [addressObject,setAddressObject] = useState<IAddress>();
    // Haupstates
    const [idAddress,setIdAddress] = useState<number | null>(null);
    const [street, setStreet, vStreet] =  useValidatenText();
    const [streetNr, setStreetNr, vStreetNr] =  useValidatenText();
    const [streetAditional, setStreetAditional] = useState<string | null>();
    const [floor, setFloor] = useState<string | null>();
    const [idPostcode, setIdPostcode, vIdPostcode] = useValidationNumber();
    // Validierung
    const [isValidated,setIsValidated] = useState(false);
    // Erweiternde States
    const [idCountry, setIdCountry] = useState<number>(1);
    const [filteredPostcodeArry,setFilteredPostcodeArry] = useState<IPostcode[]>([]);
    const [propsPostcodeArry,setPropsPostcodeArry] = useState<IProps[]>([]);
    // Metastates
    const [isEdit, setIsEdit] = useState(true);
    const [isNew,setIsNew] = useState(props.idAddress === -1);
    const [wasSuccessfullyLoad,setWasSuccessfullyLoad] = useState(true);
    const [wasSaved,setWasSaved] = useState(false);
    const [wasSuccessfullySaved,setWasSuccessfullySaved] = useState(true);
    // Healtstates
    const [childKey,setChildKey] = useState(`idAddres-${(props.idAddress)}`)


    useEffect(() => {
        if (props.saveCounter === 0 && !wasSaved && props.idAddress !== idAddress) {
            if (props.idAddress !== -1) {
                resetValues();
                getFetch("address/",props.idAddress,setAddressObject,setWasSuccessfullyLoad); 
                setIsNew(false);
            } 
            else if (props.idAddress === -1) {
                setIsNew(true);
                resetValues();
            }
        }
    },[props.idAddress])

    useEffect(() => { (addressObject) && setValues(addressObject) },[addressObject])

    useEffect(() => {
        if (idCountry && postcodeArray && props.idAddress !== -1) {
            setFilteredPostcodeArry([...postcodeArray.filter(x => Number(x.idCountry) === idCountry).map(x => x)]);

            if (addressObject !== undefined) {
                let postCode = postcodeArray.find(x => x.idPostcode === addressObject.idPostcode);
                
                if (postCode != undefined && postCode.idCountry === idCountry) {
                    setIdPostcode(addressObject.idPostcode);
                } else {
                    setIdPostcode(undefined);
                }
            }
        }
    },[idCountry,postcodeArray,addressObject])

    useEffect(() => { setIsValidated(vStreet && vStreetNr && vIdPostcode) },[vStreet, vStreetNr, vIdPostcode]);

    useEffect(() => {
        props.setChildMetastateAddress({
            idChild: "addresschild",
            isValidated:  isValidated,
            wasSaved: wasSaved,
            wasSuccessfullySaved: wasSuccessfullySaved
        } as IChildMetadata)
    }, [isValidated, wasSaved, wasSuccessfullySaved])

    useEffect(() => {(props.saveCounter > 0 ) && saveObject()},[props.saveCounter])
    useEffect(() => {(props.resetSaveCounter > 0) && setWasSaved(false)},[props.resetSaveCounter])


    const resetValues = () => {
        setAddressObject(undefined);
        setIdAddress(null);
        setStreet(null);
        setStreetNr(null);
        setStreetAditional(null);
        setFloor(null);
        setIdCountry(1);
        setIdPostcode(null);
    }

    const setValues = (localAddressObeject: IAddress | undefined) => {
        if (localAddressObeject) {
            setIdAddress(Number(localAddressObeject.idAddress));
            setStreet(localAddressObeject.Street);
            setStreetNr(localAddressObeject.StreetNr);
            setIdPostcode(localAddressObeject.idPostcode);
            (localAddressObeject.StreetAditional) && setStreetAditional(localAddressObeject.StreetAditional);

            if (wasSaved && props.setIdAddress) {
                props.setIdAddress(localAddressObeject.idAddress);
            }

            //(isNew && props.setIdAddress) && props.setIdAddress(localAddressObeject.idAddress);
        }
    }

    const saveObject = () => {
        if (street != undefined && streetNr != undefined && idPostcode != undefined) {
            console.log(`COMPANY NEW: ${isNew}`)
            let tmpObject:IAddress = {
                Street: street,
                StreetNr: streetNr,
                idPostcode: idPostcode
            };
            console.log(tmpObject);
            (streetAditional != undefined) && (tmpObject.StreetAditional = streetAditional);
            (floor != undefined) && (tmpObject.Floor = floor);
            (!isNew && idAddress != undefined) && (tmpObject.idAddress = idAddress);
            uploadFetch("address",(isNew || idAddress == undefined),tmpObject,setAddressObject,setWasSuccessfullySaved);
            setWasSaved(true);
        }
    }

    const handelChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIdCountry(Number(event.target.value));
    }
    const handelChangePostcode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIdPostcode(Number(event.target.value));
    }


    return(
        <div style={{marginTop: "50px"}}>
            <Typography variant='h6' gutterBottom>Adresse</Typography>

            <GridContainer>
                <GridItem sm={8} xs={3}>
                    <InputField label='Straße' isEdit={!props.isEmpty} value={street} setFunction={setStreet} isValidated={vStreet}/>
                </GridItem>

                <GridItem sm={4} xs={1}>
                    <InputField label='Nr.' isEdit={!props.isEmpty} value={streetNr} setFunction={setStreetNr} isValidated={vStreetNr}/>
                </GridItem>


                <GridItem sm={8} xs={2}>
                    <InputField label='Zusatz' isEdit={!props.isEmpty} value={streetAditional} setFunction={setStreetAditional}/>
                </GridItem>

                <GridItem sm={4} xs={2}>
                    <InputField label='Stockwerk' isEdit={!props.isEmpty} value={floor} setFunction={setFloor}/>
                </GridItem>

            
               <GridItem sm={3}>
                
                    <TextField
                        select
                        label="Land"
                        value={idCountry}
                        onChange={handelChangeCountry}
                        variant="standard"
                        fullWidth
                        disabled={props.isEmpty}
                     >
                    {countryArray.map( x =>
                        <MenuItem value={x.idCountry}>{x.Country}</MenuItem>
                    )}
                    </TextField>
                    
                </GridItem>
                    
                
                { /*
                <GridItem sm={3}>
                <Select
                    native
                    //onChange={handelChangePostcode}
                    error={!vIdPostcode && !props.isEmpty}
                    //defaultValue={}
                    
                    
                >
                    {filteredPostcodeArry.map( x =>
                            <option value={x.idPostcode}>{x.Postcode}</option>
                    )}
                </Select>            
                </GridItem>

                <GridItem sm={6}>
                    <TextField
                        select
                        label="Stadt"
                        value={String(idPostcode)}
                        onChange={handelChangePostcode}
                        variant="standard"
                        error={!vIdPostcode && !props.isEmpty}
                        fullWidth
                        disabled={props.isEmpty}
                     >
                    {filteredPostcodeArry.map( x =>
                        <MenuItem value={x.idPostcode}>{x.City}</MenuItem>
                    )}
                    </TextField>
                </GridItem>

                */}

                <GridItem sm={9}>
                    <Postcode isEmpty={props.isEmpty} idPostcode={idPostcode} setIdPostcode={setIdPostcode}/>
                </GridItem>



            </GridContainer>

        </div>
    )

}
export default Address;



