import { GridColDef, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../redux/combine_reducers';
import GenericOverview from '../core/GenericOverview';
import Location from './Location';



const Locations:React.FC = () => {
    const dispatch = useDispatch();
    const regionArray = useSelector((state: State) => state.regionArray.storeRegions);
    const locationArray = useSelector((state: State) => state.locationArray.storeLocation);
    // Selected Id
    const [selectedId,setSelectedId] = useState<number|undefined>();

    const columns: GridColDef[] = [
        { 
            field: 'idLocation', 
            headerName: 'Nr.', 
            width: 90,
        },
        {
            field: 'idRegion',
            headerName: 'Region',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>  regionArray.find(x => x.idRegion === Number(params.row.idRegion))?.Region,
        },
        {
            field: 'ShortName',
            headerName: 'Kurzbezeichnung',
            width: 150,
        },
        {
            field: 'Location',
            headerName: 'Standort',
            flex: 1,
        },


    ];

    return(
        <GenericOverview
            headline="Standorte"
            columns={columns}
            data={locationArray}
            idField="idLocation"
            setTargetId={setSelectedId}
            colDataGrid={6}
        >
            <Location idLocation={selectedId}/>
        </GenericOverview>
    )


}
export default Locations;