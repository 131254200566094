import { IMIMEType } from "../../interfaces/IMIMEType";

export const staticMimeTypeArray:IMIMEType[] = [
    {
        idMIMEType: 1,
        MIMEType: "application/pdf",
        FileEnding: "pdf"
    },
    {
        idMIMEType: 2,
        MIMEType: "image/jpeg",
        FileEnding: "jpg"
    },
    {
        idMIMEType: 3,
        MIMEType: "image/png",
        FileEnding: "png"
    },
    {
        idMIMEType: 4,
        MIMEType: "image/webp",
        FileEnding: "webp"
    },
]