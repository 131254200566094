import React, { useState } from "react";
import { IOfferFullObject } from "../../interfaces/IOffer";
import GridContainer from "../core/GridContainer";
import GridItem from "../core/GridItem";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import deLocale from "date-fns/locale/de";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/combine_reducers";
import { Add, Delete, Favorite, FavoriteBorder, PlusOne } from "@mui/icons-material";
import { IOfferHasRoom } from "../../interfaces/IOfferHasRoom";
import { staticOfferState } from "./staticOfferState";
import OfferGetDocument from "./OfferGetDocument";
import SendOffer from "./SendOffer";


interface IProps {
    currentObject: IOfferFullObject;
    setCurrentObject: Function;
}


const OfferEdit:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const roomArray = useSelector((state: State) => state.roomArray.storeRoom);
    const roomPriceArray = useSelector((state: State) => state.roomPriceArray.storeRoomPrice); 
    const locationArray = useSelector((state: State) => state.locationArray.storeLocation);
    const rentArray = useSelector((state: State) => state.rentArray.storeRent);
    const offerStateArray = staticOfferState;
    //
    const [addRoomIsOpen, setAddRoomIsOpen] = useState(false);
    const [idRoomNew, setIdRoomNew] = useState<number|null>(null);
    const [isFav, setIsFav] = useState(false);



    const addHasRoom = () => {
        if (idRoomNew != undefined) {
            props.setCurrentObject( {
                ...props.currentObject,
                OfferHasRoomArray: 
                    [
                        ...props.currentObject.OfferHasRoomArray, 
                        { 
                            idOffer: -1,
                            idRoom: idRoomNew,
                            isFavorite: isFav
                        } as IOfferHasRoom
                ]
            })
            setAddRoomIsOpen(false);
        }
    }

    const changeFav = (localObject:IOfferHasRoom,localIsFav:boolean) => {
        let testObj = props.currentObject.OfferHasRoomArray.find(x => x.idRoom === localObject.idRoom);
        if (testObj != undefined) {
            testObj.isFavorite = localIsFav;

            props.setCurrentObject( {
                ...props.currentObject,
                OfferHasRoomArray: 
                    [
                        ...props.currentObject.OfferHasRoomArray.map(x => (x.idRoom === testObj?.idRoom) ? testObj : x) , 
                ]
            })
        }
    }

    const removeRoom = (localObject:IOfferHasRoom,) =>  {
        props.setCurrentObject( {
            ...props.currentObject,
            OfferHasRoomArray: 
                [
                    ...props.currentObject.OfferHasRoomArray.filter(x => x.idRoom != localObject?.idRoom)
            ]
        })
    }



    return(
        <>


            <Dialog open={addRoomIsOpen} onClose={() => setAddRoomIsOpen(false)} maxWidth="md">
                <DialogTitle>Neuen Raum hinzufügen</DialogTitle>
                <DialogContent>
                    <br />
                    <GridContainer>
                        <GridItem sm={12}>
                            <TextField
                                select
                                label="Raum"
                                value={(idRoomNew == null) ? "" : String(idRoomNew)}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdRoomNew(Number(event.target.value))}
                                fullWidth
                                size="small"
                            >
                                {roomArray
                                    .filter(x => x.idLocation === props.currentObject.idLocation)
                                    .map(x => 
                                        <MenuItem value={x.idRoom} disabled={props.currentObject.OfferHasRoomArray.find(y => y.idRoom === x.idRoom) != undefined}>
                                            {x.RoomNumber} - {x.Title} 
                                        </MenuItem>
                                )}
                            </TextField>
                        </GridItem>
                        
                        <GridItem sm={12}>
                            <TextField
                                select
                                label="Empfehlen"
                                value={(isFav === true) ? "yes" : "no"}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIsFav( (event.target.value === "yes") )}
                                fullWidth
                                size="small"
                            >
                                <MenuItem value="yes">Ja</MenuItem>
                                <MenuItem value="no">Nein</MenuItem>
                            </TextField>
                        </GridItem>
                    </GridContainer>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="error" onClick={() => setAddRoomIsOpen(false)}>Schließen</Button>
                    <Button variant="contained" color="success" onClick={() => addHasRoom()}>Speichern</Button>
                </DialogActions>
            </Dialog>


            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                <GridContainer>
                    <GridItem sm={12}>
                        <OfferGetDocument idOffer={props.currentObject.idOffer} />
                        <SendOffer offerObject={props.currentObject} />
                    </GridItem>
                    <GridItem sm={6}>
                        <DesktopDatePicker
                            label="Erstellt"
                            value={props.currentObject.Created_at}
                            onChange={(newValue: Date | null) => {} }
                            //minutesStep={15}
                            //maxDate={toDateTime}
                            disabled={true}
                            renderInput={(params) => <TextField margin="normal" variant="standard" {...params} disabled={true}  fullWidth />}
                        />
                    </GridItem>

                    <GridItem sm={6}>
                        <DesktopDatePicker
                            label="Gültig bis"
                            value={props.currentObject.Expire_at}
                            onChange={(newValue: Date | null) => {} }
                            //minutesStep={15}
                            //maxDate={toDateTime}
                            disabled={true}
                            renderInput={(params) => <TextField margin="normal" variant="standard" {...params} disabled={true}  fullWidth />}
                        />
                    </GridItem>

                    <GridItem sm={12}>
                        <TextField
                            select
                            label="Standort"
                            value={String(props.currentObject.idLocation)}
                            disabled={props.currentObject.OfferHasRoomArray.length > 0}
                            onChange={ (event:React.ChangeEvent<HTMLInputElement>) => props.setCurrentObject({...props.currentObject, idLocation: Number(event.target.value)}) }
                            fullWidth
                            size="small"
                        >
                            {locationArray.map(x => 
                                <MenuItem value={x.idLocation}>
                                    {x.ShortName}
                                </MenuItem>
                            )}
                        </TextField>
                    </GridItem>

                    <GridItem sm={12}>
                        <TextField
                            select
                            label="Standort"
                            value={String(props.currentObject.idOfferState)}
                            onChange={ (event:React.ChangeEvent<HTMLInputElement>) => props.setCurrentObject({...props.currentObject, idOfferState: Number(event.target.value)}) }
                            fullWidth
                            size="small"
                        >
                            {offerStateArray.map(x => 
                                <MenuItem value={x.idOfferState}>
                                    {x.OfferState}
                                </MenuItem>
                            )}
                        </TextField>
                    </GridItem>

                    <GridItem sm={12}>
                        <Typography sx={{mt: 3}}>
                            Räume
                            <IconButton 
                                sx={{float: "right"}}
                                onClick={() => {setIdRoomNew(null); setIsFav(false); setAddRoomIsOpen(true)}}
                            ><Add/></IconButton>
                        </Typography>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>WorkBox</TableCell>
                                    <TableCell>Kategorie</TableCell>
                                    <TableCell>Empfohlen</TableCell>
                                    <TableCell>Aktionen</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {props.currentObject.OfferHasRoomArray.map(x => 
                                    <TableRow key={x.idRoom}>
                                        <TableCell>{roomArray.find(y => y.idRoom === x.idRoom)?.RoomNumber}</TableCell>
                                        <TableCell>{roomArray.find(y => y.idRoom === x.idRoom)?.RoomType}</TableCell>
                                        <TableCell>{(x.isFavorite == true) ? "Ja" : "Nein"}</TableCell>
                                        <TableCell>
                                            {
                                                (x.isFavorite == true)
                                                ? <IconButton  onClick={() => changeFav(x,false)}><FavoriteBorder/></IconButton>
                                                : <IconButton onClick={() => changeFav(x,true)}><Favorite/></IconButton>
                                            }
                                            <IconButton onClick={() => removeRoom(x)}><Delete/></IconButton>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                    </GridItem>
                </GridContainer>
            </LocalizationProvider>
        </>
    )
}
export default OfferEdit;