import { Alert, Button, createTheme, FormControl, Grid, Paper, Snackbar, TextField, ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';
import md5 from 'md5';
import React, { useEffect, useRef, useState } from 'react';
import InputField from '../components/core/InputField';

interface IProps {
    msg?: string | null;
}



const Login:React.FC<IProps> = (props) => { 
    const refUsername = useRef<HTMLInputElement>(null);
    const refPassword = useRef<HTMLInputElement>(null);
    //
    const [loginName, setLoginName] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [msg,setMsg] = useState<string | null>(null);
    const [showMsg,setShowMsg] = useState(false);


    useEffect(() => {
        if (props.msg !== undefined) {
            setMsg(props.msg);
        }
    },[props.msg])


    const theme = createTheme({
        status: {
            danger: '#e53e3e',
        },
        palette: {
            primary: {
                main: '#000',
            },
        },
    });

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        console.log(loginName);
        console.log(password);
        async function tryLogin() {
            if (loginName !== undefined && password !== null) {
                await fetch("/api/login"  ,{
                    method: "POST",
                    headers :  { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ "Login" : loginName, "Password" : md5(password) } as any)
                })
                .then(res => {
                    if (res.status == 200) {
                        return res.json();
                    } else {throw Error(String(res.status))}
                })
                .then(res => {
                        localStorage.setItem('bearer_token', res["bearer_token"]);
                        localStorage.setItem('isAdmin', res["isAdmin"]);
                        localStorage.setItem('isMarketing', res["isMarketing"]);
                        localStorage.setItem('isJanitor', res["isJanitor"]);
                        window.location.reload(); 
                })
                .catch( error => {
                    if (String(error).includes("401")) {
                            setMsg("Ihre Session ist abgelaufen.")
                        } else if (String(error).includes("404")) {
                            setMsg("Die Anmeldedaten konnten nicht gefunden werden. Bitte überprüfen Sie den Anmeldename und das Passwort");
                        } else if (String(error).includes("403")) {
                            setMsg(`Ihr Konto wurde deaktiviert!`);
                        } else {
                            setMsg(`Es ist ein unbekannter Fehler aufgeretten. Möglicherweise ist der Dienst vorübergehend offline.`);
                        }
                        setShowMsg(true);
                        setLoginName(null);
                        setPassword(null);
                })
            } else {
                setLoginName(null);
                setPassword(null);
            }
        }
        tryLogin();
    }

    return(
        <ThemeProvider theme={theme}>
            <Snackbar 
                key="savebar" 
                open={showMsg} 
                autoHideDuration={6000} 
                onClose={() => setShowMsg(false)}  
                anchorOrigin={{ 'vertical' : 'bottom', 'horizontal' : 'right' }} 
            >
                <Alert onClose={() => setShowMsg(false)} severity="error" sx={{ width: '100%' }}>
                    {msg}
                </Alert>
            </Snackbar>

            <form onSubmit={handleSubmit} autoComplete="on">
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}
                >

                    <Grid item xs={3}>
                        <img src="/assets/logo.png" width={150}/>
                    </Grid>   
                    

                    <Box sx={{mt: 10}} />
                    <Grid item xs={3}  style={{ minWidth: 300 }}>
                        <InputField name="username" label='Benutzername' value={loginName} setFunction={setLoginName} />
                    </Grid>   

                    <Box sx={{mt: 2}} />
                    <Grid item xs={3} style={{ minWidth: 300 }}>
                        <InputField name="password" label='Passwort' type='password' value={password} setFunction={setPassword} />
                    </Grid>   

                    <Box sx={{mt: 3}} />
                    <Grid item xs={3} style={{ minWidth: 300 }}>
                        <div style={{float: 'right'}}>
                            <Button size="small" variant="contained" color="primary" type="submit">Anmelden</Button>
                        </div>
                    </Grid>   
                
                </Grid> 
            </form>
        </ThemeProvider>
    )
}
export default Login;
