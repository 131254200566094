import { Button, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams, deDE } from "@mui/x-data-grid";
import React, { useState } from "react";
import { IHandoverProtocol, IHandoverProtocolFullObject } from "../../interfaces/IHandoverProtocol";
import { HandoverDialogEdit } from "./HandoverDialogEdit";


export const handoverData:IHandoverProtocolFullObject[] = [
    {
        idHandoverProtocol: 1,
        isAssumption: true,
        idRent: 1,
        ElectricityMeterReading: 0,
        RentNummer: "EBS001005",
        Company: "BackAntz",
        idPerson: 1,
        FirstName: "Daniel",
        LastName: "Kmiotek",
        DamageArray: [
            {idDamage: -1, idDamageTemplate: 1, Damage: "Rechte Wand", Text: "Loch in der Wand"},
            {idDamage: -2, idDamageTemplate: 1, Damage: "Wand gegenüber Eingang", Text: "Schmutz an der Wand"}
        ]
    }
];



export const HandoverOverview:React.FC = () => {
    const [idHandoverProtocol, setIdHandoverProtocol] = useState<number|null>(null);

    const columns:GridColDef[] = [
        { 
            field: 'idHandoverProtocol', 
            headerName: 'Nr.', 
            width: 50,
        },
        { 
            field: 'isAssumption', 
            headerName: 'Art', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => (Boolean(params.row.isAssumption)) ? "Annahme" : "Abgabe"
        },
        { 
            field: 'RentNummer', 
            headerName: 'Miete', 
            width: 100,
        },  
        { 
            field: 'Company', 
            headerName: 'Firma', 
            flex: 1
        },  
        { 
            field: 'Kunde', 
            headerName: 'Kunde', 
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.FirstName} ${params.row.LastName}`
        },
    ];



    return(
        <>
            <Typography variant='h4' gutterBottom>
                Übergabeprotokolle
                
                <Button variant="contained" sx={{float: "right"}} onClick={() => setIdHandoverProtocol(.1)}>Neu Übergabeprotokoll</Button>
            </Typography>

            <DataGrid
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                rows={handoverData}
                columns={columns}
                getRowId={(row) => row["idHandoverProtocol"]}
                autoHeight
                onRowClick={(row) => setIdHandoverProtocol(Number(row.id))}
            />

            <HandoverDialogEdit
                key={`HandoverDialogEdit-${idHandoverProtocol}`}
                idHandoverProtocol={idHandoverProtocol}
                setIdHandoverProtocol={setIdHandoverProtocol}
            />
        </>
    )
}