import { Delete, FileDownload, FileUpload } from '@mui/icons-material';
import { Button, IconButton, MenuItem, TableCell, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getFetch, uploadFetch } from '../../hooks/useFetch';
import { IDocument } from '../../interfaces/IDocument';
import { IMIMEType } from '../../interfaces/IMIMEType';
import GridItem from '../core/GridItem';
import InputField from '../core/InputField';
import { staticDocumentTypeArray } from './array_DocumentType';
import { staticMimeTypeArray } from './array_MIMEType';

interface IProps {
    idDocument?: number | null;
    documentObj?: IDocument;
    setIdDocument?: Function;
    // Wenn nur eine Zeile und fest definiert
    label?: string; // Dann kein Row mode
    idDocumentType?: number;
    idMIMEType?: number;
    // 
    saveCounter: number;
    isEmpty: boolean;
    isNew: boolean;
    droppedFile?: File;
    setDocumentWasChanged?: Function;
    // Als Chil
    dispatcherChildArrayMetastate?: Function;
    disablePublic?: boolean;
}

const Document:React.FC<IProps> = (props) => {
    // Ref für den Upload
    const fileUploadElement = React.useRef<HTMLInputElement>(null);
    // Hauptobject
    const [documentObjEmpty,setDocumentObjEmpty] = useState<IDocument>({
        idDocumentType: (props.idDocumentType === undefined) ? 1 : props.idDocumentType,
        FileName: "",
        idMIMEType: (props.idMIMEType === undefined) ? 1 : props.idMIMEType,
        isPublic: (props.idDocumentType !== undefined && props.idDocumentType === 4) ? true : false,
    });
    const [documentObj,setDocumentObj] = useState<IDocument>({...documentObjEmpty});
    const [documentOrgObj,setDocumentOrgObj] = useState<IDocument>({...documentObjEmpty});
    const [documentObjLoaded,setDocumentObjLoaded] = useState<IDocument | null>(null);
    // Notwendige Arrays
    const mimeTypeArray = staticMimeTypeArray;
    const documentTypeArray = staticDocumentTypeArray;
    // tmp
    const [tmpData,setTmpData] = useState<string|null>(null);
    // Error Meldung
    const [modalIsOpenMIMIEError,setModalIsOpenMIMIEError] = useState(false);
    // Metastate
    const [pleaseWait,setPleaseWait] = useState(false);
    const [wasSuccessfully,setWasSuccessfully] = useState(true);
    const [rowMode,setRowMode] = useState(props.label === undefined);
    const [wasSaved,setWasSaved] = useState(false);
    const [needToRemoved,setNeedToRemoved] = useState(false);


    useEffect(() => { (props.droppedFile != undefined) && setFromFile(props.droppedFile); },[props.droppedFile])

    /*
    useEffect(() => {
        console.log("LUL!");
        if (documentOrgObj.idDocumentType !== documentObj.idDocumentType &&  documentObj.idDocumentType != undefined && documentObj.idDocumentType === 4) {
            setDocumentObj({...documentObj, isPublic: true});
        }
    },[documentObj])
    */


    useEffect(() => {
        if (props.documentObj != undefined) {
            setDocumentObj(props.documentObj);
            setDocumentObjLoaded(props.documentObj);
        }
    },[props.documentObj])

    useEffect(() => {
        if (props.idDocument == undefined) {
            console.log(props.idDocument);
            setTmpData(null);
            setDocumentObj({...documentObjEmpty});
            setDocumentOrgObj({...documentObjEmpty});
        }
        else {
            console.log(props.idDocument);
            setPleaseWait(true);
            getFetch(`document/`,props.idDocument,setDocumentObjLoaded,setWasSuccessfully)
        }
    },[props.idDocument])

    useEffect(() => {
        if (props.isNew) {
            setTmpData(null);
            setDocumentObj({...documentObjEmpty});
            setDocumentOrgObj({...documentObjEmpty});
        }
    },[props.isNew])

    useEffect(() => {
        if (documentObjLoaded !== null) {
            
            setDocumentObj({...documentObjLoaded});
            setDocumentOrgObj({...documentObjLoaded});
            setPleaseWait(false);

            if (wasSaved 
                && props.setIdDocument !== undefined 
                && documentObjLoaded.idDocument !== undefined) 
            {
                props.setIdDocument(documentObjLoaded.idDocument);
            }
            else {
                
            }
            setTmpData(null);
        }
    },[documentObjLoaded])

    useEffect(() => {
        if (tmpData !== null) {
            //setDocumentObj({...documentObj});
            setDocumentObj({...documentObj, Data: tmpData.split(",")[1]});
            (props.setDocumentWasChanged !== undefined) && props.setDocumentWasChanged(true);
        } 
        else if (!wasSaved && props.setDocumentWasChanged !== undefined) {
            props.setDocumentWasChanged(false);
        }
    },[tmpData])

    useEffect(() => {
        if (props.saveCounter > 0 && documentObj.Data != undefined && !needToRemoved) {

            let uploadObject = {...documentObj, isPublic: Boolean(documentObj.isPublic)}
            setPleaseWait(true);
            setWasSaved(true);
            uploadFetch("document",(documentObj.idDocument == undefined),uploadObject,setDocumentObjLoaded,setWasSuccessfully);
        }
    },[props.saveCounter])

    /// func: Setzen der Values
    function downloadFile() {
        if (documentObj?.Data != undefined) {
            // Link -> https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
            // decode a Base64-encoded string into a new string with a character for each byte of the binary data.
            let byteCharacters = atob(documentObj?.Data);
            // Each character's code point (charCode) will be the value of the byte. 
            // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
            let byteArray = new Uint8Array(byteNumbers);
            // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
            let blob = new Blob([byteArray], {type: mimeTypeArray.find(x => x.idMIMEType === documentObj.idMIMEType)?.MIMEType});
            // Erstelle Link zum BLOB
            let blobUrl = URL.createObjectURL(blob);
            // Erstelle html-Objekt für den Download
            let a = document.createElement("a");
            // setze link auf den neu erstellen Link zum Blolb
            a.href = blobUrl;
            // Setze Downloadtitle für den Datenname
            a.download = documentObj.FileName;
            // Click 
            a.click();
        }
    }
    /// end: func
    
    const setFromFile = (uplodatedFile:File) => {
        if (uplodatedFile !== undefined && "name" in uplodatedFile && "type" in uplodatedFile) {
            let fileName:string = String(uplodatedFile["name"]);
            let mimeType: IMIMEType | undefined = mimeTypeArray.find(x => x.MIMEType === uplodatedFile["type"])
            
            if (mimeType === undefined) {
                setModalIsOpenMIMIEError(true);
            } else {
                // Init FileRead
                let reader = new FileReader();
                reader.onload = (event:object) => {
                    // Caste als Base64
                    let base64WithMime = String(reader.result);
                    console.log(base64WithMime);
                    setTmpData(base64WithMime);
                    setDocumentObj({
                        ...documentObj,
                        //Data: base64WithMime.split(".")[1],
                        FileName: fileName,
                        idMIMEType: mimeType!.idMIMEType
                    })
                }
                // Lade datei in Reader
                reader.readAsDataURL(uplodatedFile);
            }
        }
    }

    /// const: Tmp. Speichern der Datei
    const handleFileUpload = (e:React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files != undefined) {
            let uplodatedFile = e.target.files[0];
            //setDocumentObj({...documentObj, Data: (e.target.files[0])});
            setFromFile(uplodatedFile);
        }
    };
    /// end: const

    const deleteRoutine = () => {
        if (props.droppedFile != undefined) {
            setNeedToRemoved(true);
            console.log("test")
        } else {
            setTmpData(null); setDocumentObj({...documentOrgObj});
        }
    }


    if (!wasSuccessfully) {
        return <>Fehler bei Dokument</>
    }
    else if (needToRemoved) {
        return (<></>);
    }
    else if (rowMode) {
        return (
            <>
                <TableRow>
                    <TableCell>
                        <TextField
                            select
                            disabled={props.isEmpty}
                            value={String(documentObj.idDocumentType)}
                            onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setDocumentObj({...documentObj,idDocumentType: Number(event.target.value) }) }
                            variant="standard"
                            fullWidth
                        >
                            {documentTypeArray.map(x => 
                                <MenuItem value={x.idDocumentType}>
                                    {x.DocumentType}
                                </MenuItem>
                            )}
                        </TextField>
                        {/*documentTypeArray.find(x => x.idDocumentType === documentObj.idDocumentType)?.DocumentType*/}
                    </TableCell>

                    <TableCell>
                        <TextField
                            select
                            disabled={props.isEmpty}
                            value={documentObj.isPublic ? "yes" : "no"}
                            onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setDocumentObj({...documentObj, isPublic: (event.target.value === "yes") }) }
                            variant="standard"
                            fullWidth
                        >
                            <MenuItem value="yes">Ja</MenuItem>
                            <MenuItem value="no">Nein</MenuItem>
                        </TextField>
                    </TableCell>

                    <TableCell>{(pleaseWait) ? "Bitte Warten..." : documentObj.FileName}</TableCell>
                    <TableCell>
                        <input type="file" onChange={handleFileUpload} hidden={true} ref={fileUploadElement} accept={String(mimeTypeArray.map(x => x.MIMEType).join(", "))}/>

                        <IconButton disabled={props.isEmpty || tmpData === null} size="small" style={{marginLeft: 5}}  onClick={() => deleteRoutine()} color="error"><Delete/></IconButton>
                        <IconButton disabled={props.isEmpty || pleaseWait} size="small" style={{marginLeft: 5}}  onClick={() => fileUploadElement.current?.click()}><FileUpload/></IconButton>
                        <IconButton disabled={documentObj.Data == undefined} size="small" style={{marginLeft: 5}}  onClick={() => downloadFile()} color={(tmpData === null) ? "success" : "warning"}><FileDownload/></IconButton>
                    </TableCell>
                </TableRow>
            </>
        ) 
    }
    else {
        return(
            <>
            {/*
                <GridItem sm={8}>
                    <Typography>{props.label}</Typography>
                    <IconButton onClick={() => downloadFile()}><FileDownload/></IconButton>
                </GridItem>
        */}
                <GridItem sm={6}>
                    <Typography style={{marginTop: 5}}>{ (pleaseWait) ? "Bitte warten..." : props.label}{documentObj.FileName !== "" && `: ${documentObj.FileName}` } {(tmpData !== null) && "(nicht gespeichert)"}</Typography>
                </GridItem>
                <GridItem sm={6}>
                    
                    <div style={{float: 'right'}}>
                        <input type="file" onChange={handleFileUpload} hidden={true} ref={fileUploadElement} accept={String(mimeTypeArray.map(x => x.MIMEType).join(", "))}/>
                        <Button disabled={props.isEmpty || tmpData === null} size="small" style={{marginLeft: 5}}  onClick={() => { setTmpData(null); setDocumentObj({...documentOrgObj}) }} variant="outlined" color="error"><Delete/></Button>
                        <Button disabled={props.isEmpty || pleaseWait} size="small" style={{marginLeft: 5}}  onClick={() => fileUploadElement.current?.click()} variant="outlined"><FileUpload/></Button>
                        <Button disabled={documentObj.Data == undefined} size="small" style={{marginLeft: 5}}  onClick={() => downloadFile()}  variant="outlined" color={(tmpData === null) ? "success" : "warning"}><FileDownload/></Button>
                    </div>
                </GridItem>
                
            </>
        )
    }
}
export default Document;