import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUnit } from "../interfaces/IUnit";


const initialState =  { storeUnits: [] as IUnit[] }; 

const sliceUnit = createSlice({
    name: "UNITSARRAY",
    initialState,
    reducers: {
        setUnitArray: (state, action: PayloadAction<IUnit[]>) => {
            state.storeUnits = [...action.payload];
        },
        addUnitArray: (state, action: PayloadAction<IUnit>) => {
            state.storeUnits = [...state.storeUnits,action.payload];
        },
        updateUnitArray: (state, action: PayloadAction<IUnit>) => {
            let tmpArray =  state.storeUnits.map(x => x.idUnit === action.payload.idUnit ? action.payload : x);
            state.storeUnits = tmpArray;
        }
    }
})
export const { setUnitArray, addUnitArray, updateUnitArray
 } = sliceUnit.actions;
export default sliceUnit.reducer;