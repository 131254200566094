import { Check, Close } from '@mui/icons-material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../redux/combine_reducers';
import GenericOverview from '../../core/GenericOverview';
import RentAutomation from './RentAutomation';


const RentAutomations:React.FC = () => {
    const dispatch = useDispatch();
    const rentAutomationArray = useSelector((state: State) => state.rentAutomationArray.storeRentAutomation);
    // Selected Id
    const [selectedId,setSelectedId] = useState<number|undefined>();

    const columns: GridColDef[] = [
        { 
            field: 'idRentAutomation', 
            headerName: 'Nr.', 
            width: 90,
        },
        {
            field: 'Name',
            headerName: 'Name',
            flex: 1,
        }
    ];

    return(
        <GenericOverview
            headline="Buchungsautomatisierung"
            columns={columns}
            data={rentAutomationArray}
            idField="idRentAutomation"
            setTargetId={setSelectedId}
        >
            <RentAutomation idRentAutomation={selectedId}/>
        </GenericOverview>
    )

}
export default RentAutomations;