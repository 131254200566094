import React, { useEffect, useState } from "react";
import { IElectricityMeterReading } from "../../../interfaces/IElectricityMeterReading";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { deleteFetch } from "../../../hooks/useFetch";
import AlertRemove from "../../core/AlertRemove";


interface IProps {
    enableHighVoltage: boolean;
    electricityMeterReadingArray: IElectricityMeterReading[];
    setElectricityMeterReadingArray: Function;
}

interface IHelpDate {
    DateAsString: string;
    Text: string;
    disabled: boolean;
}

const dateToString = (currentDate: Date, useGerman: boolean = false) => {
    /*
    var firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    let yyyy = firstDay.getFullYear()
    let mm = firstDay.getMonth()+1
    return `${yyyy}-${}-${firstDay.getDate()}`
    */
    const [yyyy, mm, dd, h, i, s] = currentDate.toISOString().split(/T|:|-/);


    if (useGerman) {
        return `${dd}.${mm}.${yyyy}`
    } else {
        return `${yyyy}-${mm}-${dd}`
    }

}

export const dateToDateString = (stringOfDate: string) => {
    let splittetString = stringOfDate.split("-");
    return `${splittetString[2]}.${splittetString[1]}.${splittetString[0]}`
}

export const numberToStringKwh = (currentNumber: number | null) => {
    if (currentNumber === null) {
        return "-"
    } else {
        return currentNumber.toFixed(2).replace(".", ",") + " kWh"
    }
}

const getNewObject = (electricityMeterReadingArray: IElectricityMeterReading[]) => {
    let newId = -1;

    /*
    let currentDate = new Date();
    currentDate.setDate(1);
    */

    if (electricityMeterReadingArray.length > 0) {
        let newIdArray = Math.min(...electricityMeterReadingArray.map(x => x.idElectricityMeterReading));

        if (newIdArray <= newId) {
            newId = newIdArray - 1;
        }
    }

    return {
        idRoom: -1,
        Created_at: null,
        DateOfRecord: "",
        ElectricityMeterReading: (electricityMeterReadingArray.length > 0) ? electricityMeterReadingArray[0].ElectricityMeterReading : null,
        HighVoltageMeterReading: (electricityMeterReadingArray.length > 0) ? electricityMeterReadingArray[0].HighVoltageMeterReading : null,
        idElectricityMeterReading: newId
    } as IElectricityMeterReading;
}


const ElectricityMeterReadingOverview: React.FC<IProps> = (props) => {
    let isAdmin = localStorage.getItem('isAdmin');
    //
    const [isOpen, setIsOpen] = useState(false);
    //
    const [isOpenRemove, setIsOpenRemove] = useState(false);
    const [idElectricityMeterReadingRemove, setIdElectricityMeterReadingRemove] = useState<null|number>(null);
    const [wasRemoved,setWasRemoved] = useState(false);
    const [wasRemovedSuccessfully,setWasRemovedSuccessfully] = useState(true);
    //
    const [newObject, setNewObject] = useState(getNewObject(props.electricityMeterReadingArray));
    //
    const [possibleDateArray, setPossibleDateArray] = useState<IHelpDate[]>([]);
    //
    const [tempElectricityMeterReadingObject,setTempElectricityMeterReadingObject]  = useState<IElectricityMeterReading>();

    const handleOpenDialog = () => {


        if(tempElectricityMeterReadingObject?.DateOfRecord != undefined)
        {
            setNewObject({ ...newObject, DateOfRecord: tempElectricityMeterReadingObject?.DateOfRecord })
        }
        else{
            setNewObject(getNewObject(props.electricityMeterReadingArray));
        }
        setIsOpen(true);
    }

    const handleSaveDialog = () => {
        let tmpArray = [
            newObject,
            ...props.electricityMeterReadingArray
        ]
        props.setElectricityMeterReadingArray([
            ...tmpArray.sort((a, b) => new Date(a.DateOfRecord) < new Date(b.DateOfRecord) ? 1 : -1)
        ]);
        setIsOpen(false);
    }

    const handleRemove = (idElectricityMeterReading: number) => {
        if (idElectricityMeterReading < 0) {
            props.setElectricityMeterReadingArray([
                ...props.electricityMeterReadingArray.filter(x => x.idElectricityMeterReading !== idElectricityMeterReading)
            ])
        } else {
            setIdElectricityMeterReadingRemove(idElectricityMeterReading);
            setIsOpenRemove(true);
        }

    }
    const removeFromServer = () => {
        if (idElectricityMeterReadingRemove !== null) {
            deleteFetch(`electricityMeterReading/`,idElectricityMeterReadingRemove,setWasRemovedSuccessfully)
            props.setElectricityMeterReadingArray([
                ...props.electricityMeterReadingArray.filter(x => x.idElectricityMeterReading !== idElectricityMeterReadingRemove)
            ])
            setIsOpenRemove(false);
            setWasRemoved(true);
            setIdElectricityMeterReadingRemove(null);  
        } 
    }

    const getRemoveTsx = () => {
        if (idElectricityMeterReadingRemove === null) {
            return <DialogContent>Bitte warten...</DialogContent>
        } else {
            let testObject = props.electricityMeterReadingArray.find(x => x.idElectricityMeterReading === idElectricityMeterReadingRemove);

            if (testObject === undefined) {
                return <>
                        <DialogContent>
                            Fehler: Konnte Objekt nicht bestimmen!
                        </DialogContent> 
                        <DialogActions>
                            <Button variant="contained" color="success" onClick={() => setIsOpenRemove(false)}>Schließen</Button>
                        </DialogActions>
                    </>
            } else {
                return(
                    <>
                        <DialogContent>
                            Sind Sie Sicher, dass Sie den Zählerstand vom <i>{dateToDateString(testObject.DateOfRecord)}</i>
                            &nbsp;mit einem Wert von <i>{(testObject.ElectricityMeterReading === null) ? "- kWh" : numberToStringKwh(testObject.ElectricityMeterReading)}</i>
                            {(props.enableHighVoltage) &&
                                <>&nbsp;und einem Startstromzählerstand von {(testObject.HighVoltageMeterReading === null) ? "- kWh" : numberToStringKwh(testObject.HighVoltageMeterReading)}</>
                            }
                            &nbsp;<u>entgültig löschen</u> wollen?
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="error" onClick={removeFromServer}>Löschen</Button>
                            <Button variant="contained" color="success" onClick={() => setIsOpenRemove(false)}>Schließen</Button>
                        </DialogActions>
                    </>
                )
            }
        }
    }

    useEffect(() => {
        let tmpArray: IHelpDate[] = [];
        let i = 0;
        let setDateToObject:string|null = null

        var today = new Date();
        today = new Date(today.getFullYear(), today.getMonth(), 1, 4);

        while (i < 13) {
            var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0, 4);
            let dateAsString = dateToString(lastDayOfMonth);

            if ((props.electricityMeterReadingArray.find(x => x.DateOfRecord === dateAsString) !== undefined)) {
                tmpArray.push({
                    DateAsString: dateAsString,
                    Text: dateToString(lastDayOfMonth, true),
                    disabled: true
                });
            } else {
                tmpArray.push({
                    DateAsString: dateAsString,
                    Text: dateToString(lastDayOfMonth, true),
                    disabled: false
                });
                if (setDateToObject === null) {
                    setDateToObject = dateAsString;
                }
            }

            today.setMonth(today.getMonth() - 1);

            /*
            tmpArray.push({
                DateAsString: dateAsString,
                Text: dateToString(currentDate,true),
                disabled: (props.electricityMeterReadingArray.find(x => x.DateOfRecord === dateAsString) !== undefined)
            });

            currentDate.setMonth(currentDate.getMonth()-1);
            */
            i = i + 1;
        }
        setPossibleDateArray(tmpArray);
        if (setDateToObject !== null) {
            setTempElectricityMeterReadingObject({... newObject, DateOfRecord: setDateToObject})
            setNewObject({ ...newObject, DateOfRecord: setDateToObject })
        }
    
    }, [props.electricityMeterReadingArray])

    return (
        <>
            <AlertRemove
                wasRemoved={wasRemoved}
                setWasRemoved={setWasRemoved}
                wasSuccessullyRemoved={wasRemovedSuccessfully}
            />

            <Dialog
                open={isOpenRemove}
                onClose={() => setIsOpenRemove(false)}
            >
                <DialogTitle>Zählerstand löschen</DialogTitle>
                {getRemoveTsx()}
            </Dialog>

            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <DialogTitle>Neuer Zählerstand</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Abrechnungsmonat"
                        value={newObject.DateOfRecord}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewObject({ ...newObject, DateOfRecord: event.target.value })}
                        select
                        fullWidth
                        sx={{ mt: 2 }}
                        size="small"
                    >
                        {possibleDateArray.map(x =>
                            <MenuItem
                                key={x.DateAsString}
                                value={x.DateAsString}
                                disabled={x.disabled}
                            >{x.Text}</MenuItem>
                        )}
                    </TextField>
                    <TextField
                        type="number"
                        label="Zählerstand (normal)"
                        value={(newObject.ElectricityMeterReading === null) ? "" : newObject.ElectricityMeterReading}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewObject({ ...newObject, ElectricityMeterReading: (event.target.value === "") ? null : Number(event.target.value) })}
                        fullWidth
                        size="small"
                        sx={{ mt: 2 }}
                    />
                    { (props.enableHighVoltage) &&
                        <TextField
                            type="number"
                            label="Zählerstand (Starkstrom)"
                            value={(newObject.HighVoltageMeterReading === null) ? "" : newObject.HighVoltageMeterReading}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewObject({ ...newObject, HighVoltageMeterReading: (event.target.value === "") ? null : Number(event.target.value) })}
                            fullWidth
                            size="small"
                            sx={{ mt: 2 }}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsOpen(false)}>Schließen</Button>
                    <Button onClick={handleSaveDialog}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Monat</TableCell>
                        <TableCell>Wert (normal)</TableCell>
                        {(props.enableHighVoltage) && <TableCell>Wert (Starkstrom)</TableCell>}
                        <TableCell sx={{ maxWidth: 10 }}>
                            <IconButton
                                onClick={handleOpenDialog}
                                sx={{ float: "right" }} size="small"><Add /></IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.electricityMeterReadingArray.map(x =>
                        <TableRow key={x.idElectricityMeterReading}>
                            <TableCell>
                                {dateToDateString(x.DateOfRecord)}
                            </TableCell>
                            <TableCell>{(x.ElectricityMeterReading === null) ? "-" : numberToStringKwh(x.ElectricityMeterReading)}</TableCell>
                            {(props.enableHighVoltage) && <TableCell>{(x.HighVoltageMeterReading === null) ? "-" : numberToStringKwh(x.HighVoltageMeterReading)}</TableCell>}
                            <TableCell>
                                {(x.idElectricityMeterReading < 0 || Number(isAdmin) === 1)
                                    && <IconButton onClick={() => handleRemove(x.idElectricityMeterReading)}><Delete /></IconButton>
                                }
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    )
}
export default ElectricityMeterReadingOverview;