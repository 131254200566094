import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppendix } from "../interfaces/IAppendix";

const initialState =  { storeAppendix: [] as IAppendix[] }; 

const sliceAppendix = createSlice({
    name: "APPENDIXARRAY",
    initialState,
    reducers: {
        setAppendixArray: (state, action: PayloadAction<IAppendix[]>) => {
            state.storeAppendix = [...action.payload];
        },
        addAppendixArray: (state, action: PayloadAction<IAppendix>) => {
            state.storeAppendix = [...state.storeAppendix,action.payload];
        },
        updateAppendixArray: (state, action: PayloadAction<IAppendix>) => {
            let tmpArray =  state.storeAppendix.map(x => x.idAppendix === action.payload.idAppendix ? action.payload : x);
            state.storeAppendix = tmpArray;
        }
    }
})
export const { setAppendixArray, addAppendixArray, updateAppendixArray} = sliceAppendix.actions;
export default sliceAppendix.reducer;