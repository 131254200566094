import React, { useEffect, useState } from "react";
import { IDocument } from "../../interfaces/IDocument";
import { Alert, CircularProgress, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import { getFetch } from "../../hooks/useFetch";
import WaitingSequence from "../core/WaitingSequence";
import { truncate } from "fs";


export const RoomExport:React.FC = () => {
    const [isLoading,setIsLoading] = useState(false);
    const [dataObject,setDataObject] = useState<IDocument|null>(null);
    const [wasSuccessfully,setWasSuccessfully] = useState(true);

    const handleDownload = () => {
        setIsLoading(true);
        getFetch("room/export",undefined,setDataObject,setWasSuccessfully,true)
    }

    /// func: Setzen der Values
    function downloadFile() {
        if (dataObject?.Data != undefined) {
            // Link -> https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
            // decode a Base64-encoded string into a new string with a character for each byte of the binary data.
            let byteCharacters = atob(dataObject?.Data);
            // Each character's code point (charCode) will be the value of the byte. 
            // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
            let byteArray = new Uint8Array(byteNumbers);
            // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
            let blob = new Blob([byteArray], {type: "text/csv"});
            // Erstelle Link zum BLOB
            let blobUrl = URL.createObjectURL(blob);
            // Erstelle html-Objekt für den Download
            let a = document.createElement("a");
            // setze link auf den neu erstellen Link zum Blolb
            a.href = blobUrl;
            // Setze Downloadtitle für den Datenname
            a.download = dataObject.FileName;
            // Click 
            a.click();
        }
    }
    /// end: func

    useEffect(() => {
        if (dataObject !== null) {
            downloadFile();
            setIsLoading(false);
        }
    },[dataObject])

    return(
        <>
            <Dialog
                open={isLoading}
                onClose={() => {}}
            >
                <DialogContent>
                    <Typography>Export wird erstellt...</Typography>
                    
                    {(!wasSuccessfully) ? <Alert severity="error">Ein Fehler ist aufgetretten!</Alert>
                        : <CircularProgress sx={{mt: 3, ml: 3 }} size={100} style={{color: "#000000"}} />
                    }
                    
                </DialogContent>
            </Dialog>

            <IconButton sx={{float: "right"}} onClick={handleDownload}><FileDownload/></IconButton>
        </>
    )
}