import { Alert, Button, Snackbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { uploadFetch } from '../../hooks/useFetch';
import { IChildMetadata } from '../../interfaces/IChildMetadata';
import GridContainer from './GridContainer';
import GridItem from './GridItem';

export interface ISetValues {
    Attribute: string;
    Value: number | string | boolean | undefined | null;
    SetFunction: Function;
    PossibleUndefined?: boolean;
    Type?: "string" | "number" | "boolean";
    IsPrimaryKey?: boolean;
}

export interface IInputValues {
    InputElement: React.ReactNode;
    ColSpanSm?: number;
    ColSpanXs?: number;
}



interface IProps {
    headlineNew: string;
    headlineAttr?: string;
    headline?: string;
    setValuesDef: ISetValues[];
    inputFilds: IInputValues[];
    //saveFunction: Function;
    // Zum Hochladen
    endpoint: string;
    // Hauptelemeten
    mainObject?: any;
    setMainObject: Function;
    // Zum Validieren
    validatenArray: boolean[];
    // Metastates
    isEmpty: boolean;
    isNew: boolean;
    addUpdatedReduxArray?: Function;
    updatedReduxArray?: Function;
    addReduxArray?: Function;
    // Falls noch Childs vorhanden sind
    children?: React.ReactNode;
    setSaveCounterExtern?: Function;
    /// Wenn speichern als Child
    saveCounter?: number; // Wenn Child, nur dieser
    setIdFromParent?: number;
    setMetastate?: Function
    // Wenn Parent
    saveRequeire?: boolean; // Wenn Child, nur dieser
    childWasSaved?: boolean;
    setSaveCounter?: Function;
    setExternWasSaved?: Function;
}

const GenericEdit:React.FC<IProps> = (props) => {
    // Generische Metastates
    const [isValidated,setIsValidated] = useState(false);
    const [wasSaved,setWasSaved] = useState(false);
    const [wasSuccessfullySaved,setWasSuccessfullySaved] = useState(true);
    // Metastates
    const [saveCounter,setSaveCounter] = useState(0);
    // für externe Healt-States
    const [isExternValidated,setIsExternValidated] = useState(true);
    const [wasExternSaved,setWasExternSaved] = useState(true);
    const [wasExternSavedSuccessfully,setWasExternSavedSuccessfully] = useState(true);

    useEffect(() => {
        setIsValidated(props.validatenArray.every(x => x == true));
    },[props.validatenArray])

    useEffect(() => {setValuesGeneric(props.mainObject)},[props.mainObject])

    useEffect(() => {
        if (props.saveCounter !== undefined) {
            setSaveCounter(props.saveCounter);
        }
        /*
        if (props.saveCounter !== undefined && props.saveCounter > 0 && (props.saveRequeire === undefined || props.saveRequeire === true)) {
            genericSave();
        }
        */
    },[props.saveCounter, props.saveRequeire])

    useEffect(() => {
        if (props.setMetastate !== undefined) {
            props.setMetastate({
                idChild: "child",
                isValidated: isValidated,
                wasSaved: wasSaved,
                wasSuccessfullySaved: wasSuccessfullySaved
            } as IChildMetadata)
        }
    },[isValidated,wasSaved,wasSuccessfullySaved])

    useEffect(() => {
        (props.setSaveCounterExtern !== undefined) && props.setSaveCounterExtern(saveCounter);
        console.log("TRIGGERT!")
        console.log(props.childWasSaved)
        console.log(saveCounter)
        if (saveCounter > 0 && ( props.childWasSaved == undefined || props.childWasSaved == true )) {
            console.log("SAVE ROUTINE!")
            genericSave();
        }
    },[saveCounter,props.childWasSaved])

    const saveHandler = () => {
        if (props.saveCounter !== undefined && props.setSaveCounter !== undefined) {
            props.setSaveCounter(props.saveCounter+1);
        } else {
            genericSave();
        }
    }

    const genericSave = () => {
        console.log("GENERIC SAVE");
        console.log(props.setValuesDef.filter(x => (x.PossibleUndefined === undefined) || (x.PossibleUndefined !== undefined && x.PossibleUndefined == false)))
        console.log(props.setValuesDef.filter(x => (x.PossibleUndefined === undefined) || (x.PossibleUndefined !== undefined && x.PossibleUndefined == false)).filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey === false))
        console.log(props.setValuesDef.filter(x => (x.PossibleUndefined === undefined) || (x.PossibleUndefined !== undefined && x.PossibleUndefined == false)).filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey === false).every(x => x.Value != undefined))
        if (props.setValuesDef.filter(x => (x.PossibleUndefined === undefined) || (x.PossibleUndefined !== undefined && x.PossibleUndefined == false)).filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey === false).every(x => x.Value != undefined)) {
            let uploadObject:any = {};
            props.setValuesDef.map(x => {
                if (x.PossibleUndefined === undefined || x.PossibleUndefined == false) {
                    if (x.Type === undefined) {
                        uploadObject[x.Attribute] = x.Value;
                    } else {
                        if (x.Type === 'string') {
                            uploadObject[x.Attribute] = String(x.Value);
                        } 
                        else if (x.Type === 'number') {
                            uploadObject[x.Attribute] = Number(x.Value);
                        }
                        else {
                            uploadObject[x.Attribute] = Boolean(x.Value);
                        }
                    }
                    
                } else {
                    if (x.Value != undefined) {
                        if (x.Type === 'string') {
                            uploadObject[x.Attribute] = String(x.Value);
                        }
                        else if (x.Type === 'number') {
                            uploadObject[x.Attribute] = Number(x.Value);
                        }
                        else {
                            uploadObject[x.Attribute] = Boolean(x.Value);
                        }
                    }
                }
            })
            
            console.log(uploadObject);
            uploadFetch(props.endpoint,props.isNew,uploadObject, props.setMainObject,setWasSuccessfullySaved);
            setWasSaved(true);
        }
    }


    const setValuesGeneric = (targetObject:any|undefined) => {
        if (targetObject != undefined) {
            props.setValuesDef.map(x =>{
                if (x.PossibleUndefined === undefined || x.PossibleUndefined == false) {
                    if (x.Type === undefined) {
                        x.SetFunction(targetObject[x.Attribute])
                    } else {
                        if (x.Type === 'string') {
                            x.SetFunction(String(targetObject[x.Attribute]));
                        } 
                        else if (x.Type === 'number') {
                            x.SetFunction(Number(targetObject[x.Attribute]));
                        }
                        else {
                            x.SetFunction(Boolean(targetObject[x.Attribute]));
                        }
                    }
                    
                } else {
                    if (Object.hasOwn(targetObject,x.Attribute)  && targetObject[x.Attribute] != undefined) {
                        if (x.Type === 'string') {
                            x.SetFunction(String(targetObject[x.Attribute]));
                        } 
                        else if (x.Type === 'number') {
                            x.SetFunction(Number(targetObject[x.Attribute]));
                        }
                        else {
                            x.SetFunction(Boolean(targetObject[x.Attribute]));
                        }
                    }
                }
            })

            if (wasSaved) {
                if (props.addUpdatedReduxArray !== undefined) {
                    props.addUpdatedReduxArray(targetObject)
                } else {
                    if (props.isNew) {
                        (props.addReduxArray) && props.addReduxArray(targetObject);
                    } else {
                        (props.updatedReduxArray) && props.updatedReduxArray(targetObject);
                    }
                }
                setSaveCounter(0);
            }
        }
    }


    return(
        <>
            <Snackbar 
                key="savebar" 
                open={wasSaved} 
                autoHideDuration={6000} 
                onClose={() => setWasSaved(false)}  
                anchorOrigin={{ 'vertical' : 'bottom', 'horizontal' : 'right' }} 
            >
                <Alert onClose={() => setWasSaved(false)} severity={(wasSuccessfullySaved) ? "success" : "error"} sx={{ width: '100%' }}>
                    {(wasSuccessfullySaved) ? "Erfolgreich gespeichert!" : "Es ist ein Fehler aufgetretten!"}
                </Alert>
            </Snackbar>

            <Typography variant='h5'>
                {(props.isEmpty) ? "Bitte Wählen" : (props.isNew) ? props.headlineNew : (props.headlineAttr === undefined && props.headline !== undefined) ? props.headline :  (props.mainObject === undefined  || props.headlineAttr === undefined || !Object.hasOwn(props.mainObject, props.headlineAttr)) ? "ERROR" : props.mainObject[props.headlineAttr]}
                
                { (props.saveCounter === undefined || (props.saveCounter !== undefined  && props.saveRequeire !== undefined )) &&
                    <div style={{float: "right"}}>
                        {/* <Button disabled={props.isEmpty || props.isNew} variant="outlined" color="error">Löschen</Button> */}
                        <Button disabled={props.isEmpty || !isValidated} style={{marginLeft: 5}} variant="contained" onClick={() => { setSaveCounter(saveCounter+1); /*genericSave();*/ }}>Speichern</Button>
                    </div>
                }
            </Typography>



            <Box sx={{mt: 3}}></Box>
            <GridContainer>
                {props.inputFilds.map(x => 
                    <GridItem sm={(x.ColSpanSm === undefined) ? 12 : x.ColSpanSm} xs={x.ColSpanXs}>
                        {x.InputElement}
                    </GridItem>
                )}
            </GridContainer>
            
            {(props.children !== undefined) && <Box sx={{mt: 5}} />}
            {(props.children !== undefined) && props.children}
        

        </>
    )

}
export default GenericEdit;