import { Alert, Box, Button, IconButton, LinearProgress, Snackbar, Tab, Tabs, Typography } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFetch, uploadFetch } from '../../hooks/useFetch';
import { IPrice } from '../../interfaces/IPrice';
import { IRent } from '../../interfaces/IRent';
import { IRoomFullObject } from '../../interfaces/IRoom';
import { actionCreatorsRooms } from '../../redux/actionCreators';
import { State } from '../../redux/combine_reducers';
import AlertError from '../core/AlertError';
import TabPanal from '../core/TabPanal';
import WaitingSequence from '../core/WaitingSequence';
import RoomEdit from './Childs/RoomEdit';
import RoomPriceEdit from './Childs/RoomPriceEdit';
import RoomAttributeEdit from './Childs/RoomAttributeEdit';
import RoomDocumentOverview from './Childs/RoomDocumentOverview';
import { IElectricityMeterReading } from '../../interfaces/IElectricityMeterReading';
import ElectricityMeterReadingOverview from './ElectricityMeterReading/ElectricityMeterReadingOverview';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Link, LinkOff } from '@mui/icons-material';
import GenericOverview from '../core/GenericOverview';

interface IProps {
    idRoom?: number;
}


const dateStringToDeSting = (dateString:String|undefined) => {
    if (dateString == undefined) {
        return "-";
    } else {
        let splittetDateString = dateString.split("-");
        if (splittetDateString.length !== 3) {
            return "ERROR";
        } else {
            return `${splittetDateString[2]}.${splittetDateString[1]}.${splittetDateString[0]}`
        }
    }
}



const RoomFullObject:React.FC<IProps> = (props) => { 
    const dispatch = useDispatch();
    const rentArray = useSelector((state: State) => state.rentArray.storeRent);
    const { setRoomArray, addRoomArray, updataRoomArray } = bindActionCreators(actionCreatorsRooms, dispatch);
    //
    const [currentObject, setCurrentObject] = useState<IRoomFullObject|null>(null);
    const [electricityMeterReadingArray, setElectricityMeterReadingArray] = useState<IElectricityMeterReading[]>([]);
    // 
    const [isNew, setIsNew] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [wasSuccesfully,setWasSuccesfully] = useState(true);
    const [wasSaved,setWasSaved] = useState(false);
    const [wasSuccesfullySaved,setWasSuccesfullySaved] = useState(true);
    //
    const [value, setValue] = React.useState(0);
    //
    const [possibleRequestRentArray,setPossibleRequestRentArray] = useState<IRent[]>([]);
    const [possibleRentArray,setPossibleRentArray] = useState<IRent[]>([]);
    //


    const columns: GridColDef[] = [
        {
            field: 'RentNummer',
            headerName: 'Nr.',
            width: 100,
        },
        {
            field: 'RentType',
            headerName: 'Art',
            width: 100
        },
        {
            field: 'RentState',
            headerName: 'Status',
            flex: 1
        },
        {
            field: 'FromDate',
            headerName: 'Von',
            width: 100,
            valueGetter: (params: GridValueGetterParams) =>  dateStringToDeSting(params.row.FromDate)
        },
        {
            field: 'ToDate',
            headerName: 'Bis',
            width: 100,
            valueGetter: (params: GridValueGetterParams) =>  dateStringToDeSting(params.row.ToDate)
        },
        {
            field: 'Action',
            headerName: 'Aktionen',
            width: 100,
            renderCell: (params: GridRenderCellParams) : React.ReactNode => {
                return(
                    <>
                        <IconButton
                            title='Buchung öffnen'
                            onClick={() =>{
                                window.open(`https://login.workbox.berlin/customers/${params.row.idPerson}/${params.row.idRent}`, "_blank")
                            }}
                        ><Link/></IconButton>
                    </>
                )
            }
        },
    ];

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };


    const setCurrentObjectWrapper = (localCurrentObject:IRoomFullObject) => {
        setElectricityMeterReadingArray(localCurrentObject.ElectricityMeterReadingArray);
        setCurrentObject(localCurrentObject);
    }


    useEffect(() => {
        if (props.idRoom == undefined) {
            setCurrentObject(null);
            setIsNew(false);
            setWasSuccesfully(true);
            setPossibleRequestRentArray([]);
            setPossibleRentArray([]);
            setElectricityMeterReadingArray([]);
        }
        else if (props.idRoom > 0) {
            setIsNew(false);
            getFetch("room/",props.idRoom,setCurrentObjectWrapper,setWasSuccesfully);
            setPossibleRequestRentArray(
                rentArray
                    .filter(x => x.idRoom === props.idRoom)
                    .filter(x => x.idRentType === 1)
                    .filter(x => x.FromDate != undefined  && new Date(x.FromDate) <=  new Date())
                    .filter(x => x.ToDate != undefined && new Date(x.ToDate) >=  new Date())
            );
            setPossibleRentArray(
                rentArray
                    .filter(x => x.idRoom === props.idRoom)
                    .filter(x => x.idRentState < 20)
                    .filter(x => x.idRentType === 3)
                    .filter(x => x.FromDate != undefined  && new Date(x.FromDate) <=  new Date())
                    .filter(x => x.ToDate != undefined && new Date(x.ToDate) >=  new Date())
            );
        }
        else if (props.idRoom < 0) {
            setIsNew(true);
            setWasSuccesfully(true);
            setElectricityMeterReadingArray([]);
            setPossibleRequestRentArray([]);
            setPossibleRentArray([]);
            setCurrentObject({
                CustomerDescription: "",
                idLocation: 1,
                Documents: [],
                idRoomType: 1,
                isBlocked: false,
                Month_1: { MinUnits: 1, PricePerUnit: null, idUnit: 1, } as IPrice,
                Month_3: { MinUnits: 3, PricePerUnit: null, idUnit: 1, } as IPrice,
                Month_6: { MinUnits: 6, PricePerUnit: null, idUnit: 1, } as IPrice,
                Month_12: { MinUnits: 12, PricePerUnit: null, idUnit: 1, } as IPrice,
                RoomNumber: "",
                SquareMeters: null,
                Title: "",
                ActionText_Temp: "",
                Floor: null,
                Height: null,
                Length: null,
                MaxPerson: null,
                PhoneNumber: null,
                Volume: null,
                Width: null,
                DoorHeight: null,
                DoorWidth: null,
                hasDaylight: false,
                hasHighVoltageCurrent: false,
                hasWaterSupply: false,
                isStudio: false,
                ElectricityMeterReadingArray: []
            } as IRoomFullObject);
        }
    },[props.idRoom])

    const saveWrapper = (localRoomObject:IRoomFullObject|null) => {
        if (localRoomObject != undefined) {
            if (isNew) {
                addRoomArray(localRoomObject);
            } else {
                updataRoomArray(localRoomObject);
            }
            setElectricityMeterReadingArray(localRoomObject.ElectricityMeterReadingArray);
            setIsNew(false)
        }
        setIsLoading(false);
    }


    const saveRoom = () => {
        if (currentObject != undefined) {
            setIsLoading(true);
            uploadFetch("/room",isNew,
                {
                    ...currentObject, 
                    Floor: "", 
                    PhoneNumber: "", 
                    MaxPerson: -1,
                    RoomNumber: String(currentObject.RoomNumber),
                    ElectricityMeterReadingArray: electricityMeterReadingArray
                }
                ,saveWrapper,setWasSuccesfullySaved,setIsLoading);
            setWasSaved(true);
        }

    }


    if (props.idRoom == undefined) { return <>Bitte Raum wählen...</> }
    else if (!wasSuccesfully) { return <><AlertError /> </>}
    else if ( currentObject == null || (currentObject.idRoom != props.idRoom && props.idRoom !== -1)) { return <><WaitingSequence /></> }
    else {
        return(
            <>
                <Snackbar 
                    key="savebar" 
                    open={wasSaved} 
                    autoHideDuration={6000} 
                    onClose={() => setWasSaved(false)}  
                    anchorOrigin={{ 'vertical' : 'bottom', 'horizontal' : 'right' }} 
                >
                    <Alert onClose={() => setWasSaved(false)} severity={(wasSuccesfullySaved) ? "success" : "error"} sx={{ width: '100%' }}>
                        {(wasSuccesfullySaved) ? "Erfolgreich gespeichert!" : "Es ist ein Fehler aufgetretten!"}
                    </Alert>
                </Snackbar>


                <Typography variant='h5'>
                    Raum
                    <Button 
                        sx={{float: "right"}} 
                        variant='contained' 
                        onClick={() => saveRoom()}
                        disabled={
                            currentObject.RoomNumber == undefined || currentObject.RoomNumber === ""
                            || currentObject.Title == undefined || currentObject.Title === ""
                            || currentObject.SquareMeters === null
                            || currentObject.Volume === null
                            || currentObject.Height === null
                            || currentObject.Width === null
                            || currentObject.Height === null
                            || currentObject.Month_1.PricePerUnit === null
                            || currentObject.Month_3.PricePerUnit === null
                            || currentObject.Month_6.PricePerUnit === null
                            || currentObject.Month_12.PricePerUnit === null
                            || isLoading
                        }
                    >Speichern</Button>
                </Typography>
                <br />
                {(isLoading) && <LinearProgress />} 

                <Typography variant='h6' sx={{mt: 3, mb: 7}}>
                    <div style={{float: "right"}}>
                        { 
                            (currentObject.isBlocked) 
                                ? <>Blockiert</>
                                : (possibleRentArray.length > 1)
                                    ? <>Fataler Fehler: Multiple Buchungen!</>
                                    : (possibleRentArray.length == 1)
                                        &&
                                            <>
                                                Besetzt: {dateStringToDeSting(possibleRentArray[0].FromDate)} - {dateStringToDeSting(possibleRentArray[0].ToDate)}</>
                                        
                        }
                    </div>
                    <div style={{float: "right"}}>
                        { 

                            (possibleRequestRentArray.length >= 1)
                                    &&
                                    possibleRequestRentArray.map(x => 
                                        <>
                                            Anfrage: {dateStringToDeSting(x.FromDate)} - {dateStringToDeSting(x.ToDate)}
                                        </>
                                    )
                        }
                    </div>
                </Typography>

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Raum" />
                            <Tab label="Preise" />
                            <Tab label="Besonderheiten" />
                            <Tab label="Stromzähler" />
                            <Tab label="Dokumente" />
                            <Tab label="Mieten" />
                        </Tabs>
                    </Box>

                    <TabPanal value={value} index={0}>
                        <RoomEdit key={currentObject.idRoom} roomObject={currentObject} setRoomObject={setCurrentObject} />
                    </TabPanal>


                    <TabPanal value={value} index={1}>
                        <RoomPriceEdit key={currentObject.idRoom} roomObject={currentObject} setRoomObject={setCurrentObject} />
                    </TabPanal>

                    <TabPanal value={value} index={2}>
                        <RoomAttributeEdit key={currentObject.idRoom} roomObject={currentObject} setRoomObject={setCurrentObject} />
                    </TabPanal>
                    
                    <TabPanal value={value} index={3}>
                        <ElectricityMeterReadingOverview 
                            key={`ElectricityMeterReadingOverview-idRoom-${props.idRoom}`}
                            enableHighVoltage={(currentObject !== null && currentObject.hasHighVoltageCurrent == true)}
                            electricityMeterReadingArray={electricityMeterReadingArray} 
                            setElectricityMeterReadingArray={setElectricityMeterReadingArray}
                        />
                    </TabPanal>

                    <TabPanal value={value} index={4}>
                        <RoomDocumentOverview key={currentObject.idRoom} roomObject={currentObject} setRoomObject={setCurrentObject} />
                    </TabPanal>

                    <TabPanal value={value} index={5}>
                        <GenericOverview
                            idField='idRent'
                            setTargetId={() => {}}
                            data={rentArray.filter(x => x.idRoom === Number(props.idRoom))}
                            columns={columns}
                            enableAddButton={false}
                            colDataGrid={12}
                        />
                    </TabPanal>
                </Box>
            
            </>
        )


    }
}
export default RoomFullObject;
