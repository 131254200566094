import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidatenText } from '../../../hooks/useValidaten';
import { IMedium } from '../../../interfaces/IMedium';
import { actionCreatorsMediums, actionCreatorsUnits } from '../../../redux/actionCreators';
import { State } from '../../../redux/combine_reducers';
import GenericEdit, { IInputValues, ISetValues } from '../../core/GenericEdit';
import InputField from '../../core/InputField';



interface IProps {
    idMedium?: number;
}


const Medium:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const mediumArray = useSelector((state: State) => state.mediumArray.storeMediums);
    const { setMediumArray, addMediumArray, updateMediumArray } = bindActionCreators(actionCreatorsMediums, dispatch)
    // Main Object
    const [mediumObject,setMediumObject] = useState<IMedium | undefined>();
    // Haupstates
    const [idMedium,setIdMedium] = useState<number>();
    const [medium,setMedium,vMedium] = useValidatenText();
    // Optionale States
    const [urlIdentifier,setUrlIdentifier] = useState<string | null>(null);
    // Validierung
    const [validationArray, setValidationArray] = useState<boolean[]>([]);
    // Metastatea
    const [isNew,setIsNew] = useState(false);
    const [isEmpty,setIsEmpty] = useState(true);
    const [isTime,setIsTime] = useState<boolean>(false);


    useEffect(() => {
        if (props.idMedium === undefined) {
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            resetValues();
            if (props.idMedium === -1) {
                setIsNew(true)
                
            } else {
                setIsNew(false);
                setMediumObject(mediumArray.find(x => x.idMedium=== props.idMedium));
            }
        }
    },[props.idMedium])

    useEffect(() => {setValidationArray([vMedium])},[vMedium])

    const valuesArray:ISetValues[] = [
        {
            Attribute: "idMedium",
            Value: idMedium,
            SetFunction: setIdMedium,
            IsPrimaryKey: true
        },
        {
            Attribute: "Medium",
            Value: medium,
            SetFunction: setMedium,
        },
        {
            Attribute: "URL_Identifier",
            Value: urlIdentifier,
            SetFunction: setUrlIdentifier,
            PossibleUndefined: true,
            Type: 'string'
        }
    ];

    const inputField:IInputValues[] = [
        {
            InputElement: <InputField isEdit={!isEmpty} label='Medium' value={medium} setFunction={setMedium} isValidated={vMedium}/>,
        },
        {
            InputElement: <InputField isEdit={!isEmpty} label='URL Kennung' value={urlIdentifier} setFunction={setUrlIdentifier} />,
        }
        
    ]

    const resetValues = () => {
        valuesArray.filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey == false).map(x => (x.Type !== undefined && x.Type === "boolean") ? x.SetFunction(false) : x.SetFunction(null))
    }

    


    return(
        <>  
            <GenericEdit
                headlineNew="Neues Medium"
                headlineAttr="Medium"
                setValuesDef={valuesArray}
                inputFilds={inputField}
                // Zum Hochladen
                endpoint="medium"
                // Hauptelemeten
                mainObject={mediumObject}
                setMainObject={setMediumObject}
                // Zum Validieren
                validatenArray={validationArray}
                // Metastates
                isEmpty={isEmpty}
                isNew={isNew}
                updatedReduxArray={updateMediumArray}
                addReduxArray={ addMediumArray}
            />
        </>
    )

}
export default Medium;