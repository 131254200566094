import { AddCircle, DashboardCustomizeRounded, NestCamWiredStandTwoTone, RestartAlt, Restore } from '@mui/icons-material';
import { Icon, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { render } from '@testing-library/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRoom } from '../../interfaces/IRoom';
import { State } from '../../redux/combine_reducers';
import GenericOverview from '../core/GenericOverview';
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import { staticRentTimeFrameArray } from '../Rent/TmpRentTimeFrameArray';
import PriceOverview from './Childs/PriceOverview';
import deLocale from "date-fns/locale/de";
import RoomFullObject from './RoomFullObject';
import { RoomExport } from './RoomExport';



const Rooms:React.FC = () => {
    const dispatch = useDispatch();
    const locationArray = useSelector((state: State) => state.locationArray.storeLocation);
    const roomTypeArray = useSelector((state: State) => state.roomTypeArray.storeRoomType);
    const timeFrameArray = staticRentTimeFrameArray;
    //const locationArray = useSelector((state: State) => state.locationArray.storeLocation);
    const roomArray = useSelector((state: State) => state.roomArray.storeRoom);
    const rentArray = useSelector((state: State) => state.rentArray.storeRent);
    //
    const [sortedArray,setSortedArray] = useState<IRoom[]>([]);
    // Selected Id
    const [selectedId,setSelectedId] = useState<number|undefined>();
    const [selectedIdTimeFrame, setSelectedIdTimeFrame] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    // Date States
    const [fromDate,setFromDate] = useState<Date>(new Date());
    const [toDate,setToDate] = useState<Date>(new Date());


    useEffect(() => {
        let tmpArray:IRoom[] = [];

        locationArray.map(x => {
            let extendArray:IRoom[]  = [];
            extendArray = roomArray
                .filter(y => Number(x.idLocation) === y.idLocation)
                .sort((a,b) => Number(a.RoomNumber) > Number(b.RoomNumber) ? 1 : -1);
            /*
            if (searchTerm === "") {
                extendArray = roomArray
                    .filter(y => Number(x.idLocation) === y.idLocation)
                    .sort((a,b) => Number(a.RoomNumber) > Number(b.RoomNumber) ? 1 : -1);
            } else {
                extendArray = roomArray
                    .filter(y => Number(x.idLocation) === y.idLocation)
                    .filter(y => )
                    .sort((a,b) => Number(a.RoomNumber) > Number(b.RoomNumber) ? 1 : -1);
            }
            */
            tmpArray = [...tmpArray,...extendArray];
        });

        setSortedArray([...tmpArray]);

    },[roomArray,toDate])

    useEffect(() => {
        (toDate < fromDate) && setToDate(new Date(fromDate));
    },[fromDate,toDate])

    /*
    useEffect(() => {
        if (selectedIdTimeFrame === 0) {
            //setFromDate(new Date());
            setToDate(new Date());
        } else {
            let currentDate = new Date();
            setToDate(new Date( currentDate.setMonth( currentDate.getMonth() + Number( timeFrameArray.find(x => x.idRentTimeFrame === selectedIdTimeFrame)?.Units ))));
        }
    },[selectedIdTimeFrame])
    */

    useEffect(() => {console.log(toDate)},[toDate])


    const tsxCurrentStateDot = (params: GridRenderCellParams) : React.ReactNode => {
        let currentColor : "#228B22" | "#99c58f" | "#D22B2B" | "#e6e600" = "#228B22";
        let value = 0;

        if (
            params.row.isBlocked == true 
            /* || rentArray
                .filter(x => x.idRoom === params.row.idRoom)
                .filter(x => x.idRentType === 1)/web/color/green-color.html
                .filter(x => x.FromDate != undefined  && new Date(x.FromDate) <=  new Date())
                .filter(x => x.ToDate != undefined && new Date(x.ToDate) >=  new Date())
                .length
            > 0
            */
        ) {
            currentColor = "#e6e600";
            value = 1;
        }
        /*
        else if (
            rentArray
                .filter(x => x.idRoom === params.row.idRoom)
                .filter(x => x.idRentType === 1)
                .filter(x => x.FromDate != undefined  && new Date(x.FromDate) <= toDate)
                .filter(x => x.ToDate != undefined && new Date(x.ToDate) >=  fromDate)
                .length
            > 0
        ) {
            currentColor = "#99c58f";
            value = 2;
        }
        */
        else if (             
            rentArray
                .filter(x => x.idRoom === params.row.idRoom)
                .filter(x => x.idRentState < 20)
                .filter(x => x.idRentType === 3)
                .filter(x => x.FromDate != undefined  && new Date(x.FromDate) <=  toDate)
                .filter(x => x.ToDate != undefined && new Date(x.ToDate) >= fromDate)
                .length
            > 0
            
        ) {
            currentColor = "#D22B2B";
            value = 3;
        }

        return (
            <span style={{
                    height: 25,
                    width: 25,
                    backgroundColor: currentColor,
                    borderRadius: "50%",
                    display: "inline-block",
                    //color: currentColor
                }}
            >
                <div style={{marginLeft: 5, color: currentColor}}>{value}</div>
            </span>
        )
    } 

    const columns: GridColDef[] = [
        { 
            field: 'RoomNumber', 
            headerName: 'WorkBox Nr.', 
            width: 90,
            //valueGetter: (params: GridValueGetterParams)
        },
        {
            field: 'idLoction',
            headerName: 'Standort',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => locationArray.find(x => x.idLocation === Number(params.row.idLocation))?.ShortName
        },
        {
            field: 'SquareMeters',
            headerName: 'qm',
            width: 90,
        },
        {
            field: 'idRoomType',
            headerName: 'Kategorie',
            width: 90,
            valueGetter: (params: GridValueGetterParams) => roomTypeArray.find(x => x.idRoomType === Number(params.row.idRoomType))?.RoomType
        },
        {
            field: 'Title',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'isBlocked',
            headerName: 'Status',
            width: 90,
            renderCell: (params: GridRenderCellParams) => tsxCurrentStateDot(params)
        },

    ];


    return(
        <>

            <Typography variant='h4' gutterBottom>
                    Räume
                    
                    <IconButton onClick={() => setSelectedId(-1)} style={{float: 'right'}}><AddCircle/></IconButton>
                    <RoomExport/>
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                <GridContainer>
                    <GridItem sm={5}>
                        <DesktopDatePicker
                            label="Verfügbar ab"
                            value={fromDate}
                            onChange={(newValue: Date | null) => (newValue !== null) && setFromDate(newValue) }
                            //minutesStep={15}
                            //maxDate={toDateTime}
                            renderInput={(params) => <TextField margin="normal" variant="outlined" {...params} fullWidth />}
                        />
                    </GridItem>

                    <GridItem sm={5}>
                        <DesktopDatePicker
                            label="Verfügbar bis"
                            value={toDate}
                            onChange={(newValue: Date | null) => (newValue !== null) && setToDate(newValue) }
                            
                            //minutesStep={15}
                            //maxDate={toDateTime}
                            renderInput={(params) => <TextField margin="normal" variant="outlined" {...params} fullWidth />}
                        />
                    </GridItem>

                    <GridItem sm={2}>
                        <div style={{float: "right", marginTop: 20}}>
                            <IconButton onClick={() => { setFromDate(new Date()); setToDate(new Date());  }}>
                                <RestartAlt/>
                            </IconButton>
                        </div>
                    </GridItem>
                </GridContainer>


            </LocalizationProvider>
        {/*
            <GridContainer>
                <GridItem sm={12}>
                    <TextField
                        select
                        label="Verfügbar"
                        value={String(selectedIdTimeFrame)}
                        onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setSelectedIdTimeFrame( Number(event.target.value) ) }
                        variant="outlined"
                        fullWidth
                    >
                        <MenuItem value={0}>Jetzt</MenuItem>
                        
                        {timeFrameArray.filter(x => x.idRentTimeFrame !== 1).map(x => 
                            <MenuItem value={x.idRentTimeFrame}>
                                Während der nächsten {x.Description}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>

                    <GridItem sm={8}>
                        <TextField
                            name="Suche"
                            label="Suche"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setSearchTerm(String(event.target.value)) }
                        />
                    </GridItem>
               
            </GridContainer>

            <GridContainer>
                <GridItem sm={12}>
                    <TextField
                        select
                        label="Verfügbar"
                        value={String(selectedIdTimeFrame)}
                        onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setSelectedIdTimeFrame( Number(event.target.value) ) }
                        variant="outlined"
                        fullWidth
                    >
                        <MenuItem value={0}>Jetzt</MenuItem>
                        
                        {timeFrameArray.filter(x => x.idRentTimeFrame !== 1).map(x => 
                            <MenuItem value={x.idRentTimeFrame}>
                                Während der nächsten {x.Description}
                            </MenuItem>
                        )}
                    </TextField>
                </GridItem>

                    <GridItem sm={8}>
                        <TextField
                            name="Suche"
                            label="Suche"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setSearchTerm(String(event.target.value)) }
                        />
                    </GridItem>
               
            </GridContainer>
         */}


            <GenericOverview
                columns={columns}
                data={sortedArray}
                idField="idRoom"
                setTargetId={setSelectedId}
                colDataGrid={6}
                enableAddButton={false}
            >
                <RoomFullObject idRoom={selectedId} />
                
            {/* 
            <PriceOverview
                idRoom={selectedId}
                <Room idRoom={selectedId} />
            />
            */}

                
            </GenericOverview>
        </>
    )

}
export default Rooms;