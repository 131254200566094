import { Help } from '@mui/icons-material';
import { Alert, AlertTitle, Collapse, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidatenText, useValidationNumber } from '../../../hooks/useValidaten';
import { IRentState } from '../../../interfaces/IRentState';
import { actionCreatorsRentState } from '../../../redux/actionCreators';
import { State } from '../../../redux/combine_reducers';
import GenericEdit, { IInputValues, ISetValues } from '../../core/GenericEdit';
import InputField from '../../core/InputField';
import TestRentMail from '../TestRentMail/TestRentMail';
import RentStateHasAppendizes from './RentStateHasAppendix/RentStateHasAppendizes';




interface IProps {
    idRentState?: number;
}


const RentState:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const rentStateAray = useSelector((state: State) => state.rentStateArray.storeRentState);
    const rentTypeArray = useSelector((state: State) => state.rentTypeArray.storeRentType);
    const { setRentStateArray,addRentStateArray, updateRentArray } = bindActionCreators(actionCreatorsRentState, dispatch)
    // Main Object
    const [rentStateObject,setRentStateObject] = useState<IRentState | undefined>();
    // Haupstates
    const [idRentState,setIdRentState] = useState<number | null>();
    const [state,setState, vState] = useValidatenText();
    const [sendMail,setSendMail] = useState<boolean>(false);
    const [createTmpToken,setCreateTmpToken] = useState<boolean>(false);
    const [idRentType,setIdRentType,vIdRentType] = useValidationNumber();
    // Haupstates -> Mail senden
    const [appendixFileName,setAppendixFileName] = useState<string | null>(null);
    const [title,setTitle] = useState<string | null>(null);
    const [headline_1,setHeadline_1] = useState<string | null>(null);
    const [headline_2,setHeadline_2] = useState<string | null>(null);
    const [body,setBody] = useState<string | null>(null);
    const [optionalFooter,setOptionalFooter] = useState<string | null>(null);
    // Validierung
    const [validationArray, setValidationArray] = useState<boolean[]>([]);
    // Metastatea
    const [isNew,setIsNew] = useState(false);
    const [isEmpty,setIsEmpty] = useState(true);
    const [saveCounter,setSaveCounter] = useState(0);


    useEffect(() => {
        resetValues();
        if (props.idRentState === undefined) {
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            
            if (props.idRentState === -1) {
                setIsNew(true)
            } else {
                setIsNew(false);
                setRentStateObject(rentStateAray.find(x => x.idRentState === props.idRentState));
            }
        }
    },[props.idRentState])

    useEffect(() => {
        setValidationArray([vState && vIdRentType && helpCheckVal(title) && helpCheckVal(body) ])
    },[vState,sendMail,title,body,vIdRentType])


    const helpCheckVal = (strState:string|null) => {
        if (sendMail) {
            return (strState !== null && strState !== "");
        } else {
            return true;
        }
    }



    const valuesArray:ISetValues[] = [
        {
            Attribute: "idRentState",
            Value: idRentState,
            SetFunction: setIdRentState,
            IsPrimaryKey: true
        },
        {
            Attribute: "idRentType",
            Value: idRentType,
            SetFunction: setIdRentType,
            PossibleUndefined: true,
            Type: 'number'
        }, 
        {
            Attribute: "State",
            Value: state,
            SetFunction: setState,
        }, 
        {
            Attribute: "sendMail",
            Value: sendMail,
            SetFunction: setSendMail,
            Type: 'boolean',
        }, 
        {
            Attribute: "createTmpToken",
            Value: createTmpToken,
            SetFunction: setCreateTmpToken,
            Type: 'boolean',
        }, 
        {
            Attribute: "MailTitle",
            Value: title,
            SetFunction: setTitle,
            PossibleUndefined: true,
            Type: 'string'
        }, 
        {
            Attribute: "AppendixFileName",
            Value: appendixFileName,
            SetFunction: setAppendixFileName,
            PossibleUndefined: true,
            Type: 'string'
        }, 
        {
            Attribute: "MailBody",
            Value: body,
            SetFunction: setBody,
            PossibleUndefined: true,
            Type: 'string'
        }, 
        /*
        {
            Attribute: "Headline_1",
            Value: headline_1,
            SetFunction: setHeadline_1,
            PossibleUndefined: true,
            Type: 'string'
        }, 
        {
            Attribute: "Headline_2",
            Value: headline_2,
            SetFunction: setHeadline_2,
            PossibleUndefined: true,
            Type: 'string'
        }, 
        {
            Attribute: "OptionalFooter",
            Value: optionalFooter,
            SetFunction: setOptionalFooter,
            PossibleUndefined: true,
            Type: 'string'
        }
        */
    ];

    const inputField:IInputValues[] = [
        {
            InputElement:      
            <Collapse in={rentStateObject != undefined && rentStateObject.WarningMessage != undefined} style={{marginTop: 25}}>
                <Alert severity="warning">
                    <AlertTitle>Warnung</AlertTitle>
                    {(rentStateObject != undefined && rentStateObject.WarningMessage != undefined) && rentStateObject.WarningMessage}
                </Alert>
            </Collapse>
        },
        {
            InputElement: <InputField isEdit={!isEmpty} label='Status' value={state} setFunction={setState} isValidated={vState}/>,
        },
        {
            InputElement:   <TextField
                                select
                                disabled={isEmpty}
                                label="Art"
                                value={String(idRentType)}
                                error={!vIdRentType && !isEmpty}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdRentType( Number(event.target.value) ) }
                                variant="standard"
                                fullWidth
                            >
                                {rentTypeArray.map(x => 
                                    <MenuItem value={x.idRentType}>
                                        {x.Type}
                                    </MenuItem>
                                )}
                            </TextField>
        },
        {
            InputElement:         <FormControl>
                                    <FormLabel>Temporärer Authentifizierungsschlüssel <span title="Ermöglicht das einmalige externe Authentifizieren (bspw. um Dokumente hochladen zu können)">{<Help/>}</span> </FormLabel>
                                    <RadioGroup 
                                        row
                                        defaultValue="false" 
                                        value={(createTmpToken) ? "true" : "false"} 
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setCreateTmpToken(event.target.value === "true") }
                                    >
                                        <FormControlLabel disabled={isEmpty} value="true" control={<Radio />} label="Ja" />
                                        <FormControlLabel disabled={isEmpty} value="false" control={<Radio />} label="Nein" />
                                    </RadioGroup>
                                </FormControl>
        },
        {
            InputElement:         <FormControl>
                                    <FormLabel>Mail versenden</FormLabel>
                                    <RadioGroup 
                                        row
                                        defaultValue="false" 
                                        value={(sendMail) ? "true" : "false"} 
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setSendMail(event.target.value === "true") }
                                    >
                                        <FormControlLabel disabled={isEmpty} value="true" control={<Radio />} label="Ja" />
                                        <FormControlLabel disabled={isEmpty} value="false" control={<Radio />} label="Nein" />
                                    </RadioGroup>
                                </FormControl>
        },
        {
            InputElement:   <>
                                <RentStateHasAppendizes idRentState={idRentState} isEmpty={isEmpty} isNew={isNew} saveCounter={saveCounter}/>
                            </>
            ,
        },
        {
            InputElement: <InputField isEdit={!isEmpty && sendMail} label='Name der Anhangsdatei' value={appendixFileName} setFunction={setAppendixFileName} />,
        },
        {
            InputElement: <InputField isEdit={!isEmpty && sendMail} label='Title' value={title} setFunction={setTitle} isValidated={helpCheckVal(title)} />,
        },
        /*
        {
            InputElement: <InputField isEdit={!isEmpty && sendMail} label='Erste Überschrift' rows={3} value={headline_1} setFunction={setHeadline_1}  isValidated={helpCheckVal(headline_1)}/>,
        },
        {
            InputElement: <InputField isEdit={!isEmpty && sendMail} label='Zweite Überschrift' rows={3} value={headline_2} setFunction={setHeadline_2} isValidated={helpCheckVal(headline_2)}/>,
        },
        */
        {
            InputElement: <InputField isEdit={!isEmpty && sendMail} label='Emailkörper' value={body} rows={20} setFunction={setBody} isValidated={helpCheckVal(body)}/>,

        },
        {
            InputElement: <div style={{minHeight: 200, backgroundColor: "with"}}><Typography>HTML Ansicht:</Typography> <div dangerouslySetInnerHTML={{ __html: (body === null) ? "" : body }} /></div>,
        },
        /*
        {
            InputElement: <InputField isEdit={!isEmpty && sendMail} label='Fußzeile' rows={3} value={optionalFooter} setFunction={setOptionalFooter}/>,
        },
        */

    ]

    const resetValues = () => {
        valuesArray.filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey == false).map(x => (x.Type !== undefined && x.Type === "boolean") ? x.SetFunction(false) : x.SetFunction(null))
    }


    return(
        <>  
            <TestRentMail mailTitle={title} mailBody={body} disabled={isEmpty || !sendMail} />
            <GenericEdit
                headlineNew="Neuer Buchungsstatus"
                headlineAttr="State"
                setValuesDef={valuesArray}
                inputFilds={inputField}
                // Zum Hochladen
                endpoint="rentState"
                // Hauptelemeten
                mainObject={rentStateObject}
                setMainObject={setRentStateObject}
                // Zum Validieren
                validatenArray={validationArray}
                // Metastates
                isEmpty={isEmpty}
                isNew={isNew}
                updatedReduxArray={updateRentArray}
                addReduxArray={ addRentStateArray}
                setSaveCounterExtern={ setSaveCounter }
            />
        </>
    )

}
export default RentState;