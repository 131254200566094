import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IVat } from "../interfaces/IVat";
const initialState =  { storeVAT: { VAT: 0 } as IVat }; 

const sliceVat = createSlice({
    name: "VATOBJECT",
    initialState,
    reducers: {
        setVatObject: (state, action: PayloadAction<IVat>) => {
            state.storeVAT = {...action.payload};
        },
    }
})
export const { setVatObject} = sliceVat.actions;
export default sliceVat.reducer;