import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProductHasAsset } from "../interfaces/IProductHasAsset";


const initialState =  { storeProductHasAsset: [] as IProductHasAsset[] }; 

const sliceProductHasAsset = createSlice({
    name: "PRODUCTHASASSTS",
    initialState,
    reducers: {
        setProductHasAssetArray: (state, action: PayloadAction<IProductHasAsset[]>) => {
            state.storeProductHasAsset = [...action.payload];
        },
        addUpdateProductHasAssetArray: (state, action: PayloadAction<IProductHasAsset>) => {
            state.storeProductHasAsset = [...state.storeProductHasAsset,action.payload];
        },
        deleteProductHasAssetArray: (state, action: PayloadAction<IProductHasAsset>) => {
            state.storeProductHasAsset = [...state.storeProductHasAsset.filter(x  => x.idProduct !== action.payload.idProduct || x.idAsset !== action.payload.idAsset)];
        }
    }
})
export const { setProductHasAssetArray, addUpdateProductHasAssetArray, deleteProductHasAssetArray } = sliceProductHasAsset.actions;
export default sliceProductHasAsset.reducer;