import React, { useState } from 'react';
import {  Box, Button, CssBaseline, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { AccessAlarm, Accessibility, Apartment, AttachEmail, BorderAll, Brush, BugReport, Business, CalendarMonth, Category, ChevronLeft, ChevronRight, CompareArrows, Dvr, Euro, ExitToApp, FmdGood, Gradient, Home, LocalOffer, Logout, MeetingRoom, Payment, PeopleAlt, Person, PrecisionManufacturing, PresentToAll, ProductionQuantityLimits, RoomService, Stars, ThreeDRotation, Upcoming } from '@mui/icons-material';
import  DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { OverridableComponent } from '@mui/material/OverridableComponent';



const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean; 
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface INavElement {
    ListItemKey: string;
    Navigate: string;
    Icon: React.ReactElement;
    ListItemText: string;
    Divider?: boolean;
    isAdminView?: boolean;
    isMarketingView?: boolean;
}

export interface IProps {
    children?: React.ReactNode;
}


const Navbar:React.FC<IProps> = (props) => {
    const theme = useTheme();
    const [isSidebarOpen,setIsSidebarOpen] = useState(false);
    const [pageName,setPageName] = useState("Home");
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('bearer_token');
        window.location.reload();
    }

    const naviagtionFunc = (targetUrl:string,pageName:string) => {
        setPageName(pageName);
        navigate(targetUrl);
        setIsSidebarOpen(false);
    }


    const menuArray:INavElement[] = [
        {
            ListItemKey: "home",
            Navigate: "/home",
            Icon: <Home/>,
            ListItemText: "Home",
            Divider: true
        },
        {
            ListItemKey: "Kontakte",
            Navigate: "/customers",
            Icon: <Person/>,
            ListItemText: "Kontakte",
        },
        /*
        {
            ListItemKey: "Übergabeprotokoll",
            Navigate: "/handoverprotocol",
            Icon: <Brush/>,
            ListItemText: "Übergabeprotokoll",
        },
        */
        {
            ListItemKey: "calender",
            Navigate: "/calender",
            Icon: <CalendarMonth/>,
            ListItemText: "Kalenderansicht",
        },
        {
            ListItemKey: "rents",
            Navigate: "/rents",
            Icon: <RoomService/>,
            ListItemText: "Buchungen",
        },
        
        {
            ListItemKey: "inovice",
            Navigate: "/inovice",
            Icon: <Payment/>,
            ListItemText: "Rechnungen",
            Divider: true
        },
        
        /*
        {
            ListItemKey: "products",
            Navigate: "/products",
            Icon: <Category/>,
            ListItemText: "Produkte",
            isAdminView: true
        },
        */
        {
            ListItemKey: "rooms",
            Navigate: "/rooms",
            Icon: <MeetingRoom/>,
            ListItemText: "Raum",
            Divider: true
        },
        /*
        {
            ListItemKey: "roomPrices",
            Navigate: "/roomPrices",
            Icon: <Euro/>,
            ListItemText: "Raumpreise",
            isAdminView: true
        },
        {
            ListItemKey: "roomtypes",
            Navigate: "/roomTypes",
            Icon: <BorderAll/>,
            ListItemText: "Raumarten",
            Divider: true,
            isAdminView: true
        },
        {
            ListItemKey: "units",
            Navigate: "/units",
            Icon: <Gradient/>,
            ListItemText: "Einheiten"
        },
        */
        {
            ListItemKey: "Business",
            Navigate: "/companyCategory",
            Icon: <Gradient/>,
            ListItemText: "Unternehmenskategorime"
        },
        {
            ListItemKey: "locations",
            Navigate: "/locations",
            Icon: <Business/>,
            ListItemText: "Standorte",
            isAdminView: true
        },
        {
            ListItemKey: "regions",
            Navigate: "/regions",
            Icon: <FmdGood/>,
            ListItemText: "Regionen",
            Divider: true,
            isAdminView: true
        },
        {
            ListItemKey: "leadstates",
            Navigate: "/leadStates",
            Icon: <Accessibility/>,
            ListItemText: "Lead Status",
            isMarketingView: true
            
        },
        {
            ListItemKey: "leadsources",
            Navigate: "/leadSources",
            Icon: <CompareArrows/>,
            ListItemText: "Lead Quelle"
            
        },
        {
            ListItemKey: "compaign",
            Navigate: "/campaigns",
            Icon: <Stars/>,
            ListItemText: "Marketing utm_campaign",
            isMarketingView: true
            
        },
        {
            ListItemKey: "sources",
            Navigate: "/sources",
            Icon: <ExitToApp/>,
            ListItemText: "Marketing utm_source",
            isMarketingView: true
        },
        {
            ListItemKey: "mediums",
            Navigate: "/mediums",
            Icon: <PresentToAll/>,
            ListItemText: "Marketing utm_medium",
            isMarketingView: true,
            Divider: true
        },
        {
            ListItemKey: "rentStates",
            Navigate: "/rentStates",
            Icon: <Upcoming/>,
            ListItemText: "Buchungsstatus",
            isAdminView: true
        },
        /*
        {
            ListItemKey: "rentAutomation",
            Navigate: "/rentAutomations",
            Icon: <PrecisionManufacturing/>,
            ListItemText: "Buchungsautomatisierung",
            isMarketingView: true
        },
        */
        {
            ListItemKey: "appendizes",
            Navigate: "/appendizes",
            Icon: <AttachEmail/>,
            ListItemText: "Anhänge",
            isAdminView: true,
            Divider: true
        },
        {
            ListItemKey: "inovicePositionTemplate",
            Navigate: "/inovicePositionTemplate",
            Icon: <ProductionQuantityLimits/>,
            ListItemText: "Rechnungspositionen",
            isAdminView: true
        },
        /*
        {
            ListItemKey: "testingpublicrent",
            Navigate: "/testingPublicRent",
            Icon: <BugReport/>,
            ListItemText: "TESTING"
        },
        */
    ]

    const generateEntry = () => {
        let isAdmin = localStorage.getItem('isAdmin');
        let isMarketing = localStorage.getItem('isMarketing');

        return menuArray.map(x => {
            if (
                (x.isAdminView == undefined && x.isMarketingView == undefined)
                || (x.isAdminView === false && x.isMarketingView === false)
                || ((x.isAdminView === true || x.isMarketingView === true) && Number(isAdmin) === 1)
                || (x.isMarketingView === true &&  Number(isMarketing) === 1)
            ) {
                return (
                    <>
                        <ListItem key={x.ListItemKey} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton onClick={() => naviagtionFunc(x.Navigate,x.ListItemText)  }>
                            <ListItemIcon>{x.Icon}</ListItemIcon>
                            <ListItemText primary={x.ListItemText} />
                            </ListItemButton>
                        </ListItem>
                        {(x.Divider) && <Divider />}
                    </>
                )
            }
        })
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBar position="fixed" open={isSidebarOpen}  style={{ background: '#1A2027' }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{
                            marginRight: 5,
                            ...(isSidebarOpen && { display: 'none' }),
                          }}
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" noWrap component="div">
                    {pageName}
                    </Typography>

                    <Box display='flex' flexGrow={1} />
                    <Typography>v1.5.6</Typography>
                    <IconButton color="inherit" onClick={() => logout()}><Logout/></IconButton>
                </Toolbar>
            </AppBar>



            <Drawer
                variant="permanent" 
                open={isSidebarOpen}
                //onClose={() => setIsSidebarOpen(!isSidebarOpen)}
            >
                <DrawerHeader>
                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                        {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <Box
                    sx={{width: 250}}
                    role="presentation"
                    
                >
                    <List>
                        {generateEntry()}
                    </List>
                    
                </Box>
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {(props.children) && props.children}
            </Box>
        </Box>
    )
}
export default Navbar;
/*
<ListItem key="home" disablePadding>
    <ListItemButton onClick={() => changePage("home","Home") }>
        <ListItemIcon><Home/></ListItemIcon>
        <ListItemText primary="Home" />
    </ListItemButton>
</ListItem>
<Divider />

<ListItem key="reservierungen" disablePadding>
    <ListItemButton onClick={() => changePage("reservierungen","Test") }>
        <ListItemIcon><RoomService/></ListItemIcon>
        <ListItemText primary="Reservierungen" />
    </ListItemButton>
</ListItem>

<ListItem key="customer" disablePadding>
    <ListItemButton>
        <ListItemIcon><PeopleAlt/></ListItemIcon>
        <ListItemText primary="Kunden" />
    </ListItemButton>
</ListItem>


<Divider />

<ListItem key="products" disablePadding>
    <ListItemButton onClick={() => navigate("/products") }>
        <ListItemIcon><Category/></ListItemIcon>
        <ListItemText primary="Produkte" />
    </ListItemButton>
</ListItem>

<ListItem key="rooms" disablePadding>
    <ListItemButton>
        <ListItemIcon><Apartment/></ListItemIcon>
        <ListItemText primary="Räume" />
    </ListItemButton>
</ListItem>

<ListItem key="assets" disablePadding>
    <ListItemButton>
        <ListItemIcon><Dvr/></ListItemIcon>
        <ListItemText primary="Assets" />
    </ListItemButton>
</ListItem>

<ListItem key="roomtypes" disablePadding>
    <ListItemButton onClick={() => changePage("roomTypes","Raumarten") }>
        <ListItemIcon><BorderAll/></ListItemIcon>
        <ListItemText primary="Raumtypen" />
    </ListItemButton>
</ListItem>

<Divider />
<ListItem key="units" disablePadding>
    <ListItemButton onClick={() => changePage("units","Einheiten") }>
        <ListItemIcon><Gradient/></ListItemIcon>
        <ListItemText primary="Einheiten" />
    </ListItemButton>
</ListItem>

<ListItem key="regions" disablePadding>
    <ListItemButton onClick={() => changePage("regions","Regionen") }>
        <ListItemIcon><FmdGood/></ListItemIcon>
        <ListItemText primary="Regionen" />
    </ListItemButton>
</ListItem>
*/