import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getFetch, uploadFetch, useFetch } from "../../hooks/useFetch";
import { IRoom, IRoomFullObject } from "../../interfaces/IRoom";
import { ILocation } from "../../interfaces/ILocation";
import { IElectricityMeterReading } from "../../interfaces/IElectricityMeterReading";
import AlertError from "../core/AlertError";
import WaitingSequence from "../core/WaitingSequence";
import { DataGrid, deDE, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import ElectricityMeterReadingOverview, { dateToDateString, numberToStringKwh } from "../Room/ElectricityMeterReading/ElectricityMeterReadingOverview";
import { Edit, Search } from "@mui/icons-material";


interface IJanitor {
    RoomArray: IRoom[];
    LocationArray: ILocation[];
    ElectricityMeterReadingArray: IElectricityMeterReading[];
}


const JanitorSite:React.FC = () => {
    const  [janitorObject,setJanitorObject,wasSuccessfully] = useFetch<IJanitor>("janitor");
    //
    const [roomArray,setRoomArray] = useState<IRoom[]>([]);
    const [locationArray,setLocationArray] = useState<ILocation[]>([]);
    const [electricityMeterReadingArray,setElectricityMeterReadingArray] = useState<IElectricityMeterReading[]>([]);
    // Für neu
    const [currentLocation,setCurrentLocation] = useState<number|null>(null);
    const [currentRoom,setCurrentRoom] = useState<number|null>(null);
    //
    const [roomObject, setRoomObject] = useState<IRoomFullObject|null>(null);
    const [isLoadingFullObject,setIsLoadingFullObject] = useState(false);
    //
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [wasSaved,setWasSaved] = useState(false);
    const [wasSuccessfullySaved,setWasSuccessfullySaved] = useState(true);

    const columns: GridColDef[] = [
        { 
            field: 'idElectricityMeterReading', 
            headerName: 'Nr.', 
            maxWidth: 90,
            //valueGetter: (params: GridValueGetterParams)
        },
        {
            field: 'DateOfRecord',
            headerName: 'Abrechnungsmonat',
            maxWidth: 150,
            valueGetter: (params: GridValueGetterParams) => dateToDateString(params.row.DateOfRecord)
        },
        {
            field: 'LoctionShortName',
            headerName: 'Standort',
            maxWidth: 90,
        },
        {
            field: 'RoomTitle',
            headerName: 'Raum',
            width: 120,
        },
        {
            field: 'ElectricityMeterReading',
            headerName: 'Zählerstand (normal)',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => numberToStringKwh(params.row.ElectricityMeterReading)
        },
        {
            field: 'HighVoltageMeterReading',
            headerName: 'Zählerstand (Stromzähler)',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => numberToStringKwh(params.row.HighVoltageMeterReading)
        },
        {
            field: 'Action',
            headerName: 'Aktionen',
            maxWidth: 90,
            renderCell: (params: GridRenderCellParams) => 
                <IconButton
                    onClick={ () => {
                        setCurrentLocation(Number(params.row.idLocation));
                        setCurrentRoom(Number(params.row.idRoom));
                        setIsOpen(true);
                    }}
                ><Edit/></IconButton>
        },
    ]

    const handleClose = () => {
        setElectricityMeterReadingArray([
            ...electricityMeterReadingArray.filter(x => x.idElectricityMeterReading > 0)
        ]);
        setWasSuccessfullySaved(true);
        setIsOpen(false);
    }


    const handleNew = () => {
        setCurrentRoom(null);
        setIsOpen(true);
    }

    const handleSave = () => {
        if (currentRoom !== null) {
            let tmpArray = electricityMeterReadingArray
                .filter(x => x.idElectricityMeterReading < 0)
                .map(x => {return {...x, idRoom: currentRoom}})
            uploadFetch("/janitor/electricityMeterReading",
                true,
                tmpArray,
                setElectricityMeterReadingArray,
                setWasSuccessfullySaved,
                setIsLoading,
                setWasSaved
            );
        }
    }

    useEffect(() => {
        if (currentRoom !== null) {
            setIsLoadingFullObject(true);
            getFetch("janitor/roomPseudoFullObject/",currentRoom,(res:IRoomFullObject) => {setRoomObject(res); setIsLoadingFullObject(false)});
        }
    },[currentRoom])


    useEffect(() => {
        if (janitorObject != undefined) {
            setRoomArray(janitorObject.RoomArray);
            setLocationArray(janitorObject.LocationArray);
            setElectricityMeterReadingArray(janitorObject.ElectricityMeterReadingArray);
        }
    },[janitorObject])


    if (!wasSuccessfully) { return <AlertError/> }
    else if (janitorObject === undefined) { return <WaitingSequence/> }
    else {
        return(
            <>
                <Dialog
                    open={isOpen}
                    onClose={handleClose}
                    fullWidth
                    sx={{minWidth: "lg"}}
                >
                    <DialogTitle>Zählerstand</DialogTitle>
                    <DialogContent>
                        <TextField
                            sx={{mt: 2}}
                            label="Standort"
                            value={(currentLocation === null) ? "" : currentLocation}
                            onChange={(event:React.ChangeEvent<HTMLInputElement>) => setCurrentLocation(Number(event.target.value))}
                            fullWidth
                            size="small"
                            select
                        >
                            {locationArray.map(x => 
                                <MenuItem key={`location-${x.idLocation}`} value={x.idLocation}>{x.ShortName} // {x.Location}</MenuItem>
                            )}
                        </TextField> 
                        <TextField
                            sx={{mt: 2}}
                            label="Raum"
                            value={(currentRoom === null) ? "" : currentRoom}
                            onChange={(event:React.ChangeEvent<HTMLInputElement>) => setCurrentRoom(Number(event.target.value))}
                            fullWidth
                            size="small"
                            select
                        >
                            {roomArray.filter(x => 
                                (currentLocation === null) ? -1 : (x.idLocation === currentLocation)
                            ).map(x => 
                                <MenuItem key={`room-${x.idRoom}`} value={x.idRoom}>{x.Title}</MenuItem>
                            )}
                        </TextField> 

                        <Box sx={{mt: 5}} />
                        {(currentRoom === null) 
                            ? <>Bitte Raum wählen</>
                            :
                                (isLoadingFullObject) ? <>Bitte warten...</>
                                :
                                    <ElectricityMeterReadingOverview
                                        key={`meter-${currentRoom}`}
                                        enableHighVoltage={(roomObject != null && roomObject.hasHighVoltageCurrent == true)}
                                        electricityMeterReadingArray={electricityMeterReadingArray.filter(x => x.idRoom === currentRoom)}
                                        setElectricityMeterReadingArray={(elOb:IElectricityMeterReading[]) => {
                                            setElectricityMeterReadingArray([
                                                ...elOb.map(x => {return {...x, idRoom: currentRoom}}),
                                                ...electricityMeterReadingArray.filter(x => x.idRoom !== currentRoom)
                                            ])
                                        }}
                                    />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Schließen</Button>
                        <Button onClick={handleSave}>Speichern</Button>
                    </DialogActions>
                </Dialog>

                <Box sx={{p: 5, height: "100%"}}>
                    
                    <Typography variant="h3">
                        Zählerstände

                        <Button 
                            sx={{float: "right"}} 
                            variant="contained"
                            onClick={() => setIsOpen(true)}
                        ><Search/></Button>

                    </Typography>


                    <DataGrid
                        sx={{mt: 5}}
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        rows={electricityMeterReadingArray}
                        columns={columns}
                        getRowId={(row) => row["idElectricityMeterReading"]}
                        autoHeight                
                    />
                </Box>
            </>
        )
    }
}
export default JanitorSite;
