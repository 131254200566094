import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidatenText } from '../../../hooks/useValidaten';
//import { useValidatenText } from '../../hooks/useValidaten';
import { ILeadSource } from '../../../interfaces/ILeadSource';
import { actionCreatorsSources } from '../../../redux/actionCreators';
import { State } from '../../../redux/combine_reducers';
import GenericEdit, { IInputValues, ISetValues } from '../../core/GenericEdit';
import InputField from '../../core/InputField';



interface IProps {
    idSource?: number;
}


const Source:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const sourceArray = useSelector((state: State) => state.sourceArray.storeSources);
    const { setSourceArray, addSourceArray, updateSourceArray } = bindActionCreators(actionCreatorsSources, dispatch)
    // Main Object
    const [unitObject,setUnitObject] = useState<ILeadSource | undefined>();
    // Haupstates
    const [idLeadSource,setIdLeadSource] = useState<number>();
    const [source,setSource,vSource] = useValidatenText();
    // Optionale States
    const [urlIdentifier,setUrlIdentifier] = useState<string | null>(null);
    // Validierung
    const [validationArray, setValidationArray] = useState<boolean[]>([]);
    // Metastatea
    const [isNew,setIsNew] = useState(false);
    const [isEmpty,setIsEmpty] = useState(true);


    useEffect(() => {
        if (props.idSource === undefined) {
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            
            if (props.idSource === -1) {
                setIsNew(true)
                resetValues();
            } else {
                setIsNew(false);
                setUnitObject(sourceArray.find(x => x.idSource === props.idSource));
            }
        }
    },[props.idSource])

    useEffect(() => {setValidationArray([vSource])},[vSource])

    const valuesArray:ISetValues[] = [
        {
            Attribute: "idSource",
            Value: idLeadSource,
            SetFunction: setIdLeadSource,
            IsPrimaryKey: true
        },
        {
            Attribute: "Source",
            Value: source,
            SetFunction: setSource,
        },
        {
            Attribute: "URL_Identifier",
            Value: urlIdentifier,
            SetFunction: setUrlIdentifier,
            PossibleUndefined: true,
            Type: 'string'
        }
       
    ];

    const inputField:IInputValues[] = [
        {
            InputElement: <InputField isEdit={!isEmpty} label='utm_source' value={source} setFunction={setSource} isValidated={vSource}/>,
        },
        {
            InputElement: <InputField isEdit={!isEmpty} label='URL Kennung' value={urlIdentifier} setFunction={setUrlIdentifier} />,
        }
        

    ]

    const resetValues = () => {
        valuesArray.filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey == false).map(x => (x.Type !== undefined && x.Type === "boolean") ? x.SetFunction(false) : x.SetFunction(null))
    }


    return(
        <>  
            <GenericEdit
                headlineNew="Neue utm_source"
                headlineAttr="Source"
                setValuesDef={valuesArray}
                inputFilds={inputField}
                // Zum Hochladen
                endpoint="source"
                // Hauptelemeten
                mainObject={unitObject}
                setMainObject={setUnitObject}
                // Zum Validieren
                validatenArray={validationArray}
                // Metastates
                isEmpty={isEmpty}
                isNew={isNew}
                updatedReduxArray={updateSourceArray}
                addReduxArray={ addSourceArray}
            />
        </>
    )

}
export default Source;