import { ICreditState } from "../../interfaces/ICreditState";

export const staticCreditStateArray:ICreditState[] = [
    {
        idCreditState: 1,
        CreditState: "Nicht geprüft"
    },
    {
        idCreditState: 10,
        CreditState: "Anfrage offen"
    },
    {
        idCreditState: 30,
        CreditState: "Bonität positiv"
    },
    {
        idCreditState: 50,
        CreditState: "Bonität negativ"
    }
]