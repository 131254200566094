import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { IRoomFullObject } from "../../../interfaces/IRoom";
import GridContainer from "../../core/GridContainer";
import GridItem from "../../core/GridItem";

interface IProps {
    roomObject: IRoomFullObject;
    setRoomObject: Function
}


const RoomAttributeEdit:React.FC<IProps> = (props) => {
    const handleInput = (event:React.ChangeEvent<HTMLInputElement>) => {
        let splitTest = event.target.name.split("-");
        let attrName = splitTest[1];
        let testVal = {...props.roomObject} as any

        if (attrName in testVal) {
            if (splitTest[0] === "number") {
                //props.setCurrentObject({...props.currentObject, attrName : Number(event.target.value)})
                testVal[attrName] = Number(event.target.value);
            } 
            else if (splitTest[0] === "boolean") {
                //props.setCurrentObject({...props.currentObject, attrName : String(event.target.value) === "true"})
                testVal[attrName] =  String(event.target.value) === "true";
            }
            else {
                console.log({...props.roomObject, attrName : String(event.target.value)})
                //props.setCurrentObject({...props.currentObject, attrName : String(event.target.value)});
                testVal[attrName] = String(event.target.value);
            }
        } else {
            console.warn(`Can't ${attrName} in Interface`)
        }

        props.setRoomObject({...testVal})
    }

    const checkString = (possibleString:string|null|undefined|number) => {
        if (possibleString == undefined) {
            return "";
        } else {
            return String(possibleString);
        }
    }


    return(
        <>
            <GridContainer>
                <GridItem sm={12}>
                    <TextField
                        select
                        label="Tageslicht"
                        name="boolean-hasDaylight"
                        size="small"
                        value={(props.roomObject.hasDaylight == true) ? "true" : "false"}
                        onChange={handleInput}
                        fullWidth
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField>
                </GridItem>
                <GridItem sm={12}>
                    <TextField
                        select
                        label="Starkstrom"
                        name="boolean-hasHighVoltageCurrent"
                        size="small"
                        value={(props.roomObject.hasHighVoltageCurrent == true) ? "true" : "false"}
                        onChange={handleInput}
                        fullWidth
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField>
                </GridItem>
                <GridItem sm={12}>
                    <TextField
                        select
                        label="Wasseranschluss"
                        name="boolean-hasWaterSupply"
                        size="small"
                        value={(props.roomObject.hasWaterSupply == true) ? "true" : "false"}
                        onChange={handleInput}
                        fullWidth
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField>
                </GridItem>
            </GridContainer>
        </>
    )

}
export default RoomAttributeEdit;