import { Check, Close } from '@mui/icons-material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../redux/combine_reducers';
import GenericOverview from '../core/GenericOverview';
import Unit from './Unit';

const Units:React.FC = () => {
    const dispatch = useDispatch();
    const unitsArray = useSelector((state: State) => state.unitsArray.storeUnits);
    // Selected Id
    const [selectedId,setSelectedId] = useState<number|undefined>();

    const columns: GridColDef[] = [
        { 
            field: 'idUnit', 
            headerName: 'Nr.', 
            width: 90,
        },
        {
            field: 'isTime',
            headerName: 'Zeitangabe',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => (params.row.isTime == true) ? "Ja" :  "Nein" ,
        },
        {
            field: 'Unit',
            headerName: 'Einheit',
            flex: 1,
        },

    ];

    return(
        <GenericOverview
            headline="Einheiten"
            columns={columns}
            data={unitsArray}
            idField="idUnit"
            setTargetId={setSelectedId}
        >
            <Unit idUnit={selectedId}/>
        </GenericOverview>
    )

}
export default Units;