import { Box, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFetch } from '../../hooks/useFetch';
import { useValidatenText, useValidationNumber } from '../../hooks/useValidaten';
import { IChildMetadata } from '../../interfaces/IChildMetadata';
import { ICompany } from '../../interfaces/ICompany';
import { actionCreatorsCompany } from '../../redux/actionCreators';
import { State } from '../../redux/combine_reducers';
import Address from '../Address/Address';
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import InputField from '../core/InputField';
import CompanyHasDocuments from './CompanyHasDocuments';
import { staticCreditStateArray } from './staticCreditStateArray';


export interface IProps {
    idCompany?: number | null;
    setIdCompany: Function;
    saveCounter: number;
    resetSaveCounter: number;
    setChildMetadata: Function;
    // Um im Parent zu visualisieren
    setIsBlocked?: Function;
    setIsCreditChecked?: Function;
}



const Company:React.FC<IProps> = (props) => { 
    const dispatch = useDispatch();
    const companyCategoryArray = useSelector((state: State) => state.companyCategoryArray.storeCompanyCategory);
    const companyArray = useSelector((state: State) => state.companyArray.storeCompany);
    const { addCompanyArray, updateCompanyArray } = bindActionCreators(actionCreatorsCompany,dispatch);
    // Hauptobject
    const [companyObject,setCompanyObject] = useState<ICompany | undefined>();
    // Hauptbalues
    const [idCompany, setIdCompany] = useState<number | null>(null);
    const [idAddress, setIdAddress] = useState<number | null>(null);
    const [idCompanyCategory,setIdCompanyCategory] = useState<number | null>(null);
    const [company,setCompany,vCompany] = useValidatenText();
    const [website,setWebsite] = useState<string | null>(null);
    const [phoneNumber,setPhoneNumber] = useState<string | null>(null);
    const [email,setEmail] = useState<string | null>(null);
    const [isBlocked,setIsBlocked] = useState(false);
    //const [isCreditChecked,setIsCreditChecked] = useState(false);
    const [idCreditState,setIdCreditState] = useState<number>(1);
    const [internComment,setInternComment] = useState<string | null>(null);
    const [taxNumber,setTaxNumber] = useState<string | null>(null);
    const [companyRegistrationNumber,setCompanyRegistrationNumber] = useState<string | null>(null);
    const [sales,setSales] = useState<number | null>(null);
    const [ranking,setRanking] = useState<string | null>(null);
    // Auswahlarray
    const creditStateArray = staticCreditStateArray;
    // VAlidungsstates
    const [isValidatedCompany, setIsValidatedCompany] = useState(false);
    const [isValidatedAddress,setIsValidatedAddress] = useState(false);
    const [isValidatedAll,setIsValidatedAll] = useState(false);
    // Savestates
    const [wasSavedCompany,setWasSavedCompany] = useState(false);
    const [wasSavedAddress,setWasSavedAddress] = useState(false);
    const [wasSavedAll,setWasSavedAll] = useState(false);
    // Save HEaltystaty
    const [wasSuccessfullySavedCompany,setWasSuccessfullySavedCompany] = useState(true);
    const [wasSuccessfullySavedAddress,setWasSuccessfullySavedAddress] = useState(true);
    const [wasSuccessfullySavedAll,setWasSuccessfullySavedAll] = useState(true);
    // Metastatea
    const [isNew,setIsNew] = useState(false);
    const [isEmpty,setIsEmpty] = useState(true);
    // Für Childs
    const [childMetadataAddress,setChildMetadataAddress] = useState<IChildMetadata>({} as IChildMetadata);


    useEffect(() => {
        if (props.saveCounter === 0 && !wasSavedAll && props.idCompany !== idCompany) {
            //(props.idCompany !== idCompany) && resetValues();
            if (props.idCompany === undefined || props.idCompany === null) {
                setIsEmpty(true);
                setIsNew(false);
            } else {
                setIsEmpty(false);
                
                if (props.idCompany === -1) {
                    setIsNew(true)
                    setIdAddress(-1);
                    resetValues();
                } else {
                    setIsNew(false);
                    setCompanyObject(companyArray.find(x => x.idCompany === props.idCompany));
                }
            }
        }



    },[props.idCompany,companyArray])

    useEffect(() => {
        if (companyObject != undefined && companyObject.idCompany !== idCompany) {
            setIdCompany(Number(companyObject.idCompany));
            setIdAddress(companyObject.idAddress);
            //setIdCompanyCategory(companyObject.idCompanyCategory);
            setCompany(companyObject.Company);
            setIsBlocked(companyObject.isBlocked);
            //setIsCreditChecked(companyObject.isCreditChecked);
            setIdCreditState(companyObject.idCreditState);
            
            (companyObject.idCompanyCategory != undefined) && setIdCompanyCategory(companyObject.idCompanyCategory);
            (companyObject.Website != undefined) && (setWebsite(companyObject.Website));
            (companyObject.PhoneNumber != undefined) && (setPhoneNumber(companyObject.PhoneNumber));
            (companyObject.Email != undefined) && (setEmail(companyObject.Email));
            (companyObject.InternComment != undefined) && (setInternComment(companyObject.InternComment));
            (companyObject.TaxNumber != undefined) && (setTaxNumber(companyObject.TaxNumber));
            (companyObject.CompanyRegistrationNumber != undefined) && (setCompanyRegistrationNumber(companyObject.CompanyRegistrationNumber));
            (companyObject.Sales != undefined) && (setSales(companyObject.Sales));
            (companyObject.Ranking != undefined) && (setRanking(companyObject.Ranking));

            (props.setIsBlocked !== undefined) && props.setIsBlocked(companyObject.isBlocked || companyObject.idCreditState === 50);
            (props.setIsCreditChecked !== undefined) && props.setIsCreditChecked(companyObject.idCreditState === 30 || companyObject.idCreditState === 50);
        }
    },[companyObject])

    useEffect(() => {
        if (companyObject != undefined && wasSavedAll) {
            if (isNew) {
                setIsNew(false);
                (props.idCompany !== Number(companyObject.idCompany)) && props.setIdCompany(Number(companyObject.idCompany));
                addCompanyArray(companyObject);
            } else {
                updateCompanyArray(companyObject);
            }
        }
    },[companyObject,wasSavedAll])

    useEffect(() => {
        setIsValidatedAddress(childMetadataAddress.isValidated);
        setWasSavedAddress(childMetadataAddress.wasSaved);
        setWasSuccessfullySavedAddress(childMetadataAddress.wasSuccessfullySaved);
    },[childMetadataAddress])

    useEffect(() => {
        setIsValidatedCompany(vCompany);
    },[vCompany])

    useEffect(() => {setIsValidatedAll(isValidatedAddress && isValidatedCompany)},[isValidatedAddress,isValidatedCompany]);
    useEffect(() => {setWasSavedAll(wasSavedAddress && wasSavedCompany)},[wasSavedAddress,wasSavedCompany])
    useEffect(() => {setWasSuccessfullySavedAll(wasSuccessfullySavedAddress && wasSuccessfullySavedCompany)},[wasSuccessfullySavedAddress,wasSuccessfullySavedCompany])

    useEffect(() => {
        props.setChildMetadata({
            idChild: "company",
            isValidated: isValidatedAll,
            wasSaved: wasSavedAll,
            wasSuccessfullySaved: wasSuccessfullySavedAll
        } as IChildMetadata)
    },[isValidatedAll,wasSavedAll,wasSuccessfullySavedAll])



    useEffect(() => {
        if (
            props.saveCounter > 0 
            && !wasSavedCompany 
            && idAddress != undefined 
            && idAddress > 0 
            && company != undefined
        ) {
            let tmpObject:ICompany = {
                idAddress: idAddress,
                //idCompanyCategory: idCompanyCategory,
                Company: company,
                isBlocked: Boolean(isBlocked),
                //isCreditChecked: Boolean(isCreditChecked)
                idCreditState: idCreditState
            };
            (idCompanyCategory != undefined) && (tmpObject.idCompanyCategory = idCompanyCategory);
            (website != undefined) && (tmpObject.Website = website);
            (phoneNumber != undefined) && (tmpObject.PhoneNumber = phoneNumber);
            (email != undefined) && (tmpObject.Email = email);
            (internComment != undefined) && (tmpObject.InternComment = internComment);
            (taxNumber != undefined) && (tmpObject.TaxNumber = taxNumber);
            (companyRegistrationNumber != undefined) && (tmpObject.CompanyRegistrationNumber = companyRegistrationNumber);
            (sales != undefined) && (tmpObject.Sales = sales);
            (ranking != undefined) && (tmpObject.Ranking = ranking);

            (!isNew && idCompany != undefined) && (tmpObject.idCompany = idCompany);
            uploadFetch("company",isNew,tmpObject,setCompanyObject,setWasSavedCompany);
            setWasSavedCompany(true);
        }
    },[props.saveCounter,idAddress])

    useEffect(() => {
        if (props.resetSaveCounter > 0) {
            setWasSavedCompany(false);
        }
    },[props.resetSaveCounter])

    //useEffect(() => {(props.setIsBlocked !== undefined) && props.setIsBlocked(isBlocked) },[isBlocked])
    //useEffect(() => {(props.setIsCreditChecked !== undefined) && props.setIsCreditChecked(isCreditChecked) },[isCreditChecked])



    const resetValues = () => {
        setCompanyObject(undefined);
        setIdCompany(null);
        setIdAddress(null);
        setIdCompanyCategory(null);
        setCompany(null);
        setIsBlocked(false);
        //setIsCreditChecked(false);
        setIdCreditState(1);
        setWebsite(null);
        setPhoneNumber(null);
        setEmail(null);
        setInternComment(null);
        setTaxNumber(null);
        setCompanyRegistrationNumber(null);
        setSales(null);
        setRanking(null);
    }


    return(
        <>
            <Box sx={{mt: 8}}></Box>
            <Typography variant='h5' gutterBottom>Firma</Typography>

            <GridContainer>
                    <GridItem sm={12}>
                        <InputField label='Firmenname' isEdit={!isEmpty} value={company} setFunction={setCompany} isValidated={vCompany || isEmpty}/>
                    </GridItem>

                    <GridItem sm={12}>
                        <TextField
                            select
                            disabled={isEmpty}
                            label="Business"
                            value={String(idCompanyCategory)}
                            onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdCompanyCategory(Number(event.target.value)) }
                            variant="standard"
                            fullWidth
                        >
                            { companyCategoryArray.map(x =>
                                <MenuItem value={x.idCompanyCategory}>
                                    {x.Category}
                                </MenuItem>
                            )}

                        </TextField>
                    </GridItem>

                    <GridItem sm={6}>
                        <TextField
                            select
                            disabled={isEmpty}
                            label="Blockiert"
                            value={isBlocked ? "yes" : "no"}
                            onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIsBlocked(event.target.value === "yes") }
                            variant="standard"
                            fullWidth
                        >
                            <MenuItem value="yes">Ja</MenuItem>
                            <MenuItem value="no">Nein</MenuItem>
                        </TextField>
                    </GridItem>

                    { /*
                    <GridItem sm={6}>
                        <TextField
                            select
                            disabled={isEmpty}
                            label="Bonität geprüft"
                            value={isCreditChecked ? "yes" : "no"}
                            onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIsCreditChecked(event.target.value === "yes") }
                            variant="standard"
                            fullWidth
                        >
                            <MenuItem value="yes">Ja</MenuItem>
                            <MenuItem value="no">Nein</MenuItem>
                        </TextField>
                    </GridItem>
                    */}

                    <GridItem sm={6}>
                        <TextField
                            select
                            disabled={isEmpty}
                            label="Bonitätsstatus"
                            value={String(idCreditState)}
                            onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIdCreditState(Number(event.target.value)) }
                            variant="standard"
                            fullWidth
                        >
                            { creditStateArray.map(x =>
                                <MenuItem value={x.idCreditState}>
                                    {x.CreditState}
                                </MenuItem>
                            )}

                        </TextField>
                    </GridItem>

                    <GridItem sm={6}>
                        <TextField
                            disabled={isEmpty}
                            value={sales}
                            onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setSales(Number(event.target.value))}
                            label="Umsatz"
                            fullWidth
                            type="number"
                            variant="standard"
                            InputProps ={{ endAdornment: <InputAdornment position="end">Mio.</InputAdornment>}}
                        />
                    </GridItem>

                    <GridItem sm={6}>
                        <InputField label='Ranking' isEdit={!isEmpty} value={ranking} setFunction={setRanking}/>
                    </GridItem>

                    <GridItem sm={6}>
                        <InputField label='StNr.' isEdit={!isEmpty} value={taxNumber} setFunction={setTaxNumber} />
                    </GridItem>
                    <GridItem sm={6}>
                        <InputField label='Handelsregisternummer' isEdit={!isEmpty} value={companyRegistrationNumber} setFunction={setCompanyRegistrationNumber} />
                    </GridItem>

                    <GridItem sm={6} xs={2}>
                        <InputField label='Email' isEdit={!isEmpty} value={email} setFunction={setEmail || isEmpty}/>
                    </GridItem>
                    <GridItem sm={6} xs={2}>
                        <InputField label='Internetpräsenz' isEdit={!isEmpty} value={website} setFunction={setWebsite || isEmpty}/>
                    </GridItem>
            </GridContainer>



            <Address
                 idAddress={idAddress}
                 setIdAddress={setIdAddress}
                 saveCounter={props.saveCounter}
                 resetSaveCounter={props.resetSaveCounter}
                 isEmpty={isEmpty}
                 setChildMetastateAddress={setChildMetadataAddress}
            />
            
            <Box sx={{mt: 8}}></Box>
            <CompanyHasDocuments
                isEmpty={isEmpty}
                isNew={isNew}
                saveCounter={props.saveCounter}
                idCompany={idCompany}
            />
        </>
    )

}
export default Company;