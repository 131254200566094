import { Alert, CircularProgress, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';


const WaitingSequence:React.FC = () => {

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >

                <Grid item xs={3}>
                    <CircularProgress size={300} style={{color: "#000000"}} />
                </Grid>   
            
            </Grid> 
        </>
    )
}
export default WaitingSequence;