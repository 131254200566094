import { Check, Close } from '@mui/icons-material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../redux/combine_reducers';
import GenericOverview from '../../core/GenericOverview';
import LeadState from './LeadState';

const LeadStates:React.FC = () => {
    const dispatch = useDispatch();
    const sourcesArray = useSelector((state: State) => state.leadStateArray.storeLeadState);
    // Selected Id
    const [selectedId,setSelectedId] = useState<number|undefined>();

    const columns: GridColDef[] = [
        { 
            field: 'idLeadState', 
            headerName: 'Nr.', 
            width: 90,
        },
        {
            field: 'State',
            headerName: 'Status',
            flex: 1,
        },

    ];
    //<LeadSource idLeadSource={selectedId}/>

    return(
        <GenericOverview
            headline="Lead Status"
            columns={columns}
            data={sourcesArray}
            idField="idLeadState"
            setTargetId={setSelectedId}
        >
            <LeadState idLeadState={selectedId} />
        </GenericOverview>
    )

}
export default LeadStates;