import { Alert, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, Snackbar, TextField, Typography } from "@mui/material";
import { IInvoice, IInvoiceFullObject } from "../../interfaces/IInvoice";
import { SetStateAction, useEffect, useState } from "react";
import { getFetch, uploadFetch, useFetch } from "../../hooks/useFetch";
import GridContainer from "../core/GridContainer";
import GridItem from "../core/GridItem";
import { useSelector } from "react-redux";
import { State } from "../../redux/combine_reducers";
import { IPerson } from "../../interfaces/IPerson";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowSelectionModel, GridRowParams, GridValueGetterParams, deDE } from "@mui/x-data-grid";
import { IInvoicePosition } from "../../interfaces/IInvoicePosition";
import { IInvoicePositionChoice } from "../../interfaces/IInvoicePositionChoice";
import { IInvoicePositionTemplate } from "../../interfaces/IInvoicePositionTemplate";
import { inovoiceStateArray } from "./staticInvoiceStateArray";
import { Add, ArrowDownward, Delete } from "@mui/icons-material";
import { IInoiceState } from "../../interfaces/IInoiceState";
import { IInvoiceStatus } from "../../interfaces/Additional/IInvoiceStatus";
import StripeStateOverview from "../StripeState/StripeStateOverview";
import RentState from "../Rent/RentState/RentState";
import { IRent } from "../../interfaces/IRent";
import WaitingSequence from "../core/WaitingSequence";
import { IRoomFullObject } from "../../interfaces/IRoom";
import RoomFullObject from "../Room/RoomFullObject";

interface IProps {
    targetId: number | any;
    setTargetId: Function;
    invoiceArray: IInvoice[];
    setInvoiceArray: Function;
}

const getEmptyInvoice = () => {
    return {
        idInvoice: -3,
        InvoiceceNumber: -3,
        idRoom: 2,
        idUser: -1,
        Total: -1,
        InvoiceDate: "",
        idPerson: -1,
        StripeId: "",
        CurrentElectricityMeter: -1,
        CurrentHighVoltageElectricityMeter: -1,
        idInvoiceState: 1,
        idRent: -1
    } as IInvoice;
}



const castToDeSting = (dateString: Date | string | undefined | null) => {
    if (dateString == undefined) {
        return "k.A."
    } else {
        let currentDateString = new Date(dateString);
        let yyyy = currentDateString.getFullYear();
        let mm: number | string = currentDateString.getMonth() + 1; // Months start at 0!
        let dd: number | string = currentDateString.getDate();

        if (dd < 10) dd = `0${dd}`
        if (mm < 10) mm = `0${mm}`

        return `${dd}.${mm}.${yyyy}`;
    }
}

const getCurrentInvoice = (
    targetId: number | null,
    invoiceArray: IInvoice[]
) => {
    if (targetId === null || targetId < 0) {
        return getEmptyInvoice();
    } else {
        let testObject = invoiceArray.find(x => x.idInvoice === targetId);

        if (testObject === undefined) {
            return getEmptyInvoice();
        } else {
            return testObject;
        }
    }
}


const InvoiceEdit: React.FC<IProps> = (props) => {

    const roomArray = useSelector((state: State) => state.roomArray.storeRoom);
    const rentArray = useSelector((state: State) => state.rentArray.storeRent);
    const locationArray = useSelector((state: State) => state.locationArray.storeLocation);
    const [personArray, setPersonArray, wasSuccessfullyLoad] = useFetch<IPerson[]>("person");

    const [invoicePositionChoiceArray, setInvoicePositionChoiceArray, wasSuccessfullInvoicePositionChoiceArray] = useFetch<IInvoicePositionChoice[]>("invoicePositionChoice");
    const [invoicePositionTemplateArray, setInvoicePositionTemplateArray, wasSuccessfullInvoicePositionTemplateArray] = useFetch<IInvoicePositionTemplate[]>("invoicePositionTemplate");
    const [invoiceArray, setinvoiceArray, wasSuccessfullinvoiceArray] = useFetch<IInvoice[]>("Invoice");

    const [stripeStateArray, setStripeStateArray] = useState<IInvoiceStatus[] | undefined>(undefined);

    const [unitsInput, setUnitsInput] = useState<number>(1);

    const [currentObject, setCurrentObject] = useState(getEmptyInvoice());
    const [originalLocalObject, setoriginalLocalObject] = useState(getEmptyInvoice());
    const [invoicePositionArray, setInvoicePositionArray] = useState<IInvoicePosition[]>([]);

    const [electricityMeterReadingBegin, setElectricityMeterReadingBegin] = useState<number | null>(null);
    const [electricityMeterReadingEnd, setElectricityMeterReadingEnd] = useState<number | null>(null);
    const [currentIndexRentArray, setcurrentIndexRentArray] = useState<number | null>(null);
    let [currentRentObject, setcurrentRentObject] = useState<IRent>();

    const [idInvoiceChoiceInput, setidInvoiceChoiceInput] = useState<number | any>(null);
    const [wasSuccessfullInvoiceFullObject, setWasSuccessfullInvoiceFullObject] = useState(true);

    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [wasSaved, setWasSaved] = useState(false);
    const [wasSavedSuccessfully, setWasSavedSuccessfully] = useState(true);

    const [title, setTitle] = useState(currentObject.InvoiceceNumber);
    const [idLocation, setidLocation] = useState<number>(1);
    const [idInvoicePositionTemplate, setidInvoicePositionTemplate] = useState<number>(1);
    const [idPerson, setidPerson] = useState<number>(1);
    const [idRoom, setidRoom] = useState<number>(1);
    const [open, setOpen] = useState(false);

    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

    // Warnungen / Fehler
    const [hasErrorNoAddress, setHasErrorNoAddress] = useState(false);


    //Get RoomObject 
    const [isLoadingFullObject, setIsLoadingFullObject] = useState(true);
    const [roomObject, setRoomObject] = useState<IRoomFullObject | undefined>();

    useEffect(() => {
        
        if (currentObject.idRoom !== null && currentObject.idRoom > 0) {
            setIsLoadingFullObject(true);
            getFetch("room/", currentObject.idRoom, (res: IRoomFullObject) => { setRoomObject(res); setIsLoadingFullObject(false) });
        }
    }, [currentObject.idRoom])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const wrapperSaveFirstPosition = (res: IInvoicePosition[]) => {
        setInvoicePositionArray([...res])
    }

    const setFunction = (res: IInvoiceFullObject) => {
        setCurrentObject(res);
        setoriginalLocalObject(res);
        setInvoicePositionArray(res.InvoicePositionArray);
        //
        let testObject = props.invoiceArray.find(x => x.idInvoice === res.idInvoice);

        if (testObject !== undefined) {
            props.setInvoiceArray([
                ...props.invoiceArray.map(x => x.idInvoice === res.idInvoice ? res: x)
            ])
        }
        else {
            props.setInvoiceArray([
                res,
                ...props.invoiceArray
            ])
        }

        //
        window.history.pushState({}, "", `/inovice/${res.idInvoice}`);

        if (props.targetId < 0 && res.idInvoice > 0) {
            props.setInvoiceArray([
                res,
                ...props.invoiceArray
            ]);
            props.setTargetId(res.idInvoice)
        }
        getFetch("invoice/invoiceStatus/", props.targetId, setStripeStateArray)
    }

    const saveFunction = () => {
        let uploadObject: IInvoiceFullObject = {
            ...currentObject,
            InvoicePositionArray: invoicePositionArray
        }
        uploadFetch("invoice/fullObject", props.targetId < 0, uploadObject, setFunction, setWasSavedSuccessfully, setIsLoadingSave, setWasSaved)
    }

    const handleRemovePosition = () => {
        setInvoicePositionArray([
            ...invoicePositionArray.filter(x => rowSelectionModel.indexOf(x.idInvoicePosition) === -1 )
        ])
        setRowSelectionModel([]);
    }

    /*
        useEffect(() => {
        console.log("kaka")
        console.log(wasSavedSuccessfully)
    }, [wasSavedSuccessfully])

    */


    useEffect(() => {
        if (props.targetId > 0) {
            getFetch("invoice/fullObject/", props.targetId, setFunction, setWasSuccessfullInvoiceFullObject)
            getFetch("invoice/invoiceStatus/", props.targetId, setStripeStateArray)
        }
    }, [props.targetId])

    //--------------------Abfrage durch Rent -------------------------------------

    //---------------------------------------------------------
    useEffect(() => {
        if (currentObject.idRoom > 0) {
            let testObject = roomArray.find(x => x.idRoom === currentObject.idRoom);
            (testObject !== undefined) && setidLocation(testObject.idLocation);
            /*
            if (props.targetId < 0) {
                getFetch("invoicePosition/firstPosition/", currentObject.idRoom, wrapperSaveFirstPosition);
            }
            */
        }
    }, [currentObject.idRoom])


    useEffect(() => {
        if (currentObject.idRent > 0 && props.targetId < 0) {
            getFetch("invoicePosition/firstPositions/", currentObject.idRent, wrapperSaveFirstPosition);
        }
    }, [currentObject.idRent])

    useEffect(() => {
        if (personArray != undefined) {
            if (currentObject.idPerson < 0) {
                setHasErrorNoAddress(false);
            } else {
                let testObject = personArray?.find(x => x.idPerson == currentObject.idPerson);
                setHasErrorNoAddress((testObject === undefined || testObject.idAddressCompany == undefined));
            }
        }

    }, [personArray, currentObject.idPerson])



    async function saveInvoicePositions() {
        let foundPosChoice = invoicePositionChoiceArray!.find(x => x.idInvoicePositionChoice === idInvoiceChoiceInput);

        if (foundPosChoice != undefined && invoicePositionArray != undefined) {
            let minId = -2;
            let localArray = invoicePositionArray.filter(x => x.idInvoice == Number(props.targetId));

            if (localArray.length > 0) {
                let minIdArray = Math.min(...localArray.map(x => x.idInvoicePosition));

                if (minIdArray <= minId) {
                    minId = minIdArray - 1;
                }
            }

            setInvoicePositionArray([
                ...invoicePositionArray,
                {
                    idInvoicePosition: minId,
                    idInvoice: props.targetId,
                    Units: unitsInput,
                    VAT: 0.19,
                    InvoicePosition: `${foundPosChoice.InvoicePositionTemplate}: ${foundPosChoice.InvoicePositionChoice}`,
                    PricePerUnit: foundPosChoice.PricePerUnit,
                    Unit: foundPosChoice.Unit,
                    idUnit: foundPosChoice.idUnit
                } as IInvoicePosition
            ])
        }
        setOpen(false);
    }


    const checkisAdminDisabled = (localObject: IInoiceState) => {

        if (currentObject == undefined || originalLocalObject == undefined) {
            return true;
        }
        // Immer disabled, wenn abgeschlossen
        else if (originalLocalObject.idInvoiceState >= 3) {
            return true;
        }
        else if (localStorage.getItem('isAdmin') == "0") {
            return true;
        }
        else if (localObject.idInvoiceState < Number(originalLocalObject?.idInvoiceState)) {
            return true;
        } else {
            return false;
        }
    }

    function getTotalPrice(params: GridValueGetterParams) {

        return (params.row.Units * params.row.PricePerUnit).toFixed(2).replace(".", ",") + " €";
    }

    const columns: GridColDef[] = [
        {
            field: 'InvoicePosition',
            headerName: 'Titel',
            flex: 1
        },
        {
            field: 'Units',
            headerName: 'Menge',
            width: 90
        },
        {
            field: 'Unit',
            headerName: 'Einheit',
            width: 60
        },
        {
            field: 'PricePerUnit',
            headerName: 'Einzelpreis',
            width: 120,
            renderCell: (params: GridRenderCellParams) => Number(params.row.PricePerUnit).toFixed(2).replace(".", ",") + " €",
        },
        {
            field: 'Total',
            headerName: 'Gesamt',
            width: 120,
            valueGetter: getTotalPrice,
        },

    ];

    const columnsWithEdit: GridColDef[] = [
        ...columns,
        {
            field: 'Aktionen',
            headerName: 'Aktionen',
            width: 70,
            renderCell: (params: GridRenderCellParams) =>
                <IconButton
                    onClick={() => {
                        if (invoicePositionArray != undefined) {
                            setInvoicePositionArray([
                                ...invoicePositionArray?.filter(x => x.idInvoicePosition != Number(params.row.idInvoicePosition))
                            ])
                        }

                    }}
                ><Delete /></IconButton>
        },
    ]


    const saveClose = () => {
        setWasSavedSuccessfully(true)
    }

    //Ladesequenzen / Erste Auswahlmöglichekeiten 
    if (!wasSuccessfullInvoiceFullObject || !wasSuccessfullyLoad || !wasSuccessfullInvoicePositionTemplateArray || !wasSuccessfullInvoicePositionChoiceArray) {
        return <>Fehler!</>
    }
    else if (props.targetId === null) {
        return <>Bitte wählen...</>
    }
    else if (isLoadingFullObject == true || roomObject == undefined || personArray == undefined || invoicePositionArray === undefined || invoicePositionChoiceArray === undefined || invoicePositionTemplateArray === undefined) {
        return <WaitingSequence />
    }
    else {
        return (
            <>

                {/*Throw Error if StripeID is null*/}
                <Snackbar
                    key="savebar"
                    open={!wasSavedSuccessfully}
                    autoHideDuration={6000}
                    onClose={() => saveClose()}
                    anchorOrigin={{ 'vertical': 'bottom', 'horizontal': 'right' }}
                >
                    <Alert onClose={() => saveClose()} severity={(!wasSavedSuccessfully) ? "error" : "success"} sx={{ width: '100%' }}>
                        {(!wasSavedSuccessfully) ? "Es ist ein Fehler aufgetretten!" : "Erfolgreich gespeichert!"}
                    </Alert>
                </Snackbar>


                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Neues Rechnungsobjekt</DialogTitle>
                    <DialogContent>
                        <GridContainer >
                            <GridItem sm={12} >
                                <TextField
                                    select
                                    sx={{ mt: 2 }}
                                    label="Kategorie"
                                    size="small"
                                    value={idInvoicePositionTemplate}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) =>
                                            setidInvoicePositionTemplate(Number(event.target.value))
                                    }
                                    fullWidth
                                >
                                    {invoicePositionTemplateArray.map(x =>
                                        <MenuItem key={`idInvoicePositionTemplate-${x.idInvoicePositionTemplate}`} value={x.idInvoicePositionTemplate}>
                                            {x.InvoicePositionTemplate}
                                        </MenuItem>
                                    )}
                                </TextField>
                            </GridItem>
                            <GridItem sm={12}>
                                <TextField
                                    select
                                    label="Gegenstand"
                                    size="small"
                                    fullWidth
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            setidInvoiceChoiceInput(Number(event.target.value))
                                        }

                                    }
                                >
                                    {invoicePositionChoiceArray
                                        .filter(x => x.idInvoicePositionTemplate === idInvoicePositionTemplate)
                                        .map(x =>
                                            <MenuItem key={`idInvoicePositionChoice-${x.idInvoicePositionChoice}`} value={x.idInvoicePositionChoice}>
                                                {x.InvoicePositionChoice} ({x.PricePerUnit.toFixed(2).replace(".", ",") + " €"})
                                            </MenuItem>
                                        )}

                                </TextField>
                            </GridItem>
                            <GridItem sm={12}>
                                <TextField
                                    id="outlined-number"
                                    label="Einheiten"
                                    type="number"
                                    size="small"
                                    fullWidth
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUnitsInput(Number(event.target.value))}
                                />
                            </GridItem>

                        </GridContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Abbruch</Button>
                        <Button onClick={saveInvoicePositions}>Hinzufügen</Button>
                    </DialogActions>
                </Dialog>


                <Typography variant='h5'>
                    {(props.targetId < 0) ? <>Neue Rechnung</> : <>Rechnung</>}
                    <div style={{ float: "right" }}>


                        <Button
                            style={{ marginLeft: 5 }}
                            variant="contained"
                            onClick={saveFunction}
                            disabled={
                                isLoadingSave
                                || currentObject.idPerson < 0
                                || originalLocalObject.idInvoiceState >= 3
                            }
                        >Speichern</Button>

                    </div>
                </Typography>

                <Collapse style={{ marginTop: 25 }} in={hasErrorNoAddress}>
                    <Alert
                        severity="error"
                        sx={{ mb: 2 }}
                    >
                        Für den gewählten Kunden liegt keine Adresse vor!
                    </Alert>
                </Collapse>

                <Collapse
                    style={{ marginTop: 25 }}
                    in={currentObject.StripeId != undefined && currentObject.idInvoiceState === 4 && originalLocalObject.idInvoiceState !== 4}
                >
                    <Alert
                        severity="warning"
                        sx={{ mb: 2 }}
                    >
                        Die Rechnung in Stripe wird ebenfalls storniert bzw. gelöscht (bei einem Entwurf).
                    </Alert>
                </Collapse>

                <Collapse
                    in={originalLocalObject.idInvoiceState >= 3}
                >
                    <Alert
                        severity="info"
                        sx={{ mb: 2 }}
                    >
                        Diese Rechnung ist bereits abgewickelt und kann nicht mehr bearbeitet werden.
                    </Alert>
                </Collapse>


                {(isLoadingSave) && <LinearProgress />}

                <Box sx={{ mt: (isLoadingSave) ? 1 : 2 }} />
                <GridContainer>
                    <GridItem sm={12}>
                        <TextField
                            select
                            label="Status"
                            size="small"
                            value={currentObject.idInvoiceState}
                            disabled={props.targetId < 0 || originalLocalObject.idInvoiceState >= 3}
                            onChange={
                                (event: React.ChangeEvent<HTMLInputElement>) =>
                                    setCurrentObject({
                                        ...currentObject,
                                        idInvoiceState: Number(event.target.value)
                                    })
                            }
                            fullWidth
                        >
                            {inovoiceStateArray.map(x =>
                                <MenuItem
                                    key={`idLocation-${x.idInvoiceState}`}
                                    value={x.idInvoiceState}
                                    disabled={checkisAdminDisabled(x)}
                                >
                                    {x.InvoiceState}
                                </MenuItem>
                            )}

                        </TextField>
                    </GridItem>
                    <GridItem sm={6}>
                        <TextField
                            select
                            label="Standort"
                            size="small"
                            value={idLocation}
                            disabled={props.targetId > 0}
                            onChange={
                                (event: React.ChangeEvent<HTMLInputElement>) => {
                                    setidLocation(Number(event.target.value));
                                    setCurrentObject({
                                        ...currentObject,
                                        idRoom: -1,
                                        idPerson: -1,
                                        idRent: -1
                                    })
                                }
                            }
                            fullWidth
                        >
                            {locationArray.map(x =>
                                <MenuItem key={`idLocation-${x.idLocation}`} value={x.idLocation}>
                                    {x.ShortName}
                                </MenuItem>
                            )}

                        </TextField>
                    </GridItem>
                    <GridItem sm={6}>
                        <TextField
                            select
                            label="Raum"
                            size="small"
                            fullWidth
                            disabled={props.targetId > 0}
                            error={currentObject.idRoom < 0}
                            value={(currentObject.idRoom < 0) ? "" : currentObject.idRoom}
                            onChange={
                                (event: React.ChangeEvent<HTMLInputElement>) =>
                                    {
                                        setCurrentObject({
                                            ...currentObject,
                                            idRoom: Number(event.target.value),
                                            idPerson: -1,
                                            idRent: -1
                                        });
                                        setInvoicePositionArray([]);
                                    }
                            }
                        >
                            {roomArray
                                .filter(x => x.idLocation === idLocation)
                                .map(x =>
                                    <MenuItem key={`idroom-${x.idRoom}`} value={x.idRoom}>
                                        {x.Title}
                                    </MenuItem>
                                )}

                        </TextField>
                    </GridItem>
                    <GridItem sm={12}>
                        <TextField
                            select
                            label="Person"
                            size="small"
                            fullWidth
                            disabled={props.targetId > 0}
                            error={currentObject.idPerson < 0}
                            value={(currentObject.idPerson) < 0 ? "" : currentObject.idPerson}
                            onChange={
                                (event: React.ChangeEvent<HTMLInputElement>) =>
                                    setCurrentObject({
                                        ...currentObject,
                                        idPerson: Number(event.target.value),
                                        idRent: -1
                                    })
                            }
                        >
                            {personArray
                                .filter(x => {
                                    let test = rentArray
                                        .filter(y => y.idRoom === currentObject.idRoom)
                                        .filter(y => y.idPerson === x.idPerson);
                                    return (test.length > 0)
                                })
                                .map(x =>
                                    <MenuItem key={`idPerson-${x.idPerson}`} value={x.idPerson}>
                                        {x.FirstName + " " + x.LastName}
                                    </MenuItem>
                                )}
                        </TextField>
                    </GridItem>
                    <GridItem sm={12}>
                        <TextField
                            select
                            label="Miete"
                            size="small"
                            fullWidth
                            disabled={props.targetId > 0}
                            error={currentObject.idRent < 0 || currentObject.idRent === null}
                            value={(currentObject.idRent) < 0 ? "" : currentObject.idRent}
                            onChange={
                                (event: React.ChangeEvent<HTMLInputElement>) =>
                                    setCurrentObject({
                                        ...currentObject,
                                        idRent: Number(event.target.value)
                                    })
                            }
                        >
                            {rentArray
                                .filter(x => x.idPerson === currentObject.idPerson)
                                .filter(x => x.idRentState >= 7)
                                .filter(x => x.idRoom === currentObject.idRoom)
                                .map(x =>
                                    <MenuItem key={`idRent-${x.idRent}`} value={x.idRent}>
                                        {x.RentNummer} ( {castToDeSting(x.FromDate)} - {castToDeSting(x.ToDate)} )
                                    </MenuItem>
                                )}
                        </TextField>
                    </GridItem>

                    <GridItem sm={(roomObject?.hasHighVoltageCurrent) ? 3 : 4}>
                        <TextField
                            label="Erstellt"
                            variant="standard"
                            value={currentObject.InvoiceDate}
                            fullWidth
                            size="small"
                            disabled
                        />
                    </GridItem>

                    <GridItem sm={(roomObject?.hasHighVoltageCurrent) ? 3 : 4}>
                        <TextField
                            label="Stromzähler bei Rechnungserfassung"
                            variant="standard"
                            value={(currentObject.CurrentElectricityMeter < 0) ? "" : currentObject.CurrentElectricityMeter}
                            fullWidth
                            size="small"
                            disabled
                        />
                    </GridItem>

                    {(roomObject?.hasHighVoltageCurrent) &&
                        <GridItem sm={3}>
                            <TextField
                                label="Starkstromzähler bei Rechnungserfassung"
                                variant="standard"
                                value={(currentObject.CurrentHighVoltageElectricityMeter < 0) ? "" : currentObject.CurrentHighVoltageElectricityMeter}
                                fullWidth
                                size="small"
                                disabled
                            />
                        </GridItem>
                    }

                    <GridItem sm={(roomObject?.hasHighVoltageCurrent) ? 3 : 4}>
                        <TextField
                            label="Stripe"
                            variant="standard"
                            value={currentObject.StripeId}
                            fullWidth
                            size="small"
                            disabled
                        />
                    </GridItem>


                    <GridItem sm={12}>
                        <StripeStateOverview
                            key={`stripe-${props.targetId}`}
                            stripeStateArray={stripeStateArray}
                        />
                    </GridItem>

                    {(originalLocalObject.idInvoiceState === 1) &&
                        <GridItem sm={12}>
                            <IconButton
                                sx={{ float: "right" }}
                                onClick={handleClickOpen}
                            ><Add /></IconButton>
                        </GridItem>
                    }

                    
                    <GridItem sm={12}>
                        <Button 
                            variant="outlined"
                            disabled={rowSelectionModel.length === 0}
                            onClick={handleRemovePosition}
                        >
                            <ArrowDownward/>Löschen
                        </Button>
                    </GridItem>
                    <GridItem sm={12}>
                        <DataGrid
                            autoHeight={true}
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            rows={invoicePositionArray.filter(x => x.idInvoice == props.targetId)}
                            columns={columns}
                            getRowId={(row) => row.idInvoicePosition}
                            isRowSelectable={(params: GridRowParams) => originalLocalObject.idInvoiceState === 1}
                            checkboxSelection
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </>
        )
    }
}
export default InvoiceEdit;