import { Grid } from '@mui/material';
import React from 'react';

interface IProps {
    sm?: number;
    xs?: number;
    children?: React.ReactNode
}


const GridItem:React.FC<IProps> = (props) => { 
    const default_sm = 6;
    const default_xs = 4;

    return(
        <Grid item sm={(props.sm === undefined) ? default_sm : props.sm } xs={(props.xs === undefined) ? default_xs : props.xs}>
            {props.children}
        </Grid>
    )
}
export default GridItem;