import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import TabPanal from "../core/TabPanal";
import LeadSourceStatistic from "./LeadSourceStatistic";
import MarketingStatistic from "./MarketingStatistic";
import Message from "./Message";
import Messages from "./Messages";
import SourceStatistic from "./SourceStatistic";
import StripeOverview from "./StripeOverview";


const Dashboard: React.FC = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Typography variant='h3' sx={{ mb: 5 }}>Dashboard</Typography>

            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Meldungen" />
                        <Tab label="Marketingstatistik" />
                        <Tab label="utm_source-Statistik" />
                        <Tab label="Lead-/Quellenstatistik" />
                        <Tab label="Zahlungswarnung" />
                    </Tabs>
                </Box>

                <TabPanal value={value} index={0}>
                    <Messages />
                </TabPanal>

                <TabPanal value={value} index={1}>
                    <MarketingStatistic />
                </TabPanal>

                <TabPanal value={value} index={2}>
                    <SourceStatistic />
                </TabPanal>

                <TabPanal value={value} index={3}>
                    <LeadSourceStatistic />
                </TabPanal>
                <TabPanal value={value} index={4}>
                    <StripeOverview />
                </TabPanal>
            </Box>
        </>
    )
}
export default Dashboard;