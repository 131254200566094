import { Button, IconButton, TextField } from '@mui/material';
import { DesktopDatePicker, DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useState } from 'react';
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import deLocale from "date-fns/locale/de";
import { getFetch, uploadFetch } from '../../hooks/useFetch';
import { IDocument } from '../../interfaces/IDocument';
import { FileUpload } from '@mui/icons-material';

interface ITestingPublicRent {
	RoomNumber: string;
	TimeFrame: 1 | 3 | 6 | 12;
	FromDate: Date | String;
    idLocation: string;

    TaxNumber: string;

	Email: string;
	FirstName: string;
	LastName: string;
	Postcode: string;
	Street: string;
	StreetNr: string;
    Gender: "m" | "d" | "w";

	Birthday:  Date | null;
	Birthplace: String | null;

	PhoneNumer: string | null;
	MobileNumber: string | null;

	Company: string | null;
	SreetAditional: string | null;
	Leve: string | null;

    noSend: any;
    Data: IDocument[];
}

const TestingPublicRent:React.FC = () => {
    // Ref für den Upload
    const fileUploadElement = React.useRef<HTMLInputElement>(null);

    const [currentObject,setCurrentObject] = useState<ITestingPublicRent>({
        idLocation: "1",
        RoomNumber: "1",
        TimeFrame :1,
        TaxNumber: "123",
        FromDate: new Date("2022-11-03"),
        Email: "daniel@kmiotek.de",
        FirstName: "Daniel",
        LastName: "Kmiotek",
        Gender: "w",
        Postcode: "12099",
        Street: "Waldburgstraße",
        StreetNr: "1",
        Birthday: new Date(),
        Birthplace: "Pankow",
        PhoneNumer: "07031/724486",
        MobileNumber: null,
        Company: "Test",
        SreetAditional: null,
        Leve: null,
        noSend: "a",
        Data: []
    } as ITestingPublicRent);

    const handleChane = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "token") {
            setToken(e.target.value);
        } else {
            setCurrentObject(currentObject => ({...currentObject, [e.target.name] : e.target.value  }))
        }
        
    }

    const saveObject = () => {
        uploadFetch(`rent/public/rent/${token}`,true,currentObject, () => {})
    }

    const [token,setToken] = useState("Ak1KQMqSZI0x2oomyupckL69");


    /// const: Tmp. Speichern der Datei
    const handleFileUpload = (e:React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files != undefined) {
            let uplodatedFile = e.target.files[0];

            if (uplodatedFile !== undefined && "name" in uplodatedFile && "type" in uplodatedFile) {
                let fileName:string = String(uplodatedFile["name"]);
                

                // Init FileRead
                let reader = new FileReader();
                reader.onload = (event:object) => {
                    // Caste als Base64
                    let base64WithMime = String(reader.result);

                    setCurrentObject({
                        ...currentObject,
                        Data: [
                            {
                                Data: base64WithMime,
                                FileName: fileName,
                                idDocumentType: 1,
                                idMIMEType: 1,
                                isPublic: true
                            }
                        ]
                    });
                    console.log(base64WithMime);
                }
                // Lade datei in Reader
                reader.readAsDataURL(uplodatedFile);
            }
            //setDocumentObj({...documentObj, Data: (e.target.files[0])});
        }
    };
    /// end: const



    return(
        <>
            <GridContainer>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                    <GridItem sm={6}>
                        <DesktopDatePicker
                            label="Von"
                            value={currentObject.FromDate}
                            onChange={(newValue: Date | null) => {(newValue !== null) && setCurrentObject(currentObject => ({...currentObject, ["FromDate"] : newValue })) }  }
                            //minutesStep={15}
                            //maxDate={toDateTime}
                            renderInput={(params) => <TextField margin="normal" variant="standard" {...params}  fullWidth />}
                        />
                    </GridItem>
                </LocalizationProvider>

                <GridItem>
                    <TextField
                        label="Token"
                        name="token"
                        value={token}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChane}
                    />
                </GridItem>

                <GridItem>
                    <TextField
                        label="Raumnumer"
                        name="RoomNumber"
                        value={currentObject.RoomNumber}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChane}
                    />
                </GridItem>
                <GridItem>
                    <TextField
                        label="Email"
                        name="Email"
                        value={currentObject.Email}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChane}
                    />
                </GridItem>

                <GridItem>
                    <TextField
                        label="FirstName"
                        name="FirstName"
                        value={currentObject.FirstName}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChane}
                    />
                </GridItem>
                <GridItem>
                    <TextField
                        label="LastName"
                        name="LastName"
                        value={currentObject.LastName}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChane}
                    />
                </GridItem>

                <GridItem>
                    <TextField
                        label="Postcode"
                        name="Postcode"
                        value={currentObject.Postcode}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChane}
                    />
                </GridItem>
                <GridItem>
                    <TextField
                        label="Postcode"
                        name="Postcode"
                        value={currentObject.Postcode}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChane}
                    />
                </GridItem>

                
            </GridContainer>

            <input type="file" onChange={handleFileUpload} hidden={true} ref={fileUploadElement}/>
            <IconButton size="small" style={{marginLeft: 5}}  onClick={() => fileUploadElement.current?.click()}><FileUpload/></IconButton>

            <Button  onClick={() => saveObject()}>Speichern</Button>
        </>
    )
}
export default TestingPublicRent;
