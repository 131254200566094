import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMedium } from "../interfaces/IMedium";


const initialState =  { storeMediums: [] as IMedium[] }; 

const sliceMedium = createSlice({
    name: "MEDIUMSARRAY",
    initialState,
    reducers: {
        setMediumArray: (state, action: PayloadAction<IMedium[]>) => {
            state.storeMediums = [...action.payload];
        },
        addMediumArray: (state, action: PayloadAction<IMedium>) => {
            state.storeMediums = [...state.storeMediums,action.payload];
        },
        updateMediumArray: (state, action: PayloadAction<IMedium>) => {
            let tmpArray =  state.storeMediums.map(x => x.idMedium === action.payload.idMedium ? action.payload : x);
            state.storeMediums = tmpArray;
        }
    }
})
export const { setMediumArray, addMediumArray, updateMediumArray
 } = sliceMedium.actions;
export default sliceMedium.reducer;