import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRoomType } from "../interfaces/IRoomType";

const initialState =  { storeRoomType: [] as IRoomType[] }; 

const sliceRoomType = createSlice({
    name: "ROOMTYPEARRAY",
    initialState,
    reducers: {
        setRoomTypeArray: (state, action: PayloadAction<IRoomType[]>) => {
            state.storeRoomType = [...action.payload];
        },
        addRoomTypeArray: (state, action: PayloadAction<IRoomType>) => {
            state.storeRoomType = [...state.storeRoomType,action.payload];
        },
        updateRoomTypeArray: (state, action: PayloadAction<IRoomType>) => {
            let tmpArray =  state.storeRoomType.map(x => x.idRoomType === action.payload.idRoomType ? action.payload : x);
            state.storeRoomType = tmpArray;
        }
    }
})
export const { setRoomTypeArray, addRoomTypeArray, updateRoomTypeArray
 } = sliceRoomType.actions;
export default sliceRoomType.reducer;