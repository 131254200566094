import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidatenText } from '../../hooks/useValidaten';
import { IUnit } from '../../interfaces/IUnit';
import { actionCreatorsUnits } from '../../redux/actionCreators';
import { State } from '../../redux/combine_reducers';
import GenericEdit, { IInputValues, ISetValues } from '../core/GenericEdit';
import InputField from '../core/InputField';



interface IProps {
    idUnit?: number;
}


const Unit:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const unitArray = useSelector((state: State) => state.unitsArray.storeUnits);
    const { setUnitArray, addUnitArray, updateUnitArray } = bindActionCreators(actionCreatorsUnits, dispatch)
    // Main Object
    const [unitObject,setUnitObject] = useState<IUnit | undefined>();
    // Haupstates
    const [idUnit,setIdUnit] = useState<number>();
    const [unit,setUnit,vUnit] = useValidatenText();
    const [isTime,setIsTime] = useState<boolean>(false);
    const [hourPerUnit,setHourPerUnit] =useState<number>()
    // Validierung
    const [validationArray, setValidationArray] = useState<boolean[]>([]);
    // Metastatea
    const [isNew,setIsNew] = useState(false);
    const [isEmpty,setIsEmpty] = useState(true);


    useEffect(() => {
        if (props.idUnit === undefined) {
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            
            if (props.idUnit === -1) {
                setIsNew(true)
                resetValues();
            } else {
                setIsNew(false);
                setUnitObject(unitArray.find(x => x.idUnit === props.idUnit));
            }
        }
    },[props.idUnit])

    useEffect(() => {setValidationArray([vUnit])},[vUnit])

    const valuesArray:ISetValues[] = [
        {
            Attribute: "idUnit",
            Value: idUnit,
            SetFunction: setIdUnit,
            IsPrimaryKey: true
        },
        {
            Attribute: "Unit",
            Value: unit,
            SetFunction: setUnit,
        }, 
        {
            Attribute: "isTime",
            Value: isTime,
            SetFunction: setIsTime,
            Type: 'boolean',
        }, 
        {
            Attribute: "HourPerUnit",
            Value: hourPerUnit,
            SetFunction: setHourPerUnit,
            PossibleUndefined: true,
            Type: 'number'
        }
    ];

    const inputField:IInputValues[] = [
        {
            InputElement: <InputField isEdit={!isEmpty} label='Einheit' value={unit} setFunction={setUnit} isValidated={vUnit}/>,
        },
        {
            InputElement:         <FormControl>
                                    <FormLabel>Zeitliche Einheit</FormLabel>
                                    <RadioGroup 
                                        row
                                        defaultValue="false" 
                                        value={(isTime) ? "true" : "false"} 
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setIsTime(event.target.value === "true") }
                                    >
                                        <FormControlLabel disabled={isEmpty} value="true" control={<Radio />} label="Ja" />
                                        <FormControlLabel disabled={isEmpty} value="false" control={<Radio />} label="Nein" />
                                    </RadioGroup>
                                </FormControl>
        }
        

    ]

    const resetValues = () => {
        valuesArray.filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey == false).map(x => (x.Type !== undefined && x.Type === "boolean") ? x.SetFunction(false) : x.SetFunction(null))
    }


    return(
        <>  
            <GenericEdit
                headlineNew="Neue Einheit"
                headlineAttr="Unit"
                setValuesDef={valuesArray}
                inputFilds={inputField}
                // Zum Hochladen
                endpoint="unit"
                // Hauptelemeten
                mainObject={unitObject}
                setMainObject={setUnitObject}
                // Zum Validieren
                validatenArray={validationArray}
                // Metastates
                isEmpty={isEmpty}
                isNew={isNew}
                updatedReduxArray={updateUnitArray}
                addReduxArray={ addUnitArray}
            />
        </>
    )

}
export default Unit;