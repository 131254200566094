import React, { useEffect, useState } from "react";
import { IHandoverProtocol } from "../../interfaces/IHandoverProtocol";
import { Box, Card, CardContent, CardHeader, Grid, MenuItem, TextField, Typography } from "@mui/material";
import SignatureCanvas from 'react-signature-canvas'
import { IDamage } from "../../interfaces/IDamage";
import { HandoverDamageOverview } from "./HandoverDamageOverview";
import { HandoverCard } from "./HandoverCard";
import { HandoverAssetsOverview } from "./HandoverAssetsOverview";
import { HandoverTransponderOverview } from "./HandoverTransponderOverview";


interface IProps {
    handoverObject: IHandoverProtocol;
    setHandoverObject: Function;
    damageArray: IDamage[];
    setDamageArray: Function;
}


export const HandoverEdit:React.FC<IProps> = (props) => {
    const [handoverObject, setHandoverObject] = useState(props.handoverObject);


    useEffect(() => {
        props.setHandoverObject(handoverObject)
    },[handoverObject])


    return(
        <>
            <HandoverCard title="Allgemeine Informationen">
                <Grid item sm={12}><TextField label="Tag der Übergabe" value="13.09.2023" fullWidth disabled/></Grid>
                <Grid item sm={6}>
                    <TextField
                        label="Kunde"
                        value={(handoverObject.idPerson === undefined) ? "" : handoverObject.idPerson }
                        disabled={handoverObject.idHandoverProtocol > 0}
                        error={handoverObject.idPerson === undefined}
                        fullWidth
                        select
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setHandoverObject({...handoverObject, idPerson: Number(event.target.value)})}
                    >
                        <MenuItem key={`idPerson-1`} value={1}>WorkBox</MenuItem>
                        <MenuItem key={`idPerson-2`} value={2}>BackAntz</MenuItem>
                        <MenuItem key={`idPerson-3`} value={3}>Testfirma GmbH</MenuItem>
                    </TextField>
                </Grid>

                <Grid item sm={6}>
                    <TextField
                        label="Miete"
                        value={(handoverObject.idRent < 0) ? "" : handoverObject.idRent }
                        disabled={handoverObject.idHandoverProtocol > 0}
                        error={handoverObject.idRent < 0}
                        fullWidth
                        select
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setHandoverObject({...handoverObject, idRent: Number(event.target.value)})}
                    >
                        <MenuItem key={`idRent-1`} value={1}>EBS0010005</MenuItem>
                        <MenuItem key={`idRent-2`} value={2}>EBS0110011</MenuItem>
                        <MenuItem key={`idRent-3`} value={2}>LMS2340001</MenuItem>
                    </TextField>
                </Grid>


                <Grid item sm={4}>
                    <TextField
                        label="Art"
                        value={(handoverObject.isAssumption) ? "true" : "false" }
                        disabled={handoverObject.idHandoverProtocol > 0}
                        fullWidth
                        select
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setHandoverObject({...handoverObject, isAssumption: (event.target.value === "true")})}
                    >
                        <MenuItem key={`isAssumption-true`} value="true">Annahme</MenuItem>
                        <MenuItem key={`isAssumption-false`} value="false">Abgabe</MenuItem>
                    </TextField>
                </Grid>
                <Grid item sm={8}>
                    <TextField
                        label="Stromzähler"
                        type="number"
                        value={handoverObject.ElectricityMeterReading}
                        disabled={handoverObject.idHandoverProtocol > 0}
                        fullWidth
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setHandoverObject({...handoverObject, ElectricityMeterReading: Number(event.target.value)})}
                    />
                </Grid>
            </HandoverCard>

            <HandoverCard title="Miete">
                <Grid item sm={4}><TextField label="Miete" variant="standard" value="EBS0010005" fullWidth disabled/></Grid>
                <Grid item sm={8}><TextField label="Mieter" variant="standard" value="Test GmbH, Ulrike Testfrau" fullWidth disabled/></Grid>
                
                <Grid item sm={12}><TextField label="Mietobjekt" variant="standard" value="Raum EBS001, Eresburgstr. 24 – 29, 12103 Berlin" fullWidth disabled/></Grid>

                <Grid item sm={4}><TextField label="Status" variant="standard" value="Vertrag" fullWidth disabled/></Grid>
                <Grid item sm={4}><TextField label="Von" variant="standard" value="10.02.2023" fullWidth disabled/></Grid>
                <Grid item sm={4}><TextField label="Bis" variant="standard" value="10.10.2023" fullWidth disabled/></Grid>


                <Grid item sm={6}><TextField label="Status: Kaution" variant="standard" value="Gezahlt" fullWidth disabled/></Grid>
                <Grid item sm={6}><TextField label="Status: Erste Miete" variant="standard" value="Offen" fullWidth disabled/></Grid>
                <Grid item sm={6}><TextField label="Kaution" variant="standard" value="1.234,04 €" fullWidth disabled/></Grid>
                <Grid item sm={6}><TextField label="Miete" variant="standard" value="515,78 €" fullWidth disabled/></Grid>
            </HandoverCard>

            <HandoverTransponderOverview />
            <HandoverDamageOverview
                damageArray={props.damageArray}
                setDamageArray={props.setDamageArray}
            />
            <HandoverAssetsOverview/>


            <HandoverCard title="Zusammenfassung">
                <Grid item sm={3}><TextField label="Transponder über-/zurückgegeben" value="Ja" fullWidth/></Grid>
                <Grid item sm={3}><TextField label="Vollständig Renoviert" value="Nein" fullWidth/></Grid>
                <Grid item sm={3}><TextField label="Schäden vorhanden" value="Ja" fullWidth/></Grid>
                <Grid item sm={3}><TextField label="Korrektur" value="Verrechnung über Kaution" fullWidth/></Grid>
            </HandoverCard>


            <HandoverCard title="Unterschift Mitarbeiter">
                <Grid item sm={12}>
                    
                    <Typography variant="body1" sx={{float: "right"}}>Berlin, den 13.09.2023</Typography>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            <Box sx={{m: 1, float: "center",backgroundColor: "#ededed",height: 300, width: 600}} >
                                <SignatureCanvas penColor="black"  canvasProps={{height: 300, width: 600}} />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={6}>
                    <TextField label="E-Mail-Adresse" variant="outlined" value="Mnustermann@workbox.berlin" fullWidth disabled/>
                </Grid>
                <Grid item sm={6}>
                    <TextField label="Vor- und Nachname" variant="outlined" value="Max Mustermann" fullWidth disabled/>
                </Grid>
            </HandoverCard>

            <HandoverCard title="Unterschift Mieter">
                <Grid item sm={12}>
                    
                    <Typography variant="body1" sx={{float: "right"}}>Berlin, den 13.09.2023</Typography>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            <Box sx={{m: 1, float: "center",backgroundColor: "#ededed",height: 300, width: 600}} >
                                <SignatureCanvas penColor="black"  canvasProps={{height: 300, width: 600}} />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={6}>
                    <TextField label="E-Mail-Adresse" variant="outlined" value="ulrike.testfrau@test.de" fullWidth/>
                </Grid>
                <Grid item sm={6}>
                    <TextField label="Vor- und Nachname" variant="outlined" value="Ulrike Testfrau" fullWidth />
                </Grid>
            </HandoverCard>

            <Box></Box>
        </>
    )
}