import { useSelect } from '@mui/base';
import { Box } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRent } from '../../interfaces/IRent';
import { State } from '../../redux/combine_reducers';
import GenericOverview from '../core/GenericOverview';

export interface IProps {
    rentArray: IRent[],
    setCurrentObject: Function;
}

const CustomerRentOverview:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const rentArray = useSelector((state: State) => state.rentArray.storeRent);


    const prepareDate = (dateAsString:string,withTime?:boolean) => {
        if (dateAsString == undefined){ 
            return "-"; 
        }
        else {
            let  currentDate = new Date(dateAsString);
            let dd = currentDate.getDate();
            let mm = currentDate.getMonth() +1;
            let yyyy = currentDate.getFullYear();
            let hh = currentDate.getUTCHours();
            let min = currentDate.getMinutes();


            
            if (withTime == undefined || withTime === false) {
                return `${(dd > 9 ? '' : '0') + dd}.${(mm > 9 ? '' : '0') + mm}.${yyyy}`
            } else {
                return currentDate.toLocaleString();
                //return `${(dd > 9 ? '' : '0') + dd}.${(mm > 9 ? '' : '0') + mm}.${yyyy}, ${(hh > 9 ? '' : '0') + hh}:${(min > 9 ? '' : '0') + min}`
            }
            
        }
    }

    const getTotal = (row: any) => {
        let units = Number(row.Units);
        let pricePerUnit = Number(row.PricePerUnit);
        return (units*pricePerUnit).toFixed(2).replace(".",",") + " €" 
    }

    const columnsPerson: GridColDef[] = [
        { 
            field: 'RentNummer', 
            headerName: 'Auftragsnummer', 
            minWidth: 150,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => (Number(params.row.idRentType) == 1) ? String(params.row.RequestNumber) : String(params.row.RentNummer)
        },       
        { 
            field: 'idRentType', 
            headerName: 'Art', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => params.row.RentType
        },
        { 
            field: 'idRentState', 
            headerName: 'Status', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => (Number(params.row.idRentType) == 1) ? "-" : params.row.RentState
        },
        { 
            field: 'ToDate', 
            headerName: 'Bis', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => prepareDate(params.row.ToDate)
        },
        { 
            field: 'Units', 
            headerName: 'Umsatz', 
            width: 100,
            valueGetter: (params: GridValueGetterParams) => getTotal(params.row)
        },
    ]


    return(
            <GenericOverview
                columns={columnsPerson}
                data={props.rentArray}
                idField="idRent"
                setTargetId={(idRent:number|string) => props.setCurrentObject(rentArray.find(x => x.idRent === Number(idRent)))}
                colDataGrid={12}
                enableAddButton={false}
                autoHeight={true}
            />

    )
}
export default CustomerRentOverview;