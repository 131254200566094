import { Alert, Box, Button, Slide, SlideProps, Snackbar, Typography } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFetch } from '../../hooks/useFetch';
import { useValidatenText } from '../../hooks/useValidaten';
import { IRegion } from '../../interfaces/IRegion';
import { actionCreatorsRegions } from '../../redux/actionCreators';
import { State } from '../../redux/combine_reducers';
import AlertSave from '../core/AlertSave';
import GenericEdit, { IInputValues, ISetValues } from '../core/GenericEdit';
import GridContainer from '../core/GridContainer';
import GridItem from '../core/GridItem';
import InputField from '../core/InputField';

interface IProps {
    idRegion?: number;
}

const Region:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const regionArray = useSelector((state: State) => state.regionArray.storeRegions);
    const { addRegionArray, updateRegionArray } = bindActionCreators(actionCreatorsRegions, dispatch);
    // Hauptobject
    const [regionObject,setRegionObject] = useState<IRegion | undefined>();
    // Haupstates
    const [idRegion,setIdRegion] = useState<number | null>(null);
    const [region,setRegion,vRegion] = useValidatenText();
    //const [shortName,setShortName,vShortName] = useValidatenText();
    // VAlidieerung
    const [validationArray,setValidationArray] = useState<boolean[]>([]);
    // Metastates
    const [isEmpty,setIsEmpty] = useState(true);
    const [isNew,setIsNew] = useState(false);
    const [wasSaved,setWasSaved] = useState(false);
    const [wasSuccessfullySaved,setWasSuccessfullySaved] = useState(true);

    useEffect(() => {
        if (props.idRegion === undefined) {
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            
            if (props.idRegion === -1) {
                setIsNew(true)
                resetValues();
            } else {
                setIsNew(false);
                setRegionObject(regionArray.find(x => x.idRegion === props.idRegion));
            }
        }
    },[props.idRegion])

    useEffect(() => {setValidationArray([vRegion])},[vRegion])

    const valuesArray:ISetValues[] = [
        {
            Attribute: "idRegion",
            Value: idRegion,
            SetFunction: setIdRegion,
            IsPrimaryKey: true
        },
        {
            Attribute: "Region",
            Value: region,
            SetFunction: setRegion
        }, 
        /*
        {
            Attribute: "ShortName",
            Value: shortName,
            SetFunction: setShortName
        }
        */
    ];

    const inputField:IInputValues[] = [
        {
            InputElement: <InputField isEdit={!isEmpty} label='Region' value={region} setFunction={setRegion} isValidated={vRegion}/>,
        },
        /*
        {
            InputElement: <InputField isEdit={!isEmpty} label='Kurzbezeichnung' value={shortName} setFunction={setShortName} isValidated={vShortName}/>
        }
        */
    ]

    const resetValues = () => {
        valuesArray.filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey == false).map(x => x.SetFunction(null))
    }


    return(
        <>  
            <GenericEdit
                headlineNew="Neue Region"
                headlineAttr="Region"
                setValuesDef={valuesArray}
                inputFilds={inputField}
                // Zum Hochladen
                endpoint="region"
                // Hauptelemeten
                mainObject={regionObject}
                setMainObject={setRegionObject}
                // Zum Validieren
                validatenArray={validationArray}
                // Metastates
                isEmpty={isEmpty}
                isNew={isNew}
                updatedReduxArray={updateRegionArray}
                addReduxArray={addRegionArray}
            />
        </>
    )

    /*
    const dispatch = useDispatch();
    const regionArray = useSelector((state: State) => state.regionArray.storeRegions);
    const { addRegionArray, updateRegionArray } = bindActionCreators(actionCreatorsRegions, dispatch);
    // Hauptobject
    const [regionObject,setRegionObject] = useState<IRegion | undefined>();
    // Validierungsstates
    const [isValidated,setIsValidated] = useState(false);
    // Haupstates
    const [idRegion,setIdRegion] = useState<number | null>(null);
    const [region,setRegion,vRegion] = useValidatenText();
    const [shortName,setShortName,vShortName] = useValidatenText();
    // Metastates
    const [isEmpty,setIsEmpty] = useState(true);
    const [isNew,setIsNew] = useState(false);
    const [wasSaved,setWasSaved] = useState(false);
    const [wasSuccessfullySaved,setWasSuccessfullySaved] = useState(true);

    useEffect(() => {
        if (props.idRegion === undefined) {
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            
            if (props.idRegion === -1) {
                setIsNew(true)
                resetValues();
            } else {
                setIsNew(false);
                setRegionObject(regionArray.find(x => x.idRegion === props.idRegion));
            }
        }
    },[props.idRegion])

    useEffect(() => {setValues(regionObject)},[regionObject])

    useEffect(() => {setIsValidated(vRegion && vShortName)},[vRegion,vShortName])

    function SlideTransition(props: SlideProps) {
        return <Slide {...props} direction="up" />;
      }

    const resetValues = () => {
        setIdRegion(null);
        setRegion(null);
        setShortName(null);
    }

    const setValues = (tmpObject: IRegion | undefined) => {
        if (tmpObject) {
            setIdRegion(Number(tmpObject.idRegion));
            setRegion(tmpObject.Region);
            setShortName(tmpObject.ShortName);

            if (wasSaved) {
                if (isNew) {
                    addRegionArray(tmpObject);
                    setIsNew(false);
                } else {
                    updateRegionArray(tmpObject);
                }
            }
        }
    };

    const saveObject = () => {
        if (region && shortName) {
            let tmpObject:IRegion = {
                Region: region,
                ShortName: shortName
                
            };
            (idRegion) && (tmpObject.idRegion = idRegion);
            uploadFetch("region",isNew,tmpObject,setRegionObject,setWasSuccessfullySaved);
            setWasSaved(true);
        }
    }


    return(
        <>
            <Snackbar 
                key="regionsaved" 
                open={wasSaved} 
                autoHideDuration={6000} 
                onClose={() => setWasSaved(false)}  
                anchorOrigin={{ 'vertical' : 'bottom', 'horizontal' : 'right' }} 
            >
                <Alert onClose={() => setWasSaved(false)} severity={(wasSuccessfullySaved) ? "success" : "error"} sx={{ width: '100%' }}>
                    {(wasSuccessfullySaved) ? "Erfolgreich gespeichert!" : "Es ist ein Fehler aufgetretten!"}
                </Alert>
            </Snackbar>

            <Typography variant='h5'>{(isEmpty) ? "Bitte Wählen" : (isNew) ? "Neue Region" : regionObject?.Region}</Typography>

            <Box sx={{mt: 3}}></Box>
            <GridContainer>
                <GridItem sm={12}>
                    <InputField isEdit={!isEmpty} label='Region' value={region} setFunction={setRegion} isValidated={vRegion}/>
                </GridItem>
                <GridItem sm={12}>
                    <InputField isEdit={!isEmpty} label='Kurzbezeichnung' value={shortName} setFunction={setShortName} isValidated={vShortName}/>
                </GridItem>
            </GridContainer>

            <Box sx={{mt: 10}} />
            <GridContainer>
                <GridItem>
                    <Button disabled={isEmpty || isNew} variant="outlined" color="error">Löschen</Button>
                </GridItem>
                <GridItem>
                    <div style={{float: 'right'}}>
                        <Button disabled={isEmpty || !isValidated} style={{marginLeft: 5}} variant="contained" onClick={() => saveObject()}>Speichern</Button>
                    </div>
                </GridItem>
            </GridContainer>
        </>
    )
    */

}
export default Region;