import { Close, Delete, Done, Edit, MoreVert } from '@mui/icons-material';
import { Alert, AppBar, Box, Button, Collapse, Dialog, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, ListItemIcon, ListItemText, Menu, MenuItem, Radio, RadioGroup, Slide, TableCell, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFetch } from '../../hooks/useFetch';
import { useValidatenText, useValidationNumber } from '../../hooks/useValidaten';
import { IChildMetadata } from '../../interfaces/IChildMetadata';
import { IProduct } from '../../interfaces/IProduct';
import { actionCreatorsProducts } from '../../redux/actionCreators';
import { State } from '../../redux/combine_reducers';
import AlertSave from '../core/AlertSave';
import GenericEdit, { IInputValues, ISetValues } from '../core/GenericEdit';
import InputField from '../core/InputField';
import ProductHasAssets from './ProductHasAssets/ProductHasAssets';
import ProductHasRoom from './ProductHasRooms/ProductHasRooms';

interface IProps {
    idProduct?: number;
}

const Product:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const productArray = useSelector((state: State) => state.productArray.storeProducts);
    const unitArray = useSelector((state: State) => state.unitsArray.storeUnits);
    // Setter
    const { addUpdateProductArray } = bindActionCreators(actionCreatorsProducts, dispatch);
    // Haupobject
    const [productObject,setProductObject] = useState<IProduct>();
    // Haupstates
    const [idProduct,setIdProduct] = useState<number>();
    const [pricePerUnit,setPricePerUnit] = useState<number | null>(null);
    const [idUnit,setIdUnit,vIdUnit] = useValidationNumber(1);
    const [title,setTitle,vTitle] = useValidatenText();
    const [descriptionCustomer,setDescriptionCustomer,vDescriptionCustomer] = useValidatenText();
    const [minSeqMeters, setMinSeqMeters] = useState<number | null>(null);
    const [maxSeqMeters, setMaxSeqMeters] = useState<number | null>(null);
    const [isEnabled,setIsEnabled] = useState(false);
    const [isPublic,setIsPublic] = useState(false);
    const [hasAllRooms,setHasAllRooms] = useState(true);
    // VAlidierung
    const [validationArray,setValidationArray] = useState<boolean[]>([]);
    const [isValidated,setIsValidated] = useState(false);
    const [isAllValidated,setIsAllValidated] = useState(false);
    // Metastates
    const [isEmpty,setIsEmpty] = useState(props.idProduct === undefined);
    const [isNew,setIsNew] = useState(props.idProduct === -1);
    const [isEdit,setIsEdit] = useState(true);
    const [wasSavedNew,setWasSavedNew] = useState(false);
    const [wasSavedParent,setWasSavedParent] = useState(false);
    const [wasSavedAll,setWasSavedAll] = useState(false);
    const [wasSuccessfullySavedParent,setWasSuccessfullySavedParent] = useState(true);
    const [wasSuccessfullySavedAll,setWasSuccessfullySavedAll] = useState(true);
    // Childs
    const [resetSaveCounter, setResetSaveCounter] = useState(0);
    const [childSaveCounter,setChildSaveCounter] = useState(0);
    const [childMetadataProductHasRooms,setChildMetadataProductHasRooms] = useState<IChildMetadata>();
    const [childMetadataProductHasAssets,setChildMetadataProductHasAssets] = useState<IChildMetadata>();

    useEffect(() => {
        resetValues();
        if (props.idProduct === undefined) {
            setIsEmpty(true);
            setIsNew(false);
        } else {
            setIsEmpty(false);
            
            if (props.idProduct === -1) {
                setIsNew(true)
                //resetValues();
            } else {
                setIsNew(false);
                setProductObject(productArray.find(x => x.idProduct === props.idProduct));
            }
        }
    },[props.idProduct])

    useEffect(() => {setValidationArray([vIdUnit,vTitle,vDescriptionCustomer])},[vIdUnit,vTitle,vDescriptionCustomer]);

    const valuesArray:ISetValues[] = [
        {
            Attribute: "idProduct",
            Value: idProduct,
            SetFunction: setIdProduct,
            IsPrimaryKey: true
        },
        {
            Attribute: "Title",
            Value: title,
            SetFunction: setTitle
        }, 
        {
            Attribute: "CustomerDescription",
            Value: descriptionCustomer,
            SetFunction: setDescriptionCustomer
        }, 
        {
            Attribute: "idUnit",
            Value: idUnit,
            SetFunction: setIdUnit
        },
        {
            Attribute: "isEnabled",
            Value: isEnabled,
            SetFunction: setIsEnabled
        },
        {
            Attribute: "isPublic",
            Value: isPublic,
            SetFunction: setIsPublic
        },
        {
            Attribute: "hasAllRooms",
            Value: hasAllRooms,
            SetFunction: setHasAllRooms
        },
        {
            Attribute: "PricePerUnit",
            Value: pricePerUnit,
            SetFunction: setPricePerUnit,
            PossibleUndefined: true,
            Type: 'number'

        },
        {
            Attribute: "MinSquareMeters",
            Value: minSeqMeters,
            SetFunction: setMinSeqMeters,
            PossibleUndefined: true,
            Type: 'number'
        },
        {
            Attribute: "MaxSquareMeters",
            Value: maxSeqMeters,
            SetFunction: setMaxSeqMeters,
            PossibleUndefined: true,
            Type: 'number'
        },
    ];

    const inputField:IInputValues[] = [
        {
            InputElement:   <InputField isEdit={!isEmpty} label='Bezeichnung' value={title} setFunction={setTitle} isValidated={isEmpty ||  vTitle}/>,
        },
        {
            ColSpanSm: 6,
            InputElement:   <TextField
                                select
                                disabled={isEmpty}
                                label="Aktiv"
                                value={(isEnabled) ? "yes" : "no"}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIsEnabled( (event.target.value) === "yes" ) }
                                variant="standard"
                                fullWidth
                            >
                                <MenuItem value="yes">Ja</MenuItem>
                                <MenuItem value="no">Nein</MenuItem>
                            </TextField>
        },
        {
            ColSpanSm: 6,
            InputElement:   <TextField
                                select
                                disabled={isEmpty}
                                label="Öffentlich"
                                value={(isPublic) ? "yes" : "no"}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setIsPublic( (event.target.value) === "yes" ) }
                                variant="standard"
                                fullWidth
                            >
                                <MenuItem value="yes">Ja</MenuItem>
                                <MenuItem value="no">Nein</MenuItem>
                            </TextField>
        },
        {
            InputElement:   <TextField
                                select
                                disabled={isEmpty}
                                label="Alle Räume"
                                value={(hasAllRooms) ? "yes" : "no"}
                                onChange={ (event:React.ChangeEvent<HTMLInputElement>) => setHasAllRooms( (event.target.value) === "yes" ) }
                                variant="standard"
                                fullWidth
                            >
                                <MenuItem value="yes">Ja</MenuItem>
                                <MenuItem value="no">Nein</MenuItem>
                            </TextField>
        },
        {   
            ColSpanSm: 6,
            InputElement:   <InputField isEdit={!isEmpty} label='Min. qm' value={minSeqMeters} setFunction={setMinSeqMeters}/>,
        },
        {   
            ColSpanSm: 6,
            InputElement:   <InputField isEdit={!isEmpty} label='Max. qm' value={maxSeqMeters} setFunction={setMaxSeqMeters}/>,
        },
    ]

    const resetValues = () => {
        valuesArray.filter(x => x.IsPrimaryKey === undefined || x.IsPrimaryKey == false).map(x => x.SetFunction(null))
    }

    return (
        <>
            <GenericEdit
                headlineNew="Neues Produkt"
                headlineAttr="Title"
                setValuesDef={valuesArray}
                inputFilds={inputField}
                // Zum Hochladen
                endpoint="product"
                // Hauptelemeten
                mainObject={productObject}
                setMainObject={setProductObject}
                // Zum Validieren
                validatenArray={validationArray}
                // Metastates
                isEmpty={isEmpty}
                isNew={isNew}
                addUpdatedReduxArray={addUpdateProductArray}
            />
        </>
    )
}
export default Product;