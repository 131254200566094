import { Add, AddCircle, AttachEmail, Delete, Person, PlusOne } from '@mui/icons-material';
import { Avatar, Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Drawer, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFetch, getFetch, uploadFetch, useFetch } from '../../../../hooks/useFetch';
import { IAppendix } from '../../../../interfaces/IAppendix';
import { IRentStateHasAppendix } from '../../../../interfaces/IRentStateHasAppendix';
import { State } from '../../../../redux/combine_reducers';



interface IProps {
    idRentState?: number | null;
    saveCounter?: number;
    isEmpty: boolean;
    isNew: boolean;
}


const RentStateHasAppendizes:React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const appendixArray = useSelector((state: State) => state.appendixArray.storeAppendix);
    const [rsHAppendixArray,setRsHAppendixArray] = useState<IRentStateHasAppendix[]>([]);
    // Tmp State#
    const [newAppendixArray,setNewAppendixArray] = useState<IAppendix[]>([]);
    // Metastates
    const [dialogIsNew, setDialogIsNew] = useState(false);
    const [idAppendixToRemove, setIdAppendixToRemove] = useState<number | null>(null);

    const addRsHasAppend = (newEl:IRentStateHasAppendix) => {
        if (rsHAppendixArray == undefined) {
            setRsHAppendixArray([newEl]);
        } else {
            setRsHAppendixArray([newEl,...rsHAppendixArray]);
        }
    }

    const removeRsHasAppendAbend = () => {
        if (props.idRentState != undefined && idAppendixToRemove !== null) {
            deleteFetch("rentStateHasAppendix",[props.idRentState,idAppendixToRemove],() => {});
            setRsHAppendixArray([...rsHAppendixArray.filter(x => x.idAppendix !== idAppendixToRemove)]);
            setIdAppendixToRemove(null);
        }
    }

    useEffect(() => {
       if (props.isNew || props.idRentState === -1) {
            setRsHAppendixArray([]);
       } else {
            getFetch("rentStateHasAppendix/getByRentState/",props.idRentState,setRsHAppendixArray)
       } 
        
    },[props.idRentState,props.isNew])

    useEffect(() => {
        if ( props.idRentState != undefined && props.saveCounter != undefined && props.saveCounter > 0 && newAppendixArray.length > 0) {
            //let newElemetns:IRentStateHasAppendix[] = [];
            newAppendixArray.map(x => {
                let uploadObject:IRentStateHasAppendix = {
                    idRentState: Number(props.idRentState),
                    idAppendix: Number(x.idAppendix),
                    AppendixTitle: x.Title
                }
                uploadFetch("rentStateHasAppendix",true,uploadObject,addRsHasAppend);
            })
            setNewAppendixArray([]);
        }
    },[props.saveCounter])


    return(
        <>
            <Dialog onClose={() => setDialogIsNew(false)} open={dialogIsNew}>
                <DialogTitle>Hinzufügen eines Anhangs</DialogTitle>
                <List sx={{ pt: 0 }}>
                    {appendixArray.map((x) => (
                        <ListItem 
                            button 
                            onClick={() => { setNewAppendixArray([x,...newAppendixArray]); setDialogIsNew(false); }} 
                            key={x.idAppendix}
                            disabled={ 
                                newAppendixArray.find(y => y.idAppendix === x.idAppendix) != undefined
                                || ( rsHAppendixArray != undefined &&  rsHAppendixArray.find(y => y.idAppendix === x.idAppendix) != undefined)
                            }
                        >
                            <ListItemAvatar><Avatar><AttachEmail/></Avatar></ListItemAvatar>
                            <ListItemText primary={x.Title} />
                        </ListItem>
                    ))}
                </List>
            </Dialog>

            <Dialog onClose={() => setIdAppendixToRemove(null)} open={idAppendixToRemove !== null}>
                <DialogTitle>Entfernen des Anhangs</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Soll der Anhang wirklich entfernt werden?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='error' onClick={() => removeRsHasAppendAbend()}>Ja</Button>
                    <Button variant='contained' color='success' onClick={() => setIdAppendixToRemove(null)}>Nein</Button>
                </DialogActions>
            </Dialog>


            <Typography sx={{ mt: 4, mb: 1 }}  component="div">
                Anhänge{(props.isNew) &&  " (nach dem Speichern verfügbar)"}:
                <div style={{float: 'right'}}>
                    <IconButton disabled={props.isEmpty || props.isNew} onClick={() => setDialogIsNew(true)}><AddCircle/></IconButton>
                </div>
            </Typography>

            <List>
                {(newAppendixArray != undefined) && newAppendixArray.map(x => 
                    <>
                        <Divider/>
                            <ListItem secondaryAction={ 
                                <IconButton 
                                    disabled={props.isEmpty} 
                                    onClick={() => setNewAppendixArray([...newAppendixArray.filter(y => y.idAppendix !== x.idAppendix)])} 
                                    edge="end"
                                >
                                    <Delete/>
                                </IconButton> 
                            }>
                                <ListItemAvatar><Avatar><AttachEmail/></Avatar></ListItemAvatar>
                                <ListItemText primary={x.Title}/>
                            </ListItem>
                        <Divider/>
                    </>
                )}
                {(rsHAppendixArray != undefined) && rsHAppendixArray.map(x => 
                    <>
                        <Divider/>
                            <ListItem secondaryAction={ 
                                <IconButton disabled={props.isEmpty} edge="end" onClick={() => setIdAppendixToRemove(x.idAppendix)}><Delete/></IconButton> 
                            }>
                                <ListItemAvatar><Avatar><AttachEmail/></Avatar></ListItemAvatar>
                                <ListItemText primary={(x.AppendixTitle != undefined) ? x.AppendixTitle : appendixArray.find(y => y.idAppendix === x.idAppendix)?.Title}/>
                            </ListItem>
                        <Divider/>
                    </>
                )}
            </List>


        </>
    )

    /*
    return(

        <>

            <List>
                <Divider/>
                    <ListItem secondaryAction={ <IconButton disabled={props.isEmpty} edge="end"><Delete/></IconButton> }>
                        <ListItemAvatar><Avatar><AttachEmail/></Avatar></ListItemAvatar>
                        <ListItemText primary="Test"/>
                    </ListItem>
                <Divider/>
            </List>

            <Drawer
                variant='persistent'
                anchor='right'
                open={true}
                sx={{
                    width: 200,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                      width: 200,
                    },
                  }}
            >


            <Card sx={{mt: 5}}>
                <CardContent>
                    Test
                    <div style={{float: 'right'}}>
                        <IconButton><Delete/></IconButton>
                    </div>
                </CardContent>
            </Card>
            </Drawer>
            Anhänge:
                    <div style={{float: 'right'}}>
                        <IconButton><Add/></IconButton>
                    </div>
            <Card sx={{mt: 5}}>
                <CardContent>
                    Test
                    <div style={{float: 'right'}}>
                        <IconButton><Delete/></IconButton>
                    </div>
                </CardContent>
            </Card>
        </>
    )
    */

}
export default RentStateHasAppendizes;