import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRentAutomation } from "../interfaces/IRentAutomation";

const initialState =  { storeRentAutomation: [] as IRentAutomation[] }; 

const sliceRentAutomation = createSlice({
    name: "REGIONARRAY",
    initialState,
    reducers: {
        setRentAutomationArray: (state, action: PayloadAction<IRentAutomation[]>) => {
            state.storeRentAutomation = [...action.payload];
        },
        addRentAutomationArray: (state, action: PayloadAction<IRentAutomation>) => {
            state.storeRentAutomation = [...state.storeRentAutomation,action.payload];
        },
        updateRentAutomationArray: (state, action: PayloadAction<IRentAutomation>) => {
            let tmpArray =  state.storeRentAutomation.map(x => x.idRentAutomation === action.payload.idRentAutomation ? action.payload : x);
            state.storeRentAutomation = tmpArray;
        }
    }
})
export const { setRentAutomationArray, addRentAutomationArray, updateRentAutomationArray } = sliceRentAutomation.actions;
export default sliceRentAutomation.reducer;