import { useState, useEffect } from "react";

export function useValidatenText(initalState?:string) : [string | null, Function, boolean] {
    const [value,setValue] = useState<string | null>((initalState === undefined) ? null : initalState  );
    const [isValidated,setIsValidated] = useState(true);

    useEffect(() => {
        if ( !value ||  value === "") {
            setIsValidated(false);
        } else {
            setIsValidated(true);
        }
    },[value])

    return [
        value,
        setValue,
        isValidated
    ]
}

export function useValidationNumber(initalState?:number) : [number | null, Function, boolean] {
    const [value,setValue] = useState<number | null>((initalState === undefined) ? null : initalState );
    const [isValidated,setIsValidated] = useState(true);

    useEffect(() => {
        if ( !value || value == undefined || Number.isNaN(value)) {
            setIsValidated(false);
        } else {
            setIsValidated(true);
        }
        console.log(value);
    },[value])

    return [
        value,
        setValue,
        isValidated
    ]
}