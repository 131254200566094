import React, { useState } from "react";
import GridContainer from "../core/GridContainer";
import GridItem from "../core/GridItem";
import { useFetch } from "../../hooks/useFetch";
import { IInvoicePositionTemplate } from "../../interfaces/IInvoicePositionTemplate";
import AlertError from "../core/AlertError";
import WaitingSequence from "../core/WaitingSequence";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import GenericOverview from "../core/GenericOverview";
import { Check, Close } from "@mui/icons-material";
import InovicePositionTemplateEdit from "./InovicePositionTemplateEdit";
import { IInvoicePositionChoice } from "../../interfaces/IInvoicePositionChoice";


const InovicePositionTemplateOverview:React.FC = () => {
    const [
        invoicePositionTemplateArray,
        setInvoicePositionTemplateArray,
        wasSuccessfullyInvoicePositionTemplateArray
    ] = useFetch<IInvoicePositionTemplate[]>("invoicePositionTemplate");
    const [
        invoicePositionChoiceArray,
        setInvoicePositionChoiceArray,
        wasSuccessfullyInvoicePositionChoiceArray
    ] = useFetch<IInvoicePositionChoice[]>("invoicePositionChoice");
    const [targetId, setTargetId] = useState<number|null>(null);


    const columns: GridColDef[] = [
        { 
            field: 'idInvoicePositionTemplate', 
            headerName: 'Nr.', 
            width: 90,
        },
        { 
            field: 'hasCustomeLogic', 
            headerName: 'Logik', 
            width: 90,
            renderCell: (params: GridRenderCellParams) => (Boolean(params.row.hasCustomeLogic) === true) ? <Check /> : <Close/>,
        },
        { 
            field: 'InvoicePositionTemplate', 
            headerName: 'Position', 
            flex: 1
        },
    ];


    if (!wasSuccessfullyInvoicePositionTemplateArray || !wasSuccessfullyInvoicePositionChoiceArray) { return <AlertError/> }
    else if (invoicePositionTemplateArray === undefined ||  invoicePositionChoiceArray === undefined) { return <WaitingSequence/> }
    else {
        return(
                <GenericOverview
                    headline="Rechnungspositionen"
                    columns={columns}
                    data={invoicePositionTemplateArray}
                    idField="idInvoicePositionTemplate"
                    setTargetId={setTargetId}
                    colDataGrid={6}
                >
                    <InovicePositionTemplateEdit
                        key={`InovicePositionTemplateEdit-${targetId}`}
                        targetId={targetId}
                        setTargetId={setTargetId}
                        invoicePositionTemplateArray={invoicePositionTemplateArray}
                        setInvoicePositionTemplateArray={setInvoicePositionTemplateArray}
                        invoicePositionChoiceArray={invoicePositionChoiceArray}
                        setInvoicePositionChoiceArray={setInvoicePositionChoiceArray}
                    />
                </GenericOverview>
        )
    }
}
export default InovicePositionTemplateOverview;