import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompany } from "../interfaces/ICompany";


const initialState =  { storeCompany: [] as ICompany[] }; 

const sliceCompany = createSlice({
    name: "COMPANYARRAY",
    initialState,
    reducers: {
        setCompanyArray: (state, action: PayloadAction<ICompany[]>) => {
            state.storeCompany = [...action.payload];
        },
        addCompanyArray: (state, action: PayloadAction<ICompany>) => {
            state.storeCompany = [...state.storeCompany,action.payload];
        },
        updateCompanyArray: (state, action: PayloadAction<ICompany>) => {
            let tmpArray =  state.storeCompany.map(x => x.idCompany === action.payload.idCompany ? action.payload : x);
            state.storeCompany = tmpArray;
        }
    }
})
export const { setCompanyArray, addCompanyArray, updateCompanyArray} = sliceCompany.actions;
export default sliceCompany.reducer;